export const peopleDetailFieldsSection1 = [
   {
      id: "views.company.workforce_planning.people.first_name",
      name: "first_name",
      type: "text",
      required: true,
   },
   {
      id: "views.company.workforce_planning.people.last_name",
      name: "last_name",
      type: "text",
      required: true,
   },
   {
      id: "views.company.workforce_planning.people.status",
      name: "status",
      type: "pill-select",
      required: true,
   },
   {
      id: "views.company.workforce_planning.people.groups",
      name: "groups",
      type: "multi-select",
      values: null,
      required: true,
   },
   {
      id: "views.company.workforce_planning.people.job_title",
      name: "job_title",
      type: "select",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.employee_id",
      name: "employee_number",
      type: "text",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.type",
      name: "type",
      type: "radio-button",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.permission_level",
      name: "permission",
      type: "select",
      values: null,
   },
];

export const peopleDetailFieldsSection2 = [
   {
      id: "views.company.workforce_planning.people.email_address",
      name: "email",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.phone_number",
      name: "phone",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.notification_profile",
      name: "notification_profile",
      type: "select",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.enable_email",
      name: "can_receive_email",
      type: "bool",
   },
   {
      id: "views.company.workforce_planning.people.enable_sms",
      name: "can_receive_sms",
      type: "bool",
   },
   {
      id: "views.company.workforce_planning.people.enable_mobile_notification",
      name: "can_receive_mobile",
      type: "bool",
   },
];

export const peopleDetailFieldsSection3 = [
   {
      id: "views.company.workforce_planning.people.address",
      name: "address_1",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.address_2",
      name: "address_2",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.city",
      name: "city_town",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.state",
      name: "state_province",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.postal",
      name: "zipcode",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.country",
      name: "country",
      type: "text",
   },
];

export const peopleDetailFieldsSection4 = [
   {
      id: "views.company.workforce_planning.people.hourly_wage",
      name: "hourly_wage",
      type: "currency",
   },
   {
      id: "views.company.workforce_planning.people.language",
      name: "language",
      type: "select",
   },
   {
      id: "views.company.workforce_planning.people.gender",
      name: "gender",
      type: "select",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.hired_date",
      name: "hired_date",
      type: "date",
      values: null,
   },
   {
      id: "views.company.workforce_planning.people.birth_date",
      name: "dob",
      type: "date",
      values: null,
   },
];

export const peopleDetailFieldsSection5 = [
   {
      id: "views.company.workforce_planning.people.emergency_contact_name",
      name: "emergency_contact_name",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.emergency_contact_phone",
      name: "emergency_contact_number",
      type: "text",
   },
   {
      id: "views.company.workforce_planning.people.emergency_contact_relation",
      name: "emergency_contact_relation",
      type: "text",
   },
];
export const peopleDetailsGroupOptionForAdminAccess = [
   { id: "1", label: "views.company.workforce_planning.people.groups_message_for_admin" },
];
