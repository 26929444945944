import { DateUtils } from "@/lib/utils/date";
import { timeOptions, timeZoneOptions } from "@/lib/utils/timezones";
import {
   PageTitle,
   type IntegratedField,
   type PillValue,
   type ProjectListServerFilter,
   type SelectValue,
   type SortModel,
} from "@/react/prop-types";
import {
   createColumnDefinitionsFromCustomHeaders,
   findCustomField,
} from "@/react/shared/custom-field-utils";
import {
   convertDataTableConfigToSavedView,
   getReorderedColumnDefinition,
   isFieldEditable,
   isIntegratedField,
   isSensitiveField,
} from "@/react/shared/helper";
import { Lock, Plus } from "@procore/core-icons";
import { Box, Button, Flex, FlexList, useI18nContext } from "@procore/core-react";
import type {
   ColumnDefinition,
   CurrencyCellColumnDefinition,
   DataTableCellRendererProps,
   DateCellColumnDefinition,
   MultiSelectCellColumnDefinition,
   PercentCellColumnDefinition,
   PillCellColumnDefinition,
   SelectCellColumnDefinition,
   ServerSideGetRowsParams,
   TextCellColumnDefinition,
} from "@procore/data-table";
import {
   CurrencyCellEditor,
   CurrencyCellRenderer,
   DateCellEditor,
   DateCellRenderer,
   MultiSelectCellRenderer,
   PercentCellEditor,
   PercentCellRenderer,
   PillCellRenderer,
   PillSelectCellEditor,
   SelectCellEditor,
   SelectCellRenderer,
   ServerSideDataTable,
   TextCellEditor,
   TextCellRenderer,
} from "@procore/data-table";
import { formatCurrency, formatPercentage } from "@procore/labs-financials-utils";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetIntegratedFields, useGetTagOptionsQuery } from "../common/queries/queries";
import {
   ColorOnlyEditor,
   ColorSelectRenderer,
} from "../data-table/ColorSelectComponent/ColorSelectColumn";
import { DateFilter } from "../data-table/custom-filters/date-filter";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { NumericValueFilter } from "../data-table/custom-filters/numeric-value-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import {
   CustomLinkCellRenderer,
   CustomLinkEditor,
} from "../data-table/custom-link-column/custom-link-cell";
import type { CustomMultiSelect } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import { CustomMultiSelectEditor } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import { onCreateProjectClick, useProjectTearsheet } from "../tearsheets/project/project-tearsheet";
import {
   columnHeadersMap,
   customFilterTokenText,
   defaultProjectListTableConfig,
   filterFieldMap,
   filterNameMaps,
   filterRendererMap,
   generateProjectRoleColumnDefinitions,
   getDateFilterTokenText,
   isFieldLocked,
   legacyFieldNamesMap,
} from "./helpers";
import type { ProjectListDataTableProps } from "./project-list-prop-types";
import "./style.css";
import { createProjectListReport } from "./queries";
import { authManager } from "@/lib/managers/auth-manager";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { ConfirmDeleteModal } from "@/react/shared/modals/confirm-delete-modal";
import { ProjectStore } from "@/stores/project-store.core";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import { BulkEditProjectTearsheet } from "../tearsheets/project/bulk-edit-project-tearsheet";
import { FieldMapping } from "./project-list-enums";
import type { CustomFieldType } from "@laborchart-modules/common";
import { SavedViewPage } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { useExportToastContext } from "../data-table/DataExport/FileDownload";
import type { ExportFormValues } from "../data-table/DataExport/export-modal";
import { ExportModal } from "../data-table/DataExport/export-modal";
import type { LinkToProcoreColumnDefinition } from "../data-table/LinkToProcore/LinkToProcore";
import { LinkToProcoreRenderer } from "../data-table/LinkToProcore/LinkToProcore";
import {
   addCustomFieldIDToStoredConfig,
   getFormattedGroupName,
} from "../data-table/data-table-utils";
import { CreateSavedViewModal } from "../modals/saved-view/create-saved-view-modal";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

const LOCAL_STORAGE_CONFIG_KEY = "projectListTableConfig";
const LOCAL_STORAGE_SAVED_VIEW_KEY = "projectListSavedViewConfig";

// Cell formatters for the DataTable Export

export const cellFormatters = {
   status(value: any) {
      return value ? value.label.toUpperCase() : "";
   },

   format_currency(value: any) {
      return value ? formatCurrency(value) : "";
   },

   percent_complete(value: any) {
      return value ? formatPercentage(value) : formatPercentage(0);
   },

   format_date(value: any) {
      return value ? DateUtils.getShortNumericDate(value) : "";
   },

   format_array(value: any) {
      return value ? value.map((item: any) => item.label).join(", ") : "";
   },

   format_object(value: any) {
      return value ? value.label : "";
   },
   /* istanbul ignore next */
   format_bool(value: boolean) {
      return value
         ? I18n.t("views.company.workforce_planning.boolean_values.yes_value")
         : I18n.t("views.company.workforce_planning.boolean_values.no_value");
   },
};

/* istanbul ignore next */
export const renderHeaderNode = (projectIntegratedFields: IntegratedField[], field: string) => {
   return isFieldLocked(projectIntegratedFields, field) ? (
      <Lock size="sm" data-testid="lock-icon" />
   ) : null;
};

/* istanbul ignore next */
export const ProjectListDataTable = (props: ProjectListDataTableProps) => {
   const I18n = useI18nContext();
   const {
      fetchProjectList,
      groupId,
      tableApi,
      handleTableReady,
      customFields,
      groupOptions,
      updateProject,
      savedView,
      roleOptions,
      isLastNameFirst,
   } = props;

   const { checkAuthAction } = usePermissionContext();
   const { dispatch: projectTearsheetDispatch } = useProjectTearsheet();
   const [customColumnDefinitions, setCustomColumnDefinitions] = useState<ColumnDefinition[]>([]);
   //refreshCustomFields is maintained to trigger customFields useEffect when component rerenders on some event like create tearsheet button clicked
   const [refreshCustomFields, setRefreshCustomFields] = useState(false);
   const { data: integratedFields, isLoading: integratedFieldsIsLoading } =
      useGetIntegratedFields();
   const { data: tags, isLoading: tagsIsLoading } = useGetTagOptionsQuery();

   const [projectSensitiveFields, setProjectSensitiveFields] = useState<string[]>([]);
   const [projectIntegratedFields, setProjectIntegratedFields] = useState<IntegratedField[]>([]);
   const [canCreateProject, setCanCreateProject] = useState(false);
   const [canEditProjectDetails, setCanEditProjectDetails] = useState(false);
   const [canDeleteProject, setCanDeleteProject] = useState(false);
   const [canViewProjectTags, setCanViewProjectTags] = useState(false);
   const [canEditProjectTags, setCanEditProjectTags] = useState(false);
   const [canViewProjectSensitiveFields, setCanViewProjectSensitiveFields] = useState(false);
   const [canEditProjectSensitiveFields, setCanEditProjectSensitiveFields] = useState(false);
   const [canViewProjectRoles, setCanViewProjectRoles] = useState(false);
   const [canViewProjectFinancials, setCanViewProjectFinancials] = useState(false);
   const [searchValue, setSearchValueString] = useState<string>();
   const { setVisible: showFileDownloadToast, addItems: addDownloadableReport } =
      useExportToastContext();

   // Next starting_after value for pagination
   const nextStartAfter = useRef(undefined);

   // reading tableConfig like this to avoid re-renders for server side filters
   // they kind of reset when re-rendered
   const cachedConfig =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)!) ?? defaultProjectListTableConfig;

   const tableConfig = savedView ?? cachedConfig;

   const serverFilters = tableConfig.serverFilters;

   /*unique key formed to pass it to DataTable Component for remounting of component when needed
   this is required if filters are applied and someone switches the group from navigation bar
   or when create tearsheet or project hyperlink is clicked on the table cell, it cause re-rendering so remounting
   of DataTable component is needed for persisting with filters*/
   const dataTableKey = groupId + "" + JSON.stringify(serverFilters);
   const resourcePlanningRebrand = LaunchDarklyClient.getFlagValue("resource-management-rebrand");
   useEffect(() => {
      const canCreateProject = checkAuthAction(AuthAction.CREATE_PROJECT);
      const canEditProjectDetails = checkAuthAction(AuthAction.EDIT_PROJECT_DETAILS);
      const canDeleteProject = checkAuthAction(AuthAction.DELETE_PROJECT);
      const canViewProjectTags = checkAuthAction(AuthAction.VIEW_PROJECT_TAGS);
      const canEditProjectTags = checkAuthAction(AuthAction.EDIT_PROJECT_TAGS);
      const canViewProjectRoles = checkAuthAction(AuthAction.VIEW_PROJECT_ROLES);
      setCanCreateProject(canCreateProject);
      setCanEditProjectDetails(canEditProjectDetails);
      setCanDeleteProject(canDeleteProject);
      setCanViewProjectTags(canViewProjectTags);
      setCanEditProjectTags(canEditProjectTags);
      setCanViewProjectRoles(canViewProjectRoles);

      const canViewProjectSensitiveFields = checkAuthAction(AuthAction.VIEW_PROJECT_SENSITIVE);
      setCanViewProjectSensitiveFields(canViewProjectSensitiveFields);

      const canEditProjectSensitiveFields = checkAuthAction(AuthAction.EDIT_PROJECT_SENSITIVE);
      setCanEditProjectSensitiveFields(canEditProjectSensitiveFields);

      setProjectSensitiveFields(authManager.projectsSensitiveFields());

      const canViewProjectFinancials = checkAuthAction(AuthAction.VIEW_PROJECT_FINANCIALS);
      setCanViewProjectFinancials(canViewProjectFinancials);
   }, [checkAuthAction]);

   //If the Group ID prop changes, fetch the project list again
   useEffect(() => {
      if (tableApi) {
         tableApi.refreshServerSide({});
      }
   }, [groupId]);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.projects_integrated_fields)) {
            setProjectIntegratedFields(integratedFields.data.projects_integrated_fields);
         }
      }
   }, [integratedFields]);

   const onTableConfigChange = useCallback(
      (config: any) => {
         const processedConfig = addCustomFieldIDToStoredConfig(config, customFields as any);
         if (!savedView) {
            localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(processedConfig));
         } else {
            localStorage.setItem(LOCAL_STORAGE_SAVED_VIEW_KEY, JSON.stringify(processedConfig));
         }
      },
      [savedView, customFields],
   );
   const onProjectListExport = React.useCallback(
      async (params: ExportFormValues) => {
         const report = await createProjectListReport({
            formValues: params,
            groupId: groupId,
            I18n: I18n,
            customFields: customFields,
            roleOptions: roleOptions,
            configKey: savedView ? LOCAL_STORAGE_SAVED_VIEW_KEY : LOCAL_STORAGE_CONFIG_KEY,
            isLastNameFirst: isLastNameFirst,
            search: searchValue?.trim() ?? null,
         });
         showFileDownloadToast(true);
         addDownloadableReport([report.data]);
      },
      [tableConfig],
   );

   const projectStatusFilterOptions = [
      {
         id: 1,
         value: "active",
         label: I18n.t("views.company.workforce_planning.active"),
         color: "green",
      },
      {
         id: 2,
         value: "inactive",
         label: I18n.t("views.company.workforce_planning.inactive"),
         color: "gray",
      },
      {
         id: 3,
         value: "pending",
         label: I18n.t("views.company.workforce_planning.pending"),
         color: "yellow",
      },
   ];

   // Map for maintaining the CSV formatter functions for each field type can be extended for other field types
   const csvFormatterMap: any = {
      date: cellFormatters.format_date,
      bool: cellFormatters.format_bool,
      currency: cellFormatters.format_currency,
   };

   // DataTable Column definitions
   /* istanbul ignore next */
   const projectNameColumn: ColumnDefinition = {
      field: "project_name",
      headerName: I18n.t("views.company.workforce_planning.projects.name"),
      cellRenderer: CustomLinkCellRenderer,
      cellRendererParams: {
         pageTitle: PageTitle.PROJECT_LIST,
         refreshCustomFields: () => setRefreshCustomFields(!refreshCustomFields),
         getProjectDetailDispatch: projectTearsheetDispatch,
      },
      getStringFormattedValue: (value) => {
         return value;
      },
      cellEditor: CustomLinkEditor,
      editable: false,
      hidden: false,
      lockVisible: true,
      bulkEditable: true,
      bulkEditEditor: "select",
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "project_name"),
      },
   };
   /* istanbul ignore next */
   const colorProjectListColumn: ColumnDefinition = {
      field: "color",
      headerName: I18n.t("views.company.workforce_planning.projects.color"),
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => {
            return item.color;
         },
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      cellEditor: ColorOnlyEditor,
      cellEditorParams: {
         getColor: (item: { color: string }) => item.color,
      },
      sortable: false,
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "color"),
      },
      cellCSVFormatter: (value) => {
         return value.color;
      },
      editable: () => {
         return canEditProjectDetails && !isIntegratedField("color", projectIntegratedFields);
      },
   };
   /* istanbul ignore next */
   const startDateColumn: DateCellColumnDefinition = {
      field: "start_date",
      headerName: I18n.t("views.company.workforce_planning.projects.start_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      cellCSVFormatter: cellFormatters.format_date,
      filterProps: {
         getFilterTokenText: (item: any) => getDateFilterTokenText(item),
      },
      filterRenderer: DateFilter,
      flex: 1,
      editable: () => {
         return canEditProjectDetails && !isIntegratedField("start_date", projectIntegratedFields);
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "start_date"),
      },
      minWidth: 150,
      valueValidator: (value) => {
         // Check if the start date is empty or not - value is an object with value and data properties
         if (!value.value) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.required_field"),
               isRequired: true,
            };
         }
         return { isValid: true, isRequired: true };
      },
   };
   /* istanbul ignore next */
   const estEndDateColumn: DateCellColumnDefinition = {
      field: "est_end_date",
      headerName: I18n.t("views.company.workforce_planning.projects.est_end_date"),
      cellRenderer: DateCellRenderer,
      cellEditor: DateCellEditor,
      cellCSVFormatter: cellFormatters.format_date,
      filterProps: {
         getFilterTokenText: (item: any) => getDateFilterTokenText(item),
      },
      filterRenderer: DateFilter,
      flex: 1,
      minWidth: 150,
      editable: () => {
         return (
            canEditProjectDetails && !isIntegratedField("est_end_date", projectIntegratedFields)
         );
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "est_end_date"),
      },
      valueValidator: (value) => {
         // Check if the end date is before the start date or not - value is an object with value and data properties
         if (value.data?.est_end_date?.getTime() < value.data?.start_date?.getTime()) {
            return {
               isValid: false,
               errorMessage: I18n.t(
                  "views.company.workforce_planning.validations.end_date_before_start_date",
               ),
               isRequired: true,
            };
         }
         return { isValid: true };
      },
   };
   /* istanbul ignore next */
   const projectHashColumn: TextCellColumnDefinition = {
      field: "project_hash",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.project_number"),
      flex: 1,
      editable: () => {
         return (
            canEditProjectDetails &&
            !isIntegratedField(legacyFieldNamesMap["project_hash"], projectIntegratedFields)
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "job_number"),
      },
   };
   /* istanbul ignore next */
   const addressOneColumn: TextCellColumnDefinition = {
      field: "address_1",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.address"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "address_1",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "address_1"),
      },
      ...isSensitiveField(
         "address_1",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const addressTwoColumn: TextCellColumnDefinition = {
      field: "address_2",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.address_2"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "address_2",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "address_2"),
      },
      ...isSensitiveField(
         "address_2",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const cityProjectListColumn: TextCellColumnDefinition = {
      cellRenderer: TextCellRenderer,
      field: "city_town",
      headerName: I18n.t("views.company.workforce_planning.projects.city"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "city_town",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "city_town"),
      },
      ...isSensitiveField(
         "city_town",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const stateProjectListColumn: TextCellColumnDefinition = {
      cellRenderer: TextCellRenderer,
      field: "state_province",
      headerName: I18n.t("views.company.workforce_planning.projects.state"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "state_province",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "state_province"),
      },
      ...isSensitiveField(
         "state_province",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const postalProjectListColumn: TextCellColumnDefinition = {
      cellRenderer: TextCellRenderer,
      field: "zipcode",
      headerName: I18n.t("views.company.workforce_planning.projects.postal"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "zipcode",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "zipcode"),
      },
      ...isSensitiveField(
         "zipcode",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const tagsProjectListColumn: ColumnDefinition = {
      field: "tag_instances",
      headerName: I18n.t("views.company.workforce_planning.projects.tags"),
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getShape: (item: { shape: string }) => (item.shape = "pill"),
         getObjectValue: (cellValue: any) => cellValue,
         getProjectDetailDispatch: projectTearsheetDispatch,
         pageTitle: PageTitle.PROJECT_LIST,
         isEditable: canEditProjectDetails && canEditProjectTags,
      },
      editable: false, // Inline editing is not allowed for tags, only through the tearsheet. User can click on the tag to open the tearsheet and edit the tags
      getStringFormattedValue: (item) => item?.map((tag: any) => tag.label).join(", "),
      filterRenderer: MultiSelectPillFilter as any,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.projects.tags"),
         getFilterOptions: async () => {
            return tags;
         },
         getFilterTokenText: (item: any) => {
            return `${I18n.t("views.company.workforce_planning.projects.tags")}: (${
               item.value.length
            })`;
         },
         getLabel: (item: any) => item.label,
      },
      cellCSVFormatter: cellFormatters.format_array,
      hidden:
         !canViewProjectTags ||
         tableConfig.columnState.find((col: any) => col.field === "tag_instances")?.hidden,
      lockVisible: !canViewProjectTags,
   };
   /* istanbul ignore next */
   const projectTypeProjectListColumn: TextCellColumnDefinition = {
      field: "project_type",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.type"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "project_type",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "project_type"),
      },
      ...isSensitiveField(
         "project_type",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const statusProjectListColumn: PillCellColumnDefinition = {
      field: "status",
      cellRenderer: PillCellRenderer,
      filterRenderer: StatusFilter,
      headerName: I18n.t("views.company.workforce_planning.projects.status"),
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.projects.status"),
         getFilterOptions: () => {
            return projectStatusFilterOptions as any;
         },
         getFilterTokenText: (item: any) => {
            // Prefix text for the status
            const text = I18n.t("views.company.workforce_planning.projects.status") + ": ";

            // Create an array of translated status values
            const status: string[] = item.value.map((v: any) => {
               return I18n.t("views.company.workforce_planning." + v.value);
            });

            // Join the translated statuses with a comma and return the complete text
            return text + status.join(" & ");
         },
         getLabel: (item: any) => item.label,
      },
      editable: () => {
         return canEditProjectDetails && !isIntegratedField("status", projectIntegratedFields);
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "status"),
      },
      cellEditor: PillSelectCellEditor,
      cellRendererParams: {
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      cellEditorParams: {
         selectOptions: () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(projectStatusFilterOptions);
               }, 500);
            }),
         getId: (item: PillValue<any>) => item.id,
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      getStringFormattedValue: (value: PillValue<any>) => {
         if (value === undefined) {
            return "";
         }
         return value.label;
      },
      cellCSVFormatter: cellFormatters.status,
      flex: 1,
   };
   /* istanbul ignore next */
   const groupsProjectListColumn: ColumnDefinition = {
      field: "groups",
      headerName: I18n.t("views.company.workforce_planning.projects.groups"),
      cellRenderer: MultiSelectCellRenderer,
      cellRendererParams: {
         getValue(cellValue: any) {
            return getFormattedGroupName(cellValue, groupOptions, I18n);
         },
         getId: (item: SelectValue<any>) => item?.label,
      },
      cellCSVFormatter: cellFormatters.format_array,
      sortable: false,
      editable: () => {
         return (
            canEditProjectDetails &&
            !isIntegratedField(legacyFieldNamesMap["groups"], projectIntegratedFields)
         );
      },
      cellEditor: CustomMultiSelectEditor,
      cellEditorParams: {
         options: groupOptions as CustomMultiSelect[],
         getOptions: (cellValue: any) => cellValue,
      },
      getStringFormattedValue: (item) => item?.map((group: any) => group.label).join(", "),
      valueValidator: (value) => {
         // Check if the groups are empty or not - value is an object with value and data properties
         const { value: val }: { value: any } = value;
         if (val.length === 0) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.required_field"),
               isRequired: true,
            };
         }
         return { isValid: true, isRequired: true };
      },
      flex: 2,
   };
   /* istanbul ignore next */
   const customerNameProjectListColumn: TextCellColumnDefinition = {
      field: "customer_name",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.customer"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "customer_name",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "customer_name"),
      },
      ...isSensitiveField(
         "customer_name",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   const createdByProjectListColumn: TextCellColumnDefinition = {
      field: "created_by",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.created_by"),
      sortable: false,
      flex: 1,
   };

   const createdAtProjectListColumn: DateCellColumnDefinition = {
      field: "created_at",
      headerName: I18n.t("views.company.workforce_planning.projects.created"),
      cellRenderer: DateCellRenderer,
      editable: false,
      cellCSVFormatter: cellFormatters.format_date,
      flex: 1,
   };
   /* istanbul ignore next */
   const countryProjectListColumn: TextCellColumnDefinition = {
      field: "country",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.country"),
      flex: 1,
      editable: () => {
         return isFieldEditable(
            "country",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "country"),
      },
      ...isSensitiveField(
         "country",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
   };
   /* istanbul ignore next */
   const startTimeColumn: SelectCellColumnDefinition = {
      field: "daily_start_time",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.daily_start_time"),
      editable: () => {
         return (
            canEditProjectDetails && !isIntegratedField("daily_start_time", projectIntegratedFields)
         );
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "daily_start"),
      },
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => {
            return timeOptions;
         },
         getId: (item: SelectValue<any>) => item?.label,
      },
      getStringFormattedValue: (value: SelectValue<any>) => {
         if (value === undefined) {
            return "--";
         }
         return value?.label;
      },
      flex: 1,
      cellCSVFormatter: cellFormatters.format_object,
   };
   /* istanbul ignore next */
   const endTimeColumn: SelectCellColumnDefinition = {
      field: "daily_end_time",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.daily_end_time"),
      editable: () => {
         return (
            canEditProjectDetails && !isIntegratedField("daily_end_time", projectIntegratedFields)
         );
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "daily_end"),
      },
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => {
            return timeOptions;
         },
         getId: (item: SelectValue<any>) => item?.label,
      },
      getStringFormattedValue: (value: SelectValue<any>) => {
         if (value === undefined) {
            return "--";
         }
         return value?.label;
      },
      flex: 1,
      cellCSVFormatter: cellFormatters.format_object,
   };
   /* istanbul ignore next */
   const timezoneColumn: SelectCellColumnDefinition = {
      field: "timezone",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.timezone"),
      editable: () => {
         return canEditProjectDetails && !isIntegratedField("timezone", projectIntegratedFields);
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "timezone"),
      },
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () => {
            return timeZoneOptions;
         },
         getId: (item: SelectValue<any>) => item?.label,
         onSearch: ({ event }) =>
            // Use the event data (optionally the row data) to implement the search functionality
            timeZoneOptions.filter((option) => option.label.includes(event.target.value)),
      },
      getStringFormattedValue: (value: SelectValue<any>) => {
         if (value === undefined) {
            return "--";
         }
         return value?.label;
      },
      flex: 1,
      cellCSVFormatter: cellFormatters.format_object,
   };
   /* istanbul ignore next */
   const estAvgRateColumn: CurrencyCellColumnDefinition = {
      field: "bid_rate",
      headerName: I18n.t("views.company.workforce_planning.projects.est_avg_rate"),
      cellRenderer: CurrencyCellRenderer,
      editable: () => {
         return canEditProjectDetails && !isIntegratedField("bid_rate", projectIntegratedFields);
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "bid_rate"),
      },
      rightAlign: true,
      cellCSVFormatter: cellFormatters.format_currency,
      ...(canViewProjectFinancials
         ? {
              filterRenderer: NumericValueFilter,
              filterProps: {
                 getFilterHeadingText: () =>
                    I18n.t("views.company.workforce_planning.projects.est_avg_rate"),
                 getFilterTokenText: (item: any) => {
                    return customFilterTokenText(item, "est_avg_rate", I18n);
                 },
              },
           }
         : {}),
      cellEditor: CurrencyCellEditor,
      flex: 1,
      valueValidator: (value) => {
         // Check if the bid rate is number or not - value is an object with value and data properties
         const { value: val } = value;
         if (isNaN(val)) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.numeric_field", {
                  field_name: I18n.t("views.company.workforce_planning.projects.est_avg_rate"),
               }),
            };
         }
         return { isValid: true };
      },
      hidden:
         !canViewProjectFinancials ||
         tableConfig.columnState.find((col: any) => col.field === "bid_rate")?.hidden, // Hide the column if the column type is currency and the user does not have permission to view financials
      lockVisible: !canViewProjectFinancials,
   };
   /* istanbul ignore next */
   const percentCompleteColumn: PercentCellColumnDefinition = {
      field: "percent_complete",
      headerName: I18n.t("views.company.workforce_planning.projects.percent_complete"),
      cellRenderer: PercentCellRenderer,
      editable: () => {
         return isFieldEditable(
            "percent_complete",
            canEditProjectDetails,
            projectIntegratedFields,
            projectSensitiveFields,
            canEditProjectSensitiveFields,
         );
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(projectIntegratedFields, "percent_complete"),
      },
      rightAlign: true,
      cellCSVFormatter: cellFormatters.percent_complete,
      cellEditor: PercentCellEditor,
      valueValidator: (value) => {
         // Check if the percent complete is number or not - value is an object with value and data properties
         const { value: val } = value;
         if (isNaN(val)) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.numeric_field", {
                  field_name: I18n.t("views.company.workforce_planning.projects.percent_complete"),
               }),
            };
         }
         return { isValid: true };
      },
      flex: 1,
      ...isSensitiveField(
         "percent_complete",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ),
      ...(!isSensitiveField(
         "percent_complete",
         projectSensitiveFields,
         canViewProjectSensitiveFields,
         tableConfig,
      ).lockVisible
         ? {
              filterRenderer: NumericValueFilter,
              filterProps: {
                 getFilterHeadingText: () =>
                    `${I18n.t(
                       "views.company.workforce_planning.projects.percent_complete",
                    )} (${"Between 0-100"})`,
                 getFilterTokenText: (item: any) => {
                    return customFilterTokenText(item, "percent_complete", I18n);
                 },
              },
           }
         : {}),
   };
   const wageOverridesColumn: MultiSelectCellColumnDefinition = {
      field: "wage_overrides",
      headerName: I18n.t("views.company.workforce_planning.projects.wage_overrides"),
      cellRenderer: MultiSelectCellRenderer,
      editable: false,
      cellCSVFormatter: cellFormatters.format_array,
   };

   const linkToProcoreColumn: LinkToProcoreColumnDefinition = {
      field: "procore_id",
      headerName: I18n.t("views.company.workforce_planning.projects.linked_to_procore", {
         defaultValue: "Linked to Procore Project",
      }),
      cellRenderer: LinkToProcoreRenderer,
      editable: false,
   };

   const projectColumnDefinitions: ColumnDefinition[] = [
      projectNameColumn,
      colorProjectListColumn,
      projectHashColumn,
      addressOneColumn,
      addressTwoColumn,
      cityProjectListColumn,
      stateProjectListColumn,
      postalProjectListColumn,
      tagsProjectListColumn,
      statusProjectListColumn,
      groupsProjectListColumn,
      estAvgRateColumn,
      percentCompleteColumn,
      wageOverridesColumn,
      startDateColumn,
      estEndDateColumn,
      projectTypeProjectListColumn,
      customerNameProjectListColumn,
      createdByProjectListColumn,
      createdAtProjectListColumn,
      countryProjectListColumn,
      startTimeColumn,
      endTimeColumn,
      timezoneColumn,
      linkToProcoreColumn,
   ].concat(
      canViewProjectRoles
         ? generateProjectRoleColumnDefinitions(groupId, roleOptions, tableConfig)
         : [],
   );
   useEffect(() => {
      if (customFields) {
         // calling function to generate column definitions from the Custom Headers with customFields, existing Config and csvFormatterMap
         const sensitiveFieldsDetails = {
            canViewSensitiveFields: canViewProjectSensitiveFields,
            canEditSensitiveFields: canEditProjectSensitiveFields,
            sensitiveFields: projectSensitiveFields,
         };

         const customColumnDefinitions = createColumnDefinitionsFromCustomHeaders(
            customFields,
            tableConfig,
            projectIntegratedFields,
            csvFormatterMap,
            sensitiveFieldsDetails,
            canViewProjectFinancials,
            canEditProjectDetails,
         );
         if (customColumnDefinitions.length > 0) {
            setCustomColumnDefinitions(customColumnDefinitions);
         }
      }
   }, [customFields, groupId, refreshCustomFields, canEditProjectDetails, projectIntegratedFields]);

   /* istanbul ignore next */
   const handleServerSideDataRequest = useCallback(
      async ({ request, onSuccess, onError }: ServerSideGetRowsParams) => {
         try {
            let startingAfter = undefined;
            // If requesting a second page of data, apply the starting_after query param
            if (request && request.startRow && request.startRow > 0) {
               startingAfter = nextStartAfter.current;
            }
            const sortModel: SortModel[] = request.sortModel;
            const serverFilter: ProjectListServerFilter[] = request.serverFilters;

            if (customFields) {
               // Map each field in sortModel to its corresponding fieldId if it exists in customFields
               sortModel.forEach((item) => {
                  const customField = findCustomField(customFields, item.field);
                  if (customField) {
                     item.fieldId = customField.id;
                  }
               });

               // Map each filter in serverFilter to its corresponding fieldId and fieldType if it exists in customFields
               serverFilter.forEach((filter) => {
                  const customField = findCustomField(customFields, filter.field);
                  if (customField) {
                     filter.fieldId = customField.id;
                     filter.fieldType = customField.type as CustomFieldType;
                  }
               });
            }
            setSearchValueString(request.searchValue);
            const data = await fetchProjectList(
               serverFilter,
               sortModel,
               startingAfter,
               request.searchValue.trim(),
            );
            onSuccess({ rowData: data.data, rowCount: data.pagination.total_possible });
            nextStartAfter.current = data.pagination.next_starting_after;
         } catch (err) {
            onError();
         }
      },
      [groupId, customFields],
   );
   /* istanbul ignore next */
   const Delete = (props: DataTableCellRendererProps) => {
      return (
         <ConfirmDeleteModal
            id={props.data.id}
            tableApi={tableApi}
            headerText={"views.company.workforce_planning.projects.modals.delete.header"}
            deleteText={
               resourcePlanningRebrand
                  ? "views.company.workforce_planning.projects.modals.delete.description_resource"
                  : "views.company.workforce_planning.projects.modals.delete.description"
            }
            successText={"views.company.workforce_planning.projects.modals.delete.success"}
            onDelete={ProjectStore.deleteProject}
         />
      );
   };
   //Reorder the columns based on the saved column state to maintain column order

   const reorderedProjectColumnDefinitions = useMemo(
      () =>
         getReorderedColumnDefinition(
            customColumnDefinitions
               ? projectColumnDefinitions.concat(customColumnDefinitions)
               : projectColumnDefinitions,
            tableConfig.columnState,
         ),
      [customColumnDefinitions, projectColumnDefinitions, tableConfig.columnState],
   );
   /* istanbul ignore next */
   return (
      (!integratedFieldsIsLoading && !tagsIsLoading && (
         <ServerSideDataTable
            columnDefinitions={reorderedProjectColumnDefinitions}
            onServerSideDataRequest={handleServerSideDataRequest}
            getRowId={(params) => params.data.id}
            onTableConfigChange={onTableConfigChange}
            initialTableConfig={tableConfig}
            enableDynamicRowHeight
            key={dataTableKey}
         >
            <Flex
               style={{
                  height: "100%",
                  width: "100%",
               }}
               alignItems="stretch"
            >
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.FiltersPanel
                     style={{
                        marginRight: "16px",
                     }}
                  />
               </Flex>

               <Box flex="1" display="flex-column" alignItems="stretch">
                  {/* @ts-expect-error Quick Controls need to optionally accept children*/}
                  <ServerSideDataTable.QuickControls>
                     <Flex>
                        <FlexList size="xs" alignItems="center">
                           <ServerSideDataTable.Search
                              placeholder={I18n.t(
                                 "views.company.workforce_planning.projects.search_placeholder",
                              )}
                           />
                           <ServerSideDataTable.FiltersPanelButton />
                           <ServerSideDataTable.QuickFilters />
                        </FlexList>
                        <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
                           {canCreateProject && (
                              <Button
                                 onClick={() => {
                                    onCreateProjectClick(projectTearsheetDispatch);
                                    setRefreshCustomFields(!refreshCustomFields);
                                 }}
                                 icon={<Plus />}
                              >
                                 {I18n.t("views.company.workforce_planning.create")}
                              </Button>
                           )}
                           <ExportModal onExport={onProjectListExport} />
                           <CreateSavedViewModal
                              configKey={
                                 savedView ? LOCAL_STORAGE_SAVED_VIEW_KEY : LOCAL_STORAGE_CONFIG_KEY
                              }
                              tableApi={tableApi}
                              conversionFunction={convertDataTableConfigToSavedView}
                              page={SavedViewPage.PROJECTS}
                              customFields={customFields ?? []}
                              columnHeadersMap={columnHeadersMap}
                              filterNameMaps={filterNameMaps}
                              filterFieldMap={filterFieldMap}
                              filterRendererMap={filterRendererMap}
                              searchString={searchValue}
                              groupId={groupId}
                              groupOptions={groupOptions ?? []}
                           />
                           <ServerSideDataTable.ConfigPanelButton />
                        </FlexList>
                     </Flex>
                  </ServerSideDataTable.QuickControls>
                  <ServerSideDataTable.BulkActions>
                     {canEditProjectDetails && (
                        <BulkEditProjectTearsheet
                           tableApi={tableApi}
                           integratedFields={integratedFields as GetIntegratedFieldsResponse}
                           customFields={customFields ?? []}
                        />
                     )}
                     {canDeleteProject && (
                        <ConfirmDeleteModal
                           isBulk
                           tableApi={tableApi}
                           headerText={
                              "views.company.workforce_planning.projects.modals.delete.header"
                           }
                           deleteText={
                              resourcePlanningRebrand
                                 ? "views.company.workforce_planning.projects.modals.delete.description_resource"
                                 : "views.company.workforce_planning.projects.modals.delete.description"
                           }
                           successText={
                              "views.company.workforce_planning.projects.modals.delete.success"
                           }
                           onDelete={ProjectStore.batchDelete}
                        />
                     )}
                  </ServerSideDataTable.BulkActions>
                  <ServerSideDataTable.Table
                     onTableReady={handleTableReady}
                     paginationPageSize={100}
                     rowActions={canDeleteProject ? [Delete] : []}
                     rowSelectionEnabled={canEditProjectDetails || canDeleteProject}
                     selectionSSREnabled={canEditProjectDetails || canDeleteProject}
                     onCellValueChanged={(params) => {
                        if (params.newValue == null) return;
                        // Do not update end date if it is before start date
                        const startDate = params.newValue?.start_date || params.rowData.start_date;
                        const estEndDate =
                           params.newValue?.est_end_date || params.rowData.est_end_date;
                        if (estEndDate?.getTime() < startDate?.getTime()) return;

                        // Make sure the field is in the FieldMapping
                        if (params.field in FieldMapping) {
                           params.field = FieldMapping[params.field as keyof typeof FieldMapping];
                        }
                        // Do not update if group is not selected
                        if (params.field === FieldMapping.groups && params.newValue.length === 0)
                           return;
                        if (params.field === FieldMapping.bid_rate && isNaN(params.newValue))
                           return;
                        if (
                           params.field === FieldMapping.percent_complete &&
                           isNaN(params.newValue)
                        )
                           return;
                        if (params.field === FieldMapping.postal && isNaN(params.newValue)) return;

                        params.rowData[params.field] = params.newValue;
                        updateProject(params);
                     }}
                  />
               </Box>
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
               </Flex>
            </Flex>
         </ServerSideDataTable>
      )) ||
      null
   );
};
