import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type { UploadFilesResponse } from "./data-upload-store";

export abstract class DataUploadStore extends Store {
   constructor() {
      super();
   }

   static uploadFiles(dataUpload: FormData): StoreJsonResponse<UploadFilesResponse> {
      return this.requestJson({
         method: "POST",
         body: dataUpload,
         url: `/api/v3/data-upload`,
      });
   }
}
