import React from "react";
import "@bryntum/taskboard-thin/taskboard.stockholm.css";
import type { BryntumTaskBoardProps } from "@bryntum/taskboard-react-thin";
import { BryntumTaskBoard } from "@bryntum/taskboard-react-thin";

const taskBoardProps: BryntumTaskBoardProps = {};

export function BoardsContainer() {
   return (
      <>
         <BryntumTaskBoard {...taskBoardProps} />
         <BryntumTaskBoard {...taskBoardProps} />
      </>
   );
}
