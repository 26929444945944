import type { ComponentProps } from "react";
import React from "react";
import { createRoot } from "react-dom/client";
import { I18nProvider } from "@procore/core-react";
import components from "@/react/index";
import { ToastAlertProvider } from "@procore/toast-alert";
import { GroupContextProvider } from "./providers/group-context-provider";
import { PermissionContextProvider } from "./providers/permission-context-provider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
type ComponentName = keyof typeof components;

const queryClient = new QueryClient();

export default <TName extends ComponentName>(
   mountId: string,
   componentName: TName,
   props: ComponentProps<(typeof components)[TName]>,
) => {
   const component = components[componentName] ?? components.NotFound;
   const container = document.getElementById(mountId);
   const root = createRoot(container!);
   root.render(
      <QueryClientProvider client={queryClient}>
         <I18nProvider locale={window.I18n.locale as any} translations={window.I18n.translations}>
            <ToastAlertProvider>
               <GroupContextProvider>
                  <PermissionContextProvider>
                     <div className="react-container">
                        {React.createElement<any>(component, props)}
                     </div>
                  </PermissionContextProvider>
               </GroupContextProvider>
            </ToastAlertProvider>
         </I18nProvider>
      </QueryClientProvider>,
   );
   return root;
};
