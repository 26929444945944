import React, { useMemo, useState } from "react";
import { Box, Link, Pill } from "@procore/core-react";
import { Download } from "@procore/core-icons";
import { ActionMode, type AttachmentLinkProps } from "@/react/prop-types";
import { FileDeleteModal } from "../modals/confirm-file-delete-modal";
import { useModal } from "../hooks/useModal";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { truncateText, sortArray } from "../helper";
import styles from "../custom-style.scss";

import classnames from "classnames";

const cx = classnames.bind(styles);

// Component for attachment links
export const AttachmentLink: React.FC<AttachmentLinkProps> = ({
   attachments,
   onLinkClick,
   mode = ActionMode.READ,
   onAttachmentDelete = () => {},
}) => {
   //custom hook created to handle modal open and close
   const { openModal, closeModal, isOpen } = useModal();
   const [attachmentIdToDelete, setAttachmentIdToDelete] = useState("");

   const handleDelete = () => {
      onAttachmentDelete(attachmentIdToDelete);
      setAttachmentIdToDelete("");
      closeModal();
   };

   const handleModalClose = () => {
      closeModal();
      setAttachmentIdToDelete("");
   };

   const handleAttachmentDeleteClick = (attachmentId: string) => {
      setAttachmentIdToDelete(attachmentId);
      openModal();
   };

   // Memoize sorted attachments to avoid re-sorting on each render
   const sortedAttachments = useMemo(
      () => sortArray(attachments, "created_at", Order.DESCENDING),
      [attachments],
   );

   return (
      <Box>
         {sortedAttachments.map((attachment) => (
            <React.Fragment key={attachment.id}>
               {mode === ActionMode.READ ? (
                  <div style={{ display: "flex" }}>
                     <Link onClick={() => onLinkClick(attachment)}>
                        <div className={cx("attachment-link")}>
                           <span>{truncateText(attachment.name, 40)}</span>
                           <Download size="sm" style={{ marginLeft: "5px" }} />
                        </div>
                     </Link>
                  </div>
               ) : (
                  <Pill
                     onClear={() => handleAttachmentDeleteClick(attachment.id)}
                     className={cx("attachment-link-pill")}
                  >
                     {truncateText(attachment.name, 40)}
                  </Pill>
               )}
               {mode === ActionMode.EDIT && <br />}
            </React.Fragment>
         ))}
         <FileDeleteModal open={isOpen} onClose={handleModalClose} handleDelete={handleDelete} />
      </Box>
   );
};
