import React, { memo } from "react";
import type { ColumnDefinition, DataTableCellRendererProps } from "@procore/data-table";
import { withDataTableRenderer } from "@procore/data-table";
import type { PersonTearsheetActions } from "../../tearsheets/people/people-tearsheet";
import { onPersonDetailClick } from "../../tearsheets/people/people-tearsheet";
import type { Dispatch, ReactElement } from "react";
import { getInitialsFromName } from "@/react/shared/helper";
import { Avatar, Flex, Typography } from "@procore/core-react";
import { PageTitle } from "@/react/prop-types";
import { formatName } from "@/lib/utils/preferences";

interface CustomPersonCellRendererParams {
   pageTitle: string;
   getPersonDetailDispatch: Dispatch<PersonTearsheetActions>;
}

type PersonProfile = {
   name: {
      first: string;
      last: string;
   };
   profile_pic_url: string | null;
};

export const CustomPersonCell = memo(function CustomPersonCell(
   props: CustomPersonCellRendererProps,
): ReactElement {
   const { value, data, columnDefinition } = props;
   // if the value is null or undefined, return an empty fragment, this is a case when the Group By is used
   if (!value) {
      return <></>;
   }
   const { pageTitle, getPersonDetailDispatch } = columnDefinition.cellRendererParams!;
   const id = data.resource_id ? data.resource_id : data.id; // data.resource_id is used when this component is used in the assignment list page
   const personName: string = formatName(value.name);
   const personInitials: string = getInitialsFromName(value.name);
   const imageUrl = value.profile_pic_url;

   const handleClick = () => {
      // a unique identifier that the request is coming from the people list page can be enhanced to include more options
      if (pageTitle === PageTitle.PEOPLE_LIST) {
         // Open the people detail tearsheet when the user clicks on the person name
         onPersonDetailClick(getPersonDetailDispatch, id);
      }
   };
   return (
      <Flex alignItems="center">
         <Avatar>
            {imageUrl ? (
               <Avatar.Portrait data-testid="profile-pic" imageUrl={imageUrl}></Avatar.Portrait>
            ) : (
               <Avatar.Label>{personInitials}</Avatar.Label>
            )}
         </Avatar>

         <Flex direction="column" padding="md">
            <Typography
               intent="small"
               onClick={handleClick}
               style={{
                  color: "#1D5CC9",
                  fontStyle: "inherit",
                  textDecoration: "underline",
                  cursor: "pointer",
               }}
            >
               {personName}
            </Typography>
         </Flex>
      </Flex>
   );
});

export type CustomPersonCellColumnDefinition = ColumnDefinition<
   PersonProfile,
   typeof CustomPersonCell,
   CustomPersonCellRendererParams,
   never // non-editable field hence no editor
>;

export type CustomPersonCellRendererProps = DataTableCellRendererProps<
   PersonProfile | undefined,
   CustomPersonCellColumnDefinition
>;

// no editor required for this cell renderer therefore not passing the second argument
export const CustomPersonCellRenderer = withDataTableRenderer(CustomPersonCell);
