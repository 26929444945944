import { authManager } from "@/lib/managers/auth-manager";
import {
   PageTitle,
   type IntegratedField,
   type PillValue,
   type SelectValue,
   type SortModel,
} from "@/react/prop-types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import {
   createColumnDefinitionsFromCustomHeaders,
   findCustomField,
} from "@/react/shared/custom-field-utils";
import {
   getReorderedColumnDefinition,
   isSensitiveField,
   isFieldEditable,
   convertDataTableConfigToSavedView,
} from "@/react/shared/helper";
import type { CustomFieldType } from "@laborchart-modules/common";
import { Help, Lock } from "@procore/core-icons";
import { Box, Flex, FlexList, Tooltip, useI18nContext } from "@procore/core-react";
import type {
   ColumnDefinition,
   DataTableCellRendererProps,
   DateCellColumnDefinition,
   PillCellColumnDefinition,
   ServerSideGetRowsParams,
} from "@procore/data-table";
import {
   BooleanCellEditor,
   BooleanCellRenderer,
   CurrencyCellEditor,
   CurrencyCellRenderer,
   DateCellEditor,
   DateCellRenderer,
   MultiSelectCellRenderer,
   MultiSelectFilterRenderer,
   PillCellRenderer,
   PillSelectCellEditor,
   SelectCellEditor,
   SelectCellRenderer,
   ServerSideDataTable,
   TextCellEditor,
   TextCellRenderer,
} from "@procore/data-table";
import type { ReactElement } from "react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
   useGetIntegratedFields,
   useGetTagOptionsQuery,
   validatePhoneNumber,
} from "../common/queries/queries";
import {
   ColorSelectEditor,
   ColorSelectRenderer,
   CircularPillRenderer,
   ProgressBarRenderer,
} from "../data-table/ColorSelectComponent/ColorSelectColumn";
import { BooleanFilter } from "../data-table/custom-filters/bool-filter";
import { CheckboxFilterRenderer } from "../data-table/custom-filters/checkbox-filter";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { NumericValueFilter } from "../data-table/custom-filters/numeric-value-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import type { CustomMultiSelect } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import { CustomMultiSelectEditor } from "../data-table/custom-multiselect-column/custom-multiselect-column";
import {
   getFormattedGroupName,
   isFieldLocked,
   GENDER_OPTIONS,
   addCustomFieldIDToStoredConfig,
} from "../data-table/data-table-utils";
import { LinkToProcoreRenderer } from "../data-table/LinkToProcore/LinkToProcore";
import { customFilterTokenText } from "../project-list/helpers";
import {
   columnHeadersMap,
   defaultPeopleListTableConfig,
   filterFieldMap,
   filterNameMaps,
   filterRendererMap,
   legacyFieldNamesMap,
} from "./helpers";
import type { FormattedTags, ValueValidatorResponse } from "./people-list-prop-types";
import {
   PEOPLE_STATUS_OPTIONS,
   PERSON_TYPE_OPTIONS,
   type PeopleListDataTableProps,
   type PeopleListServerFilter,
} from "./people-list-prop-types";
import { PeopleListQuickControls } from "./people-list-quick-controls";
import "./style.css";
import { validateEmail } from "@/lib/utils/validation";
import { FieldMapping } from "./people-list-enums";
import { ConfirmDeletePeopleModal } from "@/react/shared/modals/confirm-delete-people-modal";
import { CustomPersonCellRenderer } from "../data-table/custom-person-cell-renderer/custom-person-cell-renderer";
import { usePersonTearsheet } from "../tearsheets/people/people-tearsheet";
import { BulkSendMessageTearsheet } from "../tearsheets/people/messages/bulk-send-message-tearsheet";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import { CreateSavedViewModal } from "../modals/saved-view/create-saved-view-modal";
import { SavedViewPage } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import { BulkEditPeopleTearsheet } from "../tearsheets/people/bulk-edit-people-tearsheet";

const LOCAL_STORAGE_CONFIG_KEY = "personListTableConfig";
const LOCAL_STORAGE_SAVED_VIEW_KEY = "personListSavedViewConfig";

export const renderHeaderNode = (
   peopleIntegratedFields: IntegratedField[],
   field: string,
): ReactElement | null => {
   return isFieldLocked(peopleIntegratedFields, field) ? (
      <Lock size="sm" data-testid="lock-icon" />
   ) : null;
};

export const renderAssignmentHeader = (overlayText: string): ReactElement => {
   return (
      <Tooltip overlay={overlayText} trigger={["hover", "focus"]}>
         <Help size="sm" style={{ cursor: "pointer" }} />
      </Tooltip>
   );
};

export const PeopleListDataTable = (props: PeopleListDataTableProps) => {
   const I18n = useI18nContext();
   const {
      customFields,
      groupOptions,
      groupId,
      savedView,
      tableApi,
      fetchPeopleList,
      handleTableReady,
      streamJobTitles,
      updatePeople,
      isLastNameFirst,
   } = props;
   const [peopleIntegratedFields, setPeopleIntegratedFields] = useState<IntegratedField[]>([]);
   const { checkAuthAction } = usePermissionContext();
   const { dispatch: personTearsheetDispatch } = usePersonTearsheet();
   const [peopleSensitiveFields, setPeopleSensitiveFields] = useState<string[]>([]);
   const [canEditPeopleDetails, setCanEditPeopleDetails] = useState(false);
   const [canDeletePeople, setCanDeletePeople] = useState(false);
   const [canViewPeopleTags, setCanViewPeopleTags] = useState(false);
   const [canEditPeopleTags, setCanEditPeopleTags] = useState(false);
   const [canViewPeopleSensitiveFields, setCanViewPeopleSensitiveFields] = useState(false);
   const [canEditPeopleSensitiveFields, setCanEditPeopleSensitiveFields] = useState(false);
   const [canViewPeopleFinancials, setCanViewPeopleFinancials] = useState(false);
   const { data: tagOptions, isLoading: isTagLoading } = useGetTagOptionsQuery();
   const { data: integratedFields } = useGetIntegratedFields();
   const [customColumnDefinitions, setCustomColumnDefinitions] = useState<ColumnDefinition[]>([]);
   const [searchValue, setSearchValue] = useState<string | undefined>(savedView?.search);
   const [phoneErrors, setPhoneErrors] = useState<ValueValidatorResponse>({
      isValid: true,
      errorMessage: "",
      isRequired: false,
   });

   const [rowIndex, setRowIndex] = useState<number | null | undefined>(null);

   const formattedOptions: FormattedTags[] = [];
   const formattedTags: FormattedTags[] = [];
   tagOptions?.forEach((tag) => {
      const formattedTag = { ...tag, shape: "pill", componentName: "multiselect" };
      formattedOptions.push(formattedTag);
      formattedTags.push({ ...formattedTag, label: tag.name });
   });

   // Next starting_after value for pagination
   const nextStartAfter = useRef(undefined);

   // reading tableConfig like this to avoid re-renders for server side filters
   // they kind of reset when re-rendered
   const cachedConfig =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)!) ?? defaultPeopleListTableConfig;

   const tableConfig = savedView ?? cachedConfig;

   const serverFilters = tableConfig.serverFilters;

   /*unique key formed to pass it to DataTable Component for remounting of component when needed
   this is required if filters are applied and someone switches the group from navigation bar
   or when create tearsheet or people hyperlink is clicked on the table cell, it cause re-rendering so remounting
   of DataTable component is needed for persisting with filters*/
   const dataTableKey = groupId + "" + JSON.stringify(serverFilters);

   const resourcePlanningRebrand = LaunchDarklyClient.getFlagValue("resource-management-rebrand");

   //If the Group ID prop changes, fetch the people list again
   /* istanbul ignore next */
   useEffect(() => {
      if (tableApi) {
         tableApi.refreshServerSide({});
      }
   }, [groupId, tableApi]);

   /* istanbul ignore next */
   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            const updatedFields = integratedFields.data.people_integrated_fields.map((field) => {
               switch (field.property) {
                  case "first_name":
                  case "last_name":
                     return { ...field, property: "person" };
                  case "is_male":
                     return { ...field, property: "gender" };
                  default:
                     return field;
               }
            });

            setPeopleIntegratedFields(updatedFields);
         }
      }
   }, [integratedFields]);

   /* istanbul ignore next */
   const onTableConfigChange = useCallback(
      (config: any) => {
         const processedConfig = addCustomFieldIDToStoredConfig(config, customFields as any);
         if (!savedView) {
            localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(processedConfig));
         } else {
            localStorage.setItem(LOCAL_STORAGE_SAVED_VIEW_KEY, JSON.stringify(processedConfig));
         }
      },
      [savedView, customFields],
   );
   /* istanbul ignore next */
   useEffect(() => {
      const canViewPeopleTags = checkAuthAction(AuthAction.VIEW_PEOPLE_TAGS);
      const canEditPeopleTags = checkAuthAction(AuthAction.EDIT_PEOPLE_TAGS);
      const canDeletePeople = checkAuthAction(AuthAction.DELETE_PEOPLE);
      const canEditPeopleDetails = checkAuthAction(AuthAction.EDIT_PEOPLE_DETAILS);

      setCanViewPeopleTags(canViewPeopleTags);
      setCanEditPeopleTags(canEditPeopleTags);
      setCanDeletePeople(canDeletePeople);
      setCanEditPeopleDetails(canEditPeopleDetails);

      const canViewPeopleSensitiveFields = checkAuthAction(AuthAction.VIEW_PEOPLE_SENSITIVE);
      setCanViewPeopleSensitiveFields(canViewPeopleSensitiveFields);

      const canEditPeopleSensitiveFields = checkAuthAction(AuthAction.EDIT_PEOPLE_SENSITIVE);
      setCanEditPeopleSensitiveFields(canEditPeopleSensitiveFields);

      setPeopleSensitiveFields(
         authManager
            .peopleSensitiveFields()
            .map((field) => (field == "is_male" ? "gender" : field)),
      );

      const canViewPeopleFinancials = checkAuthAction(AuthAction.VIEW_PEOPLE_FINANCIALS);
      setCanViewPeopleFinancials(canViewPeopleFinancials);
   }, [checkAuthAction]);

   // DataTable Column definitions
   /* istanbul ignore next */
   const peopleNameColumn: ColumnDefinition = {
      field: "person",
      headerName: I18n.t("views.company.workforce_planning.people.person_name"),
      cellRenderer: CustomPersonCellRenderer,
      cellRendererParams: {
         pageTitle: PageTitle.PEOPLE_LIST,
         getPersonDetailDispatch: personTearsheetDispatch,
      },
      editable: false,
      hidden: false,
      lockVisible: true,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "person"),
      },
   };
   /* istanbul ignore next */
   const employeeNumberPeopleListColumn: ColumnDefinition = {
      field: "employee_number",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.employee_id"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "employee_number"),
      },
      editable: () => {
         return isFieldEditable(
            "employee_number",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };
   /* istanbul ignore next */
   const permissionPeopleListColumn: ColumnDefinition = {
      field: "permission",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.permission"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["permission_level_id"]), // as integrated field map to this field
      },
   };
   /* istanbul ignore next */
   const typePeopleListColumn: ColumnDefinition = {
      field: "type",
      hidden: true,
      lockVisible: true,
      filterRenderer: CheckboxFilterRenderer,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.type"),
         getFilterOptions: () => PERSON_TYPE_OPTIONS,
         getFilterTokenText: (item: any) => {
            const text = I18n.t("views.company.workforce_planning.people.type") + ": ";

            const types: string[] = item.value.map((v: any) =>
               I18n.t("views.company.workforce_planning.people." + v.value),
            );

            return text + types.join(", ");
         },
         getLabel: (item: any) => item.label,
         index: 3,
      },
   };

   /* istanbul ignore next */
   const jobTitleColumn: ColumnDefinition = {
      field: "job_title",
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => item.color,
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      cellEditor: ColorSelectEditor,
      cellEditorParams: {
         getColor: (item: { color: string }) => item.color,
      },
      headerName: I18n.t("views.company.workforce_planning.people.job_title"),
      filterRenderer: MultiSelectFilterRenderer,
      filterProps: {
         index: 1,
         getLabel: (item: any) => item.label,
         getFilterOptions: async () => {
            return await streamJobTitles(groupId);
         },
      },
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["position_id"]), // as integrated field map to this field
      },
      enableRowGroup: true,
   };
   /* istanbul ignore next */
   const address1PeopleListColumn: ColumnDefinition = {
      field: "address_1",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.address"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "address_1"),
      },
      ...isSensitiveField(
         "address_1",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "address_1",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      cellEditorParams: {
         placeholder: I18n.t("views.company.workforce_planning.people.address"),
      },
   };

   /* istanbul ignore next */
   const address2PeopleListColumn: ColumnDefinition = {
      field: "address_2",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.address_2"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "address_2"),
      },
      ...isSensitiveField(
         "address_2",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "address_2",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };

   /* istanbul ignore next */
   const cityPeopleListColumn: ColumnDefinition = {
      field: "city_town",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.city"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "city_town"),
      },
      ...isSensitiveField(
         "city_town",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "city_town",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };
   /* istanbul ignore next */
   const stateColumn: ColumnDefinition = {
      field: "state_province",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.state"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "state_province"),
      },
      ...isSensitiveField(
         "state_province",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "state_province",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };
   /* istanbul ignore next */
   const zipcodePeopleListColumn: ColumnDefinition = {
      field: "zipcode",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.postal"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "zipcode"),
      },
      editable: () => {
         return isFieldEditable(
            "zipcode",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };
   /* istanbul ignore next */
   const countryPeopleListColumn: ColumnDefinition = {
      field: "country",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.country"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "country"),
      },
      ...isSensitiveField(
         "country",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "country",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
   };
   /* istanbul ignore next */
   const tagsPeopleListColumn: ColumnDefinition = {
      field: "tag_instances",
      headerName: I18n.t("views.company.workforce_planning.people.tags"),
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getShape: (item: { shape: string }) => (item.shape = "pill"),
         getObjectValue: (cellValue: any) => cellValue,
         getPeopleDetailDispatch: personTearsheetDispatch,
         pageTitle: PageTitle.PEOPLE_LIST,
         isEditable: canEditPeopleDetails && canEditPeopleTags,
      },
      editable: false,
      cellEditor: ColorSelectEditor,
      cellEditorParams: {
         options: formattedOptions,
         getOptions: (cellValue: any) => cellValue,
         getComponentName: "multiselect",
      },
      getStringFormattedValue: (item) => item?.map((tag: any) => tag.label).join(", "),
      ...(canViewPeopleTags
         ? {
              filterRenderer: MultiSelectPillFilter as any,
              filterProps: {
                 getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.tags"),
                 getFilterOptions: (): any => {
                    return formattedTags as any;
                 },
                 getFilterTokenText: (item: any) => {
                    return `${I18n.t("views.company.workforce_planning.people.tags")}: (${
                       item.value.length
                    })`;
                 },
                 getLabel: (item: any) => item.label,
              },
           }
         : {}),
      sortable: false,
      hidden:
         !canViewPeopleTags ||
         tableConfig.columnState.find((col: any) => col.field === "tag_instances")?.hidden,
      lockVisible: !canViewPeopleTags,
   };

   /* istanbul ignore next */
   const statusPeopleListColumn: PillCellColumnDefinition = {
      field: "status",
      headerName: I18n.t("views.company.workforce_planning.people.status"),
      cellRenderer: PillCellRenderer,
      filterRenderer: StatusFilter,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.status"),
         getFilterOptions: () => PEOPLE_STATUS_OPTIONS,
         getFilterTokenText: (item: any) => {
            // Prefix text for the status
            const text = I18n.t("views.company.workforce_planning.people.status") + ": ";

            // Create an array of translated status values
            const status: string[] = item.value.map((v: any) => {
               return I18n.t("views.company.workforce_planning." + v.value);
            });

            // Join the translated statuses with a comma and return the complete text
            return text + status.join(", ");
         },
         getLabel: (item: any) => item.label,
         index: 4,
      },
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "status"),
      },
      editable: () => {
         return isFieldEditable(
            "status",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: PillSelectCellEditor,
      cellRendererParams: {
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      cellEditorParams: {
         selectOptions: () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(PEOPLE_STATUS_OPTIONS);
               }, 500);
            }),
         getId: (item: PillValue<any>) => item.id,
         getColor: (item: PillValue<any>) => {
            return item.color;
         },
      },
      getStringFormattedValue: (value: PillValue<any>) => {
         if (value === undefined) {
            return "";
         }
         return I18n.t(value.label);
      },
      flex: 1,
   };

   /* istanbul ignore next */
   const emailPeopleListColumn: ColumnDefinition = {
      field: "email",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.email"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "email"),
      },
      ...isSensitiveField(
         "email",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "email",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      valueValidator: (value) => {
         // Email validation
         const { value: val }: { value: any } = value;
         if (val?.trim() && !validateEmail(val)) {
            return {
               isValid: false,
               errorMessage: I18n.t(
                  "views.company.workforce_planning.validations.invalid_email_address",
               ),
               isRequired: true,
            };
         }
         if (!val?.trim() && value.data.is_user) {
            return {
               isValid: false,
               errorMessage: I18n.t("views.company.workforce_planning.validations.required_field"),
               isRequired: true,
            };
         }
         return { isValid: true, isRequired: false };
      },
      valueSetter: (params) => {
         const { newValue, oldValue } = params;
         params.data.email = newValue;
         if (!newValue.trim() && params.data.is_user) {
            params.data.email = oldValue;
         }

         return true;
      },
   };

   /* istanbul ignore next */
   const hourlyWagePeopleListColumn: ColumnDefinition = {
      field: "hourly_wage",
      cellRenderer: CurrencyCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.hourly_wage"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "hourly_wage"),
      },
      filterRenderer: NumericValueFilter,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.hourly_wage"),
         getFilterTokenText: (item: any) => {
            return customFilterTokenText(
               item,
               "hourly_wage",
               I18n,
               "views.company.workforce_planning.people.",
            );
         },
      },
      editable: () => {
         return isFieldEditable(
            "hourly_wage",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: CurrencyCellEditor,
      hidden:
         !canViewPeopleFinancials ||
         tableConfig.columnState.find((col: any) => col.field === "hourly_wage")?.hidden, // Hide the column if the column type is currency and the user does not have permission to view financials
      lockVisible: !canViewPeopleFinancials,
   };

   /* istanbul ignore next */
   const notificationProfilePeopleListColumn: ColumnDefinition = {
      field: "notification_profile",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.notification_profile"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["notification_profile"]),
      },
   };
   /* istanbul ignore next */
   const phonePeopleListColumn: ColumnDefinition = {
      field: "phone",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.phone_number"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "phone"),
      },
      ...isSensitiveField(
         "phone",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "phone",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: TextCellEditor,
      valueValidator: (value) => {
         if (value.node?.rowIndex === rowIndex) {
            return phoneErrors;
         }
         return { isValid: true, isRequired: false };
      },
   };
   /* istanbul ignore next */
   const genderPeopleListColumn: ColumnDefinition = {
      field: "gender",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.gender"),
      filterRenderer: BooleanFilter as any,
      filterProps: {
         getFilterHeadingText: () => I18n.t("views.company.workforce_planning.people.gender"),
         getFilterTokenText: (item: any) => {
            const text = I18n.t("views.company.workforce_planning.people.gender") + ": ";

            const gender: string[] = item.value.map((v: any) => {
               const gender = v.value ? "male" : "female";
               return I18n.t("views.company.workforce_planning.people." + gender);
            });
            return text + gender.join(", ");
         },
         getFilterOptions: () => {
            return {
               true: I18n.t("views.company.workforce_planning.people.male"),
               false: I18n.t("views.company.workforce_planning.people.female"),
            };
         },
         index: 2,
      },
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "gender"),
      },
      sortable: false,
      ...isSensitiveField(
         "gender",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      getStringFormattedValue: (value) => {
         if (!value) return "";
         return value.id === "male"
            ? I18n.t("views.company.workforce_planning.people.male")
            : I18n.t("views.company.workforce_planning.people.female");
      },
      editable: () => {
         return isFieldEditable(
            "gender",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: SelectCellEditor,
      cellEditorParams: {
         selectOptions: () =>
            new Promise((resolve) => {
               setTimeout(() => {
                  resolve(GENDER_OPTIONS);
               }, 500);
            }),
         getId: (item: SelectValue<any>) => item?.id,
      },
   };
   /* istanbul ignore next */
   const languagePeopleListColumn: ColumnDefinition = {
      field: "language",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.language"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "language"),
      },
   };
   /* istanbul ignore next */
   const hiredDateColumn: ColumnDefinition = {
      field: "hired_date",
      cellRenderer: DateCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.hired_date"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "hired_date"),
      },
      ...isSensitiveField(
         "hired_date",
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         tableConfig,
      ),
      editable: () => {
         return isFieldEditable(
            "hired_date",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: DateCellEditor,
   };
   /* istanbul ignore next */
   const birthDatePeopleListColumn: DateCellColumnDefinition = {
      field: "dob",
      cellRenderer: DateCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.birth_date"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "dob"),
      },
      ...isSensitiveField("dob", peopleSensitiveFields, canViewPeopleSensitiveFields, tableConfig),
      editable: () => {
         return isFieldEditable(
            "dob",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: DateCellEditor,
   };
   /* istanbul ignore next */
   const receiveEmailPeopleListColumn: ColumnDefinition = {
      field: "can_receive_email",
      cellRenderer: BooleanCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_email"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["can_receive_email"]),
      },
      editable: () => {
         return isFieldEditable(
            "can_recieve_email",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: BooleanCellEditor,
   };
   /* istanbul ignore next */
   const receiveMobilePeopleListColumn: ColumnDefinition = {
      field: "can_receive_mobile",
      cellRenderer: BooleanCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_mobile"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["can_receive_mobile"]),
      },
      editable: () => {
         return isFieldEditable(
            "can_recieve_mobile",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: BooleanCellEditor,
   };
   /* istanbul ignore next */
   const receiveSmsPeopleListColumn: ColumnDefinition = {
      field: "can_receive_sms",
      cellRenderer: BooleanCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.receive_sms"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["can_receive_sms"]),
      },
      editable: () => {
         return isFieldEditable(
            "can_recieve_sms",
            canEditPeopleDetails,
            peopleIntegratedFields,
            peopleSensitiveFields,
            canEditPeopleSensitiveFields,
         );
      },
      cellEditor: BooleanCellEditor,
   };
   /* istanbul ignore next */
   const groupsPeopleListColumn: ColumnDefinition = {
      field: "groups",
      headerName: I18n.t("views.company.workforce_planning.people.groups"),
      cellRenderer: MultiSelectCellRenderer,
      cellRendererParams: {
         getValue(cellValue: any) {
            return getFormattedGroupName(cellValue, groupOptions, I18n);
         },
         getId: (item: SelectValue<any>) => item?.label,
      },
      sortable: false,
      cellEditor: CustomMultiSelectEditor,
      cellEditorParams: {
         options: groupOptions as CustomMultiSelect[],
         getOptions: (cellValue: any) => cellValue,
      },
      flex: 2,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, legacyFieldNamesMap["groups"]),
      },
   };
   /* istanbul ignore next */
   const linkedToProcoreColumn: ColumnDefinition = {
      field: "linked_to_procore",
      cellRenderer: LinkToProcoreRenderer,
      cellRendererParams: {
         entityType: "person",
      },
      headerName: I18n.t("views.company.workforce_planning.people.linked_to_procore"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () => renderHeaderNode(peopleIntegratedFields, "linked_to_procore"),
      },
      sortable: false,
   };

   //current_assignments/next_assignment field can not be locked/sensitive as they are not part of integrated/sensitive fields
   /* istanbul ignore next */
   const assignmentColumn: ColumnDefinition = {
      field: "assignments",
      cellRenderer: CircularPillRenderer,
      cellRendererParams: {
         I18n,
      },
      headerName: I18n.t("views.company.workforce_planning.people.assignments"),
      flex: 1,
      columnHeaderParams: {
         headerNode: () =>
            renderAssignmentHeader(
               I18n.t("views.company.workforce_planning.people.assignment_tooltip"),
            ),
      },
      editable: false,
      sortable: false,
   };
   /* istanbul ignore next */
   const availabilityUntilColumn: ColumnDefinition = {
      field: "current_assignments",
      cellRenderer: ProgressBarRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.availability_until"),
      editable: false,
      cellRendererParams: {
         I18n,
      },
   };

   const peopleColumnDefinitions: ColumnDefinition[] = [
      peopleNameColumn,
      employeeNumberPeopleListColumn,
      permissionPeopleListColumn,
      jobTitleColumn,
      assignmentColumn,
      availabilityUntilColumn,
      address1PeopleListColumn,
      address2PeopleListColumn,
      cityPeopleListColumn,
      stateColumn,
      zipcodePeopleListColumn,
      countryPeopleListColumn,
      tagsPeopleListColumn,
      statusPeopleListColumn,
      emailPeopleListColumn,
      hourlyWagePeopleListColumn,
      notificationProfilePeopleListColumn,
      phonePeopleListColumn,
      genderPeopleListColumn,
      languagePeopleListColumn,
      hiredDateColumn,
      birthDatePeopleListColumn,
      receiveEmailPeopleListColumn,
      receiveMobilePeopleListColumn,
      receiveSmsPeopleListColumn,
      groupsPeopleListColumn,
      linkedToProcoreColumn,
      typePeopleListColumn,
   ];
   /* istanbul ignore next */
   useEffect(() => {
      if (customFields) {
         const sensitiveFieldsDetails = {
            canViewSensitiveFields: canViewPeopleSensitiveFields,
            canEditSensitiveFields: canEditPeopleSensitiveFields,
            sensitiveFields: peopleSensitiveFields,
         };

         const customColumnDefinitions = createColumnDefinitionsFromCustomHeaders(
            customFields,
            tableConfig,
            peopleIntegratedFields,
            null,
            sensitiveFieldsDetails,
            canViewPeopleFinancials,
            canEditPeopleDetails,
         );
         if (customColumnDefinitions.length > 0) {
            setCustomColumnDefinitions(customColumnDefinitions);
         }
      }
   }, [customFields, groupId, canEditPeopleDetails, peopleIntegratedFields]);
   //Reorder the columns based on the saved column state to maintain column order
   /* istanbul ignore next */
   const reorderedPeopleColumnDefinitions = useMemo(
      () =>
         getReorderedColumnDefinition(
            customColumnDefinitions
               ? peopleColumnDefinitions.concat(customColumnDefinitions)
               : peopleColumnDefinitions,
            tableConfig.columnState,
         ),
      [customColumnDefinitions, peopleColumnDefinitions, tableConfig.columnState],
   );

   /* istanbul ignore next */
   const handleServerSideDataRequest = useCallback(
      async ({ request, onSuccess, onError }: ServerSideGetRowsParams) => {
         try {
            const { groupKeys, rowGroupCols } = request;
            let startingAfter = undefined;
            // If requesting a second page of data, apply the starting_after query param
            if ((request?.startRow ?? 0) > 0) {
               startingAfter = nextStartAfter.current;
            }
            const sortModel: SortModel[] = request.sortModel;
            const serverFilter: PeopleListServerFilter[] = request.serverFilters;

            if (customFields) {
               // Map each field in sortModel to its corresponding fieldId if it exists in customFields
               sortModel.forEach((item) => {
                  const customField = findCustomField(customFields, item.field);
                  if (customField) {
                     item.fieldId = customField.id;
                  }
               });

               // Map each filter in serverFilter to its corresponding fieldId and fieldType if it exists in customFields
               serverFilter.forEach((filter) => {
                  const customField = findCustomField(customFields, filter.field);
                  if (customField) {
                     filter.fieldId = customField.id;
                     filter.fieldType = customField.type as CustomFieldType;
                  }
               });
            }

            const searchString = searchValue?.trim() ?? request.searchValue;
            if (request.searchValue) {
               setSearchValue(request.searchValue);
            }

            const data = await fetchPeopleList(
               serverFilter,
               sortModel,
               startingAfter,
               searchString,
               groupKeys,
               rowGroupCols,
            );

            onSuccess({
               rowData: data.data,
               rowCount: rowGroupCols.length ? data.data.length : data.pagination.total_possible,
            });
            nextStartAfter.current = data.pagination.next_starting_after;
         } catch (err) {
            onError();
         }
      },
      [groupId, customFields],
   );

   /* istanbul ignore next */
   const Delete = (props: DataTableCellRendererProps) => {
      return (
         <ConfirmDeletePeopleModal
            id={props.data.id}
            tableApi={tableApi}
            headerText={"views.company.workforce_planning.people.modals.delete.header.one"}
            deleteText={
               resourcePlanningRebrand
                  ? "views.company.workforce_planning.people.modals.delete.description_resource.one"
                  : "views.company.workforce_planning.people.modals.delete.description.one"
            }
            successText={"views.company.workforce_planning.people.modals.delete.success.one"}
         />
      );
   };

   /* istanbul ignore next */
   return (
      !isTagLoading && ( // Show the table only when the tag options are loaded
         <ServerSideDataTable
            columnDefinitions={reorderedPeopleColumnDefinitions}
            onServerSideDataRequest={handleServerSideDataRequest}
            getRowId={(params) => params.data.id}
            onTableConfigChange={onTableConfigChange}
            initialTableConfig={tableConfig}
            enableDynamicRowHeight
            key={dataTableKey}
            showExpandCollapseAllToggle
         >
            <Flex
               style={{
                  height: "100%",
                  width: "100%",
               }}
               alignItems="stretch"
            >
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.FiltersPanel
                     style={{
                        marginRight: "16px",
                     }}
                  />
               </Flex>

               <Box flex="1" display="flex-column" alignItems="stretch">
                  {/* @ts-expect-error Quick Controls need to optionally accept children*/}
                  <ServerSideDataTable.QuickControls>
                     <Flex>
                        <FlexList size="xs" alignItems="center">
                           <ServerSideDataTable.Search
                              placeholder={I18n.t(
                                 "views.company.workforce_planning.people.search_placeholder",
                              )}
                           />
                           <ServerSideDataTable.FiltersPanelButton />
                           <ServerSideDataTable.QuickFilters />
                        </FlexList>
                        <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
                           <PeopleListQuickControls
                              configKey={
                                 savedView ? LOCAL_STORAGE_SAVED_VIEW_KEY : LOCAL_STORAGE_CONFIG_KEY
                              }
                              groupId={groupId}
                              customFields={customFields ?? []}
                              isLastNameFirst={isLastNameFirst}
                              search={searchValue?.trim() ?? null}
                           />
                           <CreateSavedViewModal
                              configKey={
                                 savedView ? LOCAL_STORAGE_SAVED_VIEW_KEY : LOCAL_STORAGE_CONFIG_KEY
                              }
                              tableApi={tableApi}
                              conversionFunction={convertDataTableConfigToSavedView}
                              page={SavedViewPage.PEOPLE}
                              customFields={customFields ?? []}
                              columnHeadersMap={columnHeadersMap}
                              filterNameMaps={filterNameMaps}
                              filterFieldMap={filterFieldMap}
                              filterRendererMap={filterRendererMap}
                              searchString={searchValue}
                              groupId={groupId}
                              groupOptions={groupOptions ?? []}
                           />
                           <ServerSideDataTable.ConfigPanelButton />
                        </FlexList>
                     </Flex>
                  </ServerSideDataTable.QuickControls>
                  <ServerSideDataTable.BulkActions>
                     {canEditPeopleDetails && (
                        <BulkEditPeopleTearsheet
                           tableApi={tableApi}
                           integratedFields={integratedFields as GetIntegratedFieldsResponse}
                           customFields={customFields ?? []}
                        />
                     )}
                     {canDeletePeople && (
                        <ConfirmDeletePeopleModal
                           isBulk
                           tableApi={tableApi}
                           headerText={
                              "views.company.workforce_planning.people.modals.delete.header.other"
                           }
                           deleteText={
                              resourcePlanningRebrand
                                 ? "views.company.workforce_planning.people.modals.delete.description_resource.other"
                                 : "views.company.workforce_planning.people.modals.delete.description.other"
                           }
                           successText={
                              "views.company.workforce_planning.people.modals.delete.success.other"
                           }
                           tooltipText={
                              "views.company.workforce_planning.people.bulk.delete_admin_tooltip"
                           }
                        />
                     )}
                     <BulkSendMessageTearsheet tableApi={tableApi} />
                  </ServerSideDataTable.BulkActions>
                  <ServerSideDataTable.Table
                     onTableReady={handleTableReady}
                     paginationPageSize={100}
                     rowActions={canDeletePeople ? [Delete] : []}
                     rowSelectionEnabled={canEditPeopleDetails || canDeletePeople}
                     selectionSSREnabled={canEditPeopleDetails || canDeletePeople}
                     groupDefaultExpanded={-1}
                     hideActionsOnGroupedRow
                     onCellValueChanged={async (params) => {
                        if (params.field === FieldMapping.phone) {
                           if (!params.newValue.trim()) return;
                           const result = await validatePhoneNumber(params.newValue);
                           setRowIndex(params.rowIndex);
                           if (
                              result.data.formatted_number &&
                              result.data.conflicts?.length === 0
                           ) {
                              setPhoneErrors({
                                 isValid: true,
                                 isRequired: false,
                                 errorMessage: "",
                              });
                              params.newValue = result.data.formatted_number;
                           } else {
                              if (
                                 (result.data.conflicts?.length ?? 0) > 0 &&
                                 result.data.conflicts?.[0].id === params.rowData.id
                              ) {
                                 setPhoneErrors({
                                    isValid: true,
                                    isRequired: false,
                                    errorMessage: "",
                                 });
                                 return;
                              }
                              setPhoneErrors({
                                 isValid: false,
                                 isRequired: true,
                                 errorMessage:
                                    result.data.formatted_number === null
                                       ? I18n.t(
                                            "views.company.workforce_planning.people.validations.invalid_phone_number",
                                         )
                                       : I18n.t(
                                            "views.company.workforce_planning.people.validations.phone_conflict",
                                         ),
                              });
                              return;
                           }
                        }
                        if (params.newValue == null) return;
                        if (params.field === FieldMapping.email) {
                           if (params.newValue.trim() && !validateEmail(params.newValue)) {
                              return;
                           }
                           if (!params.newValue.trim() && params.rowData.is_user) {
                              return;
                           }
                           if (!params.newValue.trim() && !params.rowData.is_user) {
                              params.newValue = null; // set email to null if it is empty and person is not a user (to avoid empty string in the database)
                           }
                        }

                        updatePeople(params);
                     }}
                  />
               </Box>
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
               </Flex>
            </Flex>
         </ServerSideDataTable>
      )
   );
};
