import type { CustomField, SortModel } from "@/react/prop-types";
import { EntityTypes } from "@/react/prop-types";
import type { AssignmentListServerFilter, CoreApiSort } from "./assignment-list-prop-types";
import { FindAssignmentsSortBy } from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-assignments";
import { convertDataTableFilters, sortOrderMap } from "../data-table/data-table-utils";
import type { DataTableConfig } from "@procore/data-table";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";

const DATE_FIELDS: any[] = ["start_date", "end_date"];
const NUMERIC_FIELDS: any[] = []; // can add values to this array when we have more filters
const NESTED_FIELDS_TYPE: any[] = [FilterFieldType.BOOL, FilterFieldType.MULTI_SELECT];
const NUMERIC_FIELDS_TYPE: any[] = [FilterFieldType.NUMBER, FilterFieldType.CURRENCY];

// Filter Name Maps
export const filterNameMaps: { [key: string]: string } = {
   start_date: "Assignment Start Date",
   end_date: "Assignment End Date",
   status: "Assignment Status",
   job_title: "Job Title",
   person: "Person",
   employee_number: "Employee ID",
};
// Filter Type Maps
export const filterTypeMaps: { [key: string]: FilterFieldType } = {
   start_date: FilterFieldType.DATE,
   end_date: FilterFieldType.DATE,
   status: FilterFieldType.SELECT,
   job_title: FilterFieldType.SELECT,
   person: FilterFieldType.SELECT,
   employee_number: FilterFieldType.TEXT,
};

const filterPropertyMaps: { [key: string]: string } = {
   job_title: "person_position",
   status: "status_id",
   person: "person_id",
};

export const convertAssignmentDataTableFilters = (filters: AssignmentListServerFilter[]) =>
   convertDataTableFilters(
      filters,
      {
         nameMapper: filterNameMaps,
         typeMapper: filterTypeMaps,
         propertyMapper: filterPropertyMaps,
      },
      {
         numeric: NUMERIC_FIELDS,
         nestedTypes: NESTED_FIELDS_TYPE,
         numericTypes: NUMERIC_FIELDS_TYPE,
         date: DATE_FIELDS,
      },
   );

export function convertDataTableSort(
   sort: SortModel[],
   isLastNameFirst: boolean = false,
): CoreApiSort {
   sortFieldMaps.person = isLastNameFirst
      ? FindAssignmentsSortBy.PERSON_NAME_LAST
      : FindAssignmentsSortBy.PERSON_NAME_FIRST;

   //by default it will sort by end date in ascending order as seen in the legacy flow
   if (sort.length == 0)
      return {
         sort_by: FindAssignmentsSortBy.ASSIGNMENT_END_DATE,
         direction: sortOrderMap.asc,
      };
   const sortObj: CoreApiSort = {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };

   /* If the sort field is custom_field, then add the custom_field_id
   fieldId will only be there in case of custom_field, it has been added explicitly to identify the custom_field
   we will also be getting an entity type in this case to switch the custom field entity type for setting the sort_by field
   */
   if (sort[0].fieldId && sort[0].entity) {
      const entity = sort[0].entity;
      sortObj["custom_field_id"] = sort[0].fieldId;
      // by default setting sort_by to assignment_custom_field, will change it based on the entity type
      sortObj["sort_by"] = sortFieldMaps["assignment_custom_field"];
      if (entity === EntityTypes.PEOPLE) {
         sortObj["sort_by"] = sortFieldMaps["person_custom_field"];
      }
      if (entity === EntityTypes.PROJECT) {
         sortObj["sort_by"] = sortFieldMaps["project_custom_field"];
      }
   }
   return sortObj;
}

export const sortFieldMaps: { [key: string]: FindAssignmentsSortBy } = {
   person: FindAssignmentsSortBy.PERSON_NAME_FIRST,
   job_title: FindAssignmentsSortBy.JOB_TITLE,
   project: FindAssignmentsSortBy.PROJECT_NAME,
   job_number: FindAssignmentsSortBy.JOB_NUMBER,
   start_date: FindAssignmentsSortBy.ASSIGNMENT_START_DATE,
   end_date: FindAssignmentsSortBy.ASSIGNMENT_END_DATE,
   status: FindAssignmentsSortBy.STATUS,
   start_time: FindAssignmentsSortBy.ASSIGNMENT_START_TIME,
   end_time: FindAssignmentsSortBy.ASSIGNMENT_END_TIME,
   assignment_custom_field: FindAssignmentsSortBy.ASSIGNMENT_CUSTOM_FIELD,
   person_custom_field: FindAssignmentsSortBy.PERSON_CUSTOM_FIELD,
   project_custom_field: FindAssignmentsSortBy.PROJECT_CUSTOM_FIELD,
   percent_allocated: FindAssignmentsSortBy.ASSIGNMENT_PERCENT_ALLOCATION,
   cost_code_name: FindAssignmentsSortBy.COST_CODE_NAME,
   label_name: FindAssignmentsSortBy.LABEL_NAME,
};

export const defaultAssignmentListTableConfig: DataTableConfig = {
   columnState: [
      {
         field: "project",
         hidden: false,
         pinned: null,
         width: 280,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "person",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: 0,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "job_number",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "job_title",
         hidden: false,
         pinned: null,
         width: 180,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "start_date",
         hidden: false,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "end_date",
         hidden: false,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "start_time",
         hidden: false,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "end_time",
         hidden: false,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "percent_allocated",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "status",
         hidden: false,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "overtime",
         hidden: true,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "cost_code_name", //category
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "label_name", // subcategory
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "work_days",
         hidden: true,
         pinned: null,
         width: 150,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_1",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "address_2",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "city_town",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "state_province",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "zipcode",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "country",
         hidden: false,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "employee_number",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
      {
         field: "gender",
         hidden: true,
         pinned: null,
         width: 200,
         sort: null,
         sortIndex: null,
         rowGroup: false,
         rowGroupIndex: null,
      },
   ],
   columnGroupState: [],
   filters: {},
   rowHeight: 49,
   serverFilters: [],
};

export const getFormattedCustomFields = (customFields: CustomField[] | null) => {
   if (!customFields) return [];
   const addEntity = (field: CustomField, entity: EntityTypes) => {
      return {
         ...field,
         entity,
         can_filter: false, // explicitly setting can_filter to false, will remove it once we have the filter implementation in place
      };
   };

   const customColumns = customFields.reduce<CustomField[]>((acc, field) => {
      const { on_assignments, on_projects, on_people } = field;

      if (on_assignments) {
         acc.push(addEntity(field, EntityTypes.ASSIGNMENT));
      }
      if (on_people) {
         acc.push(addEntity(field, EntityTypes.PEOPLE));
      }
      if (on_projects) {
         acc.push(addEntity(field, EntityTypes.PROJECT));
      }

      return acc;
   }, []);

   return customColumns;
};
