import { type useI18nContext } from "@procore/core-react";
import type { AnySchema } from "yup";
import { object, string, array, boolean } from "yup";
import type { CustomField } from "@/react/prop-types";
import { createCustomFieldSchema } from "@/react/components/tearsheets/validation-schema";

export const createPersonSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   phoneNumberValid: boolean,
) =>
   object().shape({
      first_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      last_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      group_ids: array().test(
         "group_ids",
         I18n.t("views.company.workforce_planning.people.validations.group_required"),
         /* istanbul ignore next */
         function (value) {
            if (this.parent.permission_level?.is_admin) {
               return true;
            }
            return value && value.length > 0;
         },
      ),
      employee_id: string(),
      type: object(),
      permission_level: object().nullable(),
      enable_email: boolean(),
      enable_sms: boolean(),
      email: string()
         .email(I18n.t("views.company.workforce_planning.validations.invalid_email"))
         .test(
            "email",
            I18n.t("views.company.workforce_planning.people.validations.email_required_for_users"),
            /* istanbul ignore next */
            function (value) {
               if (
                  this.parent.type.value === "user" ||
                  this.parent.type.value === "assignable_user"
               ) {
                  return !!value;
               }
               return true;
            },
         )
         .nullable(),
      phone: string()
         .test(
            "phone",
            I18n.t("views.company.workforce_planning.people.validations.invalid_phone_number"),
            () => phoneNumberValid,
         )
         .nullable(),
   });

export const peopleDetailsSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   phoneNumberValid: boolean,
   customFields?: CustomField[],
   emailFieldAvailability?: boolean,
) =>
   object().shape({
      first_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      last_name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      groups: array().test(
         "groups",
         I18n.t("views.company.workforce_planning.people.validations.group_required"),
         /* istanbul ignore next */
         function (value) {
            if (this.parent.permission?.is_admin) {
               return true;
            }
            return value && value.length > 0;
         },
      ),
      email: string()
         .email(I18n.t("views.company.workforce_planning.validations.invalid_email_address"))
         .test(
            "email",
            I18n.t("views.company.workforce_planning.people.validations.email_required_for_users"),
            /* istanbul ignore next */
            function (value) {
               if (
                  emailFieldAvailability &&
                  (this.parent.type.value === "user" ||
                     this.parent.type.value === "assignable_user")
               ) {
                  return !!value;
               }
               return true;
            },
         )
         .nullable(),
      permission: object()
         .test(
            "permission",
            I18n.t(
               "views.company.workforce_planning.people.validations.permission_level_required_for_users",
            ),
            function (value) {
               if (
                  this.parent.type.value === "user" ||
                  this.parent.type.value === "assignable_user"
               ) {
                  return !!value;
               }
               return true;
            },
         )
         .nullable(),
      phone: string()
         .test(
            "phone",
            I18n.t("views.company.workforce_planning.people.validations.invalid_phone_number"),
            () => phoneNumberValid,
         )
         .nullable(),
      ...(customFields && customFields.length > 0
         ? customFields.reduce((acc: Record<string, AnySchema>, field: CustomField) => {
              acc[field.name] = createCustomFieldSchema(field);
              return acc;
           }, {} as Record<string, AnySchema>)
         : {}),
   });
