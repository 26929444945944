import type {
   CustomField,
   IntegratedField,
   StreamResponseConversion,
   TagOption,
} from "@/react/prop-types";
import { PageTitle } from "@/react/prop-types";
import type { Dispatch, SetStateAction } from "react";
import type {
   PersonDetailFields,
   PersonDetailFormValues,
   peopleDetailsFieldsSection,
} from "./prop-types";
import { FieldMapping } from "../../people-list/people-list-enums";
import type { UpdatedValues, setFieldErrorType, setFieldValueType } from "./types";
import type { ValidatePhoneNumberResponse } from "@laborchart-modules/lc-core-api/dist/api/twilio/validate-phone-number";
import type { UpdatePersonPayload } from "@laborchart-modules/lc-core-api/dist/api/people";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";
import type { SupportedLanguage } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { useI18nContext } from "@procore/core-react";
import {
   getFilteredFields,
   isIntegratedField,
   isSensitiveFieldEditable,
} from "@/react/shared/helper";
import { legacyFieldNamesMap } from "../../people-list/helpers";
import type { PeopleListData, TagInstance } from "../../people-list/people-list-prop-types";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";

// Function to generate options for the radio buttons
export function generateRadioOptions(
   fieldSource: string | undefined,
   fieldValues: any[] | null,
   fieldName: string,
) {
   if (fieldSource === PageTitle.PEOPLE_DETAILS) {
      return fieldValues;
   }

   return (fieldValues ?? []).map((value) => ({
      id: `${fieldName}_${value}`,
      label: value,
   }));
}

// Function to handle the radio button click event
export function handleRadioClick(
   event: { target: { value: any } },
   initialValues: PersonDetailFormValues,
   setFieldValue: any,
   setDisabledField: Dispatch<SetStateAction<{ [fieldName: string]: boolean }>>,
) {
   const value = event.target.value;

   if (value === "assignable") {
      disablePermissionFields(setDisabledField);
      if (initialValues.permission?.is_admin) {
         resetAdminFields(setFieldValue);
      } else {
         resetFieldsToInitialValues(setFieldValue, initialValues);
      }
   } else {
      enablePermissionFields(setDisabledField, initialValues);
      resetFieldsToInitialValues(setFieldValue, initialValues);
   }
}

// Function to disable permission-related fields
export function disablePermissionFields(setDisabledField: {
   (value: SetStateAction<{ [fieldName: string]: boolean }>): void;
   (arg0: (prev: any) => any): void;
}) {
   setDisabledField((prev) => ({
      ...prev,
      permission: true,
      groups: false,
      notification_profile: true,
   }));
}

// Function to enable permission-related fields
export function enablePermissionFields(
   setDisabledField: {
      (value: SetStateAction<{ [fieldName: string]: boolean }>): void;
      (arg0: (prev: any) => any): void;
   },
   initialValues: PersonDetailFormValues,
) {
   setDisabledField((prev) => ({
      ...prev,
      permission: false,
      groups: initialValues.permission?.is_admin ?? false,
      notification_profile: false,
   }));
}

// Function to reset fields to initial values
export function resetFieldsToInitialValues(
   setFieldValue: setFieldValueType,
   initialValues: PersonDetailFormValues,
) {
   setFieldValue(FieldMapping.groups, initialValues.groups);
   setFieldValue(FieldMapping.permission, initialValues.permission);
}

// Function to reset fields for admin users
export function resetAdminFields(setFieldValue: setFieldValueType) {
   setFieldValue(FieldMapping.groups, []);
   setFieldValue(FieldMapping.permission, {});
}

export const getUpdatedValues = (
   initialValues: { [x: string]: any },
   values: { [x: string]: any },
) => {
   const updatedValues: UpdatedValues = {};

   for (const key in values) {
      if (values[key] !== initialValues[key]) {
         updatedValues[key] = values[key];
      }
   }
   return updatedValues;
};

export function handlePhoneNumberValidation(
   result: ValidatePhoneNumberResponse,
   setFieldValue: setFieldValueType,
   setFieldError: setFieldErrorType,
   setPhoneNumberValid: Dispatch<SetStateAction<boolean>>,
   I18nObject: ReturnType<typeof useI18nContext>,
) {
   if (result.data.formatted_number && result.data.conflicts?.length === 0) {
      setFieldValue(FieldMapping.phone, result.data.formatted_number, false);
      setFieldError(FieldMapping.phone, undefined);
      setPhoneNumberValid(true);
   } else {
      const message =
         result.data.formatted_number === null
            ? I18nObject.t(
                 "views.company.workforce_planning.people.validations.invalid_phone_number",
              )
            : I18nObject.t("views.company.workforce_planning.people.validations.phone_conflict");
      setFieldError(FieldMapping.phone, message);
      setPhoneNumberValid(false);
   }
}

export const buildPayload = (
   values: PersonDetailFormValues,
   updatedValues: UpdatedValues,
   groups: StreamResponseConversion | null | undefined,
   customFields: CustomField[],
) => {
   let updateData: UpdatePersonPayload<AuthType.SESSION> = {};
   if (updatedValues) {
      if (updatedValues.first_name || updatedValues.last_name) {
         const newName = {
            first: updatedValues.first_name
               ? updatedValues.first_name.trim()
               : values.first_name.trim(),
            last: updatedValues.last_name
               ? updatedValues.last_name.trim()
               : values.last_name.trim(),
         };
         updateData.name = newName;
         delete updatedValues.first_name;
         delete updatedValues.last_name;
      }
      if (updatedValues.job_title) {
         updateData.job_title_id = updatedValues.job_title.id ?? null;
         delete updatedValues.job_title;
      }
      if (updatedValues.type) {
         updateData.is_user =
            updatedValues.type.value === "user" || updatedValues.type.value === "assignable_user";
         updateData.is_assignable =
            updatedValues.type.value === "assignable_user" ||
            updatedValues.type.value === "assignable";
         delete updatedValues.type;
      }
      if (updatedValues.notification_profile) {
         updateData.notification_profile_id = updatedValues.notification_profile?.id ?? null;
         delete updatedValues.notification_profile;
      }
      if (updatedValues.permission) {
         updateData.permission_level_id = updatedValues.permission?.id ?? null;
         delete updatedValues.permission;
      }
      // Admins has access to all groups, so we don't need to update group_ids for them
      if (updatedValues.groups && !values.permission?.is_admin) {
         const selected_group_ids = updatedValues.groups.map((g: { id: string }) => g.id);
         updateData.group_ids = selected_group_ids.includes("select_all")
            ? groups!
                 .map(({ id }) => ({
                    [id]: Boolean(id),
                 }))
                 .reduce((acc, next) => ({ ...acc, ...next }))
            : groups!
                 .map(({ id }) => ({
                    [id]: Boolean(
                       updatedValues.groups.find(
                          ({ id: groupId }: { id: string }) => groupId === id,
                       ),
                    ),
                 }))
                 .reduce((acc, next) => ({ ...acc, ...next }));
         delete updatedValues.groups;
      }
      if (updatedValues.hired_date) {
         updateData.hired_date = updatedValues.hired_date
            ? updatedValues.hired_date.getTime()
            : null;
         delete updatedValues.hired_date;
      }
      if (updatedValues.dob) {
         updateData.dob = updatedValues.dob ? updatedValues.dob.getTime() : null;
         delete updatedValues.dob;
      }
      if (updatedValues.language) {
         updateData.language = (updatedValues.language?.id as SupportedLanguage) ?? null;
         delete updatedValues.language;
      }
      if (updatedValues.gender) {
         updateData.gender = updatedValues.gender?.id ?? null;
         delete updatedValues.gender;
      }
      if (updatedValues.status) {
         updateData.status = values.status?.id ?? null;
         delete updatedValues.status;
      }
      // Remove empty values for email
      if (updatedValues.email === "") {
         updateData.email = null;
         delete updatedValues.email;
      }
      //remove custom fields from updated values
      customFields?.forEach((field) => {
         if (field.name in updatedValues) {
            delete updatedValues[field.name];
         }
      });
      // Add remaining updated values
      updateData = { ...updateData, ...updatedValues };
   }
   return updateData;
};

export const updateSectionFields = (
   sectionFields: peopleDetailsFieldsSection | PersonDetailFields[],
   setFields: React.Dispatch<React.SetStateAction<peopleDetailsFieldsSection>>,
   peopleSensitiveFields: string[],
   canViewPeopleSensitiveFields: boolean,
   canEditPeopleSensitiveFields: boolean,
   canViewPeopleFinancials: boolean,
   personIntegratedFields: IntegratedField[],
) => {
   const filteredFieldsSection: PersonDetailFields[] = getFilteredFields(
      sectionFields as PersonDetailFields[],
      peopleSensitiveFields,
      canViewPeopleSensitiveFields,
      canViewPeopleFinancials,
   );

   const updatedFields = sectionFields.map((field) => {
      const filteredField = filteredFieldsSection.find(
         (filteredField) => filteredField.name === field.name,
      );
      const showField = filteredField !== undefined;
      let disabled = false;
      if (
         isIntegratedField(legacyFieldNamesMap[field.name], personIntegratedFields) ||
         isSensitiveFieldEditable(field.name, peopleSensitiveFields, canEditPeopleSensitiveFields)
      ) {
         disabled = true;
      }
      return {
         ...field,
         showField,
         disabled,
      };
   }) as peopleDetailsFieldsSection;
   setFields(updatedFields);
};

export const getColStart = (showField: boolean, nextShowField: boolean) => {
   if (showField) {
      return nextShowField ? 9 : 5;
   } else {
      return nextShowField ? 5 : 1;
   }
};

export function getGroupIdsForNoSelection(
   person: PeopleListData,
   isAdminSelected: boolean,
   groupOptions: Array<{ id: string }> | null,
) {
   if (isAdminSelected) {
      return undefined;
   }

   if (!groupOptions) {
      return undefined;
   }

   return groupOptions
      .map(({ id }) => ({
         [id]: Boolean(person?.group_ids?.find((groupId) => groupId === id)),
      }))
      .reduce((acc, next) => ({ ...acc, ...next }));
}

export function getSelectedTagIds(
   person: PeopleListData,
   selectedTagId: string,
   expirationDate: Date | null,
   selectedTag?: TagOption | null,
): TagInstance {
   // If selectedTag is not found, return undefined
   if (!selectedTag) return undefined;

   // Create the tag instance with expiration date
   const tagInstances = {
      [selectedTagId]: {
         expr_date: expirationDate ? getDetachedDay(expirationDate) : null,
      },
   };

   // If the person has "Admin" permission, return the tag instance
   if (person.permission === "Admin" || selectedTag.globally_accessible) {
      return tagInstances;
   }

   // If the person has group IDs, filter those that match the selectedTag group IDs
   if (selectedTag?.group_ids) {
      const selectedGroupIds = new Set(selectedTag?.group_ids);
      const matchingGroupIds = person.group_ids?.filter((groupId) => selectedGroupIds.has(groupId));

      // If there are matching group IDs, return the tag instance
      if (matchingGroupIds?.length) {
         return tagInstances;
      }
   }

   // If no conditions match, return undefined
   return undefined;
}
