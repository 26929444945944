import type { UNSAFE_MenuSelection } from "@procore/core-react";
import {
   Flex,
   Select,
   Typography,
   MultiSelect,
   Token,
   Pill,
   Tooltip,
   ProgressBar,
} from "@procore/core-react";
import type { ReactElement } from "react";
import React from "react";
import type {
   DataTableCellEditorProps,
   DataTableCellRendererProps,
   ColumnDefinition,
} from "@procore/data-table";
import { getVisibleTextColor } from "./VisibleTextColor";
import type { ProjectTearsheetActions } from "../../tearsheets/project/project-tearsheet";
import { onProjectDetailClick } from "../../tearsheets/project/project-tearsheet";
import { withDataTableEditor, withDataTableRenderer } from "@procore/data-table";
import { hexColors } from "../../common/constants/hex-colors";
import { PageTitle, TearsheetTabs } from "@/react/prop-types";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { formatDateTime } from "@/react/shared/helper";
import type { PersonTearsheetActions } from "../../tearsheets/people/people-tearsheet";
import { onPersonDetailClick } from "../../tearsheets/people/people-tearsheet";

interface ProjectAssignment {
   id: string;
   end_day: number;
   start_day: number;
   job_number: string | null;
   project_id: string;
   resource_id: string;
   project_name: string;
   project_group_ids: string[];
   is_restricted_project: boolean;
   percent_allocated?: number;
   start_time?: string;
   end_time?: string;
}
interface CircularPillProps {
   value: ProjectAssignment[] | undefined;
   columnDefinition: ColumnDefinition;
}

interface ProgressBarProps {
   value: ProjectAssignment[];
   columnDefinition: ColumnDefinition;
}

export interface ColorSelectValue {
   id: string;
   label: string;
   name: string;
   color: string;
   shape?: string;
   componentName?: string;
   objectValue?: any;
}

//Custom Cell Renderer
interface ColorSelectCellRendererParams {
   getColor: (item: ColorSelectValue) => ColorSelectValue["color"];
   getShape?: (item: ColorSelectValue) => ColorSelectValue["shape"];
   getObjectValue?: (item: ColorSelectValue) => ColorSelectValue[];
   getProjectDetailDispatch?: React.Dispatch<ProjectTearsheetActions>;
   getPeopleDetailDispatch?: React.Dispatch<PersonTearsheetActions>;
   pageTitle?: string;
   isEditable?: boolean;
}

export type ColorSelectColumnDefinition = ColumnDefinition<
   ColorSelectValue,
   typeof ColorSelectCell,
   ColorSelectCellRendererParams,
   typeof ColorSelectEditor,
   ColorSelectEditorParams
>;

export type ColorSelectRendererProps = DataTableCellRendererProps<
   ColorSelectValue,
   ColorSelectColumnDefinition
>;
const squareColorSwab = {
   // this is an example of how to use the shape property
   borderRadius: "3px",
   height: "16px",
   width: "34px",
};
export function borderColor(hex: any, percent: any) {
   // Remove the leading #
   hex = hex ? hex.replace("#", "") : "000000";

   // Convert hex to RGB value
   let r = parseInt(hex.substring(0, 2), 16);
   let g = parseInt(hex.substring(2, 4), 16);
   let b = parseInt(hex.substring(4, 6), 16);

   // Adjust brightness
   r = Math.floor(r + (255 - r) * percent);
   g = Math.floor(g + (255 - g) * percent);
   b = Math.floor(b + (255 - b) * percent);

   // Ensure values are within 0-255 range
   r = rgbValuesWithinRange(r);
   g = rgbValuesWithinRange(g);
   b = rgbValuesWithinRange(b);

   // Convert RGB back to hex
   const result = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

   return result;
}

function rgbValuesWithinRange(value: number) {
   if (value > 255) {
      return 255;
   } else if (value < 0) {
      return 0;
   } else {
      return value;
   }
}

export const ColorSwab: React.FC<{
   color: string;
   shape: string;
   label?: string;
   disabled?: any;
   focused?: any;
   removeToken?: any;
   displayRemoveToken?: boolean;
   height?: string;
   width?: string;
   handleClick?: () => void;
}> = ({
   color,
   shape,
   label,
   disabled,
   focused,
   removeToken,
   displayRemoveToken,
   height = "10px",
   width = "10px",
   handleClick,
}) =>
   shape === "pill" ? ( //in case shape is pill, Token element has been used
      <Token
         style={{
            background: `${color}`,
            marginRight: "5px",
            borderColor: `${borderColor(color, -0.2)}`,
            padding: "1px 8px",
            display: "inline-flex",
            textDecoration: "nowrap",
            color: getVisibleTextColor(color),
            height: "20px",
            cursor: "pointer",
         }}
         disabled={disabled}
         focused={focused}
         onClick={handleClick}
      >
         <Token.Label
            style={{
               fontSize: "12px",
               fontWeight: 600,
               fontFamily: "inherit",
               letterSpacing: "0.25px",
               lineHeight: "16px",
            }}
         >
            {label}
         </Token.Label>
         {displayRemoveToken ? <Token.Remove data-close onClick={removeToken} /> : ""}
      </Token>
   ) : (
      <div
         style={{
            background: `${color}`, //Common css propery for all shapes
            marginRight: "5px", //Common css propery for all shapes
            ...(shape === "square" ? squareColorSwab : {}), //css properties depends on shape
            ...(shape === "circle" ? { borderRadius: "50%", height, width } : {}), //css properties depends on shape
         }}
      />
   );

export const ColorSelectCell: React.FunctionComponent<ColorSelectRendererProps> = (
   props,
): ReactElement | null => {
   const { columnDefinition, value, data } = props;
   if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      return null;
   }
   const shape = columnDefinition?.cellRendererParams?.getShape?.(value);

   if (shape === "pill") {
      // this is used when the value is an object
      const cellValue = columnDefinition.cellRendererParams?.getObjectValue?.(value);
      const pageTitle = columnDefinition.cellRendererParams?.pageTitle ?? "";
      const isEditable = columnDefinition.cellRendererParams?.isEditable ?? false;

      const id = data.id ?? "";
      const handleClick = () => {
         // Replacing switch statement with if statement to address the sonarqube code smell
         // We can refactor this to a switch statement if we need to add more cases in the future
         if (pageTitle === PageTitle.PROJECT_LIST) {
            const projectTearsheetDispatch =
               columnDefinition.cellRendererParams?.getProjectDetailDispatch;

            // Open the project detail tearsheet with Details tab when the user clicks on the project tags
            if (projectTearsheetDispatch) {
               onProjectDetailClick(projectTearsheetDispatch, id, TearsheetTabs.DETAILS);
            }
         }
         if (pageTitle === PageTitle.PEOPLE_LIST) {
            const peopleTearsheetDispatch =
               columnDefinition.cellRendererParams?.getPeopleDetailDispatch;

            // Open the people detail tearsheet with Overview tab when the user clicks on the person tags
            if (peopleTearsheetDispatch) {
               onPersonDetailClick(peopleTearsheetDispatch, id, TearsheetTabs.OVERVIEW);
            }
         }
      };

      if (cellValue) {
         return (
            <div>
               {cellValue.map((item: any) => (
                  // here passing item.value as a fallback if id doesn't exist
                  <div
                     key={item.id ?? item.value}
                     style={{ display: "inline-block", marginBottom: "5px" }}
                  >
                     <ColorSwab
                        color={item.color}
                        shape={item.shape}
                        label={item.label}
                        handleClick={isEditable ? handleClick : () => {}}
                     />
                  </div>
               ))}
            </div>
         );
      }
   } else {
      const internalValue = value.label;

      return (
         <div>
            <Flex alignItems="center">
               <ColorSwab
                  color={
                     columnDefinition.cellRendererParams
                        ? columnDefinition.cellRendererParams.getColor(value)
                        : ""
                  }
                  shape={
                     // this is an example of how to use the shape property
                     columnDefinition.cellRendererParams?.getShape?.(value) ?? "circle"
                  }
               />
               <Typography>{internalValue}</Typography>
            </Flex>
         </div>
      );
   }
   // Return null in case none of the conditions are met
   return null;
};

//Custom Cell Editor
export interface ColorSelectEditorParams {
   getColor: (item: ColorSelectValue) => ColorSelectValue["color"];
   getId?: (item: ColorSelectValue) => ColorSelectValue["id"];
   getShape?: (item: ColorSelectValue) => ColorSelectValue["shape"];
   placeholder?: string;
   options: ColorSelectValue[];
   getOptions?: (item: ColorSelectValue) => ColorSelectValue[];
   getComponentName?: string;
}

export type ColorSelectEditorProps = DataTableCellEditorProps<
   ColorSelectValue,
   ColorSelectColumnDefinition
>;

const ColorSelectDropdown = React.forwardRef<HTMLDivElement, ColorSelectEditorProps>(
   function ColorSelectDropdown({ columnDefinition, setDataValue, stopEditing, value }, ref) {
      const [options, setOptions] = React.useState<ColorSelectValue[]>(
         columnDefinition.cellEditorParams?.options ?? [],
      );
      const selectedOptions = columnDefinition.cellEditorParams?.getOptions?.(value);
      const [selectedOpt, setSelectedOpt] = React.useState<any[]>(selectedOptions ?? []);
      const [loading, setLoading] = React.useState(false);
      /**
       * ColorSelect Event Handlers
       */

      function onSelect(selection: UNSAFE_MenuSelection | any) {
         if (selection.item && selection.item !== value) {
            // this is necessary to update the row data
            setDataValue(columnDefinition.field, selection);
         }
         if (selection && !selection.item && selection !== value) {
            // this is necessary to update multiselect data
            setSelectedOpt(selection);
            // this is necessary to update the row data
            setDataValue(columnDefinition.field, selection);
         }
      }

      async function beforeShow() {
         // If the options are known in advance, they can be defined on the cellEditorParams like below.
         // This doesn't have to happen inside beforeShow.
         const selectOptions = columnDefinition.cellEditorParams?.options ?? [];
         setLoading(true);
         // If the options aren't know in advance, requests can be performed inside beforeShow
         setOptions(selectOptions);
         setLoading(false);

         return true;
      }

      function getComponent() {
         const componentName = columnDefinition.cellEditorParams?.getComponentName ?? "select";
         //Editor for MultiSelect
         if (componentName === "multiselect") {
            const getMultiSelectId = (item: ColorSelectValue) => item.id;
            const getMultiSelectLabel = (item: ColorSelectValue) => item.name;

            const optionRenderer = (item: ColorSelectValue) => (
               <MultiSelect.Option value={item} key={item.id}>
                  <ColorSwab color={item.color} shape="pill" label={item.label} />
               </MultiSelect.Option>
            );
            const tokenRenderer = ({
               disabled,
               focused,
               option,
               removeToken,
            }: any): React.JSX.Element => (
               <ColorSwab
                  color={option.color}
                  shape="pill"
                  label={getMultiSelectLabel(option)}
                  disabled={disabled}
                  focused={focused}
                  removeToken={removeToken}
                  displayRemoveToken={true}
               />
            );
            return (
               <MultiSelect
                  getId={getMultiSelectId}
                  getLabel={getMultiSelectLabel}
                  loading={loading}
                  onChange={onSelect}
                  options={options}
                  optionRenderer={optionRenderer}
                  tokenRenderer={tokenRenderer}
                  value={selectedOpt}
                  block
                  afterHide={() => {
                     // tells DataTable that editing is finished when the Select is closed
                     stopEditing();
                  }}
               />
            );
         }
         /**
          * Default editor - Select
          * Editors must be classified as a type from this list: input(Currency or number-specific editors, textareas, etc),date(Date and DateTime), select(PillSelect, MultiSelect, TieredSelect etc).
          * Read more - https://stories.core.procore.com/?path=/docs/data-table_client-side-features-custom-cell-editor--docs
          */
         function getId(val: any) {
            return columnDefinition.cellEditorParams?.getId?.(val) ?? val;
         }

         function getLabel(val: any) {
            return columnDefinition.getStringFormattedValue?.(val);
         }
         return (
            <Select
               afterHide={() => {
                  // tells DataTable that editing is finished when the Select is closed
                  stopEditing();
               }}
               beforeShow={beforeShow}
               block
               label={getLabel(value)}
               loading={loading}
               onSelect={onSelect}
               ref={ref}
            >
               {options.map(
                  (item: any) =>
                     item && (
                        <Select.Option
                           key={getId(item)}
                           value={item}
                           selected={getId(item) === getId(value)}
                        >
                           <Flex alignItems="center">
                              <Typography>{getLabel(item)}</Typography>
                              <ColorSwab
                                 color={
                                    columnDefinition.cellRendererParams
                                       ? columnDefinition.cellRendererParams.getColor(item)
                                       : ""
                                 }
                                 shape={
                                    // this is an example of how to use the shape property inside the editor
                                    columnDefinition.cellRendererParams?.getShape?.(item) ??
                                    "circle"
                                 }
                              />
                           </Flex>
                        </Select.Option>
                     ),
               )}
            </Select>
         );
      }
      return getComponent();
   },
);

const ColorOnlyDropdown = React.forwardRef<HTMLDivElement, ColorSelectEditorProps>(
   function ColorOnlyDropdown({ columnDefinition, setDataValue, stopEditing, value }, ref) {
      const items = hexColors.map((color: string, i: number) => {
         return {
            id: i.toString(),
            value: color,
         };
      });

      function handleSelect(selection: any) {
         setDataValue(columnDefinition.field, {
            label: "",
            color: selection.item,
            shape: "square",
         } as any);
         stopEditing();
      }

      return (
         <Select onSelect={handleSelect} ref={ref}>
            {items.map((item) => (
               <Select.Option
                  key={item.id}
                  value={item.value}
                  selected={value?.color === item.value}
               >
                  <ColorSwab color={item.value} shape="square" />
               </Select.Option>
            ))}
         </Select>
      );
   },
);

export const CircularPill: React.FunctionComponent<CircularPillProps> = (
   props,
): ReactElement | null => {
   // here value will be an array containing current_assignments and next_assignments
   const { value, columnDefinition } = props;
   // Return null if value is null or undefined
   if (value === null || value === undefined) {
      return null;
   }
   const allAssignments = value?.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id),
   );
   const headerName = columnDefinition.headerName;
   const { I18n } = columnDefinition.cellRendererParams;

   // Helper function to format the overlay text
   const formatOverlayText = (): ReactElement => {
      return (
         <Tooltip.Content style={{ maxWidth: "none" }}>
            <Typography
               intent="h3"
               color="white"
               style={{ fontSize: "12px", lineHeight: "16px", letterSpacing: "0.25px" }}
            >
               {headerName}
               {":"}
            </Typography>
            {allAssignments.map(({ project_name, start_day, end_day, id }) => {
               const startDate = start_day
                  ? formatDateTime(getAttachedDate(start_day), I18n.locale)
                  : "";
               const endDate = end_day ? formatDateTime(getAttachedDate(end_day), I18n.locale) : "";
               return (
                  <div key={id}>
                     <div>
                        {project_name}, {startDate} - {endDate}{" "}
                     </div>
                  </div>
               );
            })}
         </Tooltip.Content>
      );
   };
   const color = allAssignments?.length > 0 ? "blue" : "gray";

   const overlayText = allAssignments?.length > 0 ? formatOverlayText() : "";

   // Render the component
   if (allAssignments?.length > 0) {
      return (
         <Tooltip overlay={overlayText} trigger={["hover", "focus"]}>
            <Pill color={color} style={{ cursor: "pointer" }}>
               {allAssignments.length}
            </Pill>
         </Tooltip>
      );
   } else {
      return <Pill color={color}>{allAssignments?.length}</Pill>;
   }
};

export const ProgressBarColumn: React.FunctionComponent<ProgressBarProps> = ({
   columnDefinition,
   value,
}): ReactElement | null => {
   const { I18n } = columnDefinition.cellRendererParams;
   // Return null if value is null or undefined
   if (value === null || value === undefined) {
      return null;
   }

   const totalPercentAllocated = value.reduce((total, item) => {
      return total + (item.percent_allocated ?? 0);
   }, 0);

   const progressValue =
      value && value[0]?.start_time && value[0]?.end_time
         ? 0
         : Math.max(0, 100 - (totalPercentAllocated ?? 0));

   return (
      <div
         style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
         }}
      >
         <div style={{ width: "50%" }}>
            <ProgressBar data-testid="progress-bar" value={progressValue} />
         </div>
         <div style={{ width: "50%" }}>
            <Typography style={{ marginLeft: "10px" }}>{progressValue}%</Typography>
            <Typography style={{ marginLeft: "10px" }}>
               {value.length > 0 && value[0].end_day
                  ? formatDateTime(getAttachedDate(value[0].end_day), I18n.locale)
                  : "--"}
            </Typography>
         </div>
      </div>
   );
};

export const ColorOnlyEditor = withDataTableEditor(ColorOnlyDropdown, "select");
export const ColorSelectEditor = withDataTableEditor(ColorSelectDropdown, "select");
export const ColorSelectRenderer = withDataTableRenderer(ColorSelectCell, "input");

export const CircularPillRenderer = withDataTableRenderer(CircularPill, "input");

export const ProgressBarRenderer = withDataTableRenderer(ProgressBarColumn, "input");
