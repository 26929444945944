import { app } from "@/views/app";
import { App } from "@/views/app";
import type { RouteCallback } from "@/lib/router";
import { router } from "@/lib/router";
import * as BrowserStorageUtils from "@/lib/utils/browser-storage";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { procoreAuthManager } from "@/lib/managers/procore-auth-manager";

/* ViewModels */
import { AssignmentsViewModel } from "@/views/assignments/assignments";
import { AssignmentsList2 } from "@/views/assignments-list-2/assignments-list-2";
import { ProjectsList2 } from "@/views/projects-list-2/projects-list-2";
import { ProjectDetailViewModel } from "@/views/project-detail/project-detail";
import { LaborPlansViewModel } from "@/views/labor-plans/labor-plans";
import { PeopleList2ViewModel } from "@/views/people-list-2/people-list-2";
import { PersonDetailViewModel } from "@/views/person-detail/person-detail";
import { TimeOffList2 } from "@/views/time-off-list-2/timeoff-list-2";
import { SignUpViewModel } from "@/views/sign-up/sign-up";
import { SettingsViewModel } from "@/views/settings/settings";
import { PageMissingViewModel } from "@/views/page-missing/page-missing";
import { AccessDeniedViewModel } from "@/views/access-denied/access-denied";
import { MessageListViewModel } from "@/views/message-list/message-list";
import { ResetPasswordViewModel } from "@/views/reset-password/reset-password";
import { MapViewModel } from "@/views/map/map";
import { RequestsList3ViewModel } from "@/views/requests-list-3/requests-list-3";
import { ReportsViewModel } from "@/views/reports/reports";
import { LookAheadReportViewModel } from "@/views/reports/vm/look-ahead-report/look-ahead-report";
import { AssignmentHistoryReportViewModel } from "@/views/reports/vm/assignment-history-report/assignment-history-report";
import { TagActionReportViewModel } from "@/views/reports/vm/tag-action-report/tag-action-report";
import { AssignmentAlerts } from "@/views/assignment-alerts/assignment-alerts";
import { ManpowerReportViewModel } from "@/views/reports/vm/manpower-report/manpower-report";
import { Gantt2ViewModel } from "@/views/gantt-2/gantt-2";
import { NewGanttViewModel } from "@/views/new-gantt/gantt.core";
import { ProjectQrProfileViewModel } from "@/views/project-qr-profile/project-qr-profile";
import { PersonQrProfileViewModel } from "@/views/person-qr-profile/person-qr-profile";
import { ActivityViewModel } from "@/views/activity/activity";
import { TotalsViewModel } from "@/views/totals/totals";
import { HomeViewModel } from "@/views/home/home";

/* Models */
import { PermissionLevel } from "@/models/permission-level";
import { maybeInjectTranslations } from "@/translations";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { BoardsViewModel } from "@/views/boards/boards";

export function MainRoutes(basePath?: string): void {
   router.setBasePath(basePath);
   // Any site wides that need to be set.
   const setupDefaultPage: RouteCallback = function (req, next) {
      app.enableGroupSelection();
      return next();
   };
   // Add the base route.
   router.registerRoute("/home", authManager.protect, function () {
      app.disableGroupSelection();
      app.hideBackBtn();
      app.setNavBucket(App.NavBuckets.HOME);
      return app.setContent(App.RouteName.HOME, new HomeViewModel());
   });
   router.registerRoute(
      "/groups/:groupId/assignments",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.VIEW_ASSIGNMENTS], function () {
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.ASSIGNMENTS);
            return app.setContent(
               App.RouteName.ASSIGNMENTS_PAGE,
               new AssignmentsViewModel(req.query ?? null),
            );
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/boards",
      authManager.protect,
      setupDefaultPage,
      async function () {
         await maybeInjectTranslations();
         return authManager.checkActions([PermissionLevel.Action.VIEW_ASSIGNMENTS], function () {
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.ASSIGNMENTS);
            return app.setContent(App.RouteName.BOARDS, new BoardsViewModel());
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/map",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.ACCESS_MAP_PAGE], function () {
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.DASHBOARDS);
            return app.setContent(App.RouteName.MAP, new MapViewModel(req.query ?? null));
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/time-off",
      authManager.protect,
      setupDefaultPage,
      function () {
         return authManager.checkActions(
            [PermissionLevel.Action.VIEW_PEOPLE, PermissionLevel.Action.VIEW_PEOPLE_TIMEOFF],
            async function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.TIME_OFF);
               await maybeInjectTranslations();
               return app.setContent(App.RouteName.TIME_OFF_LIST, new TimeOffList2());
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/projects",
      authManager.protect,
      setupDefaultPage,
      function () {
         return authManager.checkActions([PermissionLevel.Action.VIEW_PROJECT], async function () {
            await maybeInjectTranslations();
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.PROJECTS);
            return app.setContent(App.RouteName.PROJECT_LIST, new ProjectsList2());
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/projects/:projectId",
      authManager.protect,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.VIEW_PROJECT], function () {
            app.disableGroupSelection();
            app.showBackBtn();
            app.setNavBucket(App.NavBuckets.PROJECTS);
            return app.setContent(
               App.RouteName.PROJECT_DETAIL,
               new ProjectDetailViewModel(req.params?.projectId),
            );
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/projects/:projectId/create-labor-plan",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.MANAGE_REQUESTS],
            async function () {
               if (!req.params) throw new Error("Invalid route.");
               app.hideBackBtn();
               await maybeInjectTranslations();
               const fromGantt = req.query && req.query.gantt ? true : false;
               return app.setIsolatedContent(
                  App.RouteName.LABOR_PLANS,
                  new LaborPlansViewModel(req.params.projectId!, req.params.groupId!, fromGantt),
               );
            },
         );
      },
   );
   router.registerRoute("/groups/:groupId/people/:personId", authManager.protect, function (req) {
      return authManager.checkActions([PermissionLevel.Action.VIEW_PEOPLE], function () {
         app.disableGroupSelection();
         app.showBackBtn();
         app.setNavBucket(App.NavBuckets.PEOPLE);
         return app.setContent(
            App.RouteName.PERSON_DETAIL,
            new PersonDetailViewModel(req.params!.personId),
         );
      });
   });
   router.registerRoute(
      "/groups/:groupId/people",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.VIEW_PEOPLE], async function () {
            await maybeInjectTranslations();
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.PEOPLE);
            return app.setContent(
               App.RouteName.PEOPLE_LIST,
               new PeopleList2ViewModel(req.query ?? null),
            );
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/assignments-list",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.VIEW_ASSIGNMENTS],
            async function () {
               await maybeInjectTranslations();
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.ASSIGNMENTS);
               return app.setContent(
                  App.RouteName.ASSIGNMENTS_LIST,
                  new AssignmentsList2(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/reports",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ALLOW_EXPORTING_DATA],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.REPORTS);
               return app.setContent(
                  App.RouteName.REPORTS,
                  new ReportsViewModel(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/reports/look-ahead",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ALLOW_EXPORTING_DATA, PermissionLevel.Action.VIEW_ASSIGNMENTS],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.REPORTS);
               return app.setContent(
                  App.RouteName.LOOK_AHEAD_REPORT,
                  new LookAheadReportViewModel(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/reports/assignment-history",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ALLOW_EXPORTING_DATA, PermissionLevel.Action.VIEW_ASSIGNMENTS],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.REPORTS);
               return app.setContent(
                  App.RouteName.ASSIGNMENT_HISTORY_REPORT,
                  new AssignmentHistoryReportViewModel(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/reports/tag-action",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ALLOW_EXPORTING_DATA, PermissionLevel.Action.VIEW_PEOPLE_TAGS],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.REPORTS);
               return app.setContent(
                  App.RouteName.TAG_ACTION_REPORT,
                  new TagActionReportViewModel(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/reports/workforce",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ALLOW_EXPORTING_DATA, PermissionLevel.Action.VIEW_ASSIGNMENTS],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.REPORTS);
               return app.setContent(
                  App.RouteName.MANPOWER_REPORT,
                  new ManpowerReportViewModel(req.query ?? null),
               );
            },
         );
      },
   );
   router.registerRoute("/groups/:groupId/reports/manpower", function (req) {
      return router.redirect(`/groups/${req.params?.groupId}/reports/workforce`);
   });
   router.registerRoute("/company/:company_id/messages", authManager.protect, function (req) {
      app.disableGroupSelection();
      app.hideBackBtn();
      app.setNavBucket(App.NavBuckets.COMMUNICATIONS);
      return app.setContent(App.RouteName.MESSAGES, new MessageListViewModel(req.query ?? null));
   });
   router.registerRoute(
      "/company/:company_id/assignment-alerts",
      authManager.protect,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.VIEW_ALERTS], function () {
            app.disableGroupSelection();
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.COMMUNICATIONS);
            return app.setContent(
               App.RouteName.ASSIGNMENT_ALERTS,
               new AssignmentAlerts(req.query ?? null),
            );
         });
      },
   );
   router.registerRoute(
      "/groups/:groupId/requests",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.VIEW_REQUESTS], function () {
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.REQUESTS);
            return app.setContent(
               App.RouteName.REQUESTS_LIST,
               new RequestsList3ViewModel(req.query ?? null),
            );
         });
      },
   );
   router.registerRoute("/company/:companyId/settings", authManager.protect, async function (req) {
      app.disableGroupSelection();
      app.hideBackBtn();
      app.setNavBucket(null);
      await maybeInjectTranslations();
      return app.setContent(
         App.RouteName.SETTINGS,
         new SettingsViewModel(req.query ?? null, req.params!.companyId),
      );
   });
   router.registerRoute(
      "/groups/:groupId/gantt",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions(
            [PermissionLevel.Action.ACCESS_GANTT_PAGE, PermissionLevel.Action.VIEW_ASSIGNMENTS],
            async function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.ASSIGNMENTS);
               await maybeInjectTranslations();

               if (typeof req.query?.viewId == "string") {
                  try {
                     const storedSavedView = BrowserStorageUtils.getJsonValue(req.query.viewId);
                     if (storedSavedView) {
                        req.query.savedView = storedSavedView as any;
                     } else {
                        const savedView = (
                           await SavedViewStore.getSavedView(req.query.viewId).payload
                        ).data;
                        if (!savedView) throw new Error("Saved view not found");
                        BrowserStorageUtils.storeJsonValue(req.query.viewId, savedView);
                        req.query.savedView = savedView as any;
                     }
                     delete req.query.viewId;
                  } catch (error) {
                     console.error(
                        `failure while trying to pre-load saved view with ID ${req.query.viewId}:`,
                        error,
                     );
                  }
               }
               return app.setContent(App.RouteName.GANTT, new Gantt2ViewModel(req.query ?? {}));
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/new-gantt",
      authManager.protect,
      setupDefaultPage,
      async function () {
         await maybeInjectTranslations();
         return authManager.checkActions(
            [PermissionLevel.Action.ACCESS_GANTT_PAGE, PermissionLevel.Action.VIEW_ASSIGNMENTS],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.ASSIGNMENTS);
               return app.setContent(App.RouteName.GANTT, new NewGanttViewModel());
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/activity",
      authManager.protect,
      setupDefaultPage,
      function () {
         return authManager.checkOrActions(
            [
               PermissionLevel.Action.VIEW_PEOPLE_ACTIVITY,
               PermissionLevel.Action.VIEW_PROJECT_ACTIVITY,
            ],
            function () {
               app.hideBackBtn();
               app.setNavBucket(App.NavBuckets.DASHBOARDS);
               return app.setContent(App.RouteName.ACTIVITY, new ActivityViewModel());
            },
         );
      },
   );
   router.registerRoute(
      "/groups/:groupId/totals",
      authManager.protect,
      setupDefaultPage,
      function (req) {
         return authManager.checkActions([PermissionLevel.Action.ACCESS_TOTALS_PAGE], function () {
            app.hideBackBtn();
            app.setNavBucket(App.NavBuckets.DASHBOARDS);
            return app.setContent(App.RouteName.TOTALS, new TotalsViewModel(req.query ?? null));
         });
      },
   );

   // Trying to keep the URL short for QR Codes.
   router.registerRoute("/qrc/:companyQrId/pr/:projectQrId", async function (req) {
      app.hideBackBtn();
      await maybeInjectTranslations();
      return app.setIsolatedContent(
         App.RouteName.PROJECT_QR_PROFILE,
         new ProjectQrProfileViewModel(req.params?.companyQrId, req.params?.projectQrId),
         {
            blockDispose: true,
         },
      );
   });
   // Trying to keep the URL short for QR Codes.
   router.registerRoute("/qrc/:companyQrId/pe/:personQrId", function (req) {
      app.hideBackBtn();
      return app.setIsolatedContent(
         App.RouteName.PERSON_QR_PROFILE,
         new PersonQrProfileViewModel(req.params?.companyQrId, req.params?.personQrId),
         {
            blockDispose: true,
         },
      );
   });
   router.registerRoute("/sign-in", function () {
      return router.navigate(null, "/home");
   });
   router.registerRoute("/login", function () {
      return router.navigate(null, "/home");
   });
   router.registerRoute("/sign-up", function () {
      app.hideBackBtn();
      return app.setIsolatedContent(App.RouteName.SIGN_UP, new SignUpViewModel());
   });
   router.registerRoute("/reset-password/:resetId", function (req) {
      app.hideBackBtn();
      return app.setIsolatedContent(
         App.RouteName.REST_PASSWORD,
         new ResetPasswordViewModel(req.params?.resetId),
      );
   });
   router.registerRoute("/oauth/callback", function (req) {
      return procoreAuthManager.oauthCallback(req);
   });
   router.registerRoute(
      "/webclients/host/companies/tools/workforce-planning/oauth/callback",
      function (req) {
         return procoreAuthManager.oauthCallback(req);
      },
   );
   router.registerRoute("/404", function () {
      app.hideBackBtn();
      return app.setIsolatedContent("", new PageMissingViewModel());
   });
   return router.registerRoute("/access-denied", function () {
      app.hideBackBtn();
      return app.setIsolatedContent("", new AccessDeniedViewModel());
   });
}
