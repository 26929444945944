import * as React from "react";
import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   Page,
   Required,
   useI18nContext,
} from "@procore/core-react";
import type { FormikProps } from "formik";
import {
   timeOptions,
   timeZoneOptions,
   timeZoneSearchComparator,
   TIME_ZONES_MAP,
} from "@/lib/utils/timezones";
import type { CreateProjectPayload } from "@laborchart-modules/lc-core-api/dist/api/projects/create-project";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import { ProjectStore } from "@/stores/project-store.core";
import { createProjectSchema } from "./validation-schemas";
import { useGetGroupOptionsQuery } from "../../common/queries/queries";
import type { CreateProjectFormValues } from "./types";
import { PROJECT_STATUS_OPTIONS } from "../../project-list/project-list-prop-types";
import { ColorPicker } from "../../common/components/color-picker";
import { DEFAULT_END_TIME, DEFAULT_START_TIME } from "@/react/shared/constants";

async function createProject(values: CreateProjectFormValues) {
   const payload: CreateProjectPayload = {
      name: values.name,
      job_number: values.job_number,
      status: values.status.id,
      group_ids: values.group_ids.map(({ id }) => id),
      start_date: values.start_date ? new Date(values.start_date).getTime() : null,
      est_end_date: values.est_end_date ? new Date(values.est_end_date).getTime() : null,
      daily_start_time: values.daily_start_time.id,
      daily_end_time: values.daily_end_time.id,
      timezone: TIME_ZONES_MAP[values.timezone?.id] ?? null,
      address_1: values.address_1,
      address_2: values.address_2,
      city_town: values.city_town,
      state_province: values.state_province,
      country: values.country,
      zipcode: values.zipcode,
      bid_rate: values.bid_rate,
      color: values.color,
   };
   const response = await ProjectStore.createProject(payload).payload;
   return response;
}

export function CreateProjectTearsheet(props: {
   onClose: () => void;
   onProjectCreated: (projectId: string) => void;
   callback?: (data: any) => void;
}) {
   const I18n = useI18nContext();
   const { data: groups } = useGetGroupOptionsQuery();
   const validationSchema = createProjectSchema(I18n);

   const initialValues: CreateProjectFormValues = {
      name: "",
      job_number: "",
      status: {
         id: ProjectStatus.ACTIVE,
         label: I18n.t("views.company.workforce_planning.active"),
         color: "green",
      },
      group_ids: [],
      start_date: new Date(),
      est_end_date: null,
      daily_start_time: {
         id: DEFAULT_START_TIME,
         label: timeOptions.find(({ id }) => id === DEFAULT_START_TIME)!.label,
      },
      daily_end_time: {
         id: DEFAULT_END_TIME,
         label: timeOptions.find(({ id }) => id === DEFAULT_END_TIME)!.label,
      },
      timezone: timeZoneOptions[0],
      address_1: "",
      address_2: "",
      city_town: "",
      state_province: "",
      country: "",
      zipcode: "",
      bid_rate: 0,
      color: "#FF7433",
   };

   async function handleSubmit(values: CreateProjectFormValues) {
      const response = await createProject(values);
      props.onProjectCreated(response.data.id);
   }

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            <Page.Header>
               <Page.Title>
                  <H1>{I18n.t("views.company.workforce_planning.projects.create_title")}</H1>
               </Page.Title>
            </Page.Header>
            <Form
               view="create"
               initialValues={initialValues}
               validationSchema={validationSchema}
               onSubmit={handleSubmit}
            >
               {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
               {({ submitForm, isSubmitting }: FormikProps<CreateProjectFormValues>) => (
                  <>
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <Card>
                           <Box padding="md">
                              <Form.Form>
                                 <Form.Row>
                                    <Form.Text
                                       colStart={1}
                                       colWidth={4}
                                       name="name"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.name",
                                       )}
                                       required
                                    />
                                    <Form.Text
                                       colStart={5}
                                       colWidth={4}
                                       name="job_number"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.project_number",
                                       )}
                                    />
                                    <Form.PillSelect
                                       colStart={9}
                                       colWidth={4}
                                       name="status"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.status",
                                       )}
                                       required
                                       options={PROJECT_STATUS_OPTIONS.map((status) => ({
                                          ...status,
                                          label: I18n.t(status.label),
                                       }))}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.MultiSelect
                                       colStart={1}
                                       colWidth={4}
                                       name="group_ids"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.groups",
                                       )}
                                       options={groups ?? []}
                                       required
                                    />
                                    <Form.DateSelect
                                       colStart={5}
                                       colWidth={4}
                                       name="start_date"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.start_date",
                                       )}
                                       required
                                    />
                                    <Form.DateSelect
                                       colStart={9}
                                       colWidth={4}
                                       name="est_end_date"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.est_end_date",
                                       )}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Select
                                       colStart={1}
                                       colWidth={4}
                                       name="daily_start_time"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.daily_start_time",
                                       )}
                                       options={timeOptions}
                                       onSearch={false}
                                    />
                                    <Form.Select
                                       colStart={5}
                                       colWidth={4}
                                       name="daily_end_time"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.daily_end_time",
                                       )}
                                       options={timeOptions}
                                       onSearch={false}
                                    />
                                    <Form.Select
                                       colStart={9}
                                       colWidth={4}
                                       name="timezone"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.timezone",
                                       )}
                                       options={timeZoneOptions}
                                       searchComparator={timeZoneSearchComparator}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Text
                                       colStart={1}
                                       colWidth={4}
                                       name="address_1"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.address",
                                       )}
                                    />
                                    <Form.Text
                                       colStart={5}
                                       colWidth={4}
                                       name="address_2"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.address_2",
                                       )}
                                    />
                                    <Form.Text
                                       colStart={9}
                                       colWidth={4}
                                       name="city_town"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.city",
                                       )}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Text
                                       colStart={1}
                                       colWidth={4}
                                       name="state_province"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.state",
                                       )}
                                    />
                                    <Form.Text
                                       colStart={5}
                                       colWidth={4}
                                       name="zipcode"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.postal",
                                       )}
                                    />
                                    <Form.Text
                                       colStart={9}
                                       colWidth={4}
                                       name="country"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.country",
                                       )}
                                    />
                                 </Form.Row>
                                 <Form.Row>
                                    <Form.Currency
                                       colStart={1}
                                       colWidth={4}
                                       name="bid_rate"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.est_avg_rate",
                                       )}
                                    />
                                    <Form.Field
                                       name="color"
                                       label={I18n.t(
                                          "views.company.workforce_planning.projects.color",
                                       )}
                                       as={ColorPicker}
                                       colStart={5}
                                       colWidth={4}
                                    />
                                 </Form.Row>
                              </Form.Form>
                           </Box>
                        </Card>
                     </Page.Body>
                     <Page.Footer>
                        <Box padding="md">
                           <Flex justifyContent="space-between" alignItems="center">
                              <Required showLabel />
                              <FlexList space="sm">
                                 <Button
                                    variant="tertiary"
                                    disabled={isSubmitting}
                                    onClick={props.onClose}
                                 >
                                    {I18n.t("views.company.workforce_planning.cancel")}
                                 </Button>
                                 <Button type="submit" disabled={isSubmitting} onClick={submitForm}>
                                    {I18n.t("views.company.workforce_planning.create")}
                                 </Button>
                              </FlexList>
                           </Flex>
                        </Box>
                     </Page.Footer>
                  </>
               )}
            </Form>
         </Page.Main>
      </Page>
   );
}
