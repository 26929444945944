import React from "react";
import { createRoot } from "react-dom/client";
import type { DataUploadPropTypes } from "@/react/prop-types";
import { I18nProvider } from "@procore/core-react";
import { DataUpload } from "./components/data-upload/data-upload";
import { ToastAlertProvider } from "@procore/toast-alert";

export default (props: DataUploadPropTypes) => {
   const container = document.getElementById("data-upload-react-mount");
   const root = createRoot(container!);
   root.render(
      <I18nProvider locale={window.I18n.locale as any} translations={window.I18n.translations}>
         <ToastAlertProvider>
            <DataUpload {...props} />
         </ToastAlertProvider>
      </I18nProvider>,
   );
};
