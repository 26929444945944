import { Flex, Typography, Input } from "@procore/core-react";
import type { ReactElement } from "react";
import React from "react";
import type {
   DataTableCellRendererProps,
   DataTableCellEditorProps,
   ColumnDefinition,
} from "@procore/data-table";
import { withDataTableRenderer } from "@procore/data-table";
import type { ProjectTearsheetActions } from "../../tearsheets/project/project-tearsheet";
import { onProjectDetailClick } from "../../tearsheets/project/project-tearsheet";
import { PageTitle } from "@/react/prop-types";

interface CustomLinkCellRendererParams {
   pageTitle: string;
   getProjectDetailDispatch?: React.Dispatch<ProjectTearsheetActions>;
   refreshCustomFields?: () => void;
}

export const CustomLinkCell: React.FunctionComponent<CustomLinkRendererProps> = (
   props,
): ReactElement | null => {
   const { value, data, columnDefinition } = props;
   const pageTitle = columnDefinition.cellRendererParams?.pageTitle ?? "";
   const id = data.project_id ? data.project_id : data.id; // data.project_id is used when this component is used in the assignment list page

   const handleClick = () => {
      // Replacing switch statement with if statement to address the sonarqube code smell
      // We can refactor this to a switch statement if we need to add more cases in the future
      if (pageTitle === PageTitle.PROJECT_LIST) {
         const projectTearsheetDispatch =
            columnDefinition.cellRendererParams?.getProjectDetailDispatch;
         // Open the project detail tearsheet when the user clicks on the project name
         if (projectTearsheetDispatch) {
            onProjectDetailClick(projectTearsheetDispatch, id);
            columnDefinition.cellRendererParams?.refreshCustomFields?.();
         }
      }
   };
   if (value === "" || id === "") {
      return null;
   }
   return (
      <div>
         <Flex alignItems="center">
            <Typography
               title={value}
               intent="small"
               style={{
                  color: "#1D5CC9",
                  fontStyle: "inherit",
                  textDecoration: "underline",
                  cursor: "pointer",
               }}
               onClick={handleClick}
            >
               {value}
            </Typography>
         </Flex>
      </div>
   );
};

export type CustomLinkColumnDefinition = ColumnDefinition<
   string,
   typeof CustomLinkCell,
   CustomLinkCellRendererParams,
   typeof CustomLinkEditor
>;

export type CustomLinkRendererProps = DataTableCellRendererProps<
   string,
   CustomLinkColumnDefinition
>;

// Enabling inline editing for the column would prevent users from clicking on the column to open the tearsheet and view details.

export type CustomLinkEditorProps = DataTableCellEditorProps<string, CustomLinkColumnDefinition>;

export const CustomLinkEditor = React.forwardRef<HTMLInputElement, CustomLinkEditorProps>(
   function CustomLinkEditor({ columnDefinition, setDataValue, stopEditing, value }, ref) {
      /* istanbul ignore next */
      function onBlur(e: React.FocusEvent<HTMLInputElement>) {
         setDataValue(columnDefinition.field, e.target.value);
         stopEditing();
      }
      return (
         <div>
            <Input ref={ref} defaultValue={value} onBlur={onBlur} title={value} />
         </div>
      );
   },
);

export const CustomLinkCellRenderer = withDataTableRenderer(CustomLinkCell, "input");
