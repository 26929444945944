import React, { useEffect, useState } from "react";
import { Pencil } from "@procore/core-icons";
import {
   Box,
   Button,
   Card,
   DateSelect,
   Flex,
   FlexList,
   Form,
   Grid,
   H1,
   H3,
   Menu,
   Page,
   Spinner,
   Tearsheet,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import { useToastAlertContext } from "@procore/toast-alert";
import { ErrorBanner } from "../../error-banner";
import { useRowSelectionState } from "@procore/data-table";
import type { CustomField, IntegratedField, TagOption } from "@/react/prop-types";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { GENDER_OPTIONS } from "../../data-table/data-table-utils";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useGetGroupOptionsQuery } from "../../common/queries/queries";
import {
   useGetJobTitles,
   useGetPermissionLevelOptions,
   useGetTagOptionsQuery,
} from "../../common/queries/queries";
import type {
   EditPeopleFormValues,
   PeopleListData,
} from "../../people-list/people-list-prop-types";
import { PEOPLE_STATUS_OPTIONS } from "../../people-list/people-list-prop-types";
import { DefaultStoreCore } from "@/stores/default-store.core";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { PersonStore } from "@/stores/person-store.core";
import type { FormikProps } from "formik";
import { isIntegratedField } from "@/react/shared/helper";
import { groupByCategories } from "../helper";
import { renderCustomFields } from "@/react/shared/custom-field-utils";
import type { BulkEditPeopleTearsheetPropTypes } from "./types";
import { getGroupIdsForNoSelection, getSelectedTagIds } from "./person-detail-helper";
import { legacyFieldNamesMap } from "../../people-list/helpers";

export const BulkEditPeopleTearsheet = (props: BulkEditPeopleTearsheetPropTypes) => {
   const { tableApi, integratedFields, customFields } = props;
   const I18n = useI18nContext();
   const [isOpen, setIsOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const selectedRows = useRowSelectionState().map((x: any) => x.data);
   const isAdminSelected = selectedRows.filter((x: any) => x.permission_level?.is_admin).length > 0;
   const { showToast } = useToastAlertContext();
   const { groupId } = useGroupContext();
   const { data: groupOptions } = useGetGroupOptionsQuery();
   const { data: jobTitles } = useGetJobTitles(groupId);
   const { data: permission_levels } = useGetPermissionLevelOptions();
   const { data: tagOptions } = useGetTagOptionsQuery();

   const [peopleIntegratedFields, setPeopleIntegratedFields] = useState<IntegratedField[]>([]);
   const [languageOptions, setLanguageOptions] = useState<Array<{ id: string; label: string }>>([]);
   const [tagsLoading, setTagsLoading] = useState(true);
   const [selectedTagId, setSelectedTagId] = useState("");
   const [groupedItems, setGroupedItems] = useState<{ [key: string]: any }>({});
   const [filteredTagOptions, setFilteredTagOptions] = useState<TagOption[]>(tagOptions ?? []);
   const [expireDateDisplay, setExpireDateDisplay] = useState(false);
   const [expirationDate, setExpirationDate] = useState<Date | null>(null);

   useEffect(() => {
      if (tagOptions?.length) {
         setTagsLoading(false);
         setFilteredTagOptions(tagOptions);
      }
   }, [tagOptions]);

   const initialValues: EditPeopleFormValues = {
      gender: undefined,
      group_ids: [],
      job_title: undefined,
      permission_level: null,
      hourly_wage: "",
      language: undefined,
      status: undefined,
      country: "",
      state_province: "",
   };

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            setPeopleIntegratedFields(integratedFields.data.people_integrated_fields);
         }
      }
   }, [integratedFields]);

   const handleClose = async () => {
      setIsOpen(false);
      setError(false);
      setLoading(false);
   };

   const handleSubmit = async (values: EditPeopleFormValues) => {
      const basePayload: any = {
         is_user: values.type
            ? values.type.value === "user" || values.type.value === "assignable_user"
            : undefined,
         is_assignable: values.type
            ? values.type.value === "assignable_user" || values.type?.value === "assignable"
            : undefined,
         permission_level_id: values.permission_level ? values.permission_level.id : null,
         job_title_id: values.job_title?.id,
         status: values.status?.id,
         gender: values.gender?.id,
         language: values.language?.id,
         group_ids: groupOptions!
            .map(({ id }) => ({
               [id]: Boolean(values.group_ids?.find(({ id: groupId }) => groupId === id)),
            }))
            .reduce((acc, next) => ({ ...acc, ...next })),
         hourly_wage: values.hourly_wage,
         tag_instances: selectedTagId
            ? {
                 [selectedTagId]: {
                    expr_date: expirationDate ? getDetachedDay(expirationDate) : null,
                 },
              }
            : null,
         state_province: values.state_province,
         country: values.country,
      };

      // Remove group_ids if all groups are unchecked, this to prevent sending empty group_ids
      // and remove the groups from the projects selected
      const notGroupsSelected = Object.values(basePayload.group_ids).every((value) => !value);

      Object.entries(basePayload).forEach(([fieldName]) => {
         // Removing integrated fields from basePayload as they are not editable
         if (isIntegratedField(legacyFieldNamesMap[fieldName], peopleIntegratedFields)) {
            delete basePayload[fieldName];
         }
         // Remove empty fields from basePayload
         if (
            basePayload[fieldName] === null ||
            basePayload[fieldName] === "" ||
            basePayload[fieldName] === undefined ||
            (typeof basePayload[fieldName] === "object" &&
               Object.keys(basePayload[fieldName]).length === 0)
         ) {
            delete basePayload[fieldName];
         }
      });

      if (customFields) {
         basePayload.custom_fields = processCustomFields(customFields, values);
      }
      const selectedTag = tagOptions?.find((tags) => tags.id === selectedTagId);

      const payload = selectedRows
         .filter((x) => !x.excluded)
         .map((x: PeopleListData) => ({
            id: x.id,
            ...basePayload,
            group_ids: notGroupsSelected
               ? getGroupIdsForNoSelection(x, isAdminSelected, groupOptions!)
               : basePayload.group_ids,
            tag_instances: getSelectedTagIds(x, selectedTagId, expirationDate, selectedTag),
         }));

      try {
         setLoading(true);
         await PersonStore.updatePeopleStream(payload).stream;
         showToast.success(
            I18n.t("views.company.workforce_planning.people.bulk_edit.success", {
               count: selectedRows.length,
            }),
         );
         tableApi?.refreshServerSide({});
         setIsOpen(false);
         setLoading(false);
         setExpirationDate(null);
         setExpireDateDisplay(false);
         setSelectedTagId("");
      } catch (err) {
         setLoading(false);
         setError(true);
      }
   };

   /* istanbul ignore next */
   const handleExpireDateDisplay = (item: any) => {
      setSelectedTagId(item);
      setExpireDateDisplay(false);

      const selectedTag = tagOptions?.find((tag: any) => tag.id === item);

      if (selectedTag?.require_expr_date) {
         setExpireDateDisplay(true);
      }
   };
   const getDefaultLanguageOptions = (): Array<{ id: string; label: string }> => {
      return DefaultStoreCore.LanguageOptions.map((language) => ({
         id: language.value,
         label: language.name,
      }));
   };

   useEffect(() => {
      setLanguageOptions(getDefaultLanguageOptions());
   }, []);

   function processCustomFields(
      customFields: CustomField[],
      values: Record<string, any>,
   ): { [key: string]: any } {
      return customFields.reduce((acc, customField) => {
         const updatedValue =
            customField.name in values ? values[customField.name] : customField.value;
         if (
            !(
               isIntegratedField(customField.id, peopleIntegratedFields) ||
               customField.integration_only
            )
         ) {
            let processedValue;
            switch (customField.type) {
               case CustomFieldType.DATE:
                  processedValue = updatedValue
                     ? getDetachedDay(new Date(updatedValue))
                     : undefined;
                  break;
               case CustomFieldType.MULTI_SELECT:
                  processedValue =
                     updatedValue && updatedValue.length > 0
                        ? updatedValue.map((v: any) => v.label)
                        : undefined;
                  break;
               case CustomFieldType.SELECT:
                  processedValue = updatedValue ? updatedValue.label : undefined;
                  break;
               default:
                  processedValue = updatedValue;
                  break;
            }
            if (processedValue !== undefined) {
               acc[customField.id as string] = processedValue;
            }
         }
         return acc;
      }, {} as { [key: string]: any });
   }

   const FilterTags = (searchTerm: string) => {
      const filteredTags = tagOptions?.filter((tag) =>
         tag.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
      );
      if (filteredTags) setFilteredTagOptions(filteredTags);
   };

   useEffect(() => {
      setGroupedItems(groupByCategories(filteredTagOptions));
   }, [filteredTagOptions]);

   const sortByCategory = ([a]: [string, any], [b]: [string, any]) => {
      if (a === "All Tags") return 1;
      if (b === "All Tags") return -1;
      return 0;
   };

   const sortedEntries = Object.entries(groupedItems).sort(sortByCategory);

   return (
      <>
         <Button
            onClick={() => setIsOpen(true)}
            icon={<Pencil />}
            variant="tertiary"
            data-testid="edit-btn"
         />
         <Tearsheet open={isOpen} onClose={handleClose} data-testid="tearsheet">
            <Spinner loading={loading}>
               <Page>
                  <Page.Main
                     style={{
                        width: 800,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                     }}
                  >
                     <Page.Header>
                        {error && (
                           <Page.Banner style={{ width: "400px" }}>
                              <ErrorBanner
                                 title={I18n.t("views.company.workforce_planning.error")}
                                 content={I18n.t(
                                    "views.company.workforce_planning.people.bulk_edit.error",
                                 )}
                              />
                           </Page.Banner>
                        )}
                        <Page.Title>
                           <H1>
                              {I18n.t("views.company.workforce_planning.people.bulk_edit.header")}
                           </H1>
                        </Page.Title>
                     </Page.Header>

                     <Form view="update" onSubmit={handleSubmit} initialValues={initialValues}>
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {({ setFieldValue, values }: FormikProps<any>) => {
                           return (
                              <Form.Form>
                                 <Page.Body style={{ width: "100%", marginBottom: 20 }}>
                                    <Card>
                                       <Box padding="md">
                                          <Form.Row>
                                             <Form.Select
                                                name="gender"
                                                options={GENDER_OPTIONS.map((item) => ({
                                                   ...item,
                                                   label: I18n.t(
                                                      `views.company.workforce_planning.people.${item.id}`,
                                                   ),
                                                }))}
                                                colStart={1}
                                                colWidth={6}
                                                onSearch={false}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.gender",
                                                )}
                                                disabled={isIntegratedField(
                                                   "gender",
                                                   peopleIntegratedFields,
                                                )}
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.people.gender",
                                                )}
                                             />
                                             <Form.MultiSelect
                                                name="group_ids"
                                                colStart={7}
                                                colWidth={6}
                                                options={groupOptions ?? []}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.groups",
                                                )}
                                                disabled={
                                                   isIntegratedField(
                                                      "group_ids",
                                                      peopleIntegratedFields,
                                                   ) || isAdminSelected
                                                } // Admin users cannot be assigned to groups; they have access to all groups
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.people.groups",
                                                )}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                colStart={1}
                                                colWidth={6}
                                                name="job_title"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.job_title",
                                                )}
                                                options={jobTitles ?? []}
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.people.job_title",
                                                )}
                                                disabled={isIntegratedField(
                                                   "position_id",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                             <Form.PillSelect
                                                colStart={7}
                                                colWidth={6}
                                                name="status"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.status",
                                                )}
                                                options={PEOPLE_STATUS_OPTIONS.map((status) => ({
                                                   ...status,
                                                   label: I18n.t(status.label),
                                                }))}
                                                disabled={isIntegratedField(
                                                   "status",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.RadioButtons
                                                colStart={1}
                                                colWidth={6}
                                                name="type"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.type",
                                                )}
                                                options={[
                                                   {
                                                      id: 0,
                                                      label: (
                                                         <Typography>
                                                            {`${I18n.t(
                                                               "views.company.workforce_planning.people.assignable",
                                                            )} `}
                                                            <i>
                                                               {I18n.t(
                                                                  "views.company.workforce_planning.people.assignable_desc",
                                                               )}
                                                            </i>
                                                         </Typography>
                                                      ),
                                                      value: "assignable",
                                                   },
                                                   {
                                                      id: 1,
                                                      label: (
                                                         <Typography>
                                                            {`${I18n.t(
                                                               "views.company.workforce_planning.people.assignable_user",
                                                            )} `}
                                                            <i>
                                                               {I18n.t(
                                                                  "views.company.workforce_planning.people.assignable_user_desc",
                                                               )}
                                                            </i>
                                                         </Typography>
                                                      ),
                                                      value: "assignable_user",
                                                   },
                                                   {
                                                      id: 2,
                                                      label: (
                                                         <Typography>
                                                            {`${I18n.t(
                                                               "views.company.workforce_planning.people.user",
                                                            )} `}
                                                            <i>
                                                               {I18n.t(
                                                                  "views.company.workforce_planning.people.user_desc",
                                                               )}
                                                            </i>
                                                         </Typography>
                                                      ),
                                                      value: "user",
                                                   },
                                                ]}
                                                disabled={isIntegratedField(
                                                   "person_type",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                             <Form.Select
                                                colStart={7}
                                                colWidth={6}
                                                name="permission_level"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.permission_level",
                                                )}
                                                options={permission_levels}
                                                disabled={
                                                   !values.type ||
                                                   values.type.value === "assignable"
                                                }
                                                /* istanbul ignore next */
                                                onSelect={(e) => {
                                                   const { item } = e;
                                                   if (item.is_admin) {
                                                      // Admins has access to all groups
                                                      setFieldValue("group_ids", []);
                                                   }
                                                }}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                colStart={1}
                                                colWidth={6}
                                                name="language"
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.language",
                                                )}
                                                options={languageOptions ?? []}
                                                placeholder={I18n.t(
                                                   "views.company.workforce_planning.people.language",
                                                )}
                                                disabled={isIntegratedField(
                                                   "language",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                             <Form.Currency
                                                name="hourly_wage"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.hourly_wage",
                                                )}
                                                disabled={isIntegratedField(
                                                   "hourly_wage",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Text
                                                name="state_province"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.state",
                                                )}
                                                disabled={isIntegratedField(
                                                   "state_province",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                             <Form.Text
                                                name="country"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.people.country",
                                                )}
                                                disabled={isIntegratedField(
                                                   "country",
                                                   peopleIntegratedFields,
                                                )}
                                             />
                                          </Form.Row>

                                          <Box padding="sm">
                                             <Grid.Row>
                                                <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                                   <H3>
                                                      {I18n.t(
                                                         "views.company.workforce_planning.details_tags.tag_title",
                                                      )}{" "}
                                                   </H3>
                                                </Grid.Col>
                                                {expireDateDisplay && (
                                                   <Grid.Col colWidth={6}>
                                                      <H3>
                                                         {I18n.t(
                                                            "views.company.workforce_planning.details_tags.tags_expiration_date_label",
                                                         )}
                                                      </H3>
                                                   </Grid.Col>
                                                )}
                                             </Grid.Row>
                                             <Grid.Row>
                                                <Grid.Col colWidth={6} style={{ display: "flex" }}>
                                                   <Box>
                                                      {tagsLoading &&
                                                      filteredTagOptions?.length == 0 ? (
                                                         <Typography>
                                                            {I18n.t(
                                                               "views.company.workforce_planning.project_details_tags.no_tags",
                                                            )}
                                                         </Typography>
                                                      ) : (
                                                         <Card shadowStrength={2}>
                                                            <Menu
                                                               scrollable
                                                               style={{ maxHeight: "300px" }}
                                                               onSelect={({ item }) =>
                                                                  handleExpireDateDisplay(item)
                                                               }
                                                               onSearch={(e) =>
                                                                  FilterTags(e.target.value || "")
                                                               }
                                                            >
                                                               <Menu.Search
                                                                  placeholder={I18n.t(
                                                                     "views.company.workforce_planning.details_tags.tag_search_placeholder",
                                                                  )}
                                                               />
                                                               <Menu.Header>
                                                                  <Box padding="lg">
                                                                     <Typography
                                                                        color="gray70"
                                                                        italic
                                                                        style={{
                                                                           display: "flex",
                                                                           justifyContent: "center",
                                                                        }}
                                                                     >
                                                                        {I18n.t(
                                                                           "views.company.workforce_planning.details_tags.tag_menu_header",
                                                                        )}
                                                                     </Typography>
                                                                  </Box>
                                                               </Menu.Header>
                                                               <Menu.Options>
                                                                  {sortedEntries.length > 0 ? (
                                                                     sortedEntries.map(
                                                                        ([category, items]) => (
                                                                           <React.Fragment
                                                                              key={category}
                                                                           >
                                                                              <Menu.Group>
                                                                                 {category}
                                                                              </Menu.Group>
                                                                              {items.map(
                                                                                 (item: any) => (
                                                                                    <Menu.Item
                                                                                       selected={
                                                                                          selectedTagId ===
                                                                                          item.id
                                                                                       }
                                                                                       key={item.id}
                                                                                       item={
                                                                                          item.id
                                                                                       }
                                                                                       style={{
                                                                                          backgroundColor:
                                                                                             "white",
                                                                                       }}
                                                                                    >
                                                                                       {item.name}
                                                                                    </Menu.Item>
                                                                                 ),
                                                                              )}
                                                                           </React.Fragment>
                                                                        ),
                                                                     )
                                                                  ) : (
                                                                     <Menu.Item
                                                                        style={{
                                                                           pointerEvents: "none",
                                                                        }}
                                                                     >
                                                                        {I18n.t(
                                                                           "views.company.workforce_planning.project_details_tags.no_tags",
                                                                        )}
                                                                     </Menu.Item>
                                                                  )}
                                                               </Menu.Options>
                                                            </Menu>
                                                         </Card>
                                                      )}
                                                   </Box>
                                                </Grid.Col>
                                                {expireDateDisplay && (
                                                   <Grid.Col colWidth={6}>
                                                      <DateSelect
                                                         onChange={(date) => {
                                                            setExpirationDate(date);
                                                         }}
                                                         value={expirationDate ?? undefined}
                                                      />
                                                   </Grid.Col>
                                                )}
                                             </Grid.Row>
                                          </Box>

                                          {customFields && (
                                             <>
                                                <hr />
                                                {renderCustomFields(customFields, I18n, false)}
                                             </>
                                          )}
                                       </Box>
                                    </Card>
                                 </Page.Body>
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="flex-end" alignItems="center">
                                          <FlexList space="md">
                                             <Button variant="tertiary" onClick={handleClose}>
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button variant="primary" type="submit">
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              </Form.Form>
                           );
                        }}
                     </Form>
                  </Page.Main>
               </Page>
            </Spinner>
         </Tearsheet>
      </>
   );
};
