import type { FormFieldValueComponentProps } from "@procore/core-react";
import { FlexList, RadioButton, Typography, useI18nContext } from "@procore/core-react";
import React, { useEffect } from "react";
import { getPersonTypes } from "./tearsheet-utils";
import { handleRadioClick } from "./person-detail-helper";
import { FieldMapping } from "../../people-list/people-list-enums";
import type { FieldProps } from "formik";
import type { PersonDetailFormValues } from "./prop-types";
import { ActionMode } from "@/react/prop-types";
export interface RadioButtonFieldProps extends FieldProps {
   initialValues: PersonDetailFormValues;
   setFieldValue: (field: string, value: any) => void;
   setDisabledField: React.Dispatch<React.SetStateAction<{ [fieldName: string]: boolean }>>;
   formViewMode?: "read" | "update";
   disabledField: boolean;
}

export function RadioButtonField(
   props: Readonly<FormFieldValueComponentProps> & RadioButtonFieldProps,
) {
   const { initialValues, setFieldValue, setDisabledField, formViewMode, disabledField } = props;
   const I18n = useI18nContext();
   const [checkedValue, setCheckedValue] = React.useState<{
      id: number;
      label: React.JSX.Element;
      value: string;
   }>(props.field.input.value);

   const personTypes = getPersonTypes(I18n);

   useEffect(() => {
      setCheckedValue(props.field.input.value);
   }, [props.field.input.value]);

   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value;
      personTypes.forEach((type) => {
         if (type.value === value) {
            setCheckedValue(type);
            setFieldValue(FieldMapping.type, type);
         }
      });
      handleRadioClick(e, initialValues, setFieldValue, setDisabledField);
   }
   return (
      <FlexList space="sm" direction="column">
         <RadioButton
            id={"0"}
            checked={checkedValue?.value === "assignable"}
            value={"assignable"}
            onChange={(e) => handleChange(e)}
            disabled={formViewMode === ActionMode.READ ? true : disabledField}
         >
            <Typography>
               {`${I18n.t("views.company.workforce_planning.people.assignable")} `}
               <i>{I18n.t("views.company.workforce_planning.people.assignable_desc")}</i>
            </Typography>
         </RadioButton>
         <RadioButton
            id={"1"}
            checked={checkedValue?.value === "assignable_user"}
            value={"assignable_user"}
            onChange={(e) => handleChange(e)}
            disabled={formViewMode === ActionMode.READ ? true : disabledField}
         >
            <Typography>
               {`${I18n.t("views.company.workforce_planning.people.assignable_user")} `}
               <i>{I18n.t("views.company.workforce_planning.people.assignable_user_desc")}</i>
            </Typography>
         </RadioButton>
         <RadioButton
            id={"2"}
            checked={checkedValue?.value === "user"}
            value={"user"}
            onChange={(e) => handleChange(e)}
            disabled={formViewMode === ActionMode.READ ? true : disabledField}
         >
            <Typography>
               {`${I18n.t("views.company.workforce_planning.people.user")} `}
               <i>{I18n.t("views.company.workforce_planning.people.user_desc")}</i>
            </Typography>
         </RadioButton>
      </FlexList>
   );
}
