import type {
   ColumnMap,
   CustomField,
   SortModel,
   StreamResponseConversion,
} from "@/react/prop-types";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { CellValueChangeParams, DataTableConfig } from "@procore/data-table";
import {
   convertDataTableFilters,
   createDefaultColumnState,
   sortOrderMap,
} from "../data-table/data-table-utils";
import { FieldMapping, FindPeopleSortBy } from "./people-list-enums";
import type { CoreApiSort, PeopleListServerFilter } from "./people-list-prop-types";
import { CustomFieldType } from "@laborchart-modules/common";
import { PersonStore } from "@/stores/person-store.core";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import MultiSelectPillFilter from "../data-table/custom-filters/multiselect-pill-filter";
import { StatusFilter } from "../data-table/custom-filters/status-filter";
import { NumericValueFilter } from "../data-table/custom-filters/numeric-value-filter";
import { DateFilter } from "../data-table/custom-filters/date-filter";

export function convertDataTableSort(
   sort: SortModel[],
   isLastNameFirst: boolean = false,
): CoreApiSort {
   sortFieldMaps.person = isLastNameFirst
      ? FindPeopleSortBy.PERSON_NAME_LAST
      : FindPeopleSortBy.PERSON_NAME_FIRST;
   if (sort.length == 0) return {};
   const sortObj: CoreApiSort = {
      sort_by: sortFieldMaps[sort[0].field],
      direction: sortOrderMap[sort[0].sort],
   };
   // If the sort field is custom_field, then add the custom_field_id
   // fieldId will only be there in case of custom_field, it has been added explicitly to identify the custom_field
   if (sort[0].fieldId) {
      sortObj["sort_by"] = sortFieldMaps["custom_field"];
      sortObj["custom_field_id"] = sort[0].fieldId;
   }
   return sortObj;
}

export const defaultPeopleListTableConfig: DataTableConfig = {
   columnState: [
      {
         ...createDefaultColumnState(),
         field: "person",
         sort: "asc",
         sortIndex: 0,
      },
      {
         ...createDefaultColumnState(),
         field: "employee_number",
         width: 240,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "permission",
         width: 230,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "type",
         width: 230,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "job_title",
         width: 250,
      },
      {
         ...createDefaultColumnState(),
         field: "assignments",
         width: 368,
      },
      {
         ...createDefaultColumnState(),
         field: "current_assignments",
         width: 250,
      },
      {
         ...createDefaultColumnState(),
         field: "address_1",
         width: 368,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "address_2",
         width: 368,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "city_town",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "state_province",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "zipcode",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "country",
         width: 200,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "tag_instances",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "status",
         width: 140,
      },
      {
         ...createDefaultColumnState(),
         field: "email",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "hourly_wage",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "notification_profile",
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "phone",
         width: 200,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "gender",
         width: 200,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "language",
         width: 230,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "hired_date",
         width: 200,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "dob",
         width: 200,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "can_receive_email",
         width: 100,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "can_receive_mobile",
         width: 100,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "can_receive_sms",
         width: 100,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "groups",
         width: 140,
         hidden: true,
      },
      {
         ...createDefaultColumnState(),
         field: "linked_to_procore",
         width: 50,
         hidden: true,
      },
   ],
   columnGroupState: [],
   filters: {},
   rowHeight: 49,
   serverFilters: [],
};

export function filterGroupDetails({
   groupOptions,
   personGroupList,
}: {
   groupOptions: StreamResponseConversion | null;
   personGroupList: string[];
}) {
   // Filter the groupOptions based on the personGroupList
   const filtered = groupOptions?.filter((groupOption) => personGroupList.includes(groupOption.id));
   return filtered;
}

const DATE_FIELDS: any[] = ["hired_date"];
const NUMERIC_FIELDS: any[] = ["hourly_wage"];
const NESTED_FIELDS_TYPE: any[] = [FilterFieldType.BOOL, FilterFieldType.MULTI_SELECT];
const NUMERIC_FIELDS_TYPE: any[] = [FilterFieldType.NUMBER, FilterFieldType.CURRENCY];

export const convertPeopleDataTableFilters = (filters: PeopleListServerFilter[]) =>
   convertDataTableFilters(
      filters,
      {
         nameMapper: filterNameMaps,
         typeMapper: filterTypeMaps,
         propertyMapper: filterPropertyMaps,
      },
      {
         numeric: NUMERIC_FIELDS,
         nestedTypes: NESTED_FIELDS_TYPE,
         numericTypes: NUMERIC_FIELDS_TYPE,
         date: DATE_FIELDS,
      },
   );

export const filterNameMaps: { [key: string]: string } = {
   hired_date: "People Hired Date",
   status: "People Status",
   tag_instances: "People Tags",
   job_title: "People Job Titles",
   gender: "People Gender",
   type: "People Type",
   hourly_wage: "People Hourly Wage",
};

export const filterFieldMap: ColumnMap = {
   tag_instances: "tag_instances",
   status: "status",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   start_date: "start_date",
   est_end_date: "est_end_date",
   job_title: "job_title",
   gender: "gender",
   type: "type",
   hourly_wage: "hourly_wage",
};

export const filterRendererMap: ColumnMap = {
   tag_instances: MultiSelectPillFilter,
   status: StatusFilter,
   bid_rate: NumericValueFilter,
   percent_complete: NumericValueFilter,
   start_date: DateFilter,
   est_end_date: DateFilter,
   hourly_wage: NumericValueFilter,
};

export const columnHeadersMap: ColumnMap = {
   name: "person",
   permission: "permission",
   employee_number: "employee_number",
   position: "job_title",
   address_1: "address_1",
   address_2: "address_2",
   city_town: "city_town",
   state_province: "state_province",
   zip_code: "zipcode",
   type: "project_type",
   status: "status",
   customer_name: "customer_name",
   created_by: "created_by",
   created_at: "created_at",
   country: "country",
   start_date: "start_date",
   est_end_date: "est_end_date",
   daily_start_time: "daily_start_time",
   daily_end_time: "daily_end_time",
   dob: "dob",
   timezone: "timezone",
   bid_rate: "bid_rate",
   percent_complete: "percent_complete",
   wage_overrides: "wage_overrides",
   groups: "groups",
   tag_instances: "tag_instances",
   procore_id: "procore_id",
   next_assignments: "assignments",
   current_assignments: "current_assignments",
   email: "email",
   hourly_wage: "hourly_wage",
   notification_profile: "notification_profile",
   phone: "phone",
   gender: "gender",
   language: "language",
   hired_date: "hired_date",
   can_receive_email: "can_receive_email",
   can_receive_mobile: "can_receive_mobile",
   can_receive_sms: "can_receive_sms",
   linked_to_procore: "linked_to_procore",
};

const filterTypeMaps: { [key: string]: FilterFieldType } = {
   hired_date: FilterFieldType.DATE,
   job_title: FilterFieldType.SELECT,
   status: FilterFieldType.SELECT,
   gender: FilterFieldType.BOOL,
   type: FilterFieldType.SELECT,
   hourly_wage: FilterFieldType.NUMBER,
   tag_instances: FilterFieldType.MULTI_SELECT,

   date: FilterFieldType.DATE,
   bool: FilterFieldType.BOOL,
   currency: FilterFieldType.CURRENCY,
   "multi-select": FilterFieldType.MULTI_SELECT,
   number: FilterFieldType.NUMBER,
   select: FilterFieldType.SELECT,
   text: FilterFieldType.TEXT,
};

const filterPropertyMaps: { [key: string]: string } = {
   job_title: "position_id",
   status: "status",
   gender: "is_male",
   type: "person_type",
   hourly_wage: "hourly_wage",
   tag_instances: "tag_instances",
};

export const sortFieldMaps: { [key: string]: FindPeopleSortBy } = {
   person: FindPeopleSortBy.PERSON_NAME_FIRST,
   permission: FindPeopleSortBy.PERMISSION,
   job_title: FindPeopleSortBy.JOB_TITLE,
   status: FindPeopleSortBy.PERSON_STATUS,
   email: FindPeopleSortBy.EMAIL,
   hourly_wage: FindPeopleSortBy.HOURLY_WAGE,
   address_1: FindPeopleSortBy.ADDRESS_1,
   address_2: FindPeopleSortBy.ADDRESS_2,
   city_town: FindPeopleSortBy.CITY_TOWN,
   state_province: FindPeopleSortBy.STATE_PROVINCE,
   zipcode: FindPeopleSortBy.ZIPCODE,
   hired_date: FindPeopleSortBy.HIRED_DATE,
   language: FindPeopleSortBy.LANGUAGE,
   employee_number: FindPeopleSortBy.EMPLOYEE_NUMBER,
   phone: FindPeopleSortBy.PHONE,
   dob: FindPeopleSortBy.DOB,
   can_receive_email: FindPeopleSortBy.CAN_RECIEVE_EMAIL,
   can_receive_mobile: FindPeopleSortBy.CAN_RECIEVE_MOBILE,
   can_receive_sms: FindPeopleSortBy.CAN_RECIEVE_SMS,
   custom_field: FindPeopleSortBy.CUSTOM_FIELD,
   country: FindPeopleSortBy.COUNTRY,
   notification_profile: FindPeopleSortBy.NOTIFICATION_PROFILE,
   current_assignments: FindPeopleSortBy.CURRENT_ASSIGNMENTS,
};

export const fieldTranslationKeyMap: ColumnMap = {
   person: "views.company.workforce_planning.people.person_name",
   employee_number: "views.company.workforce_planning.people.employee_id",
   permission: "views.company.workforce_planning.people.permission",
   job_title: "views.company.workforce_planning.people.job_title",
   address_1: "views.company.workforce_planning.people.address",
   address_2: "views.company.workforce_planning.people.address_2",
   city_town: "views.company.workforce_planning.people.city",
   state_province: "views.company.workforce_planning.people.state",
   zipcode: "views.company.workforce_planning.people.postal",
   country: "views.company.workforce_planning.people.country",
   tag_instances: "views.company.workforce_planning.people.tags",
   status: "views.company.workforce_planning.people.status",
   email: "views.company.workforce_planning.people.email",
   hourly_wage: "views.company.workforce_planning.people.hourly_wage",
   notification_profile: "views.company.workforce_planning.people.notification_profile",
   phone: "views.company.workforce_planning.people.phone_number",
   gender: "views.company.workforce_planning.people.gender",
   language: "views.company.workforce_planning.people.language",
   hired_date: "views.company.workforce_planning.people.hired_date",
   dob: "views.company.workforce_planning.people.birth_date",
   can_receive_email: "views.company.workforce_planning.people.receive_email",
   can_receive_mobile: "views.company.workforce_planning.people.receive_mobile",
   can_receive_sms: "views.company.workforce_planning.people.receive_sms",
   groups: "views.company.workforce_planning.people.groups",
   linked_to_procore: "views.company.workforce_planning.people.linked_to_procore",
   assignments: "views.company.workforce_planning.people.assignments",
   current_assignments: "views.company.workforce_planning.people.availability_until",
};

export const legacyFieldNamesMap: ColumnMap = {
   person: "name",
   employee_number: "employee_number",
   permission: "permission",
   job_title: "position",
   address_1: "address_1",
   address_2: "address_2",
   city_town: "city_town",
   state_province: "state_province",
   zipcode: "zipcode",
   country: "country",
   tag_instances: "tag_instances",
   status: "status",
   email: "email",
   hourly_wage: "hourly_wage",
   notification_profile: "notification_profile_id",
   phone: "phone",
   gender: "is_male",
   language: "language",
   hired_date: "hired_date",
   dob: "dob",
   can_receive_email: "can_recieve_email",
   can_receive_mobile: "can_recieve_mobile",
   can_receive_sms: "can_recieve_sms",
   groups: "group_ids",
   linked_to_procore: "has_procore_mapping",
   first_name: "first_name",
   last_name: "last_name",
   type: "person_type",
   is_user: "person_type",
   is_assignable: "person_type",
   emergency_contact_name: "emergency_contact_name",
   emergency_contact_number: "emergency_contact_number",
   emergency_contact_relation: "emergency_contact_relation",
   emergency_contact_email: "emergency_contact_email",
   permission_level_id: "permission_level_id",
   position_id: "position_id",
   job_title_id: "position_id",
   // This naming should be fleshed out once the legacy people list is gone.
   current_assignments: "current_assignments",
   availability_until: "assignments",
   assignments: "assignments_count",
};

// added this function over here to improve testability
export const updatePeople = async (
   params: CellValueChangeParams<any>,
   customFields: CustomField[] | null,
) => {
   const { id } = params.rowData;
   const { field: fieldName, newValue } = params;

   // Build payload based on field type and custom field properties
   const payload = { id, ...buildFieldPayload(fieldName, newValue, customFields) };

   // Send the payload to ProjectStore and return the response
   return PersonStore.updatePeopleStream([payload]);
};

// Helper to build field-specific parts of the payload
export const buildFieldPayload = (
   fieldName: string,
   newValue: any,
   customFields: CustomField[] | null,
) => {
   if (fieldName === FieldMapping.dob || fieldName === FieldMapping.hired_date) {
      return { [fieldName]: newValue ? newValue.getTime() : null };
   }

   if (fieldName === FieldMapping.gender || fieldName === FieldMapping.status) {
      return { [fieldName]: newValue ? newValue.id.toLowerCase() : null };
   }

   if (fieldName === FieldMapping.hourly_wage) {
      return { [fieldName]: newValue ? parseFloat(newValue) : null };
   }

   const customFieldPayload = handleCustomField(fieldName, newValue, customFields);
   return customFieldPayload ? { custom_fields: customFieldPayload } : { [fieldName]: newValue };
};

// Process custom fields and return the custom field payload
export const handleCustomField = (
   fieldName: string,
   value: any,
   customFields: CustomField[] | null,
) => {
   const customField = customFields?.find((x) => x.integration_name === fieldName);
   if (!customField) return null;

   let customFieldValue = value;
   if (customField.type === CustomFieldType.HEX_COLOR) {
      customFieldValue = value.color;
   } else if (customField.type === CustomFieldType.DATE) {
      customFieldValue = getDetachedDay(value);
   } else if (
      customField.type === CustomFieldType.NUMBER ||
      customField.type === CustomFieldType.CURRENCY
   ) {
      customFieldValue = Number(value);
   }

   return { [customField.id]: customFieldValue };
};
