import { object, date, number, boolean, string, array, mixed } from "yup";
import { compareDates } from "./project/helpers";
import type { useI18nContext } from "@procore/core-react";
import type { CustomField } from "@/react/prop-types";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

// Helper function for work day validation
const workDayValidationTest = (selectedWorkDays: { [key: string]: boolean }) =>
   function (date: Date) {
      if (!date) return false;
      const day = date.getDay();
      return selectedWorkDays[day];
   };

// Shared function to generate base schema
export const getBaseSchemaForRequestAndAssignment = (
   I18n: ReturnType<typeof useI18nContext>,
   selectedWorkDays: { [key: string]: boolean },
   assignmentByAllocation: boolean,
   assignmentByTime: boolean,
   includeStatus: boolean,
) => ({
   project: object().required(
      I18n.t("views.company.workforce_planning.validations.required_field"),
   ),
   category: object().nullable(),
   subcategory: object().nullable(),
   start_date: date()
      .required(I18n.t("views.company.workforce_planning.validations.required_field"))
      .test(
         "validate-work-day",
         I18n.t("views.company.workforce_planning.validations.must_be_a_work_day"),
         workDayValidationTest(selectedWorkDays),
      ),
   end_date: date()
      .test(
         "validate-end-date",
         I18n.t("views.company.workforce_planning.validations.end_date_before_start_date"),
         function (endDate) {
            const { start_date: startDate } = this.parent;
            return compareDates({ startDate, endDate });
         },
      )
      .required(I18n.t("views.company.workforce_planning.validations.required_field"))
      .test(
         "validate-work-day",
         I18n.t("views.company.workforce_planning.validations.must_be_a_work_day"),
         workDayValidationTest(selectedWorkDays),
      ),
   work_days: object().test(
      "validate-work-days",
      I18n.t("views.company.workforce_planning.labor_plan.validations.work_days"),
      function (values: any) {
         let isValid = false;
         Object.keys(values).forEach((i) => {
            if (values[i]) {
               isValid = true;
            }
         });
         return isValid;
      },
   ),
   ...(assignmentByTime && {
      start_time: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      end_time: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   }),
   ...(assignmentByAllocation && {
      percent_allocated: number().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   }),
   ...(includeStatus && {
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   }),
});

export const createCustomFieldSchema = (field: CustomField) => {
   switch (field.type) {
      case CustomFieldType.BOOL:
         return boolean().nullable();
      case CustomFieldType.CURRENCY:
      case CustomFieldType.NUMBER:
         return number().nullable();
      case CustomFieldType.DATE:
         return date().nullable();
      case CustomFieldType.PARAGRAPH:
      case CustomFieldType.TEXT:
         return string().nullable();
      case CustomFieldType.SELECT:
         return object({
            id: string(),
            label: string(),
         }).nullable();
      case CustomFieldType.MULTI_SELECT:
         return array(
            object({
               id: string(),
               label: string(),
            }),
         ).nullable();
      default:
         return mixed().nullable();
   }
};
