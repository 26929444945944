import type { Filter } from "@/lib/components/chip-filter/chip-filter";
import { authManager } from "@/lib/managers/auth-manager";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { Assignment2Store as AssignmentStore } from "@/stores/assignment-2-store.core";
import { defaultStore } from "@/stores/default-store";
import { SettingsStore } from "@/stores/settings-store.core";
import type {
   CalendarModel,
   Gantt,
   TaskModel,
   TaskModelConfig,
   TaskStoreConfig,
} from "@bryntum/gantt-thin";
import { CalendarManagerStore } from "@bryntum/gantt-thin";
import { TaskStore } from "@bryntum/gantt-thin";
import { BryntumGantt, BryntumGanttProjectModel } from "@bryntum/gantt-react-thin";
import "@bryntum/gantt/gantt.stockholm.css";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { Ban, CaretsInVertical, CaretsOutVertical, Excavator } from "@procore/core-icons";
import { Box, DetailPage, Link, P, Spinner, useI18nContext } from "@procore/core-react";
import { timeFormat } from "d3-time-format";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import TearsheetProvider from "../tearsheets";
import { DAYS, getCalendarsGanttData } from "./gantt-calendar";
import type { ExtractGanttProp } from "./gantt-config";
import {
   ENABLE_TASK_SPLITTING,
   ganttConfig,
   ProcoreTaskModel,
   projectConfig,
} from "./gantt-config";
import {
   getGanttConfigurePanelValues,
   updateGanttConfigurePanelLocalStorage,
} from "./gantt-config-panel";
import { GanttControlPanel, GanttViewMode } from "./gantt-control-panel";
import { MyXlsProvider } from "./gantt-export-provider";
import { GanttFilterPanel } from "./gantt-filter-panel";
import { GanttModalType, showGanttModal } from "./gantt-modals";
import type { GanttTearsheetRef } from "./gantt-tearsheets";
import {
   GanttCreateAssignmentTearsheet,
   GanttCreateRequestTearsheet,
   GanttEditAssignmentTearsheet,
   GanttEditRequestTearsheet,
   GanttPersonDetailsTearsheet,
   GanttProjectDetailsTearsheet,
   GanttSendAssignmentAlertsTearsheet,
} from "./gantt-tearsheets";
import { GanttTotalsService } from "./gantt-totals";

import type { CompanyEntityOptionsResponse } from "@/react/prop-types";
import { GanttStore } from "@/stores/gantt-custom-filter-store.core";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import { BrowserRouter, useSearchParams } from "react-router-dom";
import { useGetSavedView } from "../common/queries/queries";
import "./gantt-container.css";
import {
   clearChanges,
   findSegmentSelected,
   getColorShades,
   getInitialFilters,
   getSelectedBarColorHexValues,
   getTextColorForBackground,
   groupTasks,
   resumeAutoSync,
   sortPriorityLevels,
   suspendAutoSync,
} from "./gantt-utils";
import type {
   ganttFilterType,
   GanttOptions,
   GanttProject,
   RawGanttData,
   TotalByDay,
} from "./prop-types";
import { AssignmentBarColors, TaskType, TotalUnitType } from "./prop-types";
import { onGridRowBeforeDropFinalize } from "./gantt-event-handlers";
import { taskRenderer } from "./gantt-renderers";
import type { ViewPreset } from "@bryntum/scheduler-thin";
import { PresetManager } from "@bryntum/scheduler-thin";
import type { Column, ColumnStore } from "@bryntum/grid-thin";
import type { MenuItemConfig, Panel } from "@bryntum/core-thin";
import {
   AddAction,
   DateHelper,
   Model,
   StateTrackingManager,
   StringHelper,
} from "@bryntum/core-thin";
import { EventSegmentModel, EventUpdateAction } from "@bryntum/schedulerpro-thin";

export const GanttContainer = () => {
   return (
      <BrowserRouter>
         <TearsheetProvider projectsTableApi={undefined}>
            <Container />
         </TearsheetProvider>
      </BrowserRouter>
   );
};

const TOTALS_HEIGHT_ROW = 10;
const ganttTotalService = new GanttTotalsService();

const GANTT_EXPANDED_PROJECTS_KEY = "gantt-expanded-projects";
const GANTT_EXPANDED_PROJECTS = JSON.parse(
   localStorage.getItem(GANTT_EXPANDED_PROJECTS_KEY) ?? "[]",
);

const defaultDateFormat = defaultStore.getDateFormat();
const dateFormat = timeFormat("%m/%d/%Y");
const formatTime = timeFormat("%-I:%M %p"); // formats the time to "h:mm am/pm" (eg. 1:30 pm)
const fileExportName = `Gantt_${timeFormat("%Y%m%d")(new Date())}`;

const Container = () => {
   // Check search params for viewId, and load saved view data if necessary
   const [searchParams] = useSearchParams();
   const viewId = searchParams.get("viewId");
   const { data: savedViewData, isLoading: savedViewLoading } = useGetSavedView(viewId);

   const hasPermissionToViewPeopleTimeoff = !!authManager
      .authedUser()
      ?.permissionLevel()
      ?.viewPeopleTimeoff();
   const { groupId: contextGroupId } = useGroupContext();
   const groupId = localStorage.getItem("selectedGroupId") ?? contextGroupId;
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const [ganttData, setGanttData] = useState<RawGanttData>();
   const [customFilterFields, setCustomFilterFields] =
      useState<CompanyEntityOptionsResponse | null>(null);
   // All Gantt data for all projects without filters
   const [ganttDataAll, setGanttDataAll] = useState<RawGanttData>();
   // stm (the undo/redo feature) stands for "StateTrackingManager": https://bryntum.com/products/gantt/docs/api/Core/data/stm/StateTrackingManager
   const [stmLoaded, setStmLoaded] = useState<boolean>(false);
   const [isMaskOn, setIsMaskOn] = useState<boolean>(true);
   const [expandedTasks, setExpandedTasks] = useState<Set<string>>(
      new Set(GANTT_EXPANDED_PROJECTS),
   );
   const [positionHistory, setPositionHistory] = useState(0);

   let droppingGridRow = false;

   //#region utility functions
   const expandTask = (id: string) => {
      const updatedExpandedTasks = expandedTasks.add(id);
      setExpandedTasks(new Set([...updatedExpandedTasks]));
      setTimeout(() => {
         localStorage.setItem(
            GANTT_EXPANDED_PROJECTS_KEY,
            JSON.stringify(Array.from(updatedExpandedTasks)),
         );
      }, 10);
   };

   const collapseTask = (id: string) => {
      expandedTasks.delete(id);
      setExpandedTasks(new Set([...expandedTasks]));
      setTimeout(() => {
         localStorage.setItem(
            GANTT_EXPANDED_PROJECTS_KEY,
            JSON.stringify(Array.from(new Set(expandedTasks))),
         );
      }, 10);
   };
   //#endregion

   /* For more information on what is inside of the PresetManager, you can view the file `node_modules/@bryntum/gantt/gantt.module.js`
      and search for line "var PresetManager = class extends PresetStore"
 
      You can also check the Bryntum docs here for examples on how to use or alter this PresetManager or create custom ViewPreset's:
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/PresetManager
         - https://bryntum.com/products/gantt/docs/api/Scheduler/preset/ViewPreset
   */
   const availablePresets = (PresetManager.records.slice(3, 11) as ViewPreset[]).map(
      (preset: ViewPreset) => {
         return {
            id: "my_" + preset.id,
            base: preset.id,
            // mainUnit and defaultSpan are used to dynamically set the viewing range of our gantt
            mainUnit: preset.mainUnit,
            defaultSpan: preset.defaultSpan,
            tickWidth: preset.tickWidth + 20,
            // timeResolution makes drag-and-drop snaps to your unit
            timeResolution: {
               unit: "day",
               increment: 1,
            },
         };
      },
   ) as ViewPreset[];

   const viewPreset =
      availablePresets.find(
         (preset: any) => preset.base === localStorage.getItem("gantt-view-preset"),
      ) ?? (availablePresets.at(-1) as ViewPreset);

   const mainUnitInMs = (() => {
      const ONE_DAY = 1000 * 60 * 60 * 24;
      switch (viewPreset.mainUnit) {
         case "day":
            return ONE_DAY;
         case "week":
            return ONE_DAY * 7;
         case "month":
            return ONE_DAY * 30;
         case "year":
            return ONE_DAY * 365;
         default:
            return ONE_DAY * 365;
      }
   })();

   const mainUnitOffset = mainUnitInMs * (viewPreset.defaultSpan ?? 1);

   const START_DATE = new Date();
   START_DATE.setTime(START_DATE.getTime() - mainUnitOffset);
   const END_DATE = new Date();
   END_DATE.setTime(END_DATE.getTime() + mainUnitOffset * 4);

   const [currentGanttRange, setCurrentGanttRange] = useState({
      startDay: getDetachedDay(START_DATE),
      endDay: getDetachedDay(END_DATE),
   });
   const earliestStartDateViewed = useRef<number>(currentGanttRange.startDay);
   const latestEndDateViewed = useRef<number>(currentGanttRange.endDay);
   const startDate = useRef<Date>(getAttachedDate(currentGanttRange.startDay));
   const endDate = useRef<Date>(getAttachedDate(currentGanttRange.endDay));

   const [ganttViewMode, setGanttViewMode] = useState<GanttViewMode>(
      (localStorage.getItem("gantt-view-mode") as GanttViewMode) ?? GanttViewMode.PROJECTS,
   );

   const [paidShiftHours, setPaidShiftHours] = useState(8); // Paid shift is 8 hours by default
   const fetchTimeoutId = useRef<number>(NaN);
   // Checking for saved-view ganttFilter, then check localStorage for last applied ganttFilter, then fallback to default filters
   const savedViewConfig: any = savedViewData?.data?.view_config;
   const [search, setSearch] = useState<string>(
      savedViewConfig?.search ?? localStorage.getItem("gantt-search") ?? "",
   );
   const prevSearch = useRef<string>(search);
   const ganttFilterSaved: ganttFilterType | null = JSON.parse(
      localStorage.getItem("gantt-filter")!,
   );
   const [ganttFilter, setGanttFilter] = useState<ganttFilterType>(
      getInitialFilters(ganttFilterSaved),
   );
   const [filterPanelOpen, setFilterPanelOpen] = useState<boolean>(false);
   const canViewProject = checkAuthAction(AuthAction.VIEW_PROJECT);
   const canViewPeople = checkAuthAction(AuthAction.VIEW_PEOPLE);
   const projectDetailsTearsheetRef = useRef<GanttTearsheetRef>(null);
   const createAssignmentTearsheetRef = useRef<GanttTearsheetRef>(null);
   const sendAssignmentAlertsTearsheetRef = useRef<GanttTearsheetRef>(null);
   const createRequestTearsheetRef = useRef<GanttTearsheetRef>(null);
   const editAssignmentTearsheetRef = useRef<GanttTearsheetRef>(null);
   const editRequestTearsheetRef = useRef<GanttTearsheetRef>(null);
   const personDetailsTearsheetRef = useRef<GanttTearsheetRef>(null);

   // Using a ref for this so that it's value persists across component re-renders and does not cause a re-render when it itself is updated
   const isInitialLoadComplete = useRef<boolean>(false);

   // Always fetch this supporting data on initial page load (empty dependency array so only gets executed once)
   useEffect(() => {
      if (LaunchDarklyClient.getFlagValue("gantt-conflict-handler")) {
         fetchData({ bypassFiltersForConflictTracking: true });
      }

      const fetchCostingData = async () => {
         const costingInfo = await SettingsStore.getCostingInfo()?.payload;
         setPaidShiftHours(costingInfo?.data?.paid_shift_hours ?? 8);
      };

      const fetchCustomFieldsFilters = async () => {
         const customFilterFields = await GanttStore.getGanttCustomFilter(groupId).payload;
         setCustomFilterFields(customFilterFields);
      };

      try {
         fetchCostingData();
         fetchCustomFieldsFilters();
      } catch (e) {
         console.error(e);
      }
   }, []);

   // As soon as potential saved-view data loads, fetch Gantt data
   useEffect(() => {
      // Made this async so that we can await the fetchData call before updating isInitialLoadComplete to true
      const fetchDataAsync = async () => {
         if (savedViewLoading) return;

         if (savedViewData) {
            // Update the Gantt UI to reflect the saved-view that we're loading
            const ganttViewConfig = savedViewData.data?.view_config as any;
            setGanttFilter(ganttViewConfig.ganttFilter);
            setCustomFilterFields(ganttViewConfig.customFilterFields);
            setGanttViewMode(ganttViewConfig.ganttViewMode);
            setSearch(ganttViewConfig.search);
         }

         await fetchData({
            savedViewConfig,
         });
         isInitialLoadComplete.current = true;
      };

      fetchDataAsync();
   }, [savedViewLoading]);

   useEffect(() => {
      if (!isInitialLoadComplete.current) return;

      localStorage.setItem("gantt-filter", JSON.stringify(ganttFilter));
      // On a manual filter change,remove viewId param from URL so that the last opened saved view is not reloaded on next page refresh
      const url = new URL(window.location.href);
      if (url.searchParams.has("viewId")) {
         url.searchParams.delete("viewId");
         window.history.replaceState({}, "", url.toString());
      }

      fetchData();
   }, [ganttFilter]);

   // This effect is used to update the customFieldsFilters in the ganttFilter state when the custom field settings are updated
   useEffect(() => {
      if (customFilterFields) {
         const updatedFilters = Object.entries(ganttFilter.customFieldsFilters).reduce(
            (acc: any, [key, value]) => {
               const matchingFilter = customFilterFields.projects_custom_field_filters.find(
                  (filter: any) => filter.id === key,
               );

               if (matchingFilter) {
                  acc[key] = value; // Retain the value if a match is found
               }

               return acc;
            },
            {},
         );
         localStorage.setItem(
            "gantt-filter",
            JSON.stringify({
               ...ganttFilter,
               customFieldsFilters: updatedFilters,
            }),
         );
      }
   }, [customFilterFields]);

   useEffect(() => {
      if (!isInitialLoadComplete.current) return;

      // Making sure we don't call fetchData on the for this effect on initial page load. We only want to execute this
      // after the user themselves updates the input value.
      if (search != prevSearch.current) {
         prevSearch.current = search;
         localStorage.setItem("gantt-search", search ?? "");
         fetchData();
      }
   }, [search]);

   // const getDateFilterFormatted = (date: string): string => {
   //    const dateObj = new Date(date);
   //    const monthStr = `${dateObj.getMonth() < 10 ? "0" : ""}${dateObj.getMonth()}`;
   //    const dayStr = `${dateObj.getDate() < 10 ? "0" : ""}${dateObj.getDate()}`;
   //    const yearStr = `${dateObj.getFullYear()}`;

   //    return `${yearStr}${monthStr}${dayStr}`;
   // };

   useEffect(() => {
      if (!isInitialLoadComplete.current) return;

      clearTimeout(fetchTimeoutId.current);
      const currentStartDate = currentGanttRange.startDay;
      const currentEndDate = currentGanttRange.endDay;

      const hasViewRangeExtended = () => {
         // If we've already zoomed out past the starting or ending point of the current range then there's no need to fetch
         // more data because we've already loaded a larger set of data which includes the current range.
         let rangeViewExtended = false;

         if (currentStartDate < earliestStartDateViewed.current) {
            rangeViewExtended = true;
            earliestStartDateViewed.current = currentStartDate;
            startDate.current = new Date(getAttachedDate(currentStartDate));
         }
         if (currentEndDate > latestEndDateViewed.current) {
            rangeViewExtended = true;
            latestEndDateViewed.current = currentEndDate;
            endDate.current = new Date(getAttachedDate(currentEndDate));
         }

         return rangeViewExtended;
      };

      // Wait until we go 500ms without receiving another zoom event to check if we need to fetch more data (debounced for performance)
      fetchTimeoutId.current = window.setTimeout(() => {
         if (hasViewRangeExtended()) {
            fetchData();
            if (LaunchDarklyClient.getFlagValue("gantt-conflict-handler")) {
               fetchData({ bypassFiltersForConflictTracking: true });
            }
         }
      }, 500);
   }, [currentGanttRange]);

   // When the view mode is change triggers this function
   // to hide/show columns and allow grouping,
   useEffect(() => {
      const cols = ganttRef.current?.instance.columns as ColumnStore;
      if (!cols) return;

      let columnsToHide: string[] = [];
      let columnsToShow: string[] = [];
      if (ganttViewMode === GanttViewMode.PROJECTS) {
         columnsToHide = ["resourceId"];
         columnsToShow = ["name", "jobTitleName", "startDate", "endDate"];
      } else {
         columnsToHide = ["name", "jobTitleName", "startDate", "endDate"];
         columnsToShow = ["resourceId"];
      }

      // First we trigger the show else the sidebar will reduce to 0
      columnsToShow.forEach((field) =>
         cols.forEach((col: Column) => {
            if (col.field === field) col.show();
         }),
      );
      columnsToHide.forEach((field) =>
         cols.forEach((col: Column) => {
            if (col.field === field) col.hide();
         }),
      );

      if (ganttViewMode === GanttViewMode.RESOURCES) {
         // These tasks are not relevant to the resource view, and cannot be treeGrouped by resourceId.
         const excludedTaskTypes = new Set([
            TaskType.PROJECT,
            TaskType.CATEGORY,
            TaskType.SUBCATEGORY,
         ]);
         ganttRef.current?.instance.taskStore.filter((task: any) => {
            const taskType = task.getData("type");
            return !excludedTaskTypes.has(taskType);
         });
      }
   }, [ganttViewMode]);

   const getFilterFormatted = (filter: ganttFilterType): Record<string, Filter[]> => {
      const appliedFilters: Record<string, Filter[]> = {};

      if (filter.jobTitles?.length)
         appliedFilters["Job Titles"] = filter.jobTitles.map((jobTitle) => {
            return {
               property: "position_id",
               negation: false,
               filterName: "Job Titles",
               value: jobTitle.id,
            } as Filter;
         });

      if (filter.projectStatuses?.length)
         appliedFilters["Status"] = filter.projectStatuses.map((status) => {
            return {
               property: "status",
               negation: false,
               filterName: "Status",
               value: status.name.toLowerCase(),
            } as Filter;
         });

      if (filter.assignmentsOrRequests?.length || filter.peopleOrEquipment?.length)
         appliedFilters["Only Show"] = [
            ...filter.assignmentsOrRequests.map((value) => {
               return {
                  property: "only_show",
                  negation: false,
                  filterName: "Only Show",
                  value,
               } as Filter;
            }),
            ...filter.peopleOrEquipment.map((value) => {
               return {
                  property: "only_show",
                  negation: false,
                  filterName: "Only Show",
                  value,
               } as Filter;
            }),
         ];

      if (filter.hideEmptyProject)
         appliedFilters["Hide Empty Projects"] = [
            {
               property: "only_projects_with_data",
               negation: false,
               filterName: "Hide Empty Projects",
               value: true,
            } as Filter,
         ];
      // custom field filters
      if (filter.customFieldsFilters) {
         Object.entries(filter.customFieldsFilters).forEach(([key, value]) => {
            appliedFilters[key] = value as Filter[];
         });
      }
      // if (filter.startDate?.qualifier && filter.startDate?.date.length)
      //    appliedFilters["Start Date"] = [
      //       {
      //          property: "start_date",
      //          negation: false,
      //          filterName: "Start Date",
      //          value: getDateFilterFormatted(filter.startDate.date),
      //          classifier: filter.startDate.qualifier,
      //       } as Filter,
      //    ];

      // if (filter.endDate?.qualifier && filter.endDate?.date.length)
      //    appliedFilters["Est End Date"] = [
      //       {
      //          property: "est_end_date",
      //          negation: false,
      //          filterName: "Est End Date",
      //          value: getDateFilterFormatted(filter.endDate.date),
      //          classifier: filter.endDate.qualifier,
      //       } as Filter,
      //    ];

      return appliedFilters;
   };

   /**
    * Fetches Gantt data with options to apply filters and search parameters.
    *
    * @param {Object} options - The options for fetching Gantt data.
    * @param {boolean} [options.bypassFiltersForConflictTracking=false] - If true, fetches all Gantt data for all projects without filters,
    * which is useful for resolving scheduling conflicts. If false, applies filters and search parameters.
    * @param {ganttFilterType} [options.ganttFilters=ganttFilter] - The filters to apply when fetching Gantt data.
    *
    * @returns {Promise<void>} - A promise that resolves when the data fetching is complete.
    */
   const fetchData = async (
      {
         bypassFiltersForConflictTracking = false,
         savedViewConfig,
      }: {
         bypassFiltersForConflictTracking?: boolean;
         savedViewConfig?: any;
      } = {
         bypassFiltersForConflictTracking: false,
         savedViewConfig: null,
      },
   ) => {
      const startDay = earliestStartDateViewed.current;
      const endDay = latestEndDateViewed.current;
      const params: GanttOptions = {
         skip: 0,
         projectSort: "name",
         group_id: groupId,
         startDay,
         endDay,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (!bypassFiltersForConflictTracking) {
         ganttRef.current?.instance.mask({
            mode: "bright",
            cls: "gantt-loading-mask",
            html: (
               <div
                  style={{
                     height: "100%",
                     width: "100%",
                     display: "grid",
                     placeItems: "center",
                  }}
               >
                  <Spinner loading={true} />
               </div>
            ),
         });
         setIsMaskOn(true);

         const appliedFilters = getFilterFormatted(savedViewConfig?.ganttFilter ?? ganttFilter);
         params.filters = appliedFilters;
         // check if a search value was passed in explicitly (like would be the case when fetching data for a saved-view), if not use the search state value
         params.search = savedViewConfig?.search ?? search;
      }

      const rawGanttData = await AssignmentStore.getRawProjectsGanttData(params);

      const projectsCount = rawGanttData.projects.length;
      let assignmentsCount = 0;
      let requestsCount = 0;

      rawGanttData?.projects?.forEach((project: any) => {
         assignmentsCount += project.assignments.length;
         requestsCount += project.requests.length;
      });

      const taskCount = projectsCount + assignmentsCount + requestsCount;

      console.info("GANTT TASK COUNT: ", taskCount);

      if (bypassFiltersForConflictTracking) {
         setGanttDataAll(rawGanttData);
      } else {
         setGanttData(rawGanttData);
         ganttRef.current?.instance.unmask();
         setIsMaskOn(false);
      }
   };

   // The BryntumGantt is a wrapper for the actually underlying gantt instance. If you want to,
   // you can use ganttRef.current.instance to access the actual underlying gantt instance.
   const ganttRef = useRef<BryntumGantt>(null);
   const projectRef = useRef<BryntumGanttProjectModel>(null);
   (window as any).ganttRef = ganttRef;

   const getDatesRangeString = (
      startDate: Date,
      endDate: Date,
      startTime?: string,
      endTime?: string,
   ) => {
      return `${DateHelper.format(startDate, defaultDateFormat)} ${
         startTime ?? ""
      } - ${DateHelper.format(endDate, defaultDateFormat)} ${endTime ?? ""}`;
   };
   // Function to parse "HH:MM:SS" strings into Date objects
   const parseTimeString = (timeString: string): Date => {
      try {
         const [hours, minutes, seconds] = timeString.split(":").map(Number);
         const date = new Date();
         date.setHours(hours, minutes, seconds, 0);
         return date;
      } catch (e) {
         console.error("Error parsing time string:", timeString, e);
         return new Date();
      }
   };

   // Function that returns the percentage allocation of the assignment/request
   const getPercentAllocation = (projectJobNumber: string, id: string): number | null => {
      const project = ganttData?.projects.find(
         (project) => project.job_number === projectJobNumber,
      );

      const task =
         project?.assignments.find((assignment) => assignment.id === id) ??
         project?.requests.find((request) => request.id === id) ??
         project?.equipment_assignments.find(
            (equipmentAssignment) => equipmentAssignment.id === id,
         );

      return task?.percent_allocated ?? null;
   };

   const getTaskTimeAllocation = (
      startTime: any,
      endTime: any,
      projectJobNumber: any,
      id: any,
   ): string => {
      if (!startTime || !endTime) {
         return `${getPercentAllocation(projectJobNumber, id)}`;
      }
      return `${startTime} - ${endTime}`;
   };

   // Function that returns custom tooltip template for tasks
   const tooltipTemplateCallback = (taskRecord: any, startDate: Date, endDate: Date): string => {
      const id = taskRecord.getData("id");
      const startTime = taskRecord.getData("startTime");
      const endTime = taskRecord.getData("endTime");
      const projectJobNumber = taskRecord.getData("projectJobNumber");
      const type = taskRecord.getData("type");

      let isAssignmentOrRequest = false,
         taskTimeAllocation = "";

      if ([TaskType.ASSIGNMENT, TaskType.REQUEST, TaskType.EQUIPMENT_ASSIGNMENT].includes(type)) {
         isAssignmentOrRequest = true;
         taskTimeAllocation = getTaskTimeAllocation(startTime, endTime, projectJobNumber, id);
      }

      return `<div>
      <div style="text-align: center">${StringHelper.encodeHtml(
         getDatesRangeString(startDate, endDate),
      )}</div>
      ${
         isAssignmentOrRequest
            ? `<div style="text-align: center">
            ${StringHelper.encodeHtml(
               `${startTime && endTime ? taskTimeAllocation : `${taskTimeAllocation}%`}`,
            )}
         </div>`
            : ""
      }
      </div>`;
   };

   /**
    * This function returns a pill displaying the number of open requests that appear on the project's bar
    */
   const requestsNumberPill = (requestsNumber: number): string => {
      if (!requestsNumber) return "";

      const pillsHeader = `${requestsNumber} open request${requestsNumber > 1 ? "s" : ""}`;

      return `<div class='ganttProjectRequestsPill'>${pillsHeader}</div>`;
   };

   const hideWeekends = (gantt: Gantt, value: boolean) => {
      const { timeAxis } = gantt;

      gantt.element.classList.toggle("b-hide-weekends", value);

      gantt.runWithTransition(() => {
         if (value) {
            timeAxis.filterBy(
               (tick: any) =>
                  timeAxis.unit !== "day" ||
                  (tick.startDate.getDay() !== 6 && tick.startDate.getDay() !== 0),
            );
         } else {
            timeAxis.clearFilters();
         }
      });
   };

   const addCalendarIfNotExists = (calendar: CalendarModel) => {
      const manager = (ganttRef.current?.instance as any)
         .calendarManagerStore as CalendarManagerStore;

      if (!manager.getById(calendar.id)) manager.add(calendar);
   };

   const taskSchedulingConflictHandler = (context: Record<string, any>, isResizing = false) => {
      const { taskRecord, timeDiff = 0, date: newEndDate } = context;
      let { taskRecords } = context;

      // Resize events have `taskRecord` property, Drag - `taskRecords` (array), we aim to handle both consistently
      if (!taskRecords) taskRecords = [taskRecord];

      if (!taskRecords.length) return;

      const taskRecordsWithConflicts = taskRecords.filter((task: any) => {
         const id = task.getData("id");
         const resourceId = task.getData("resourceId");
         const startDate = isResizing
            ? task.getData("startDate")
            : new Date(task.getData("startDate").getTime() + timeDiff);
         const endDate = isResizing
            ? newEndDate
            : new Date(task.getData("endDate").getTime() + timeDiff);

         const overAllocatedTask = taskStoreAllData.find((record: any) => {
            if (record.type !== TaskType.ASSIGNMENT) return false;

            if (!(endDate > record.startDate && startDate < record.endDate)) return false;

            const recordResourceId = record.getData("resourceId");
            const recordId = record.getData("id");

            return resourceId === recordResourceId && recordId !== id;
         });

         return !!overAllocatedTask;
      });

      if (!taskRecordsWithConflicts.length) return;

      context.async = true;
      const modalDialogConflictsListContent: {
         [personId: string]: Array<{
            projectName: string;
            personName: string;
            startDate: Date;
            endDate: Date;
         }>;
      } = {};
      taskRecordsWithConflicts.forEach((task: any) => {
         const id = task.getData("id");
         const resourceId = task.getData("resourceId");
         const projectId = task.getData("projectId");
         const name = task.getData("name");
         const startDate = isResizing
            ? task.getData("startDate")
            : new Date(task.getData("startDate").getTime() + timeDiff);
         const endDate = isResizing
            ? newEndDate
            : new Date(task.getData("endDate").getTime() + timeDiff);

         const overlappingTaskFromAnotherProject = taskStoreAllData.find((record: any) => {
            if (record.type !== TaskType.ASSIGNMENT) return false;

            if (!(endDate > record.startDate && startDate < record.endDate)) return false;

            const recordResourceId = record.getData("resourceId");
            const recordId = record.getData("id");
            const recordProjectId = record.getData("projectId");

            return (
               resourceId === recordResourceId && recordId !== id && recordProjectId !== projectId
            );
         });

         // If assigned person has task in another project we should show project name in the modal
         const projectName = overlappingTaskFromAnotherProject
            ? overlappingTaskFromAnotherProject.getData("projectName")
            : task.getData("projectName");

         // modalDialogConflictsListContent.push(
         //    `${name} is already scheduled on ${projectName} project during the dates ${dateFormat(
         //       startDate,
         //    )} - ${dateFormat(endDate)}. `,
         // );
         if (!modalDialogConflictsListContent[resourceId])
            modalDialogConflictsListContent[resourceId] = [];

         modalDialogConflictsListContent[resourceId].push({
            projectName,
            personName: name,
            startDate,
            endDate,
         });
      });

      showGanttModal({
         type: GanttModalType.SchedulingConflict,
         modalId: "gantt-confirmation-modal-container",
         title: "Scheduling conflict",
         body: (
            <div className="schedulingConflictModalBody">
               <p style={{ marginBottom: "12px" }}>
                  These changes create scheduling conflicts for the following assignments. Would you
                  like to proceed anyway?
               </p>

               <div
                  className="unhide-scrollbar"
                  style={{
                     height: "200px",
                     overflow: "scroll",
                     padding: "12px",
                     borderRadius: "4px",
                     border: "solid 1px grey",
                  }}
               >
                  {Object.values(modalDialogConflictsListContent).map(
                     (conflicts, index: number) => (
                        <details
                           style={{
                              marginBottom: "12px",
                           }}
                           key={index}
                           open
                        >
                           <summary>{conflicts[0].personName}</summary>
                           <ul
                              style={{
                                 marginLeft: "32px",
                                 listStyle: "disc",
                              }}
                           >
                              {conflicts.map((data, index: number) => (
                                 <li key={index}>
                                    {data.projectName}: {dateFormat(data.startDate)} -{" "}
                                    {dateFormat(data.endDate)}
                                 </li>
                              ))}
                           </ul>
                        </details>
                     ),
                  )}
               </div>
            </div>
         ),
         onSave: async () => {
            context.finalize(true);
         },
         onCancel: async () => {
            context.finalize(false);
         },
      });
   };

   //#region project model config
   const createProjectTask = (project: GanttProject) => {
      const isExpanded = localStorage.getItem("gantt-expanded") === "true";

      return {
         id: project.id,
         name: project.name,
         startDate: new Date(project.start_date),
         endDate: new Date(project.est_end_date),
         // Adding prefix of "project" to "projectColor" to make it consistent with assignments & requests
         projectColor: project.color,
         projectJobNumber: project.job_number,
         dailyStartTime: project.daily_start_time,
         dailyEndTime: project.daily_end_time,
         type: TaskType.PROJECT,
         expanded: expandedTasks.has(project.id) ?? isExpanded,
         resizable: true,
         requestsNumber: project.requests?.length ?? 0,
         manuallyScheduled: true,
         constraintType: null,
         projectAssignments: project.assignments,
         wageOverrides: project.wage_overrides,
         workedHours: "main",
         sortPriority: sortPriorityLevels[TaskType.PROJECT],
      } as Partial<TaskModelConfig>;
   };

   const tasksData = useMemo(() => {
      return (
         ganttData?.projects.map((project: GanttProject) => {
            const {
               tasks: projectChildren,
               // earliestTaskStartDate,
               // latestTaskEndDate,
            } = groupTasks(
               project,
               ganttData.people,
               ganttData.jobTitles,
               ganttData.equipment,
               expandedTasks,
               ganttFilter.hideEmptyCategories,
            );

            return {
               ...createProjectTask(project),
               children: projectChildren,
            };
         }) ?? []
      );
   }, [ganttData]);

   /**
    * Get calendars data and replace timeoff reasons with their translated values
    */
   const calendarsStore = useMemo(
      () =>
         new CalendarManagerStore({
            autoCommit: false,
            syncDataOnLoad: false,
            data: getCalendarsGanttData(ganttData, hasPermissionToViewPeopleTimeoff).map(
               (calendar) => {
                  calendar.intervals = calendar.intervals.map((interval: any) => {
                     if (interval.name) {
                        const reasonKey = interval.name.split(" ").join("_");
                        interval.name = I18n.t(
                           `views.company.workforce_planning.time_off.reasons.${reasonKey}`,
                        );
                     }

                     return interval;
                  });
                  return calendar;
               },
            ),
         }),
      [ganttData],
   );
   //#endregion

   //#region Columns
   // This headerRenderer function expects you to return a string, so we're using the renderToString method to generate
   // static HTML string of our React components. This static HTML does not include any JavaScript or interactivity,
   // such as event handlers like onClick, so we're using a setTimeout to defer the execution of the querySelectors
   // until after the current callstack is clear (ie. until after the header finishes rendering). This async defermnet
   // ensures that the DOM elements are ready and available when we attempt to get them with querySelector.
   const expandCollapseHeaderRenderer: any = ({ headerElement, column }: any) => {
      // These go in the setTimeout to make sure that the children of the headerElement have time to render first.
      setTimeout(() => {
         const expandAllIcon = headerElement.querySelector(".gantt-expand-all-icon") as HTMLElement;
         const collapseAllIcon = headerElement.querySelector(
            ".gantt-collapse-all-icon",
         ) as HTMLElement;

         expandAllIcon?.addEventListener("click", () => {
            expandAllIcon.style.display = "none";
            collapseAllIcon.style.display = "block";
            column.grid.collapseAll();
            localStorage.setItem("gantt-expanded", "false");
         });
         collapseAllIcon?.addEventListener("click", () => {
            expandAllIcon.style.display = "block";
            collapseAllIcon.style.display = "none";
            column.grid.expandAll();
            localStorage.setItem("gantt-expanded", "true");
         });
      }, 10);

      const isExpanded = localStorage.getItem("gantt-expanded") === "true";

      return `
         ${renderToString(
            <CaretsOutVertical
               className="gantt-expand-all-icon"
               data-testid="gantt-expand-all-icon"
               style={{ display: isExpanded ? "block" : "none" }}
            />,
         )}
            ${renderToString(
               <CaretsInVertical
                  className="gantt-collapse-all-icon"
                  data-testid="gantt-collapse-all-icon"
                  style={{ display: isExpanded ? "none" : "block" }}
               />,
            )}
         <span>${column.text}</span>
      `;
   };

   const columns = useMemo<ExtractGanttProp<"columns">>(
      () => [
         {
            alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
            type: "name",
            text: "Project Name",
            field: "name",
            width: 250,
            htmlEncode: false,
            hidden: false,
            sortable: true,
            headerRenderer: expandCollapseHeaderRenderer,
            renderer: ({ record, cellElement }: any) =>
               taskRenderer({
                  record,
                  cellElement,
                  ganttViewMode,
                  canViewPeople,
                  canViewProject,
                  personDetailsTearsheetRef,
                  projectDetailsTearsheetRef,
                  ganttRef,
                  expandedTasks,
               }),
         },
         {
            alwaysClearCell: false, // v6 defaults this to true, so we're explicitly setting back to false to maintain prior behavior for now
            type: "name",
            text: "Job Title",
            field: "jobTitleName",
            hidden: false,
            headerRenderer: ({ column }: any) => {
               return `<span>${column.text}</span>`;
            },
         },
         {
            type: "name",
            text: "Type",
            field: "type",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_project_id",
            field: "projectId",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_project_name",
            field: "projectName",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_category_id",
            field: "parentCategoryId",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_category_name",
            field: "parentCategoryName",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_subcategory_id",
            field: "parentSubcategoryId",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "parent_subcategory_name",
            field: "parentSubcategoryName",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            text: "Project Number",
            field: "projectJobNumber",
            hidden: true,
            exportable: true,
         },
         {
            type: "date",
            field: "startDate",
            text: "Project Start Date",
            hidden: false,
            exportable: true,
            flex: 1,
            renderer: ({ record }: any) => {
               const taskType = record.getData("type");
               if (taskType !== TaskType.PROJECT) return null;

               return dateFormat(record.getData("startDate"));
            },
         },
         {
            type: "date",
            field: "startTime",
            text: "Start Time",
            hidden: true,
            exportable: true,
         },
         {
            type: "date",
            field: "endTime",
            text: "End Time",
            hidden: true,
            exportable: true,
         },
         {
            type: "date",
            field: "endDate",
            text: "Project End Date",
            hidden: false,
            exportable: true,
            flex: 1,
            renderer: ({ record }: any) => {
               const taskType = record.getData("type");
               if (taskType !== TaskType.PROJECT) return null;

               return dateFormat(record.getData("endDate"));
            },
         },
         {
            type: "calendar",
            field: "workDays",
            text: "Work Days",
            hidden: true,
            exportable: true,
         },
         {
            type: "name",
            field: "resourceId",
            text: "Resource Name",
            exportable: false,
            flex: 1,
            hidden: true,
            headerRenderer: expandCollapseHeaderRenderer,
            renderer: ({ record, cellElement }: any) => {
               const resourceName = record.firstChild?.getData("resourceName");
               const projectName = record.getData("projectName");

               if (record.getData("type") === undefined) {
                  if (resourceName) {
                     cellElement.classList.add("gantt-grid-resource-text");
                     cellElement.classList.add("gantt-project-name");
                     if (canViewPeople) {
                        return (
                           <Link
                              onClick={() => {
                                 const personId = String(
                                    record.originalData.firstGroupChild.resourceId,
                                 );
                                 personDetailsTearsheetRef.current?.handleOpenTearsheet({
                                    projectId: "",
                                    personId,
                                    callback: () => {},
                                 });
                              }}
                           >
                              {resourceName}
                           </Link>
                        );
                     } else {
                        return <span>{resourceName}</span>;
                     }
                  } else {
                     cellElement.classList.add("gantt-grid-resource-text");
                     return <span>Unassigned</span>;
                  }
               } else {
                  cellElement.classList.add("gantt-grid-project-text");
                  return <span>{projectName}</span>;
               }
            },
            sortable(left: any, right: any) {
               // Will sort only if has children, this is to assure is a grouped item
               if (left.firstChild && right.firstChild) {
                  const name1: string = left.firstChild.getData("resourceName") ?? "";
                  const name2: string = right.firstChild.getData("resourceName") ?? "";
                  return name1.localeCompare(name2);
               }
               return 0;
            },
         },
         {
            type: "date",
            field: "taskStartDate",
            text: "Task Start Date",
            exportable: true,
            flex: 1,
            hidden: false,
            renderer: ({ record }: any) => {
               const taskType = record.getData("type");
               if (
                  taskType === TaskType.ASSIGNMENT ||
                  taskType === TaskType.REQUEST ||
                  taskType === TaskType.EQUIPMENT_ASSIGNMENT
               ) {
                  const startDate = record.getData("startDate");
                  return startDate ? dateFormat(startDate) : "";
               } else return "";
            },
            sortable: (left: any, right: any) => {
               if (!left || !right) return 0;

               const leftTaskType = left.getData("type");
               const rightTaskType = right.getData("type");

               if (
                  (leftTaskType === TaskType.ASSIGNMENT ||
                     leftTaskType === TaskType.REQUEST ||
                     leftTaskType === TaskType.EQUIPMENT_ASSIGNMENT) &&
                  (rightTaskType === TaskType.ASSIGNMENT ||
                     rightTaskType === TaskType.REQUEST ||
                     rightTaskType === TaskType.EQUIPMENT_ASSIGNMENT)
               ) {
                  const leftParentId =
                     left.getData("parentCategoryId") || left.getData("parentProjectId");
                  const rightParentId =
                     right.getData("parentCategoryId") || right.getData("parentProjectId");

                  // Only sort if both records have the same parent to keep the categories and projects in order
                  if (leftParentId === rightParentId) {
                     const startDate1 = new Date(left.getData("startDate") || 0);
                     const startDate2 = new Date(right.getData("startDate") || 0);
                     return startDate1.getTime() - startDate2.getTime();
                  }
               }
               return 0;
            },
         },
         {
            type: "date",
            field: "taskEndDate",
            text: "Task End Date",
            exportable: true,
            flex: 1,
            hidden: false,
            renderer: ({ record }: any) => {
               const taskType = record.getData("type");
               if (
                  taskType === TaskType.ASSIGNMENT ||
                  taskType === TaskType.REQUEST ||
                  taskType === TaskType.EQUIPMENT_ASSIGNMENT
               ) {
                  const endDate = record.getData("endDate");
                  return endDate ? dateFormat(endDate) : "";
               } else return "";
            },
            sortable: (left: any, right: any) => {
               if (!left || !right) return 0;

               const leftTaskType = left.getData("type");
               const rightTaskType = right.getData("type");

               if (
                  (leftTaskType === TaskType.ASSIGNMENT ||
                     leftTaskType === TaskType.REQUEST ||
                     leftTaskType === TaskType.EQUIPMENT_ASSIGNMENT) &&
                  (rightTaskType === TaskType.ASSIGNMENT ||
                     rightTaskType === TaskType.REQUEST ||
                     rightTaskType === TaskType.EQUIPMENT_ASSIGNMENT)
               ) {
                  const leftParentId =
                     left.getData("parentCategoryId") || left.getData("parentProjectId");
                  const rightParentId =
                     right.getData("parentCategoryId") || right.getData("parentProjectId");

                  // Only sort if both records have the same parent to keep the categories and projects in order
                  if (leftParentId === rightParentId) {
                     const endDate1 = new Date(left.getData("endDate") || 0);
                     const endDate2 = new Date(right.getData("endDate") || 0);
                     return endDate1.getTime() - endDate2.getTime();
                  }
               }
               return 0;
            },
         },
      ],
      [canViewProject],
   );

   const strips = useMemo<ExtractGanttProp<"strips">>(
      () => ({
         right: {
            type: "panel",
            dock: "right",
            header: false,
            collapsible: true,
            ui: "procore",
            cls: "b-sidebar",
            scrollable: { overflowY: true },
            collapsed: true,
            defaults: {
               labelPosition: "above",
               width: "15em",
            },
            items: [
               {
                  tag: "span",
                  html: "Configure Gantt",
               },
               {
                  type: "slidetoggle",
                  label: "Project Information",
                  labelPosition: "after", // we have some custom css to support the 'after' styling
                  value: getGanttConfigurePanelValues().showProjectInformation,
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("showProjectInformation", value);
                     ganttRef!.current!.instance.element.classList.toggle(
                        "b-hide-project-information",
                        !value,
                     );
                  },
                  id: "project-information",
               },
               {
                  type: "slidetoggle",
                  label: "Allocation Information",
                  labelPosition: "after", // we have some custom css to support the 'after' styling
                  value: getGanttConfigurePanelValues().showAllocationInformation,
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("showAllocationInformation", value);
                     ganttRef!.current!.instance.element.classList.toggle(
                        "hide-allocation-information",
                        !value,
                     );
                  },
                  id: "allocation-information",
               },
               hasPermissionToViewPeopleTimeoff && {
                  type: "slidetoggle",
                  label: "Time Off Information",
                  labelPosition: "after",
                  value: getGanttConfigurePanelValues().showTimeoff,
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("showTimeoff", value);
                     ganttRef!.current!.instance.element.classList.toggle(
                        "hide-time-off-reasons",
                        !value,
                     );
                  },
                  id: "time-off",
               },
               {
                  type: "slidetoggle",
                  label: "Hide weekends",
                  labelPosition: "after", // we have some custom css to support the 'after' styling
                  value: getGanttConfigurePanelValues().hideWeekends,
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("hideWeekends", value);
                     hideWeekends(ganttRef!.current!.instance!, value);
                  },
                  id: "hide-weekends",
               },
               {
                  ref: "rowHeightSlider",
                  type: "slider",
                  label: "Row height",
                  labelPosition: "above",
                  min: 25,
                  max: 45,
                  showValue: false,
                  showTooltip: true,
                  value: getGanttConfigurePanelValues().rowHeight,
                  onInput: ({ value }: any) => {
                     ganttRef!.current!.instance.rowHeight = value + TOTALS_HEIGHT_ROW;
                     ganttRef!.current!.instance.element.style.setProperty(
                        "--non-working-time-padding-top",
                        `${(value - 15) / 2}px`,
                     );
                     ganttRef!.current!.instance.element.style.setProperty(
                        "--non-working-time-height",
                        `${value - 1}px`,
                     );
                  },
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("rowHeight", value);
                     // TODO: Whenever this bug-fix gets released by Bryntum, we should be able to remove the below here
                     // Bug ticket: https://github.com/bryntum/support/issues/9698
                     ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = true;
                     ganttRef!.current!.instance.features.taskNonWorkingTime.disabled = false;
                  },
                  id: "row-height",
               },
               {
                  ref: "borderRadiusSlider",
                  type: "slider",
                  label: "Task border radius",
                  labelPosition: "above",
                  min: 0,
                  value: getGanttConfigurePanelValues().taskBorderRadius,
                  max: 20,
                  showValue: false,
                  showTooltip: true,
                  onInput: ({ value }: any) => {
                     ganttRef!.current!.instance.element.style.setProperty(
                        "--task-border-radius",
                        `${value}px`,
                     );
                  },
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("taskBorderRadius", value);
                  },
                  id: "border-radius",
               },
               {
                  type: "combo",
                  label: "Assignment Bar Colors",
                  value: getGanttConfigurePanelValues().assignmentBarColors || "Project",
                  items: [
                     { value: AssignmentBarColors.PROJECT, text: "Project" },
                     {
                        value: AssignmentBarColors.ALLOCATION_PERCENTAGE,
                        text: "Allocation Percentage",
                     },
                     { value: AssignmentBarColors.JOB_TITLES, text: "Job Titles" },
                     { value: AssignmentBarColors.OVERTIME, text: "Overtime" },
                     { value: AssignmentBarColors.STATUS, text: "Status" },
                  ],
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("assignmentBarColors", value);
                     ganttRef.current!.instance.refreshRows();
                  },
                  id: "assignment-bar-colors",
                  style: "margin-top: 2em",
               },
               {
                  tag: "span",
                  html: "Totals",
                  style: "margin-top: 2em",
               },
               {
                  type: "slidetoggle",
                  label: "Assignment Totals",
                  labelPosition: "after",
                  value: getGanttConfigurePanelValues().showTotals,
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("showTotals", value);
                     ganttRef.current!.instance.refreshRows();
                  },
                  id: "show-totals",
               },
               {
                  type: "combo",
                  label: "Totals Cell Units",
                  value: getGanttConfigurePanelValues().totalsCellUnits || "People",
                  items: [
                     { value: TotalUnitType.PEOPLE, text: "People" },
                     { value: TotalUnitType.HOURS, text: "Hours" },
                     { value: TotalUnitType.COST, text: "Cost" },
                     { value: TotalUnitType.MAN_DAYS, text: "Man Days" },
                  ],
                  onChange: ({ value }: any) => {
                     updateGanttConfigurePanelLocalStorage("totalsCellUnits", value);
                     ganttRef.current!.instance.refreshRows();
                  },
                  id: "totals-cell-units",
               },
            ],
         },
      }),
      [],
   );
   //#endregion

   //#region Features
   const taskNonWorkingTimeFeature = useMemo<ExtractGanttProp<"taskNonWorkingTimeFeature">>(
      () => ({
         mode: "both",
         tooltipTemplate({ name: timeOffReason, startDate, endDate, taskRecord }) {
            // don't show tooltip for users without permission to view time off
            if (!hasPermissionToViewPeopleTimeoff) return;
            // don't show tooltip for weekends
            if (!timeOffReason) return;

            const dateFormat = timeFormat("%Y-%m-%d");
            const intervalId = `${dateFormat(startDate)}_${dateFormat(endDate)}`;
            const name = taskRecord.getData("name");
            const jobTitleName = taskRecord.getData("jobTitleName");
            const jobTitleColor = taskRecord.getData("jobTitleColor");
            const calendarId = taskRecord.getData("calendar");
            const currentCalendar = calendarsStore.data.find(
               (calendar: any) => calendar.id === calendarId,
            );
            // time-off interval ID includes a date part and a unique id, we need to separate and verify the date portion
            const currentTimeOffInterval = (currentCalendar as any)?.intervals.find(
               (interval: any) => interval.id?.split("__")[0] === intervalId,
            );

            if (!currentTimeOffInterval) return;

            endDate.setDate(endDate.getDate() - 1); // we want to show the last day of the time off at the tooltip
            const { startTime, endTime, repeat, isPaid, applyToSaturday, applyToSunday } =
               currentTimeOffInterval;
            const startTimeFormatted = formatTime(parseTimeString(startTime));
            const endTimeFormatted = formatTime(parseTimeString(endTime));
            const banIcon = renderToString(<Ban size="sm" color="#232729" />);

            return StringHelper.xss`
               <div class='gantt-project-tooltip ganttDayOffTooltip'>
                  <div class='gantt-project-tooltip__header'>
                        <span class="gantt-project-tooltip-color-icon" style="color: ${jobTitleColor}"></span>
                        <b>${name}</b>
                        <span class='job-title dayOffTooltipSecondary'>${jobTitleName}</span>
                  </div>
                  <hr>
                  <div class='gantt-project-tooltip__footer'>
                        <div>
                           <b>Time Off</b><br>
                        </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Dates</b><br>
                           ${getDatesRangeString(startDate, endDate)}
                        </div>
                        <div style="min-width: 200px">
                           <b>Times</b><br>
                           ${startTimeFormatted} - ${endTimeFormatted}
                        </div>
                     </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Reason</b><br>
                           <span class="dayOffTooltipSecondary">${timeOffReason}</span>
                        </div>
                        <div style="min-width: 200px">
                           <b>Type</b><br>
                            <span class="dayOffTooltipSecondary">${
                               isPaid ? "Paid" : "Unpaid"
                            }</span>
                        </div>
                     </div>
                     <div class='flex' style="gap: 0">
                        <div style="min-width: 200px">
                           <b>Weekends</b><br>
                           <div style="display: flex; ${applyToSaturday ? "display: none" : ""}">
                           <img src="data:image/svg+xml;base64,${btoa(
                              banIcon,
                           )}" style="margin-right: 8px">
                           <span>Saturday</span>
                           </div>
                           <div style="display: flex; ${applyToSunday ? "display: none" : ""}">
                           <img src="data:image/svg+xml;base64,${btoa(
                              banIcon,
                           )}" style="margin-right: 8px">
                           <span>Sunday</span>
                           </div>
                        </div>
                        <div style="min-width: 200px">
                           <b>Repeat</b><br>
                           <span class="dayOffTooltipSecondary repeat">${repeat}</span>
                        </div>
                     </div>
                  </div>
               </div>
            `;
         },
      }),
      [ganttData],
   );

   const taskResizeFeature = useMemo<ExtractGanttProp<"taskResizeFeature">>(
      () => ({
         tooltipTemplate({ startDate, endDate, record }) {
            // For some reason the taskDragFeature respects the fact that our endDate is set to 11:59 PM of it's proper day, but this taskResizeFeature
            // is rounding up to midnight of the next day! To safely correct this, we're simply subtracting one minute from whatever the endDate
            // received here is. Now if Bryntum happens to fix this bug it will just decrement to 11:58 PM of the proper day and that's no big deal at all.
            // As long as it's at the end of the proper day, we're good!
            endDate.setMinutes(endDate.getMinutes() - 1);

            return tooltipTemplateCallback(record, startDate, endDate);
         },
         tip: {
            htmlCls: "taskDragTooltipWrapper resize",
            bodyCls: "taskDragTooltip",
         },
      }),
      [],
   );

   const taskDragFeature = useMemo<ExtractGanttProp<"taskDragFeature">>(
      () => ({
         dragAllSelectedTasks: true,
         tooltipTemplate({ startDate, endDate, taskRecord }) {
            // Be sure that endDate is not rounding up to next day by simply subtracting one minute from whatever the endDate received here is.
            endDate.setMinutes(endDate.getMinutes() - 1);

            return tooltipTemplateCallback(taskRecord, startDate, endDate);
         },
         tip: {
            htmlCls: "taskDragTooltipWrapper",
            bodyCls: "taskDragTooltip",
         },
      }),
      [],
   );

   const pdfExportFeature = useMemo<ExtractGanttProp<"pdfExportFeature">>(
      () => ({
         exportServer: "/api/v3/gantt-export-pdf",
         fileFormat: "pdf",
         fileName: fileExportName,
         scheduleRange: "currentview",
         showErrorToast: true,
      }),
      [],
   );

   const excelExporterFeature = useMemo<ExtractGanttProp<"excelExporterFeature">>(
      () => ({
         dateFormat: defaultDateFormat,
         xlsProvider: MyXlsProvider,
         exporterConfig: {
            columns: [
               {
                  type: "name",
                  text: "type",
                  field: "type",
               },
               {
                  type: "name",
                  text: "id",
                  field: "id",
               },
               {
                  type: "name",
                  text: "name",
                  field: "name",
               },
               {
                  type: "name",
                  text: "job_title",
                  field: "jobTitleName",
               },
               {
                  type: "name",
                  text: "parent_project_id",
                  field: "projectId",
               },
               {
                  type: "name",
                  text: "parent_project_name",
                  field: "projectName",
               },
               {
                  type: "name",
                  text: "parent_category_id",
                  field: "parentCategoryId",
               },
               {
                  type: "name",
                  text: "parent_category_name",
                  field: "parentCategoryName",
               },
               {
                  type: "name",
                  text: "parent_subcategory_id",
                  field: "parentSubcategoryId",
               },
               {
                  type: "name",
                  text: "parent_subcategory_name",
                  field: "parentSubcategoryName",
               },
               { type: "name", text: "project_number", field: "projectJobNumber" },
               {
                  type: "startdate",
                  field: "startDate",
                  text: "start_date",
               },
               {
                  type: "enddate",
                  field: "endDate",
                  text: "end_date",
               },
               {
                  type: "date",
                  field: "startTime",
                  text: "start_time",
                  renderer: ({ value }: { value?: string }) => {
                     if (!value) return;

                     return formatTime(parseTimeString(value));
                  },
               },
               {
                  type: "date",
                  field: "endTime",
                  text: "end_time",
                  renderer: ({ value }: { value?: string }) => {
                     if (!value) return;

                     return formatTime(parseTimeString(value));
                  },
               },
               {
                  type: "calendar",
                  field: "workDays",
                  text: "work_days",
                  renderer: ({ value }: { value?: Record<number, boolean> }) => {
                     if (!value) return;

                     return DAYS.filter((_, index) => value[index]).join(" ");
                  },
               },
            ],
         },
      }),
      [],
   );

   const labelsFeature = useMemo<ExtractGanttProp<"labelsFeature">>(
      () => ({
         bottom: {
            renderer: ({ taskRecord }: any) => {
               if (taskRecord.getData("type") !== TaskType.PROJECT) return null;

               // Need to be retrieved from instance, state is not updated yet at this point,
               // if retrieving from state it will be the previous value
               const viewPreset = (ganttRef.current?.instance.viewPreset as ViewPreset)?.base;
               if (!viewPreset) return null;
               if (!getGanttConfigurePanelValues().showTotals) return null;

               const {
                  projectAssignments: assignments,
                  wageOverrides = [],
                  startDate,
                  endDate,
                  dailyEndTime,
                  dailyStartTime,
               } = taskRecord;

               const projectId = taskRecord.get("id");
               const totalsUnit =
                  (getGanttConfigurePanelValues().totalsCellUnits as TotalUnitType) ??
                  TotalUnitType.PEOPLE;
               const hideWeekendsValue = getGanttConfigurePanelValues().hideWeekends;

               let interval: moment.unitOfTime.StartOf;
               switch (viewPreset) {
                  case "weekAndMonth":
                  case "weekDateAndMonth":
                     interval = "week";
                     break;
                  case "monthAndYear":
                     interval = "month";
                     break;
                  case "year-200by100":
                  case "year":
                  case "year-50by100":
                     interval = "quarter";
                     break;
                  case "weekAndDayLetter":
                  default:
                     interval = "day";
               }

               ganttTotalService.addProject(
                  {
                     id: projectId,
                     assignments,
                     start_date: startDate,
                     est_end_date: endDate,
                     daily_start_time: dailyStartTime,
                     daily_end_time: dailyEndTime,
                  } as GanttProject,
                  ganttData?.people ?? [],
                  paidShiftHours,
                  wageOverrides,
               );

               let workedByGranularity = ganttTotalService.getTotalsByUnit(
                  projectId,
                  interval,
                  totalsUnit,
               );

               if (interval === "day") {
                  workedByGranularity = workedByGranularity.filter((day: TotalByDay) => {
                     return hideWeekendsValue && [0, 6].includes(moment(day.date).day())
                        ? null
                        : day;
                  });
               }

               const tickDate = new Date(startDate);
               const tickSize = ganttRef.current?.instance.tickSize ?? 0;
               const currentTick =
                  ganttRef.current?.instance.timeAxis.getTickFromDate(tickDate) ?? -1;
               const tickStart = currentTick % 1;

               return {
                  tag: "div",
                  "data-testid": "totals-units-row",
                  style: {
                     display: "flex",
                     position: "relative",
                     left: tickSize * tickStart * -1,
                     justifySelf: "flex-start",
                  },
                  children: workedByGranularity.map((worked) => ({
                     style: {
                        width: tickSize,
                     },
                     text: Number(worked[totalsUnit]).toLocaleString(),
                  })),
               };
            },
         },
      }),
      [paidShiftHours, ganttViewMode, ganttTotalService, ganttRef],
   );

   const treeGroupFeature = useMemo<ExtractGanttProp<"treeGroupFeature">>(() => {
      return {
         levels: ganttViewMode === GanttViewMode.PROJECTS ? null : ["name"],
         expandParents: ganttViewMode === GanttViewMode.PROJECTS ? false : true,
      };
   }, [ganttViewMode]);
   //#endregion

   //#region Gantt Events
   /**
    * Create TaskStore instance with all tasks data, without filters
    */
   const taskStoreAllData = useMemo<TaskStore>(() => {
      const data =
         ganttDataAll?.projects.map((project: GanttProject) => {
            const { tasks: projectChildren } = groupTasks(
               project,
               ganttDataAll.people,
               ganttDataAll.jobTitles,
               ganttDataAll.equipment,
               expandedTasks,
            );

            return {
               ...createProjectTask(project),
               children: projectChildren,
            };
         }) ?? [];

      const store = new TaskStore({
         modelClass: ProcoreTaskModel,
         data,
         syncDataOnLoad: false,
         autoCommit: false,
         autoLoad: false,
      });

      return store;
   }, [ganttDataAll]);

   /**
    * Update `taskStoreAllData` to include new assignment dates for handling scheduling conflicts
    */
   const updateTaskStoreAllData = (action: string, records: Model[], changes: object): void => {
      if (
         action === "update" &&
         (("startDate" in changes && "endDate" in changes) ||
            ("duration" in changes && "endDate" in changes))
      ) {
         const newRecords = records.map((record) => ({
            id: record.getData("id"),
            startDate: record.getData("startDate"),
            endDate: record.getData("endDate"),
            duration: record.getData("duration"),
         }));
         taskStoreAllData.applyChangeset({ updated: newRecords });
      }
   };

   const taskStore = useMemo<TaskStore>(() => {
      const taskStoreConfig: TaskStoreConfig = {
         onBeforeSort: ({ source }) => {
            const ganttViewMode = localStorage.getItem("gantt-view-mode") as GanttViewMode;
            // Always add primary sort on custom field "sortPriority". This ensures that assignments -> requests -> categories/subcategories
            // always appear in that order, regardless of the secondary sort that is applied on top of it.
            // TODO: this conditional logic needs to be improved so that it consistently works as intended (ie. cat/subcats are always sorted by sequence
            // and you can also re-order them at any time)
            if (droppingGridRow) return;

            if (ganttViewMode === GanttViewMode.PROJECTS) {
               source.sort(
                  [{ field: "sortPriority" }, { field: "sequence" }, ...source.sorters],
                  true,
                  true,
                  true,
               );
            }
         },
         modelClass: ProcoreTaskModel,
         fields: ["name", "resourceId"],
         syncDataOnLoad: false,
      };

      if (LaunchDarklyClient.getFlagValue("gantt-conflict-handler"))
         taskStoreConfig.onChange = ({ action, records, changes }) =>
            updateTaskStoreAllData(action, records, changes);

      return new TaskStore(taskStoreConfig);
   }, []);

   const stm = useMemo<StateTrackingManager>(() => {
      const stmConfig = new StateTrackingManager({
         autoRecord: true,
         getTransactionTitle: (transaction) => {
            // your custom code to analyze the transaction and return custom transaction title
            const lastAction = transaction.queue[transaction.queue.length - 1];

            let title = "";
            if (lastAction instanceof AddAction) {
               title = "Add new record";
            } else if (lastAction instanceof EventUpdateAction) {
               title = "Update record";
            }

            return title;
         },
         listeners: {
            recordingStop: ({ stm }) => {
               setPositionHistory(stm.position);
            },
            restoringStop: ({ stm }) => {
               setPositionHistory(stm.position);
            },
         },
      });

      return stmConfig;
   }, []);

   //#endregion

   // const ganttConfirmationModalRef = useRef<typeof GanttConfirmationModal>(null);

   // EXAMPLES of how you can access different internal stores of the ProjectModel (https://bryntum.com/products/gantt/docs/guide/Gantt/data/project_data#updating-the-project-data):
   // ganttRef.current?.instance.project.changes
   // ganttRef.current?.instance.project.taskStore.changes
   // ganttRef.current?.instance.project.timeRangeStore.changes
   // ganttRef.current?.instance.widgetMap.right.toggleCollapsed()
   // ganttRef.current?.instance.element.style.setProperty("--row-height", `${getGanttConfigurePanelValues().rowHeight}px`);

   return (
      <DetailPage width="block">
         <DetailPage.Main className="grandchildren-border-box">
            <DetailPage.Body>
               <DetailPage.Card style={{ display: "flex", overflow: "hidden" }}>
                  <GanttFilterPanel
                     ganttFilter={ganttFilter}
                     setGanttFilter={setGanttFilter}
                     disabled={isMaskOn}
                     onClose={() => setFilterPanelOpen(false)}
                     jobTitles={ganttData?.jobTitles ?? []}
                     customFilterFields={customFilterFields}
                     isOpen={filterPanelOpen}
                  />
                  <DetailPage.Section className="detailPageSection">
                     <Box>
                        <Spinner
                           loading={!isInitialLoadComplete.current}
                           data-testid="loading-spinner"
                        >
                           <GanttControlPanel
                              search={search}
                              setSearch={setSearch}
                              ganttViewMode={ganttViewMode}
                              setGanttViewMode={(value) => setGanttViewMode(value)}
                              toggleFilterPanel={() =>
                                 setFilterPanelOpen((prevState) => !prevState)
                              }
                              toggleConfigPanel={() => {
                                 (
                                    ganttRef.current?.instance.widgetMap.right as Panel
                                 ).toggleCollapsed();
                              }}
                              ganttFilter={ganttFilter}
                              setGanttFilter={setGanttFilter}
                              onViewDateChange={(date) => {
                                 ganttRef.current?.instance.scrollToDate(date ?? new Date(), {
                                    block: "start",
                                 });
                              }}
                              onZoomOut={() => ganttRef.current?.instance.zoomOut()}
                              onZoomIn={() => ganttRef.current?.instance.zoomIn()}
                              onZoomToFit={() => ganttRef.current?.instance.zoomToFit()}
                              fileExportName={fileExportName}
                              ganttRef={ganttRef}
                              customFilterFields={customFilterFields}
                              stm={stm}
                              stmPosition={positionHistory}
                           />
                           {ganttData && (
                              <>
                                 <BryntumGanttProjectModel
                                    ref={projectRef}
                                    {...projectConfig}
                                    onBeforeSend={(event) => {
                                       const { requestConfig }: { requestConfig: any } = event;
                                       const taskStoreChanges =
                                          ganttRef.current?.instance.taskStore.changes;
                                       const parsedBody = JSON.parse(requestConfig.body);

                                       if (!parsedBody.tasks) return Promise.resolve();

                                       if (parsedBody.tasks.removed) {
                                          parsedBody.tasks.removed = parsedBody.tasks.removed.map(
                                             (rem1: any) => {
                                                const taskRecord = taskStoreChanges?.removed?.find(
                                                   (rem2) => rem2.getData("id") === rem1.id,
                                                );

                                                return {
                                                   id: taskRecord?.getData("id"),
                                                   projectId: taskRecord?.getData("projectId"),
                                                   type: taskRecord?.getData("type"),
                                                   categoryId: taskRecord?.getData("categoryId"),
                                                   subcategoryId:
                                                      taskRecord?.getData("subcategoryId"),
                                                };
                                             },
                                          );
                                       } else if (parsedBody.tasks.updated) {
                                          parsedBody.tasks.updated = parsedBody.tasks.updated.map(
                                             (updatedTask: any) => {
                                                if (updatedTask.parentId) {
                                                   const newParent =
                                                      ganttRef.current!.instance.taskStore.getById(
                                                         updatedTask.parentId,
                                                      );
                                                   if (newParent) {
                                                      const parentType = newParent.getData("type");
                                                      updatedTask.parentType = parentType;
                                                      updatedTask.parentProjectId =
                                                         parentType === TaskType.PROJECT
                                                            ? newParent.getData("id")
                                                            : newParent.getData("projectId");
                                                      updatedTask.parentCategoryId =
                                                         parentType === TaskType.CATEGORY
                                                            ? newParent.getData("id")
                                                            : newParent.getData("categoryId");
                                                      updatedTask.parentSubcategoryId =
                                                         parentType === TaskType.SUBCATEGORY
                                                            ? newParent.getData("id")
                                                            : newParent.getData("subcategoryId");
                                                   }
                                                }

                                                if (
                                                   updatedTask.type === "category" &&
                                                   !updatedTask.categoryId
                                                ) {
                                                   updatedTask.categoryId = updatedTask.id;
                                                } else if (
                                                   updatedTask.type === "subcategory" &&
                                                   !updatedTask.subcategoryId
                                                ) {
                                                   updatedTask.subcategoryId = updatedTask.id;
                                                }
                                                return updatedTask;
                                             },
                                          );
                                       }

                                       requestConfig.body = JSON.stringify(parsedBody);
                                       return Promise.resolve();
                                    }}
                                    tasks={tasksData}
                                    taskStore={taskStore}
                                    calendarManagerStore={calendarsStore}
                                    onDataReady={() => {
                                       if (!stmLoaded) {
                                          setStmLoaded(true);
                                          stm.addStore(taskStore);
                                          stm.enable();
                                       }
                                    }}
                                    onLoad={() => {
                                       ganttRef.current?.instance.unmask();
                                       setIsMaskOn(false);
                                    }}
                                    stm={stm}
                                 />
                                 <BryntumGantt
                                    ref={ganttRef}
                                    {...ganttConfig}
                                    project={projectRef}
                                    labelsFeature={labelsFeature}
                                    onBeforePresetChange={(preset: any) => {
                                       if (preset.from === undefined) return;
                                       localStorage.setItem(
                                          "gantt-view-preset",
                                          String(preset.to.base),
                                       );
                                    }}
                                    onDateRangeChange={(e) => {
                                       setCurrentGanttRange({
                                          startDay: getDetachedDay(e.new.startDate),
                                          endDay: getDetachedDay(e.new.endDate),
                                       });
                                    }}
                                    onExpandNode={({ record }) => {
                                       const id =
                                          ganttViewMode === GanttViewMode.PROJECTS
                                             ? record.get("id")
                                             : "generated_" +
                                               record.firstChild.getData("resourceId") +
                                               "_";

                                       expandTask(id);
                                    }}
                                    onCollapseNode={({ record }) => {
                                       const id =
                                          ganttViewMode === GanttViewMode.PROJECTS
                                             ? record.get("id")
                                             : "generated_" +
                                               record.firstChild.getData("resourceId") +
                                               "_";

                                       collapseTask(id);
                                    }}
                                    treeGroupFeature={treeGroupFeature}
                                    startDate={startDate.current}
                                    endDate={endDate.current}
                                    presets={availablePresets}
                                    viewPreset={viewPreset}
                                    rowHeight={
                                       (getGanttConfigurePanelValues().rowHeight || 25) +
                                       TOTALS_HEIGHT_ROW
                                    }
                                    columns={columns}
                                    strips={strips}
                                    taskNonWorkingTimeFeature={taskNonWorkingTimeFeature}
                                    taskTooltipFeature={{
                                       maxWidth: "unset",
                                       minWidth: "400px",
                                       width: "max-content",
                                       style: "border-radius: 6px;",
                                       template: ({ taskRecord }: any) => {
                                          const id = taskRecord.getData("id");
                                          const startDate = taskRecord.getData("startDate");
                                          const endDate = taskRecord.getData("endDate");
                                          const name = taskRecord.getData("name");
                                          const type = taskRecord.getData("type");
                                          const projectName = taskRecord.getData("projectName");
                                          const projectColor = taskRecord.getData("projectColor");
                                          const projectJobNumber =
                                             taskRecord.getData("projectJobNumber");
                                          const categoryName = taskRecord.getData("categoryName");
                                          const jobTitleColor = taskRecord.getData(
                                             "projectJjobTitleColorobNumber",
                                          );
                                          const jobTitleName = taskRecord.getData("jobTitleName");
                                          const workDays = taskRecord.getData("workDays");

                                          if (type === TaskType.PROJECT) {
                                             const dailyStartTime =
                                                taskRecord.getData("dailyStartTime");
                                             const dailyEndTime =
                                                taskRecord.getData("dailyEndTime");
                                             const startTime = formatTime(
                                                parseTimeString(dailyStartTime),
                                             );
                                             const endTime = formatTime(
                                                parseTimeString(dailyEndTime),
                                             );

                                             return StringHelper.xss`
                                                <div class='gantt-project-tooltip'>
                                                   <div class='gantt-project-tooltip__header'>
                                                      <span class="gantt-project-tooltip-color-icon" style="color: ${projectColor}"></span>
                                                      <b>${name} ${
                                                projectJobNumber ? `(${projectJobNumber})` : ""
                                             }</b>
                                                   </div>
                                                   <hr>
                                                   <div class='gantt-project-tooltip__footer'>
                                                      <div class='flex'>
                                                         <div class='gantt-project-tooltip-date'>
                                                            <b>Dates</b><br>
                                                             ${getDatesRangeString(
                                                                startDate,
                                                                endDate,
                                                             )}
                                                         </div>
                                                         <div class='gantt-project-tooltip-daily'>
                                                            <b>Times</b><br>
                                                            ${startTime} - ${endTime}
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             `;
                                          } else if (
                                             type === TaskType.ASSIGNMENT ||
                                             type === TaskType.REQUEST ||
                                             type === TaskType.EQUIPMENT_ASSIGNMENT
                                          ) {
                                             const dailyStartTime = taskRecord.getData("startTime");
                                             const dailyEndTime = taskRecord.getData("endTime");
                                             const startTime = dailyStartTime
                                                ? formatTime(parseTimeString(dailyStartTime))
                                                : null;
                                             const endTime = dailyEndTime
                                                ? formatTime(parseTimeString(dailyEndTime))
                                                : null;
                                             const isTaskWithTime = startTime && endTime;
                                             const isRequest = type === TaskType.REQUEST;
                                             const isEqipment =
                                                type === TaskType.EQUIPMENT_ASSIGNMENT;
                                             const taskName = isRequest ? "Request" : name;
                                             const taskTimeAllocation = getTaskTimeAllocation(
                                                startTime,
                                                endTime,
                                                projectJobNumber,
                                                id,
                                             );
                                             const workingDayClass = (day: number) =>
                                                workDays?.[day] ? "bold" : "thin";
                                             const excavatorIcon = renderToString(
                                                <Excavator size="sm" color="#4f5964" />,
                                             );

                                             return StringHelper.xss`
                                                <div class='gantt-project-tooltip'>
                                                   <div class='gantt-project-tooltip__header'>
                                                         <span class="gantt-project-tooltip-color-icon" style="display: ${
                                                            isEqipment ? "none" : "inline"
                                                         }; color: ${jobTitleColor}"></span>
                                                         <img src="data:image/svg+xml;base64,${btoa(
                                                            excavatorIcon,
                                                         )}" style="display: ${
                                                isEqipment ? "inline" : "none"
                                             }; margin: 2px 4px 2px 2px">
                                                         <b><div class='${
                                                            isRequest
                                                               ? "requestPill"
                                                               : "gantt-tooltip-assignment-header"
                                                         }'>${taskName}</div></b>
                                                         <span class='job-title'>${jobTitleName}</span>
                                                   </div>
                                                   <hr>
                                                   <div class='gantt-project-tooltip__footer'>
                                                      <div>
                                                         <b>${projectName} (${projectJobNumber})</b>
                                                      </div>
                                                      <div class='flex'>
                                                         <div class='gantt-project-tooltip-date'>
                                                            <b>Dates</b><br>
                                                            ${getDatesRangeString(
                                                               startDate,
                                                               endDate,
                                                            )}
                                                         </div>
                                                         <div class='gantt-project-tooltip-daily'>
                                                            <b>${
                                                               startTime && endTime
                                                                  ? "Times"
                                                                  : "Assignment Allocation"
                                                            }</b><br>
                                                            ${
                                                               isTaskWithTime
                                                                  ? taskTimeAllocation
                                                                  : `${taskTimeAllocation}% (${
                                                                       (paidShiftHours *
                                                                          Number(
                                                                             taskTimeAllocation,
                                                                          )) /
                                                                       100
                                                                    } hours)`
                                                            }
                                                         </div>
                                                      </div>
                                                      <div>
                                                         <div class='gantt-project-tooltip-date'>
                                                            <b>Work Days</b><br>
                                                            <div class='work-days'>
                                                               <div class='${workingDayClass(
                                                                  0,
                                                               )}'>Su</div>
                                                               <div class='${workingDayClass(
                                                                  1,
                                                               )}'>M</div>
                                                               <div class='${workingDayClass(
                                                                  2,
                                                               )}'>Tu</div>
                                                               <div class='${workingDayClass(
                                                                  3,
                                                               )}'>W</div>
                                                               <div class='${workingDayClass(
                                                                  4,
                                                               )}'>Th</div>
                                                               <div class='${workingDayClass(
                                                                  5,
                                                               )}'>F</div>
                                                               <div class='${workingDayClass(
                                                                  6,
                                                               )}'>Sa</div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             `;
                                          } else if (
                                             type === TaskType.CATEGORY ||
                                             type === TaskType.SUBCATEGORY
                                          ) {
                                             return StringHelper.xss`
                                                <div class='gantt-project-tooltip'>
                                                   <div class='gantt-project-tooltip__header'>
                                                      <span class="gantt-project-tooltip-color-icon" style="color: ${projectColor}"></span>
                                                      <b>${projectName} (${projectJobNumber})</b>
                                                   </div>
                                                   <hr>
                                                   <div class='gantt-project-tooltip__footer'>
                                                      <div>
                                                         <b>${
                                                            categoryName ? categoryName + ", " : ""
                                                         }${taskRecord.name}</b>
                                                      </div>
                                                      <div class='gantt-project-tooltip-date'>
                                                         <b>Dates</b><br>
                                                         ${getDatesRangeString(startDate, endDate)}
                                                      </div>
                                                   </div>
                                                </div>
                                             `;
                                          } else {
                                             return "";
                                          }
                                       },
                                    }}
                                    taskResizeFeature={taskResizeFeature}
                                    pdfExportFeature={pdfExportFeature}
                                    excelExporterFeature={excelExporterFeature}
                                    printFeature
                                    onRenderRows={({ source }) => {
                                       source.element.style.setProperty(
                                          "--task-border-radius",
                                          `${getGanttConfigurePanelValues().taskBorderRadius}px`,
                                       );
                                       source.element.style.setProperty(
                                          "--row-height",
                                          `${getGanttConfigurePanelValues().rowHeight}px`,
                                       );
                                       source.element.style.setProperty(
                                          "--non-working-time-padding-top",
                                          `${
                                             (getGanttConfigurePanelValues().rowHeight - 15) / 2
                                          }px`,
                                       );
                                       source.element.style.setProperty(
                                          "--non-working-time-height",
                                          `${getGanttConfigurePanelValues().rowHeight - 1}px`,
                                       );
                                       hideWeekends(
                                          source as Gantt,
                                          getGanttConfigurePanelValues().hideWeekends,
                                       );
                                    }}
                                    onRenderRow={({ record, row }) => {
                                       // jobTitleColor is available as a custom property because we explicitly added it when we were building the assignments and requests data
                                       // for the tasksData object in our ProjectModel
                                       const jobTitleColor = record.getData("jobTitleColor");
                                       const isEqipment =
                                          record.getData("type") === TaskType.EQUIPMENT_ASSIGNMENT;

                                       // This query selector is using the meta ID value that associates all cells of a row together.
                                       // Since it is just querySelector and not querySelectorAll, it will only grab the first cell of the row, which is the one that
                                       // contains the i tag with class ".b-icon.b-tree-icon.b-icon-tree-leaf" that we want to style. So this chained selector should
                                       // look inside of the first element that has our row ID and then grab the icon element that's inside of it.
                                       const leafIcon = document.querySelector(
                                          `[data-id='${row.id}'] i.b-icon.b-tree-icon.b-icon-tree-leaf`,
                                       );

                                       if (leafIcon) {
                                          if (isEqipment && !leafIcon.getAttribute("hidden")) {
                                             // For equipment assignments we need to replace leaf color icon with excavator icon
                                             leafIcon.setAttribute("hidden", "true");
                                             const excavatorIcon = renderToString(
                                                <Excavator size="sm" style={{ margin: "0 4px" }} />,
                                             );

                                             leafIcon.parentElement?.insertAdjacentHTML(
                                                "afterbegin",
                                                excavatorIcon,
                                             );
                                          } else if (jobTitleColor) {
                                             leafIcon.setAttribute(
                                                "style",
                                                `color: ${jobTitleColor}`,
                                             );
                                          }
                                       }

                                       // Add horizontal rule below the projectName row cells. The way that Bryntum rows are rendered are a bit weird, so in order to work properly,
                                       // javascript needs to be paired with the CSS rules for "hr.display = 'none'" that are found in gantt.styl
                                       if (
                                          record.getData("type") === TaskType.PROJECT &&
                                          row.element.querySelector("hr") == null
                                       ) {
                                          const hr = document.createElement("hr");
                                          hr.setAttribute(
                                             "style",
                                             "width: 100%; opacity: 0.5; margin: 0; position: absolute; bottom: -1px;",
                                          );
                                          row.element.appendChild(hr);
                                       }
                                    }}
                                    taskRenderer={({ taskRecord, renderData }) => {
                                       const id = taskRecord.getData("id");
                                       const projectJobNumber =
                                          taskRecord.getData("projectJobNumber");
                                       const entityType = taskRecord.getData("type");
                                       const projectColor = taskRecord.getData("projectColor");
                                       const requestsNumber = taskRecord.getData("requestsNumber");
                                       const isProjectEntity = entityType === TaskType.PROJECT;
                                       const isCategoryEntity =
                                          entityType === TaskType.CATEGORY ||
                                          entityType === TaskType.SUBCATEGORY;
                                       const isTaskEntity =
                                          entityType === TaskType.ASSIGNMENT ||
                                          entityType === TaskType.REQUEST ||
                                          entityType === TaskType.EQUIPMENT_ASSIGNMENT;

                                       const [baseColor, lightColor, lightestColor] =
                                          getColorShades(projectColor);

                                       const isSegment = taskRecord instanceof EventSegmentModel;
                                       const hasSegments = taskRecord?.segments?.length > 0;

                                       const selectedBarColor = getSelectedBarColorHexValues(
                                          taskRecord,
                                          paidShiftHours,
                                       );

                                       // Adjusts task bar colors based on type of task:
                                       //   - projects will be base
                                       //   - categories/subcategories will be lighter
                                       //   - assignments will be chosen by they color configured in the gantt config panel
                                       //   - requests will be white with the chosen color as a dashed border
                                       if (!isSegment && !hasSegments) {
                                          if (isProjectEntity) {
                                             renderData.style += `
                                                background-color: ${baseColor};
                                                color: ${getTextColorForBackground(baseColor)};
                                             `;
                                          } else if (isCategoryEntity) {
                                             renderData.style += `background-color: ${lightColor};`;
                                          } else if (isTaskEntity) {
                                             renderData.style += `text-align: right;`;

                                             if (
                                                entityType === TaskType.ASSIGNMENT ||
                                                entityType === TaskType.EQUIPMENT_ASSIGNMENT
                                             ) {
                                                renderData.style += `
                                                   border-width: 2px;
                                                   border-color: ${selectedBarColor};
                                                   --event-background-color: ${selectedBarColor};
                                                   background-color: ${selectedBarColor};
                                                   color: ${getTextColorForBackground(
                                                      selectedBarColor,
                                                   )};
                                                `;
                                             } else if (entityType === TaskType.REQUEST) {
                                                renderData.style += `
                                                   border-width: 2px; 
                                                   border-color: ${selectedBarColor}; 
                                                   background-color: white; 
                                                   border-style: dashed;
                                                   color: #667280;
                                                `;
                                             }
                                          } else if (taskRecord.firstChild) {
                                             const jobTitleColor =
                                                taskRecord.firstChild.getData("jobTitleColor");
                                             renderData.style += `
                                                background-color: ${jobTitleColor};
                                                color: ${getTextColorForBackground(jobTitleColor)};
                                             `;
                                          }
                                       } else {
                                          renderData.style += `
                                             border-color: ${lightColor};
                                             --event-background-color: ${lightestColor} !important;
                                             --event-border-color: ${lightColor} !important;
                                          `;
                                       }

                                       // Takes care of rendering the task info on the task bar
                                       let textContent = "";

                                       if (isCategoryEntity) {
                                          textContent = StringHelper.xss`${
                                             taskRecord.name
                                          } | ${dateFormat(
                                             new Date(taskRecord.startDate),
                                          )} - ${dateFormat(new Date(taskRecord.endDate))}`;
                                       } else if (isProjectEntity) {
                                          const projectNumberStr = projectJobNumber
                                             ? `(${projectJobNumber})`
                                             : "";

                                          textContent =
                                             StringHelper.xss`${
                                                taskRecord.name
                                             } | ${projectNumberStr} ${dateFormat(
                                                new Date(taskRecord.startDate),
                                             )} - ${dateFormat(new Date(taskRecord.endDate))}` +
                                             requestsNumberPill(requestsNumber);
                                       } else if ((isTaskEntity && !isSegment) || isSegment) {
                                          const taskSource = taskRecord;

                                          const dailyStartTime = taskSource.getData("startTime");
                                          const dailyEndTime = taskSource.getData("endTime");
                                          const startTime = dailyStartTime
                                             ? formatTime(parseTimeString(dailyStartTime))
                                             : null;
                                          const endTime = dailyEndTime
                                             ? formatTime(parseTimeString(dailyEndTime))
                                             : null;

                                          textContent = StringHelper.xss`<span class="task-allocation-information">${getTaskTimeAllocation(
                                             startTime,
                                             endTime,
                                             projectJobNumber,
                                             id,
                                          )}${!startTime && !endTime ? "%" : ""}</span>`;
                                       }

                                       if (isProjectEntity || isCategoryEntity)
                                          if (
                                             getGanttConfigurePanelValues()
                                                .showProjectInformation === false
                                          ) {
                                             ganttRef.current?.instance?.element.classList.add(
                                                "b-hide-project-information",
                                             );
                                          }

                                       if (
                                          getGanttConfigurePanelValues()
                                             .showAllocationInformation === false
                                       ) {
                                          ganttRef.current?.instance?.element.classList.add(
                                             "hide-allocation-information",
                                          );
                                       }

                                       if (getGanttConfigurePanelValues().showTimeoff === false) {
                                          ganttRef.current?.instance?.element.classList.add(
                                             "hide-time-off-reasons",
                                          );
                                       }

                                       if (entityType === TaskType.EQUIPMENT_ASSIGNMENT) {
                                          const excavatorIcon = renderToString(
                                             <Excavator size="sm" color="#4f5964" />,
                                          );
                                          textContent = excavatorIcon + textContent;
                                       }

                                       return textContent;
                                    }}
                                    taskDragFeature={taskDragFeature}
                                    onGridRowBeforeDragStart={() => {
                                       if (!droppingGridRow) droppingGridRow = true;
                                    }}
                                    onGridRowBeforeDropFinalize={onGridRowBeforeDropFinalize}
                                    onGridRowDrop={() => {
                                       if (droppingGridRow) droppingGridRow = false;
                                    }}
                                    onBeforeTaskDropFinalize={({
                                       context,
                                    }: Record<string, any>) => {
                                       if (
                                          !LaunchDarklyClient.getFlagValue("gantt-conflict-handler")
                                       )
                                          return;

                                       taskSchedulingConflictHandler(context);
                                    }}
                                    onBeforeTaskResizeFinalize={({
                                       context,
                                    }: Record<string, any>) => {
                                       if (
                                          !LaunchDarklyClient.getFlagValue("gantt-conflict-handler")
                                       )
                                          return;

                                       taskSchedulingConflictHandler(context, true);
                                    }}
                                    onCellDblClick={({ record }) => {
                                       if (
                                          ![TaskType.CATEGORY, TaskType.SUBCATEGORY].includes(
                                             record.getData("type"),
                                          )
                                       )
                                          return false;
                                    }}
                                    onTaskMenuBeforeShow={({
                                       taskRecord: menuTaskRecord,
                                       items,
                                    }) => {
                                       const type = menuTaskRecord.getData("type");

                                       const isOptionHidden = (option: MenuItemConfig) =>
                                          ENABLE_TASK_SPLITTING &&
                                          !(option as any).hasClickedOnGrid;

                                       const createGroupingMenuOption = (items as any)
                                          .createAssignmentGrouping as MenuItemConfig;
                                       const deleteGroupingMenuOption = (items as any)
                                          .deleteAssignmentGrouping as MenuItemConfig;
                                       const createAssignmentMenuOption = (items as any)
                                          .createAssignment as MenuItemConfig;
                                       const deleteAssignmentMenuOption = (items as any)
                                          .deleteAssignment as MenuItemConfig;
                                       const editAssignmentMenuOption = (items as any)
                                          .editAssignment as MenuItemConfig;
                                       const createRequestMenuOption = (items as any)
                                          .createRequest as MenuItemConfig;
                                       const deleteRequestMenuOption = (items as any)
                                          .deleteRequest as MenuItemConfig;
                                       const editRequestMenuOption = (items as any)
                                          .editRequest as MenuItemConfig;
                                       const splitTaskMenuOption =
                                          (items as any).splitTask ||
                                          ({ hidden: true } as MenuItemConfig);
                                       const sendAssignmentAlertsMenuOption = (items as any)
                                          .sendAssignmentAlerts as MenuItemConfig;

                                       createAssignmentMenuOption.hidden = true;
                                       createRequestMenuOption.hidden = true;
                                       createGroupingMenuOption.hidden = true;
                                       editAssignmentMenuOption.hidden = true;
                                       splitTaskMenuOption.hidden = true;
                                       editRequestMenuOption.hidden = true;
                                       sendAssignmentAlertsMenuOption.hidden = true;
                                       deleteGroupingMenuOption.hidden = true;
                                       deleteAssignmentMenuOption.hidden = true;
                                       deleteRequestMenuOption.hidden = true;

                                       if (type === TaskType.PROJECT) {
                                          createGroupingMenuOption.hidden =
                                             isOptionHidden(createGroupingMenuOption);
                                          createAssignmentMenuOption.hidden = isOptionHidden(
                                             createAssignmentMenuOption,
                                          );
                                          createRequestMenuOption.hidden =
                                             isOptionHidden(createRequestMenuOption);
                                          sendAssignmentAlertsMenuOption.hidden = isOptionHidden(
                                             sendAssignmentAlertsMenuOption,
                                          );
                                       } else if (type === TaskType.CATEGORY) {
                                          createGroupingMenuOption.hidden =
                                             isOptionHidden(createGroupingMenuOption);
                                          deleteGroupingMenuOption.hidden =
                                             isOptionHidden(deleteGroupingMenuOption);
                                          createAssignmentMenuOption.hidden = isOptionHidden(
                                             createAssignmentMenuOption,
                                          );
                                          createRequestMenuOption.hidden =
                                             isOptionHidden(createRequestMenuOption);
                                       } else if (type === TaskType.SUBCATEGORY) {
                                          deleteGroupingMenuOption.hidden =
                                             isOptionHidden(deleteGroupingMenuOption);
                                          createAssignmentMenuOption.hidden = isOptionHidden(
                                             createAssignmentMenuOption,
                                          );
                                          createRequestMenuOption.hidden =
                                             isOptionHidden(createRequestMenuOption);
                                       } else if (type === TaskType.ASSIGNMENT) {
                                          editAssignmentMenuOption.hidden = false;
                                          deleteAssignmentMenuOption.hidden = false;
                                          splitTaskMenuOption.hidden = false;
                                       } else if (type === TaskType.REQUEST) {
                                          editRequestMenuOption.hidden =
                                             isOptionHidden(editRequestMenuOption);
                                          deleteRequestMenuOption.hidden =
                                             isOptionHidden(deleteRequestMenuOption);
                                       }
                                       if (!checkAuthAction(AuthAction.MANAGE_ASSIGNMENTS)) {
                                          createAssignmentMenuOption.hidden = true;
                                          editAssignmentMenuOption.hidden = true;
                                          deleteAssignmentMenuOption.hidden = true;
                                          splitTaskMenuOption.hidden = true;
                                       }
                                       if (!checkAuthAction(AuthAction.MANAGE_REQUESTS)) {
                                          createRequestMenuOption.hidden = true;
                                          editRequestMenuOption.hidden = true;
                                          deleteRequestMenuOption.hidden = true;
                                       }
                                       if (
                                          !checkAuthAction(AuthAction.MANAGE_ALERTS) ||
                                          LaunchDarklyClient.getFlagValue(
                                             "gantt-send-assignment-alerts",
                                          ) !== true
                                       ) {
                                          sendAssignmentAlertsMenuOption.hidden = true;
                                       }

                                       if (ENABLE_TASK_SPLITTING) {
                                          if (
                                             !(deleteAssignmentMenuOption as any).hasClickedOnTask
                                          ) {
                                             deleteAssignmentMenuOption.hidden = true;
                                          }
                                          if (!(editAssignmentMenuOption as any).hasClickedOnTask) {
                                             editAssignmentMenuOption.hidden = true;
                                          }
                                          if (!(splitTaskMenuOption as any).hasClickedOnTask) {
                                             splitTaskMenuOption.hidden = true;
                                          }

                                          if (!(createGroupingMenuOption as any).hasClickedOnGrid) {
                                             createGroupingMenuOption.hidden = true;
                                          }
                                          if (!(deleteGroupingMenuOption as any).hasClickedOnGrid) {
                                             deleteGroupingMenuOption.hidden = true;
                                          }
                                       } else {
                                          splitTaskMenuOption.hidden = true;
                                       }

                                       if (type === TaskType.PROJECT) {
                                          createGroupingMenuOption.text = "add category";
                                          createGroupingMenuOption.onItem = async ({
                                             taskRecord,
                                          }) => {
                                             if (!taskRecord) taskRecord = menuTaskRecord;

                                             const newId = new Model()
                                                .generateId()
                                                .split("_")
                                                .at(-1);
                                             const newCategory = {
                                                id: newId,
                                                name: "New Category",
                                                type: "category",
                                                projectId: taskRecord.getData("id"),
                                                expanded: true,
                                                sortPriority: sortPriorityLevels[TaskType.CATEGORY],
                                             };

                                             const newTask =
                                                await ganttRef.current!.instance.addSubtask(
                                                   taskRecord as TaskModel,
                                                   {
                                                      data: {
                                                         ...newCategory,
                                                      },
                                                   },
                                                );

                                             expandTask(newTask.get("id"));

                                             ganttRef.current!.instance.features.cellEdit.startEditing(
                                                {
                                                   record: newTask,
                                                   field: "name",
                                                },
                                             );
                                          };

                                          // only show 'send assignmnet alerts' option for non-empty projects
                                          if (
                                             menuTaskRecord.allChildren.filter((child) =>
                                                ["assignment", "request"].includes(
                                                   child.getData("type"),
                                                ),
                                             ).length === 0
                                          ) {
                                             sendAssignmentAlertsMenuOption.hidden = true;
                                          }
                                       } else if (type === TaskType.CATEGORY) {
                                          createGroupingMenuOption.text = "add subcategory";
                                          createGroupingMenuOption.onItem = async ({
                                             taskRecord,
                                          }) => {
                                             if (!taskRecord) taskRecord = menuTaskRecord;

                                             const projectId = taskRecord.getData("projectId");
                                             const categoryId = taskRecord.getData("id");
                                             const newId = new Model()
                                                .generateId()
                                                .split("_")
                                                .at(-1);

                                             const newSubcategory = {
                                                id: newId,
                                                name: "New Subcategory",
                                                text: "New Subcategory",
                                                type: "subcategory",
                                                projectId,
                                                categoryId,
                                                expanded: true,
                                                sortPriority:
                                                   sortPriorityLevels[TaskType.SUBCATEGORY],
                                             };

                                             const newTask =
                                                await ganttRef.current!.instance.addSubtask(
                                                   taskRecord as TaskModel,
                                                   {
                                                      data: {
                                                         ...newSubcategory,
                                                      },
                                                   },
                                                );

                                             expandTask(newTask.get("id"));

                                             ganttRef.current!.instance.features.cellEdit.startEditing(
                                                {
                                                   record: newTask,
                                                   field: "name",
                                                },
                                             );
                                          };

                                          deleteGroupingMenuOption.text = "delete category";
                                          deleteGroupingMenuOption.onItem = ({ taskRecord }) => {
                                             if (!taskRecord) taskRecord = menuTaskRecord;

                                             showGanttModal({
                                                type: GanttModalType.DeleteConfirmation,
                                                modalId: "gantt-confirmation-modal-container",
                                                title: "Delete Category?",
                                                body: (
                                                   <P>
                                                      <span>
                                                         Are you sure you want to permanently delete
                                                         this category? Doing so will remove all
                                                         assignments and requests within the
                                                         category.{" "}
                                                      </span>
                                                      <b style={{ fontWeight: "600" }}>
                                                         This action cannot be undone.
                                                      </b>
                                                   </P>
                                                ),
                                                onSave: async () => {
                                                   if (taskRecord)
                                                      ganttRef.current?.instance.taskStore.remove(
                                                         taskRecord,
                                                      );
                                                },
                                             });
                                          };
                                       } else if (type === TaskType.SUBCATEGORY) {
                                          deleteGroupingMenuOption.text = "delete subcategory";
                                          deleteGroupingMenuOption.onItem = ({ taskRecord }) => {
                                             if (!taskRecord) taskRecord = menuTaskRecord;

                                             showGanttModal({
                                                type: GanttModalType.DeleteConfirmation,
                                                modalId: "gantt-confirmation-modal-container",
                                                title: "View Export",
                                                body: (
                                                   <P>
                                                      <span>
                                                         Are you sure you want to permanently delete
                                                         this subcategory? Doing so will remove all
                                                         assignments and requests within the
                                                         subcategory.{" "}
                                                      </span>
                                                      <b style={{ fontWeight: "600" }}>
                                                         This action cannot be undone.
                                                      </b>
                                                   </P>
                                                ),
                                                onSave: () => {
                                                   ganttRef.current?.instance.taskStore.remove(
                                                      taskRecord as TaskModel,
                                                   );
                                                },
                                             });
                                          };
                                       }

                                       createAssignmentMenuOption.onItem = ({ taskRecord }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const projectId =
                                             type === TaskType.PROJECT
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("projectId");
                                          const categoryId =
                                             type === TaskType.CATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("categoryId");
                                          const subcategoryId =
                                             type === TaskType.SUBCATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("subcategoryId");

                                          createAssignmentTearsheetRef.current?.handleOpenTearsheet(
                                             {
                                                projectId,
                                                categoryId,
                                                subcategoryId,
                                                callback: async ({
                                                   newTask,
                                                   calendarForNewTask,
                                                }) => {
                                                   const ganttInstance: any =
                                                      ganttRef.current?.instance;

                                                   addCalendarIfNotExists(calendarForNewTask);
                                                   await ganttInstance.crudManager.suspendAutoSync();

                                                   const newTaskRecord =
                                                      await ganttRef.current?.instance.addSubtask(
                                                         taskRecord as TaskModel,
                                                         {
                                                            data: newTask,
                                                         },
                                                      );
                                                   // We're explicitly updating endDate here because for some reason, Bryntum isn't setting correct endDate on addSubtask
                                                   if (newTaskRecord)
                                                      newTaskRecord.endDate = newTask.endDate;

                                                   await ganttInstance.crudManager.clearChanges();
                                                   await ganttInstance.crudManager.resumeAutoSync();

                                                   if (
                                                      !ganttInstance.taskStore.find(
                                                         (task: any) => task.id === newTask.id,
                                                      )
                                                   )
                                                      ganttInstance.taskStore.add(newTask);

                                                   taskStoreAllData.add(newTask);
                                                },
                                             },
                                          );
                                       };

                                       editAssignmentMenuOption.onItem = ({
                                          taskRecord,
                                          domEvent,
                                       }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const projectId =
                                             type === TaskType.PROJECT
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("projectId");
                                          const categoryId =
                                             type === TaskType.CATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("categoryId");
                                          const subcategoryId =
                                             type === TaskType.SUBCATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("subcategoryId");

                                          let recordToEdit = taskRecord;
                                          let segmentToEdit: EventSegmentModel | null;
                                          let segments: EventSegmentModel[] = [];

                                          if (ENABLE_TASK_SPLITTING) {
                                             segments = (taskRecord as TaskModel).segments as any;

                                             segmentToEdit = findSegmentSelected(
                                                segments as any,
                                                domEvent,
                                             ) as any;
                                             if (segmentToEdit) recordToEdit = segmentToEdit;
                                          }

                                          editAssignmentTearsheetRef.current?.handleOpenTearsheet({
                                             assignmentId: recordToEdit.getData("id"),
                                             projectId,
                                             categoryId,
                                             subcategoryId,
                                             callback: async ({
                                                newTask,
                                                calendarForNewTask,
                                             }: any) => {
                                                await suspendAutoSync(ganttRef);

                                                const updateSingleTask = () => {
                                                   addCalendarIfNotExists(calendarForNewTask);

                                                   newTask.calendar = calendarForNewTask.name;

                                                   const taskFromStore = taskStore.getById(
                                                      taskRecord?.id ?? "",
                                                   );

                                                   taskFromStore?.set(newTask);
                                                   taskFromStore?.clearChanges();
                                                };

                                                // If we are editing a task that is a segment, we need to update that segment in the task store
                                                if (ENABLE_TASK_SPLITTING && segmentToEdit) {
                                                   const updatedSegments = segments.map(
                                                      (s: EventSegmentModel) =>
                                                         s.id === segmentToEdit?.id
                                                            ? new EventSegmentModel({
                                                                 ...s,
                                                                 ...newTask,
                                                                 id: s.id,
                                                              })
                                                            : s,
                                                   );
                                                   await (taskRecord as TaskModel).setSegments(
                                                      updatedSegments as any,
                                                   );
                                                   taskRecord?.clearChanges();
                                                } else {
                                                   updateSingleTask();
                                                }

                                                await clearChanges(ganttRef);
                                                await resumeAutoSync(ganttRef);
                                             },
                                          });
                                       };

                                       sendAssignmentAlertsMenuOption.onItem = ({ taskRecord }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const projectId =
                                             type === TaskType.PROJECT
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("projectId");

                                          sendAssignmentAlertsTearsheetRef.current?.handleOpenTearsheet(
                                             {
                                                projectId,
                                             },
                                          );
                                       };

                                       deleteAssignmentMenuOption.onItem = ({
                                          taskRecord,
                                          domEvent,
                                       }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const segments: EventSegmentModel[] = (
                                             taskRecord as TaskModel
                                          ).segments as any;

                                          const segmentToRemove = findSegmentSelected(
                                             segments as any,
                                             domEvent,
                                          );

                                          showGanttModal({
                                             type: GanttModalType.DeleteConfirmation,
                                             modalId: "gantt-confirmation-modal-container",
                                             title: "Delete Assignment?",
                                             body: (
                                                <P>
                                                   <span>
                                                      Are you sure you want to permanently delete
                                                      this assignment?{" "}
                                                   </span>
                                                   <b style={{ fontWeight: "600" }}>
                                                      This action cannot be undone.
                                                   </b>
                                                </P>
                                             ),
                                             onSave: async () => {
                                                if (
                                                   segments &&
                                                   segments.length > 0 &&
                                                   segmentToRemove
                                                ) {
                                                   const newSegments = [...segments];
                                                   const segmentIndex = newSegments.findIndex(
                                                      (segment) =>
                                                         segment.id === segmentToRemove?.id,
                                                   );
                                                   const isHead = segmentIndex === 0;
                                                   const segmentToSyncRemove = newSegments.splice(
                                                      segmentIndex,
                                                      1,
                                                   )[0];
                                                   if (segmentToSyncRemove) {
                                                      const taskData = {
                                                         ...(segmentToSyncRemove as any)
                                                            .originalData,
                                                      };

                                                      /**
                                                       * Clone and remove the segment from the task, add a clone from the segment
                                                       * so this could send an update to the server when removed, preventing updates
                                                       * from another segments
                                                       */
                                                      const cloneTask = async () => {
                                                         const [newTask] =
                                                            ganttRef.current?.instance.taskStore.add(
                                                               taskData,
                                                               true,
                                                            ) as Model[];
                                                         await (
                                                            taskRecord as TaskModel
                                                         ).setSegments(
                                                            newSegments.map(
                                                               (segment) =>
                                                                  (segment as any).originalData,
                                                            ),
                                                         );
                                                         taskRecord?.clearChanges();
                                                         return newTask;
                                                      };

                                                      // ganttRef.current?.instance.project.beginBatch();
                                                      await (
                                                         ganttRef.current?.instance as any
                                                      ).crudManager.suspendAutoSync();

                                                      // If is the head segment, we need all data from the segment
                                                      // that is next to the head segmnet
                                                      // and clone it into the record, this way it prevents updates
                                                      // and then clone and remove the original task
                                                      if (isHead) {
                                                         const newHead: any = {
                                                            ...(newSegments[0] as any).originalData,
                                                         };
                                                         Object.keys(newHead).forEach((key) =>
                                                            taskRecord?.set(key, newHead[key]),
                                                         );
                                                      }
                                                      const newTask = await cloneTask();

                                                      await (
                                                         ganttRef.current?.instance as any
                                                      ).crudManager.clearChanges();
                                                      await (
                                                         ganttRef.current?.instance as any
                                                      ).crudManager.resumeAutoSync();

                                                      newTask.remove();
                                                   }
                                                } else if (taskRecord) {
                                                   ganttRef.current?.instance.taskStore.remove(
                                                      taskRecord,
                                                   );
                                                   taskStoreAllData.remove(taskRecord);
                                                }
                                             },
                                          });
                                       };

                                       createRequestMenuOption.onItem = ({ taskRecord }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const projectId =
                                             type === TaskType.PROJECT
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("projectId");
                                          const categoryId =
                                             type === TaskType.CATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("categoryId");
                                          const subcategoryId =
                                             type === TaskType.SUBCATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("subcategoryId");

                                          createRequestTearsheetRef.current?.handleOpenTearsheet({
                                             projectId,
                                             categoryId,
                                             subcategoryId,
                                             callback: async ({ newTasks }: any) => {
                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.suspendAutoSync();

                                                const projectId = newTasks[0].projectId;

                                                for (const newTask of newTasks) {
                                                   const newTaskRecord =
                                                      await ganttRef.current?.instance.addSubtask(
                                                         taskRecord as TaskModel, // Parent task (Project, Category, or Subcategory)
                                                         {
                                                            data: newTask,
                                                         },
                                                      );
                                                   // Using this method to update the endDate because is updating the schedule otherwise the endDate change is not reflected
                                                   await newTaskRecord?.setEndDate(newTask.endDate);
                                                }

                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.clearChanges();
                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.resumeAutoSync();

                                                const project =
                                                   ganttRef.current?.instance.taskStore.getById(
                                                      projectId,
                                                   );
                                                project?.set(
                                                   "requestsNumber",
                                                   (project?.get("requestsNumber") ?? 0) +
                                                      newTasks.length,
                                                );
                                             },
                                          });
                                       };

                                       editRequestMenuOption.onItem = ({ taskRecord }) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          const projectId =
                                             type === TaskType.PROJECT
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("projectId");
                                          const categoryId =
                                             type === TaskType.CATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("categoryId");
                                          const subcategoryId =
                                             type === TaskType.SUBCATEGORY
                                                ? taskRecord.getData("id")
                                                : taskRecord.getData("subcategoryId");

                                          editRequestTearsheetRef.current?.handleOpenTearsheet({
                                             requestId: taskRecord.getData("id"),
                                             projectId,
                                             categoryId,
                                             subcategoryId,
                                             callback: async ({ newTasks }: any) => {
                                                const newTask = newTasks[0];
                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.suspendAutoSync();

                                                const taskFromStore =
                                                   ganttRef.current?.instance.taskStore.getById(
                                                      taskRecord?.id ?? "",
                                                   );

                                                if (newTask.type === "request") {
                                                   taskFromStore?.set(newTask);
                                                } else {
                                                   const newTaskRecord =
                                                      await ganttRef.current?.instance.addSubtask(
                                                         taskRecord.parent as TaskModel, // Parent task (Project, Category, or Subcategory)
                                                         {
                                                            data: newTask,
                                                         },
                                                      );
                                                   // Using this method to update the endDate because is updating the schedule otherwise the endDate change is not reflected
                                                   await newTaskRecord?.setEndDate(newTask.endDate);
                                                   taskRecord.remove();
                                                }

                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.clearChanges();
                                                await (
                                                   ganttRef.current?.instance as any
                                                ).crudManager.resumeAutoSync();
                                             },
                                          });
                                       };

                                       deleteRequestMenuOption.onItem = ({ taskRecord }: any) => {
                                          if (!taskRecord) taskRecord = menuTaskRecord;

                                          showGanttModal({
                                             type: GanttModalType.DeleteConfirmation,
                                             modalId: "gantt-confirmation-modal-container",
                                             title: "Delete Request?",
                                             body: (
                                                <P>
                                                   <span>
                                                      Are you sure you want to permanently delete
                                                      this request?{" "}
                                                   </span>
                                                   <b style={{ fontWeight: "600" }}>
                                                      This action cannot be undone.
                                                   </b>
                                                </P>
                                             ),
                                             onSave: async () => {
                                                if (taskRecord) {
                                                   ganttRef.current?.instance.taskStore.remove(
                                                      taskRecord,
                                                   );

                                                   const project =
                                                      ganttRef.current?.instance.taskStore.getById(
                                                         taskRecord.getData("projectId"),
                                                      );
                                                   project?.set(
                                                      "requestsNumber",
                                                      (project?.get("requestsNumber") ?? 0) - 1,
                                                   );
                                                }
                                             },
                                          });
                                       };

                                       if (ENABLE_TASK_SPLITTING) {
                                          let showMenu = false;
                                          for (const item in items) {
                                             if (
                                                Object.prototype.hasOwnProperty.call(items, item)
                                             ) {
                                                const element: any = items[item];
                                                if (
                                                   typeof element === "object" &&
                                                   !element.hidden
                                                ) {
                                                   showMenu = true;
                                                   break;
                                                } else if (element === true) {
                                                   showMenu = true;
                                                   break;
                                                }
                                             }
                                          }
                                          return showMenu;
                                       }
                                       return true;
                                    }}
                                 />
                              </>
                           )}
                        </Spinner>
                     </Box>
                  </DetailPage.Section>
               </DetailPage.Card>
               <GanttProjectDetailsTearsheet ref={projectDetailsTearsheetRef} />
               <GanttPersonDetailsTearsheet ref={personDetailsTearsheetRef} />
               <GanttCreateAssignmentTearsheet ref={createAssignmentTearsheetRef} />
               <GanttSendAssignmentAlertsTearsheet ref={sendAssignmentAlertsTearsheetRef} />
               <GanttCreateRequestTearsheet ref={createRequestTearsheetRef} />
               <GanttEditAssignmentTearsheet ref={editAssignmentTearsheetRef} />
               <GanttEditRequestTearsheet ref={editRequestTearsheetRef} />
            </DetailPage.Body>
         </DetailPage.Main>
      </DetailPage>
   );
};
