import { Box, Flex, FlexList, useI18nContext } from "@procore/core-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
   AssignmentListDataTableProps,
   AssignmentListServerFilter,
} from "./assignment-list-prop-types";
import { useProjectTearsheet } from "../tearsheets/project/project-tearsheet";
import { usePersonTearsheet } from "../tearsheets/people/people-tearsheet";
import { defaultAssignmentListTableConfig } from "./assignment-list-helpers";
import type {
   BooleanCellColumnDefinition,
   ColumnDefinition,
   DateCellColumnDefinition,
   PercentCellColumnDefinition,
   ServerSideGetRowsParams,
   TextCellColumnDefinition,
} from "@procore/data-table";
import {
   BooleanCellRenderer,
   DateCellRenderer,
   MultiSelectFilterRenderer,
   PercentCellRenderer,
   SelectCellRenderer,
   ServerSideDataTable,
   TextCellRenderer,
} from "@procore/data-table";
import { CustomLinkCellRenderer } from "../data-table/custom-link-column/custom-link-cell";
import type { FormattedOption, IntegratedField, SortModel } from "@/react/prop-types";
import { EntityTypes, PageTitle } from "@/react/prop-types";
import { CustomPersonCellRenderer } from "../data-table/custom-person-cell-renderer/custom-person-cell-renderer";
import { getReorderedColumnDefinition } from "@/react/shared/helper";
import { ColorSelectRenderer } from "../data-table/ColorSelectComponent/ColorSelectColumn";
import { DateFilter } from "../data-table/custom-filters/date-filter";
import {
   generateProjectRoleColumnDefinitions,
   getDateFilterTokenText,
} from "../project-list/helpers";
import { TextFilter } from "../data-table/custom-filters/text-filter";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { authManager } from "@/lib/managers/auth-manager";
import {
   createColumnDefinitionsFromCustomHeaders,
   findCustomField,
} from "@/react/shared/custom-field-utils";
import { useGetIntegratedFields } from "../common/queries/queries";
import { renderHeaderNode as renderProjectHeaderNode } from "../project-list/project-list-data-table";
import { renderHeaderNode as renderPeopleHeaderNode } from "../people-list/people-list-data-table";

const LOCAL_STORAGE_CONFIG_KEY = "assignmentListTableConfig";

export const getProjectString = (I18n: ReturnType<typeof useI18nContext>) => {
   return I18n.t("views.company.workforce_planning.project");
};
/* istanbul ignore next */
export const AssignmentListDataTable = (props: AssignmentListDataTableProps) => {
   const I18n = useI18nContext();
   const {
      tableApi,
      fetchAssignmentList,
      handleTableReady,
      groupId,
      streamJobTitles,
      streamViewableStatuses,
      streamPeople,
      customFields,
      roleOptions,
   } = props;
   const [customColumnDefinitions, setCustomColumnDefinitions] = useState<ColumnDefinition[]>([]);
   const { checkAuthAction } = usePermissionContext();
   const { data: integratedFields, isLoading: integratedFieldsIsLoading } =
      useGetIntegratedFields();
   const [canViewPeopleSensitiveFields, setCanViewPeopleSensitiveFields] = useState(false);
   const [canViewPeopleFinancials, setCanViewPeopleFinancials] = useState(false);
   const [peopleSensitiveFields, setPeopleSensitiveFields] = useState<string[]>([]);
   const [canViewProjectSensitiveFields, setCanViewProjectSensitiveFields] = useState(false);
   const [canViewProjectFinancials, setCanViewProjectFinancials] = useState(false);
   const [projectSensitiveFields, setProjectSensitiveFields] = useState<string[]>([]);
   const [projectIntegratedFields, setProjectIntegratedFields] = useState<IntegratedField[]>([]);
   const [peopleIntegratedFields, setPeopleIntegratedFields] = useState<IntegratedField[]>([]);
   const [canManageAssignment, setCanManageAssignment] = useState(false);
   const [canViewProjectRoles, setCanViewProjectRoles] = useState(false);
   const { dispatch: projectTearsheetDispatch } = useProjectTearsheet();
   const { dispatch: personTearsheetDispatch } = usePersonTearsheet();
   // Next starting_after value for pagination
   const nextStartAfter = useRef(undefined);
   // reading tableConfig like this to avoid re-renders for server side filters
   // they kind of reset when re-rendered
   const cachedConfig =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)!) ??
      defaultAssignmentListTableConfig;

   const tableConfig = cachedConfig;

   const serverFilters = tableConfig.serverFilters;

   /*unique key formed to pass it to DataTable Component for remounting of component when needed
      this is required if filters are applied and someone switches the group from navigation bar
      or when create tearsheet or project, people hyperlink is clicked on the table cell, it cause re-rendering so remounting
      of DataTable component is needed for persisting with filters*/
   const dataTableKey = groupId + "" + JSON.stringify(serverFilters);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.projects_integrated_fields)) {
            setProjectIntegratedFields(integratedFields.data.projects_integrated_fields);
         }
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            const updatedFields = integratedFields.data.people_integrated_fields.map((field) => {
               switch (field.property) {
                  case "first_name":
                  case "last_name":
                     return { ...field, property: "person" };
                  case "is_male":
                     return { ...field, property: "gender" };
                  default:
                     return field;
               }
            });

            setPeopleIntegratedFields(updatedFields);
         }
      }
   }, [integratedFields]);

   useEffect(() => {
      //people
      const canViewPeopleSensitiveFields = checkAuthAction(AuthAction.VIEW_PEOPLE_SENSITIVE);
      setCanViewPeopleSensitiveFields(canViewPeopleSensitiveFields);
      const canViewPeopleFinancials = checkAuthAction(AuthAction.VIEW_PEOPLE_FINANCIALS);
      setCanViewPeopleFinancials(canViewPeopleFinancials);
      setPeopleSensitiveFields(
         authManager
            .peopleSensitiveFields()
            .map((field) => (field == "is_male" ? "gender" : field)),
      );

      //projects
      const canViewProjectSensitiveFields = checkAuthAction(AuthAction.VIEW_PROJECT_SENSITIVE);
      setCanViewProjectSensitiveFields(canViewProjectSensitiveFields);
      setProjectSensitiveFields(authManager.projectsSensitiveFields());
      const canViewProjectFinancials = checkAuthAction(AuthAction.VIEW_PROJECT_FINANCIALS);
      setCanViewProjectFinancials(canViewProjectFinancials);
      const canViewProjectRoles = checkAuthAction(AuthAction.VIEW_PROJECT_ROLES);
      setCanViewProjectRoles(canViewProjectRoles);

      //assignments
      const canManageAssignment = checkAuthAction(AuthAction.MANAGE_ASSIGNMENTS);
      setCanManageAssignment(canManageAssignment);
   }, [checkAuthAction]);

   //If the Group ID prop changes, fetch the project list again
   useEffect(() => {
      if (tableApi) {
         tableApi.refreshServerSide({});
      }
   }, [groupId]);

   const onTableConfigChange = useCallback((config: any) => {
      localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(config));
   }, []);

   // DataTable Column definitions
   const projectNameColumn: ColumnDefinition = {
      field: "project",
      headerName: I18n.t("views.company.workforce_planning.project"),
      cellRenderer: CustomLinkCellRenderer,
      cellRendererParams: {
         pageTitle: PageTitle.PROJECT_LIST,
         getProjectDetailDispatch: projectTearsheetDispatch,
      },
      getStringFormattedValue: (value) => {
         return value;
      },
      editable: false,
      lockVisible: true,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "project_name"),
      },
   };

   const peopleNameColumn: ColumnDefinition = {
      field: "person",
      headerName: I18n.t("views.company.workforce_planning.people.name"),
      cellRenderer: CustomPersonCellRenderer,
      cellRendererParams: {
         pageTitle: PageTitle.PEOPLE_LIST,
         getPersonDetailDispatch: personTearsheetDispatch,
      },
      editable: false,
      lockVisible: true,
      filterRenderer: MultiSelectFilterRenderer,
      filterProps: {
         index: 1,
         getLabel: (item: FormattedOption) => item.label,
         getFilterOptions: async () => {
            return await streamPeople(groupId);
         },
      },
      columnHeaderParams: {
         headerNode: () => renderPeopleHeaderNode(peopleIntegratedFields, "person"),
      },
   };

   const projectNumberColumn: TextCellColumnDefinition = {
      field: "job_number",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.projects.project_number"),
      editable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "job_number"),
      },
   };

   const jobTitleColumn: ColumnDefinition = {
      field: "job_title",
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => item.color,
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      headerName: I18n.t("views.company.workforce_planning.people.job_title"),
      editable: false,
      filterRenderer: MultiSelectFilterRenderer,
      filterProps: {
         index: 1,
         getLabel: (item: FormattedOption) => item.label,
         getFilterOptions: async () => {
            return await streamJobTitles(groupId);
         },
      },
      columnHeaderParams: {
         headerNode: () => renderPeopleHeaderNode(peopleIntegratedFields, "position_id"),
      },
   };

   const startDateColumn: DateCellColumnDefinition = {
      field: "start_date",
      headerName: I18n.t("views.company.workforce_planning.assignment.start_date"),
      cellRenderer: DateCellRenderer,
      filterProps: {
         getFilterTokenText: (item: any) => getDateFilterTokenText(item),
      },
      filterRenderer: DateFilter,
   };

   const endDateColumn: DateCellColumnDefinition = {
      field: "end_date",
      headerName: I18n.t("views.company.workforce_planning.assignment.end_date"),
      cellRenderer: DateCellRenderer,
      filterProps: {
         getFilterTokenText: (item: any) => getDateFilterTokenText(item),
      },
      filterRenderer: DateFilter,
   };

   const startTimeColumn: TextCellColumnDefinition = {
      field: "start_time",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.assignment.start_time"),
   };

   const endTimeColumn: TextCellColumnDefinition = {
      field: "end_time",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.assignment.end_time"),
   };

   const percentAllocatedColumn: PercentCellColumnDefinition = {
      field: "percent_allocated",
      cellRenderer: PercentCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.assignment.percent_allocated"),
      editable: false,
      rightAlign: true,
   };

   const statusColumn: ColumnDefinition = {
      field: "status",
      cellRenderer: ColorSelectRenderer,
      cellRendererParams: {
         getColor: (item: { color: string }) => item.color,
         getShape: (item: { shape: string }) => item.shape,
      },
      getStringFormattedValue: (item) => item.label,
      headerName: I18n.t("views.company.workforce_planning.people.status"),
      filterRenderer: MultiSelectFilterRenderer,
      filterProps: {
         index: 1,
         getLabel: (item: FormattedOption) => item.label,
         getFilterOptions: async () => {
            return await streamViewableStatuses();
         },
      },
      columnHeaderParams: {
         headerNode: () => renderPeopleHeaderNode(peopleIntegratedFields, "status"),
      },
   };

   const overtimeColumn: BooleanCellColumnDefinition = {
      field: "overtime",
      cellRenderer: BooleanCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.assignment.overtime"),
      editable: false,
      sortable: false,
   };

   const categoryColumn: TextCellColumnDefinition = {
      field: "cost_code_name",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.category"),
      editable: false,
   };

   const subCategoryColumn: TextCellColumnDefinition = {
      field: "label_name",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.subcategory"),
      editable: false,
   };

   const workDaysColumn: TextCellColumnDefinition = {
      field: "work_days",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.work_days"),
      editable: false,
      sortable: false,
   };

   const addressOneColumn: TextCellColumnDefinition = {
      field: "address_1",
      cellRenderer: TextCellRenderer,
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.address",
      )}`,
      sortable: false,
      editable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "address_1"),
      },
   };

   const addressTwoColumn: TextCellColumnDefinition = {
      field: "address_2",
      cellRenderer: TextCellRenderer,
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.address_2",
      )}`,
      sortable: false,
      editable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "address_2"),
      },
   };

   const cityColumn: TextCellColumnDefinition = {
      field: "city_town",
      cellRenderer: TextCellRenderer,
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.city",
      )}`,
      sortable: false,
      editable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "city_town"),
      },
   };

   const stateColumn: TextCellColumnDefinition = {
      cellRenderer: TextCellRenderer,
      field: "state_province",
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.state",
      )}`,
      editable: false,
      sortable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "state_province"),
      },
   };

   const zipCodeColumn: TextCellColumnDefinition = {
      cellRenderer: TextCellRenderer,
      field: "zipcode",
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.postal",
      )}`,
      editable: false,
      sortable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "zipcode"),
      },
   };

   const countryColumn: TextCellColumnDefinition = {
      field: "country",
      cellRenderer: TextCellRenderer,
      headerName: `${getProjectString(I18n)} ${I18n.t(
         "views.company.workforce_planning.projects.country",
      )}`,
      editable: false,
      sortable: false,
      columnHeaderParams: {
         headerNode: () => renderProjectHeaderNode(projectIntegratedFields, "country"),
      },
   };

   const employeeNumberColumn: TextCellColumnDefinition = {
      field: "employee_number",
      cellRenderer: TextCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.employee_id"),
      editable: false,
      sortable: false,
      filterRenderer: TextFilter as any,
      columnHeaderParams: {
         headerNode: () => renderPeopleHeaderNode(peopleIntegratedFields, "employee_number"),
      },
   };

   const genderColumn: ColumnDefinition = {
      field: "gender",
      cellRenderer: SelectCellRenderer,
      headerName: I18n.t("views.company.workforce_planning.people.gender"),
      sortable: false,
      getStringFormattedValue: (value) => {
         if (!value) return "";
         return value.id === "male"
            ? I18n.t("views.company.workforce_planning.people.male")
            : I18n.t("views.company.workforce_planning.people.female");
      },
      editable: false,
      columnHeaderParams: {
         headerNode: () => renderPeopleHeaderNode(peopleIntegratedFields, "gender"),
      },
   };

   const assignmentColumnDefinitions: ColumnDefinition[] = [
      projectNameColumn,
      peopleNameColumn,
      projectNumberColumn,
      jobTitleColumn,
      startDateColumn,
      endDateColumn,
      startTimeColumn,
      endTimeColumn,
      percentAllocatedColumn,
      statusColumn,
      overtimeColumn,
      categoryColumn,
      subCategoryColumn,
      workDaysColumn,
      addressOneColumn,
      addressTwoColumn,
      cityColumn,
      stateColumn,
      zipCodeColumn,
      countryColumn,
      employeeNumberColumn,
      genderColumn,
   ].concat(
      canViewProjectRoles
         ? generateProjectRoleColumnDefinitions(groupId, roleOptions, tableConfig)
         : [],
   );

   useEffect(() => {
      if (customFields) {
         // Group customFields by entity type (Assignment, Project, People)
         const fieldsByEntity = customFields.reduce((acc: Record<string, any[]>, field) => {
            const entity = field.entity!;
            if (!acc[entity]) {
               acc[entity] = [];
            }
            acc[entity].push(field);
            return acc;
         }, {});

         // Temporary array to store column definitions
         let tempColumnDefinitions: ColumnDefinition[] = [];

         // looping through the formed object to create custom column definition for each entity type
         Object.entries(fieldsByEntity).forEach(([entity, fields]) => {
            let sensitiveFieldsDetails;
            let canEditDetails = false; // keeping it default to false, on legacy we don't allow editing people/project custom fields
            let integratedFields: IntegratedField[] = []; // keeping it default to an empty array as there is no integrated fields for assignment as of now
            let canViewFinancials = false;

            switch (entity) {
               case EntityTypes.ASSIGNMENT:
                  sensitiveFieldsDetails = {
                     canViewSensitiveFields: true, // for assignments there is no permission as of now
                     canEditSensitiveFields: true, // for assignments there is no permission as of now
                     sensitiveFields: [],
                  };
                  canEditDetails = canManageAssignment;
                  canViewFinancials = true;
                  break;
               case EntityTypes.PEOPLE:
                  sensitiveFieldsDetails = {
                     canViewSensitiveFields: canViewPeopleSensitiveFields,
                     canEditSensitiveFields: false, // on legacy we don't allow editing people custom fields
                     sensitiveFields: peopleSensitiveFields,
                  };
                  integratedFields = peopleIntegratedFields;
                  canViewFinancials = canViewPeopleFinancials;
                  break;
               case EntityTypes.PROJECT:
                  sensitiveFieldsDetails = {
                     canViewSensitiveFields: canViewProjectSensitiveFields,
                     canEditSensitiveFields: false, // on legacy we don't allow editing project custom fields
                     sensitiveFields: projectSensitiveFields,
                  };
                  canViewFinancials = canViewProjectFinancials;
                  integratedFields = projectIntegratedFields;
                  break;
               default:
                  sensitiveFieldsDetails = {
                     canViewSensitiveFields: false,
                     canEditSensitiveFields: false,
                     sensitiveFields: [],
                  };
                  integratedFields = [];
                  break;
            }
            // Generate column definitions for the current entity
            const customColumnDefinitions = createColumnDefinitionsFromCustomHeaders(
               fields,
               tableConfig,
               integratedFields,
               null,
               sensitiveFieldsDetails,
               canViewFinancials,
               canEditDetails,
            );
            // Append to temporary array
            if (customColumnDefinitions.length > 0) {
               tempColumnDefinitions = [...tempColumnDefinitions, ...customColumnDefinitions];
            }
         });
         setCustomColumnDefinitions(tempColumnDefinitions);
      }
   }, [
      customFields,
      groupId,
      canManageAssignment,
      canViewPeopleSensitiveFields,
      canViewProjectSensitiveFields,
      canViewPeopleFinancials,
      canViewProjectFinancials,
      peopleSensitiveFields,
      projectSensitiveFields,
      peopleIntegratedFields,
      projectIntegratedFields,
   ]);

   //Reorder the columns based on the saved column state to maintain column order
   const reorderedProjectColumnDefinitions = useMemo(
      () =>
         getReorderedColumnDefinition(
            customColumnDefinitions
               ? assignmentColumnDefinitions.concat(customColumnDefinitions)
               : assignmentColumnDefinitions,
            tableConfig.columnState,
         ),
      [customColumnDefinitions, assignmentColumnDefinitions, tableConfig.columnState],
   );

   const handleServerSideDataRequest = useCallback(
      async ({ request, onSuccess, onError }: ServerSideGetRowsParams) => {
         try {
            let startingAfter = undefined;
            // If requesting a second page of data, apply the starting_after query param
            if (request && request.startRow && request.startRow > 0) {
               startingAfter = nextStartAfter.current;
            }
            const sortModel: SortModel[] = request.sortModel;
            if (customFields) {
               const enumValues = Object.values(EntityTypes).join("|");
               // this will match pattern like Assignment's custom_field, Project's custom_field, People's custom_field
               const regex = new RegExp(`\\b(${enumValues})'\\s?s\\s+(.+)\\b`, "i");
               // Map each field in sortModel to its corresponding fieldId and entity if it exists in customFields
               sortModel.forEach((item) => {
                  /*this matching is required as we are maintaining custom fields based on entity types 
                  and the server side data table returns the field value, based on how we map them.
                  */
                  const match = regex.exec(item.field);
                  if (match) {
                     const type = match[1]; // Capturing group contains the matched type
                     const field = match[2]; // Capturing group contains the matched field
                     const enumKey = type.toUpperCase() as keyof typeof EntityTypes;
                     const customField = findCustomField(customFields, field);
                     if (customField) {
                        item.fieldId = customField.id;
                        item.entity = EntityTypes[enumKey]; // this can later be used for switching the sort_by attribute in API
                     }
                  }
               });
            }
            const serverFilter: AssignmentListServerFilter[] = request.serverFilters;
            const data = await fetchAssignmentList(serverFilter, sortModel, startingAfter);
            onSuccess({ rowData: data.data, rowCount: data.pagination.total_possible });
            nextStartAfter.current = data.pagination.next_starting_after;
         } catch (err) {
            onError();
         }
      },
      [groupId],
   );

   return (
      (!integratedFieldsIsLoading && (
         <ServerSideDataTable
            columnDefinitions={reorderedProjectColumnDefinitions}
            onServerSideDataRequest={handleServerSideDataRequest}
            getRowId={(params) => params.data.id}
            onTableConfigChange={onTableConfigChange}
            initialTableConfig={tableConfig}
            enableDynamicRowHeight
            key={dataTableKey}
         >
            <Flex
               style={{
                  height: "100%",
                  width: "100%",
               }}
               alignItems="stretch"
            >
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.FiltersPanel
                     style={{
                        marginRight: "16px",
                     }}
                  />
               </Flex>

               <Box flex="1" display="flex-column" alignItems="stretch">
                  {/* @ts-expect-error Quick Controls need to optionally accept children*/}
                  <ServerSideDataTable.QuickControls>
                     <Flex>
                        <FlexList size="xs" alignItems="center">
                           <ServerSideDataTable.FiltersPanelButton />
                           <ServerSideDataTable.QuickFilters />
                        </FlexList>
                        <FlexList size="xs" alignItems="center" style={{ marginLeft: "auto" }}>
                           <ServerSideDataTable.ConfigPanelButton />
                        </FlexList>
                     </Flex>
                  </ServerSideDataTable.QuickControls>
                  <ServerSideDataTable.Table
                     onTableReady={handleTableReady}
                     paginationPageSize={100}
                     rowSelectionEnabled={true} // later on this can be made permission based
                     selectionSSREnabled={true} // later on this can be made permission based
                  />
               </Box>
               <Flex flex="0 1 0px" alignItems="stretch">
                  <ServerSideDataTable.ContextPanel style={{ marginLeft: "16px" }} />
               </Flex>
            </Flex>
         </ServerSideDataTable>
      )) ||
      null
   );
};
