import "./data-upload.styl";
import template from "./data-upload.pug";
import { DataUploadStore } from "@/stores/data-upload-store.core";
import renderDataUpload from "@/react/render-data-upload";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

export class DataUploadViewModel extends PageContentViewModel {
   constructor() {
      super(template(), "Settings - Data Upload");
   }

   addedToParent(): void {
      renderDataUpload({
         uploadFiles: this.uploadFiles,
      });
   }

   async uploadFiles(dataUpload: FormData): Promise<Record<string, any> | undefined> {
      try {
         const result = await DataUploadStore.uploadFiles(dataUpload).payload;
         return result;
      } catch (error) {
         console.error("Error in uploadFiles: ", error);
         throw error;
      }
   }
}
