import type { useI18nContext } from "@procore/core-react";
import type { AnySchema } from "yup";
import { object, string, number, date, array } from "yup";
import type { CustomField } from "@/react/prop-types";
import { compareDates } from "./helpers";
import { createCustomFieldSchema } from "../validation-schema";

export const createProjectSchema = (I18n: ReturnType<typeof useI18nContext>) =>
   object().shape({
      name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      job_number: string().nullable(),
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      group_ids: array().min(
         1,
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      start_date: date().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      est_end_date: date()
         .test(
            "validate-end-date",
            I18n.t("views.company.workforce_planning.validations.end_date_before_start_date"),
            function (endDate) {
               const { start_date: startDate } = this.parent;
               return compareDates({ startDate, endDate });
            },
         )
         .nullable(),
      timezone: object().nullable(),
      daily_start_time: object(),
      daily_end_time: object(),
      address_1: string(),
      address_2: string(),
      city_town: string(),
      state_province: string(),
      zipcode: string(),
      bid_rate: number(),
   });

export const projectDetailSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   customFields?: CustomField[],
) =>
   object().shape({
      name: string().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      job_number: string().nullable(),
      status: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      group_ids: array().min(
         1,
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      start_date: date().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      est_end_date: date()
         .test(
            "validate-end-date",
            I18n.t("views.company.workforce_planning.validations.end_date_before_start_date"),
            function (endDate) {
               const { start_date: startDate } = this.parent;
               return compareDates({ startDate, endDate });
            },
         )
         .nullable(),
      timezone: object().nullable(),
      daily_start_time: object(),
      daily_end_time: object(),
      address_1: string().nullable(),
      address_2: string().nullable(),
      city_town: string().nullable(),
      state_province: string().nullable(),
      zipcode: string().nullable(),
      bid_rate: number().nullable(),
      customer_name: string().nullable(),
      percent_complete: number().nullable(),
      project_type: string().nullable(),
      ...(customFields && customFields.length > 0
         ? customFields.reduce((acc: Record<string, AnySchema>, field: CustomField) => {
              acc[field.name] = createCustomFieldSchema(field);
              return acc;
           }, {} as Record<string, AnySchema>)
         : {}),
   });

export const projectSpecificationsSchema = () =>
   object().shape({
      default_recipients: array().of(string()),
   });

export const bulkEditProjectSchema = (
   I18n: ReturnType<typeof useI18nContext>,
   customFields?: CustomField[],
) =>
   object().shape({
      job_number: number().nullable(),
      status: object().nullable(),
      group_ids: array().nullable(),
      start_date: date().nullable(),
      est_end_date: date()
         .test(
            "validate-end-date",
            I18n.t("views.company.workforce_planning.validations.end_date_before_start_date"),
            function (endDate) {
               const { start_date: startDate } = this.parent;
               return compareDates({ startDate, endDate });
            },
         )
         .nullable(),
      daily_start_time: object().nullable(),
      daily_end_time: object().nullable(),
      timezone: object().nullable(),
      address_1: string().nullable(),
      address_2: string().nullable(),
      city_town: string().nullable(),
      state_province: string().nullable(),
      country: string().nullable(),
      zipcode: string().nullable(),
      bid_rate: number().nullable(),
      color: string().nullable(),
      customer_name: string().nullable(),
      percent_complete: number().nullable(),
      project_type: string().nullable(),
      ...(customFields && customFields.length > 0
         ? customFields.reduce((acc: Record<string, AnySchema>, field: CustomField) => {
              acc[field.name] = createCustomFieldSchema(field);
              return acc;
           }, {} as Record<string, AnySchema>)
         : {}),
   });

export const wageOverridesSchema = (I18n: ReturnType<typeof useI18nContext>) =>
   object().shape({
      job_title: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      wage: number().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   });

export const rolesSchema = (I18n: ReturnType<typeof useI18nContext>) =>
   object().shape({
      job_title: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
      resources: array()
         .of(
            object().shape({
               id: string().required(),
               label: string().required(),
            }),
         )
         .min(1, I18n.t("views.company.workforce_planning.validations.required_field"))
         .required(I18n.t("views.company.workforce_planning.validations.required_field")),
   });

export const customAssignmentAlertsSchema = (I18n: ReturnType<typeof useI18nContext>) =>
   object().shape({
      send_as: object().required(
         I18n.t("views.company.workforce_planning.validations.required_field"),
      ),
   });
