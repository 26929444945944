import type { Dispatch, SetStateAction } from "react";
import React, { useMemo } from "react";
import { MultiSelect, Checkbox, Switch, Panel, Box, useI18nContext } from "@procore/core-react";
import type { ganttFilterOptionTypes, ganttFilterType, projectStatus } from "./prop-types";
import { FilterResourceType } from "./prop-types";
import { FilterTaskType } from "./prop-types";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";
import { INITIAL_GANTT_FILTER } from "./gantt-config";
import "./gantt-container.css";
import { GanttCustomFilter } from "./custom_filters/gantt-custom-filter";
import { TokenRenderer } from "./gantt-filter-token-renderer";

export const statuses: projectStatus[] = [
   { id: 0, name: "Active" },
   { id: 1, name: "Inactive" },
   { id: 2, name: "Pending" },
];

// export const dateQualifiers: { label: string; name: string }[] = [
//    { label: dateQualifier.ONORBEFORE, name: "On or before" },
//    { label: dateQualifier.ONORAFTER, name: "On or after" },
//    { label: dateQualifier.AFTER, name: "After" },
//    { label: dateQualifier.BEFORE, name: "Before" },
//    { label: dateQualifier.ON, name: "On" },
// ];

type GanttFilterPanelProps = {
   ganttFilter: ganttFilterType;
   setGanttFilter: Dispatch<SetStateAction<ganttFilterType>>;
   onClose: () => void;
   jobTitles: JobTitle[];
   customFilterFields?: any;
   disabled: boolean;
   isOpen: boolean;
};

export const GanttFilterPanel = (props: GanttFilterPanelProps) => {
   const { ganttFilter, disabled, setGanttFilter, onClose, jobTitles, customFilterFields, isOpen } =
      props;

   const I18n = useI18nContext();
   const jobTitlesMapped: Array<{ id: string; name: string }> = useMemo(
      () =>
         jobTitles.map((jobTitle) => ({
            id: jobTitle.id,
            name: jobTitle.name,
         })),
      [jobTitles],
   );
   // const getDateQualifiersName = (label: dateQualifier | null): string | null => {
   //    return dateQualifiers.find((qualifier) => qualifier.label === label)?.name ?? null;
   // };
   const handleFilterChange = (value: any, filterType: ganttFilterOptionTypes): void => {
      setGanttFilter((ganttFilter) => ({
         ...ganttFilter,
         [filterType]: value,
      }));
   };
   const handleAssignmentsRequestsChange = (onlyShow: FilterTaskType): void => {
      const current = ganttFilter.assignmentsOrRequests;
      const shouldRemove = current.includes(onlyShow);
      let updated: FilterTaskType[] = [];

      if (shouldRemove) updated = current.filter((el) => el !== onlyShow);
      else updated = [...current, onlyShow];

      setGanttFilter((ganttFilter) => ({
         ...ganttFilter,
         assignmentsOrRequests: updated,
      }));
   };
   const handlePeopleEquipmentChange = (onlyShow: FilterResourceType): void => {
      const current = ganttFilter.peopleOrEquipment;
      const shouldRemove = current.includes(onlyShow);
      let updated: FilterResourceType[] = [];

      if (shouldRemove) updated = current.filter((el) => el !== onlyShow);
      else updated = [...current, onlyShow];

      setGanttFilter((ganttFilter) => ({
         ...ganttFilter,
         peopleOrEquipment: updated,
      }));
   };
   // const handleDateQualifierChange = (item: string, dateFilter: string): void => {
   //    setGanttFilter((ganttFilter: any) => ({
   //       ...ganttFilter,
   //       [dateFilter]: {
   //          qualifier: item,
   //          date: ganttFilter[dateFilter].date,
   //       },
   //    }));
   // };
   // const handleDateChange = (date: Date | null, dateFilter: string): void => {
   //    setGanttFilter((ganttFilter: any) => ({
   //       ...ganttFilter,
   //       [dateFilter]: {
   //          qualifier: ganttFilter[dateFilter].qualifier,
   //          date: date?.toLocaleDateString() || "",
   //       },
   //    }));
   // };

   const clearAllFilters = (): void => {
      setGanttFilter(INITIAL_GANTT_FILTER);
   };

   return (
      <div className={isOpen ? "filterPanel visible" : "filterPanel"}>
         <Panel
            style={{
               border: "1px solid #e0e0e0",
               width: "500px",
               maxHeight: "76vh",
            }}
         >
            <Panel.Header onClose={onClose}>
               <Panel.Title>
                  {I18n.t("views.company.workforce_planning.gantt.filter.filters")}
               </Panel.Title>
               <Panel.HeaderAction title={"Reset"} onClick={clearAllFilters}>
                  <Panel.Title style={{ fontSize: "14px" }}>
                     {I18n.t("views.company.workforce_planning.gantt.filter.clear_all_filters")}
                  </Panel.Title>
               </Panel.HeaderAction>
            </Panel.Header>
            <Panel.Body>
               <Box style={{ pointerEvents: disabled ? "none" : "unset", overflowY: "scroll" }}>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">
                        {I18n.t("views.company.workforce_planning.gantt.filter.job_title")}
                     </h3>
                     <MultiSelect
                        getLabel={(jobTitle) => jobTitle.name}
                        options={jobTitlesMapped}
                        tokenRenderer={TokenRenderer}
                        value={ganttFilter.jobTitles}
                        onChange={(value) => handleFilterChange(value, "jobTitles")}
                        className="ganttFilterMultiselect"
                     />
                  </label>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">
                        {I18n.t("views.company.workforce_planning.gantt.filter.status")}
                     </h3>
                     <MultiSelect
                        getLabel={(status) => status.name}
                        options={statuses}
                        tokenRenderer={TokenRenderer}
                        value={ganttFilter.projectStatuses}
                        onChange={(value) => handleFilterChange(value, "projectStatuses")}
                        className="ganttFilterMultiselect"
                     />
                  </label>
                  <div className="ganttFilterLabel">
                     <h3 className="filterName">
                        {I18n.t("views.company.workforce_planning.gantt.filter.only_show")}
                     </h3>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.assignmentsOrRequests.includes(
                              FilterTaskType.ASSIGNMENTS,
                           )}
                           onChange={() =>
                              handleAssignmentsRequestsChange(FilterTaskType.ASSIGNMENTS)
                           }
                        >
                           {I18n.t(
                              "views.company.workforce_planning.gantt.filter.filter_text.assignments",
                           )}
                        </Checkbox>
                     </label>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.assignmentsOrRequests.includes(
                              FilterTaskType.REQUESTS,
                           )}
                           onChange={() => handleAssignmentsRequestsChange(FilterTaskType.REQUESTS)}
                        >
                           {I18n.t(
                              "views.company.workforce_planning.gantt.filter.filter_text.requests",
                           )}
                        </Checkbox>
                     </label>
                  </div>
                  <div className="ganttFilterLabel">
                     <h3 className="filterName">
                        {I18n.t("views.company.workforce_planning.gantt.filter.type")}
                     </h3>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.peopleOrEquipment.includes(
                              FilterResourceType.LABOR,
                           )}
                           onChange={() => handlePeopleEquipmentChange(FilterResourceType.LABOR)}
                        >
                           {I18n.t(
                              "views.company.workforce_planning.gantt.filter.filter_text.labor",
                           )}
                        </Checkbox>
                     </label>
                     <label style={{ marginBottom: "10px" }}>
                        <Checkbox
                           checked={ganttFilter.peopleOrEquipment.includes(
                              FilterResourceType.EQUIPMENT,
                           )}
                           onChange={() =>
                              handlePeopleEquipmentChange(FilterResourceType.EQUIPMENT)
                           }
                        >
                           {I18n.t(
                              "views.company.workforce_planning.gantt.filter.filter_text.equipment",
                           )}
                        </Checkbox>
                     </label>
                  </div>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">
                        {I18n.t(
                           "views.company.workforce_planning.gantt.filter.hide_empty_projects",
                        )}
                     </h3>
                     <Switch
                        checked={ganttFilter.hideEmptyProject}
                        onChange={() =>
                           handleFilterChange(!ganttFilter.hideEmptyProject, "hideEmptyProject")
                        }
                     />
                  </label>
                  <label className="ganttFilterLabel">
                     <h3 className="filterName">Hide Empty Categories</h3>
                     <Switch
                        checked={ganttFilter.hideEmptyCategories}
                        onChange={() =>
                           handleFilterChange(
                              !ganttFilter.hideEmptyCategories,
                              "hideEmptyCategories",
                           )
                        }
                     />
                  </label>
                  {
                     customFilterFields?.projects_custom_field_filters.map((filter: any) => {
                        if (filter) {
                           return (
                              <GanttCustomFilter
                                 filter={filter}
                                 setGanttFilter={setGanttFilter}
                                 ganttFilter={ganttFilter}
                                 key={filter.id}
                              />
                           );
                        }
                     })
                     /*<div className="ganttFilterLabel">
                     <h3 className="filterName">Start Date</h3>
                     <div style={{ display: "flex" }}>
                        <Select
                           style={{ maxWidth: "40%", marginRight: "15px" }}
                           label={getDateQualifiersName(ganttFilter.startDate.qualifier)}
                           onSelect={({ item }) => handleDateQualifierChange(item, "startDate")}
                        >
                           {dateQualifiers.map(
                              (dateQualifier: Record<string, string>, index: number) => {
                                 return (
                                    <Select.Option
                                       key={index}
                                       value={dateQualifier.label}
                                       selected={
                                          ganttFilter.startDate.qualifier === dateQualifier.label
                                       }
                                    >
                                       {dateQualifier.name}
                                    </Select.Option>
                                 );
                              },
                           )}
                        </Select>
                        <DateSelect
                           disabled={!ganttFilter.startDate.qualifier}
                           value={
                              ganttFilter.startDate.date
                                 ? new Date(ganttFilter.startDate.date)
                                 : undefined
                           }
                           onChange={(date) => handleDateChange(date, "startDate")}
                        />
                     </div>
                  </div>
                  <div className="ganttFilterLabel">
                     <h3 className="filterName">End Date</h3>
                     <div style={{ display: "flex" }}>
                        <Select
                           style={{ maxWidth: "40%", marginRight: "15px" }}
                           label={getDateQualifiersName(ganttFilter.endDate.qualifier)}
                           onSelect={({ item }) => handleDateQualifierChange(item, "endDate")}
                        >
                           {dateQualifiers.map(
                              (dateQualifier: Record<string, string>, index: number) => {
                                 return (
                                    <Select.Option
                                       key={index}
                                       value={dateQualifier.label}
                                       selected={
                                          ganttFilter.startDate.qualifier === dateQualifier.label
                                       }
                                    >
                                       {dateQualifier.name}
                                    </Select.Option>
                                 );
                              },
                           )}
                        </Select>
                        <DateSelect
                           disabled={!ganttFilter.endDate.qualifier}
                           value={
                              ganttFilter.endDate.date
                                 ? new Date(ganttFilter.endDate.date)
                                 : undefined
                           }
                           onChange={(date) => handleDateChange(date, "endDate")}
                        />
                     </div>
                  </div>*/
                  }
               </Box>
            </Panel.Body>
         </Panel>
      </div>
   );
};
