import React from "react";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type {
   FilterClassifier,
   FilterFieldType,
} from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { Lock } from "@procore/core-icons";
import { getDateFilterTokenText } from "../components/project-list/helpers";
import type {
   CustomField,
   HexColor,
   IntegratedField,
   ProjectListServerFilter,
   SensitiveFieldProps,
} from "../prop-types";
import { EntityTypes } from "../prop-types";
import { BooleanFilter } from "../components/data-table/custom-filters/bool-filter";
import { DateFilter } from "../components/data-table/custom-filters/date-filter";
import { TextFilter } from "../components/data-table/custom-filters/text-filter";
import { NumericValueFilter } from "../components/data-table/custom-filters/numeric-value-filter";
import {
   MultiSelectFilterRenderer,
   DateCellRenderer,
   TextCellRenderer,
   MultiSelectCellRenderer,
   SelectCellRenderer,
   CurrencyCellRenderer,
   NumberCellRenderer,
   BooleanCellRenderer,
   TextCellEditor,
   NumberCellEditor,
   CurrencyCellEditor,
   BooleanCellEditor,
   SelectCellEditor,
   DateCellEditor,
} from "@procore/data-table";
import { isIntegratedField, isSensitiveField, isSensitiveFieldEditable } from "./helper";
import {
   ColorOnlyEditor,
   ColorSelectRenderer,
} from "../components/data-table/ColorSelectComponent/ColorSelectColumn";
import type { useI18nContext } from "@procore/core-react";
import { Form } from "@procore/core-react";
import { ColorPicker } from "../components/common/components/color-picker";
import { DateUtils } from "@/lib/utils/date";
import type { SerializedCustomFieldInstance } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-instance-serializer";
/*common function written that can be shared across multiple files
parameters: filter: ProjectListServerFilter, filterNameMaps: { [key: string]: string }, filterTypeMaps: { [key: string]: FilterFieldType }
*/
export function convertDateFilters(
   filter: ProjectListServerFilter,
   filterNameMaps: { [key: string]: string },
   filterTypeMaps: { [key: string]: FilterFieldType },
): Filter[] {
   const dateFilters: Filter[] = [];
   filter.selected.forEach(
      (x: { date: string; classifier: { label: string; value: FilterClassifier } }) => {
         const filterObject: Filter = {
            name: filter.fieldId ? filter.fieldId : filterNameMaps[filter.field],
            property: filter.fieldId ? "custom_fields" : filter.field,
            type: filterTypeMaps[filter.fieldType ? filter.fieldType : filter.field],
            value_sets: [
               {
                  negation: false,
                  value: getDetachedDay(new Date(x.date)),
                  classifier: x.classifier.value,
               },
            ],
         };
         if (filter.fieldId) {
            filterObject.custom_field_id = filter.fieldId;
         }
         dateFilters.push(filterObject);
      },
   );
   return dateFilters;
}

/*Function to determine cell renderer based on field type can be extended to include more field types
parameters: fieldType: string
*/
export function getCellRenderer(fieldType: string) {
   switch (fieldType) {
      case CustomFieldType.TEXT:
      case CustomFieldType.PARAGRAPH:
         return TextCellRenderer;
      case CustomFieldType.DATE:
         return DateCellRenderer;
      case CustomFieldType.MULTI_SELECT:
         return MultiSelectCellRenderer;
      case CustomFieldType.CURRENCY:
         return CurrencyCellRenderer;
      case CustomFieldType.SELECT:
         return SelectCellRenderer;
      case CustomFieldType.HEX_COLOR:
         return ColorSelectRenderer;
      case CustomFieldType.NUMBER:
         return NumberCellRenderer;
      case CustomFieldType.BOOL:
         return BooleanCellRenderer;
      default:
         return TextCellRenderer;
   }
}

//Function to get common filter props
export function getCommonFilterProps(filterName: string, filterType?: string): any {
   const filterProps: any = {
      getFilterHeadingText: () => filterName,
      getFilterTokenText: (item: any) => {
         const text = filterName + ": ";
         const values: string[] = item.value.map((v: any) => {
            if (filterType === CustomFieldType.BOOL) {
               return v.label;
            } else {
               return v.value;
            }
         });
         return text + values.join(" & ");
      },
      getLabel: (item: any) => item.label,
   };
   return filterProps;
}

/* Function to return the CellEditor based on the column field type */
function getCellEditor(type: CustomFieldType) {
   switch (type) {
      case "number":
         return NumberCellEditor;
      case "currency":
         return CurrencyCellEditor;
      case "hex-color":
         return ColorOnlyEditor;
      case "bool":
         return BooleanCellEditor;
      case "select":
         return SelectCellEditor;
      case "date":
         return DateCellEditor;
      default:
         return TextCellEditor;
   }
}

/* Returns cell editor params for the Custom Field Cell Editor based on the type */
function getCellEditorParams(custom_field: CustomField) {
   if (custom_field.type === CustomFieldType.SELECT) {
      return {
         selectOptions: custom_field.values,
      };
   }
}

/*Function to generate column definitions from the Custom Headers
parameters: custom_fields: CustomField[], config: any, csvFormatterMap: any
*/

export function createColumnDefinitionsFromCustomHeaders(
   custom_fields: CustomField[],
   config: any,
   integratedFields: IntegratedField[], // we need to check against the integrated fields to allow editing apart from the integration_only flag
   csvFormatterMap?: any,
   sensitiveFieldsDetails?: SensitiveFieldProps,
   canViewFinancials?: boolean,
   canEditDetails?: boolean,
) {
   // Create a map of column state by field name from the config coming from the local storage
   const columnStateMap = new Map(config.columnState.map((column: any) => [column.field, column]));

   const nonSortableCustomFields = new Set([
      CustomFieldType.MULTI_SELECT,
      CustomFieldType.BOOL,
      CustomFieldType.HEX_COLOR,
      CustomFieldType.PARAGRAPH,
   ]);

   const getFilterProps = (type: CustomFieldType, name: string, values: any) => {
      const commonFilterProps = getCommonFilterProps(name, type);
      const filterTokenText = (item: any) => {
         const classifier = item.value[0].classifier.value;
         const value = item.value[0].value;
         return `${name} ${classifier} ${value}`;
      };

      switch (type) {
         case CustomFieldType.BOOL:
            return { filterRenderer: BooleanFilter, filterProps: commonFilterProps };
         case CustomFieldType.DATE:
            return {
               filterRenderer: DateFilter,
               filterProps: { getFilterTokenText: (item: any) => getDateFilterTokenText(item) },
            };
         case CustomFieldType.TEXT:
            return { filterRenderer: TextFilter, filterProps: commonFilterProps };
         case CustomFieldType.CURRENCY:
         case CustomFieldType.NUMBER:
            return {
               filterRenderer: NumericValueFilter,
               filterProps: { getFilterTokenText: (item: any) => filterTokenText(item) },
            };
         case CustomFieldType.MULTI_SELECT:
         case CustomFieldType.SELECT:
            return {
               filterRenderer: MultiSelectFilterRenderer,
               filterProps: {
                  getLabel: (value: string) => value,
                  getFilterOptions: () => values,
                  getFilterTokenText: (item: any) => `${name}: ${item.value.join(" or ")}`,
               },
            };
         default:
            break;
      }
   };

   const applyTypeSpecificProps = (type: CustomFieldType, columnDefinition: any) => {
      if (type === CustomFieldType.HEX_COLOR) {
         return {
            ...columnDefinition,
            cellRendererParams: {
               getColor: (item: HexColor) => item.color,
               getShape: (item: HexColor) => item.shape,
            },
            getStringFormattedValue: (item: HexColor) => item.label,
         };
      }
      return columnDefinition;
   };

   return custom_fields.map((item: CustomField) => {
      const { name, id, values, can_filter, integration_name, integration_only, entity } = item;
      const type = item.type as CustomFieldType;
      const {
         canViewSensitiveFields = false,
         canEditSensitiveFields = false,
         sensitiveFields = [],
      } = sensitiveFieldsDetails ?? {};

      /* entity attribute will only be there when this method gets called from assignment-list-data-table
      we are maintaining this additional attribute to maintain uniqueness among custom fields cause same custom field
      can be associated with multiple entities, so appending entity to the integration_name and name will provide a unique field
      and headerName combination for the data table and user will be able to identify on UI which custom field belongs to which entity, same logic is there
      on the legacy view
      */
      const updatedIntegrationName = entity ? `${entity}'s ${integration_name}` : integration_name;
      const updatedName = entity ? `${entity}'s ${name}` : name;

      // Create the base column definition
      let columnDefinition: any = {
         field: updatedIntegrationName,
         headerName: updatedName,
         sortable: !nonSortableCustomFields.has(type),
         cellRenderer: getCellRenderer(type),
         editable:
            !(integration_only || isIntegratedField(id, integratedFields)) &&
            type != CustomFieldType.MULTI_SELECT &&
            canEditDetails &&
            !isSensitiveFieldEditable(integration_name, sensitiveFields, canEditSensitiveFields),
         cellEditor:
            integration_only ||
            isIntegratedField(id, integratedFields) ||
            type === CustomFieldType.MULTI_SELECT
               ? null
               : getCellEditor(type),
         cellEditorParams: getCellEditorParams(item),
         pinned: null,
         hidden: true,
         lockVisible: false,
         key: id,
         width: entity ? 230 : 170,
         columnHeaderParams: {
            headerNode: () =>
               integration_only || isIntegratedField(id, integratedFields) ? (
                  <Lock size="sm" data-testid="lock-icon" />
               ) : null,
         },
         /*we are only appending this code block for the assignment-list-data-table rest all of the pages will keep working as expected
         this valueGetter is responsible for switching between custom_fields based on entity type and will return the mapped value for the
         respective field
         */
         ...(entity && {
            valueGetter: (params: any) => {
               let customFields: CustomField[] = [];
               if (entity === EntityTypes.ASSIGNMENT) {
                  customFields = params.data.custom_fields;
               } else if (entity === EntityTypes.PROJECT) {
                  customFields = params.data.project_custom_fields;
               } else if (entity === EntityTypes.PEOPLE) {
                  customFields = params.data.people_custom_fields;
               }
               if (customFields) {
                  const customField = findCustomField(customFields, integration_name);
                  if (customField?.type === CustomFieldType.HEX_COLOR && customField?.value) {
                     return { label: "", color: customField.value, shape: "square" };
                  }
                  if (customField?.type === CustomFieldType.DATE && customField?.value) {
                     return DateUtils.getAttachedDate(customField.value);
                  }
                  return customField?.value ?? null;
               }
               return null;
            },
         }),
      };

      // Determine visibility based on conditions
      if (sensitiveFieldsDetails) {
         const visiblityProps = isSensitiveField(
            integration_name,
            sensitiveFields,
            canViewSensitiveFields,
            config,
         );
         columnDefinition = { ...columnDefinition, ...visiblityProps };
         if (!visiblityProps.lockVisible && can_filter) {
            const filterProps = getFilterProps(type, updatedIntegrationName, values);
            columnDefinition = { ...columnDefinition, ...filterProps };
         }
      }

      // Check if there's existing configuration for this field
      const existingConfig: any = columnStateMap.get(integration_name);
      if (existingConfig) {
         if (!sensitiveFieldsDetails) {
            if (can_filter) {
               const filterProps = getFilterProps(type, updatedIntegrationName, values);
               columnDefinition = { ...columnDefinition, ...filterProps };
            }
            columnDefinition.hidden = existingConfig.hidden;
            columnDefinition.lockVisible = existingConfig.lockVisible;
         }

         columnDefinition.sort = existingConfig.sort;
         columnDefinition.sortIndex = existingConfig.sortIndex;
      }

      // Apply type-specific properties
      columnDefinition = applyTypeSpecificProps(type, columnDefinition);

      // Apply csv formatter for the field type if available
      if (csvFormatterMap?.[type]) {
         columnDefinition.cellCSVFormatter = csvFormatterMap[type];
      }

      // Remove type currency field if user does not have permission to view financials
      if (type === CustomFieldType.CURRENCY && !canViewFinancials) {
         columnDefinition.hidden = true;
         columnDefinition.lockVisible = true;
         delete columnDefinition.filterRenderer;
         delete columnDefinition.filterProps;
      }

      return columnDefinition;
   });
}

//Function to find custom field with strict matching
export function findCustomField(customFields: CustomField[], fieldName: string) {
   return customFields.find((field) => field.integration_name === fieldName);
}

//Function to process CustomField Value
export function processCustomFieldValue(
   customField: CustomField,
   values: Record<string, any>,
): any {
   if (customField.name in values) {
      customField.value = values[customField.name];
   }

   let processedValue: any;

   switch (customField.type) {
      case CustomFieldType.DATE:
         processedValue = customField.value ? getDetachedDay(new Date(customField.value)) : null;
         break;
      case CustomFieldType.MULTI_SELECT:
         processedValue =
            customField.value && customField.value.length > 0
               ? customField.value.map((value: any) => value.label)
               : null;
         break;
      case CustomFieldType.SELECT:
         processedValue = customField.value ? customField.value.label : null;
         break;
      default:
         processedValue = customField.value;
         break;
   }

   return processedValue;
}

// Function to render custom fields
export const renderCustomFields = (
   fields: CustomField[],
   I18n: ReturnType<typeof useI18nContext>,
   filterIntegrationOnly = true,
): React.JSX.Element[] => {
   const rows = [];
   const filteredFields = filterIntegrationOnly
      ? fields.filter((field: CustomField) => !field.integration_only)
      : fields;

   for (let i = 0; i < filteredFields.length; i += 3) {
      const rowFields = filteredFields.slice(i, i + 3);
      const validComponents = rowFields
         .map((field: CustomField, index: number) => {
            let FormComponent = null;
            let additionalProps = {};
            let colStartBase: 1 | 5 | 9;
            switch (index) {
               case 0:
                  colStartBase = 1;
                  break;
               case 1:
                  colStartBase = 5;
                  break;
               default:
                  colStartBase = 9;
                  break;
            }
            switch (field.type) {
               case CustomFieldType.DATE:
                  FormComponent = Form.DateSelect;
                  break;
               case CustomFieldType.NUMBER:
                  FormComponent = Form.Number;
                  break;
               case CustomFieldType.TEXT:
                  FormComponent = Form.Text;
                  break;
               case CustomFieldType.SELECT:
                  FormComponent = Form.Select;
                  additionalProps = {
                     options:
                        field.values?.map((value: string) => ({
                           id: field.name + "_" + value,
                           label: value,
                        })) || [],
                     onSearch: false,
                     placeholder: I18n.t("views.company.workforce_planning.select_option"),
                  };
                  break;
               case CustomFieldType.MULTI_SELECT:
                  FormComponent = Form.MultiSelect;
                  additionalProps = {
                     options:
                        field.values?.map((value: string) => ({
                           id: field.name + "_" + value,
                           label: value,
                        })) || [],
                     placeholder: I18n.t("views.company.workforce_planning.select_option"),
                  };
                  break;
               case CustomFieldType.PARAGRAPH:
                  FormComponent = Form.TextArea;
                  break;
               case CustomFieldType.BOOL:
                  FormComponent = Form.Checkbox;
                  break;
               case CustomFieldType.CURRENCY:
                  FormComponent = Form.Currency;
                  break;
               case CustomFieldType.HEX_COLOR:
                  FormComponent = Form.Field;
                  additionalProps = {
                     as: ColorPicker,
                  };
            }

            if (!FormComponent) return null;

            if (field.integration_only) {
               additionalProps = { ...additionalProps, disabled: true };
            }

            return (
               <React.Fragment key={`custom-field-fragment-outside-${field.id}-${index}`}>
                  <FormComponent
                     key={`custom-field-${field.id}`}
                     colStart={colStartBase}
                     colWidth={4}
                     name={field.name}
                     // @ts-expect-error: passing element as needed to append lock icon
                     label={
                        <div style={{ display: "flex", alignItems: "center" }}>
                           <span>{field.name}</span>
                           {field.integration_only && (
                              <Lock size="sm" style={{ marginLeft: "3px" }} />
                           )}
                        </div>
                     }
                     {...additionalProps}
                  />
               </React.Fragment>
            );
         })
         .filter((component: any) => component !== null);

      if (validComponents.length > 0) {
         rows.push(<Form.Row key={`custom-fields-row-${i}`}>{validComponents}</Form.Row>);
      }
   }
   return rows;
};

export const processCustomFieldsFormValues = (customFields: SerializedCustomFieldInstance[]) => {
   return customFields.reduce((acc: any, field: any) => {
      if (field.type === CustomFieldType.DATE && field.value) {
         acc[field.name] = DateUtils.getAttachedDate(field.value);
      } else if (field.type === CustomFieldType.MULTI_SELECT) {
         acc[field.name] = field.value.map((value: string) => ({
            id: `${field.name}_${value}`,
            label: value,
         }));
      } else if (field.type === CustomFieldType.SELECT) {
         acc[field.name] = { id: `${field.name}_${field.value}`, label: field.value };
      } else {
         acc[field.name] = field.value || null;
      }
      return acc;
   }, {});
};
// Function to process custom fields for Assignment and Request forms
export const processCustomFields = (
   customFields: CustomField[],
   values: Record<string, any>,
): { [key: string]: any } => {
   const resultantCustomField: { [key: string]: any } = {};

   customFields.forEach((customField: CustomField) => {
      // Process the custom field if it is not integration-only
      if (!customField.integration_only) {
         const processedValue = processCustomFieldValue(customField, values);
         if (processedValue != undefined) {
            resultantCustomField[customField.id] = processedValue;
         }
      }
   });
   return resultantCustomField;
};

// Function to process custom fields with permission
export function processCustomFieldsWithPermission(
   customFields: CustomField[],
   values: Record<string, any>,
   integratedFields: IntegratedField[],
   canViewFinancials?: boolean,
   sensitiveFields?: string[],
   canEditSensitiveFields?: boolean,
): { [key: string]: any } {
   const resultantCustomField: { [key: string]: any } = {};
   customFields.forEach((customField: CustomField) => {
      // Process the custom field if it is not an integrated field and not integration-only
      if (!(isIntegratedField(customField.id, integratedFields) || customField.integration_only)) {
         const processedValue = processCustomFieldValue(customField, values);
         if (processedValue != undefined) {
            resultantCustomField[customField.id] = processedValue;
         }
      }
      // If the custom field type is currency  and canViewProjectFinancials is false, then remove the field from the payload
      if (!canViewFinancials && customField.type === CustomFieldType.CURRENCY) {
         delete resultantCustomField[customField.id];
      }
      // If the custom field is sensitive and user does not have permission to view it, then remove the field from the payload
      if (sensitiveFields) {
         if (
            isSensitiveFieldEditable(
               customField.name,
               sensitiveFields,
               canEditSensitiveFields ?? false,
            )
         ) {
            delete resultantCustomField[customField.id];
         }
      }
   });
   return resultantCustomField;
}
