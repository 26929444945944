import React from "react";
import { AssignmentListDataTable } from "./assignment-list-data-table";
import { type SortModel } from "@/react/prop-types";
import { useGroupContext } from "@/react/providers/group-context-provider";
import type { FindAssignmentsPaginatedQueryParams } from "@laborchart-modules/lc-core-api";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import {
   convertAssignmentDataTableFilters,
   convertDataTableSort,
   getFormattedCustomFields,
} from "./assignment-list-helpers";
import { useViewPreferenceContext } from "@/react/providers/permission-context-provider";
import { PersonTearsheetProvider } from "../tearsheets/people/people-tearsheet";
import { FileDownloadToastProvider } from "../data-table/DataExport/FileDownload";
import { DetailPage, Box, useI18nContext } from "@procore/core-react";
import type { TableApi } from "@procore/data-table";
import { ProjectTearsheetProvider } from "../tearsheets/project/project-tearsheet";
import { setJobValue, setPersonGender, setPersonValue } from "../people-list/people-list-container";
import "./style.css";
import { getAttachedDate } from "@laborchart-modules/common/dist/datetime";
import { formatDateTime } from "@/react/shared/helper";
import type { SerializedStatus } from "@laborchart-modules/common/dist/rethink/serializers/status-serializer";
import { timeOptions } from "@/lib/utils/timezones";
import type { AssignmentListServerFilter } from "./assignment-list-prop-types";
import {
   createRoleAttrs,
   streamJobTitles,
   streamPeople,
   streamViewableStatuses,
} from "../data-table/data-table-utils";
import { useGetCustomFields } from "../common/queries/queries";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { DateUtils } from "@/lib/utils/date";
import { useGetProjectRoleOptions } from "../project-list/queries";
import { formatName } from "@/lib/utils/preferences";

export const setStatusValue = (status: SerializedStatus) => {
   return {
      label: status?.name ?? "",
      color: status?.color ?? "",
      shape: "circle",
   };
};

const entityTypes = [
   CustomFieldEntity.ASSIGNMENT,
   CustomFieldEntity.PROJECT,
   CustomFieldEntity.PERSON,
];

export function AssignmentListContainer() {
   const I18n = useI18nContext();
   const { groupId } = useGroupContext();
   const { getViewPreference } = useViewPreferenceContext();
   const isLastNameFirst = getViewPreference()?.displayLastNamesFirst() ?? false;
   const [tableApi, setTableApi] = React.useState<TableApi>();
   const { data: customFields } = useGetCustomFields(entityTypes);
   const { data: roleOptions } = useGetProjectRoleOptions(groupId);
   // Format custom fields for the data table adding entity type (Assignment, Project, Person)
   const formattedCustomFields = getFormattedCustomFields(customFields);
   const handleTableReady = (api: TableApi) => setTableApi(api);
   /* istanbul ignore next */
   const fetchAssignmentList = async (
      filters: AssignmentListServerFilter[],
      sortModel: SortModel[],
      startingAfter?: string,
   ) => {
      const coreApiFilters = convertAssignmentDataTableFilters(filters);
      const coreApiSort = convertDataTableSort(sortModel, isLastNameFirst);
      const params: FindAssignmentsPaginatedQueryParams = {
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         limit: 100,
         starting_after: startingAfter,
         sort_by: coreApiSort.sort_by,
         sort_direction: coreApiSort.direction,
         group_id: groupId === "my-groups" ? undefined : groupId,
         custom_field_id: coreApiSort.custom_field_id, // required when sorted by custom field
         filters: coreApiFilters,
      };
      const assignmentData = await Assignment2Store.getAssignments(params).payload;
      const assignmentListDetails = [];

      for (const assignmentList of assignmentData.data) {
         const formattedProjectRoles = assignmentList.project.roles.map((role) => ({
            ...role,
            assignee_name: formatName(role.person.name), // reformatting array to pass to the expected arguments to the utility function
         }));

         // calling the utility function to generate role attributes
         const roleAttrs: Record<string, any> = createRoleAttrs(formattedProjectRoles);
         // Initialize the base assignment details
         const assignmentDetails: Record<string, any> = {
            ...assignmentList,
            person: setPersonValue(assignmentList.person as any),
            job_title: setJobValue(assignmentList.person.job_title),
            project: assignmentList.project.name,
            job_number: assignmentList.project.job_number,
            start_date: assignmentList.start_day
               ? new Date(formatDateTime(getAttachedDate(assignmentList.start_day), I18n.locale))
               : null,
            end_date: assignmentList.end_day
               ? new Date(formatDateTime(getAttachedDate(assignmentList.end_day), I18n.locale))
               : null,
            start_time: assignmentList.start_time
               ? timeOptions.find(({ id }) => id === assignmentList.start_time)!.label
               : "",
            end_time: assignmentList.end_time
               ? timeOptions.find(({ id }) => id === assignmentList.end_time)!.label
               : "",
            address_1: assignmentList.project.address_1,
            address_2: assignmentList.project.address_2,
            city_town: assignmentList.project.city_town,
            state_province: assignmentList.project.state_province,
            zipcode: assignmentList.project.zipcode,
            country: assignmentList.project.country,
            employee_number: assignmentList.person.employee_number,
            gender: assignmentList.person.gender
               ? setPersonGender(assignmentList.person.gender, I18n)
               : null,
            status: assignmentList.status ? setStatusValue(assignmentList.status) : null,
            project_custom_fields: assignmentList.project.custom_fields,
            people_custom_fields: assignmentList.person.custom_fields,
            cost_code_name: assignmentList.category?.name, // category
            label_name: assignmentList.subcategory?.name, // subcategory
            work_days: DateUtils.getFormattedWorkDays(assignmentList.work_days),
            ...roleAttrs,
         };
         assignmentListDetails.push(assignmentDetails);
      }
      return { data: assignmentListDetails, pagination: assignmentData.pagination };
   };
   return (
      <PersonTearsheetProvider peopleTableApi={tableApi}>
         <ProjectTearsheetProvider projectsTableApi={tableApi}>
            <FileDownloadToastProvider>
               <DetailPage width="block" className={"border-box assignment-list-detail-page"}>
                  <DetailPage.Main className="assignment-list-page-main">
                     <DetailPage.Body className="assignment-list-page-body">
                        <DetailPage.Card className="assignment-list-card">
                           <DetailPage.Section className="assignment-list-section">
                              <Box
                                 style={{
                                    height: "100%",
                                 }}
                              >
                                 {customFields && formattedCustomFields && roleOptions && (
                                    <AssignmentListDataTable
                                       fetchAssignmentList={fetchAssignmentList}
                                       tableApi={tableApi}
                                       handleTableReady={handleTableReady}
                                       groupId={groupId}
                                       streamJobTitles={streamJobTitles}
                                       streamViewableStatuses={streamViewableStatuses}
                                       streamPeople={streamPeople}
                                       customFields={formattedCustomFields}
                                       roleOptions={roleOptions}
                                    />
                                 )}
                              </Box>
                           </DetailPage.Section>
                        </DetailPage.Card>
                     </DetailPage.Body>
                  </DetailPage.Main>
               </DetailPage>
            </FileDownloadToastProvider>
         </ProjectTearsheetProvider>
      </PersonTearsheetProvider>
   );
}
