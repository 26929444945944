import type { BryntumGanttProps } from "@bryntum/gantt-react-thin";
import { TaskType } from "./prop-types";

export const onGridRowBeforeDropFinalize: BryntumGanttProps["onGridRowBeforeDropFinalize"] = ({
   context,
}) => {
   const { oldPositionContext, parent } = context;
   const parentType = parent.getData("type");

   // Disallow user from dropping a task another task of the same type
   const droppingIntoSameTaskType = oldPositionContext.some(
      (oldPosition: any) => oldPosition.record.getData("type") === parentType,
   );
   if (droppingIntoSameTaskType) return false;

   // If user is dragging any categories, they must drop them in a project
   const droppingSomeCategories = oldPositionContext.some(
      (oldPosition: any) => oldPosition.record.getData("type") === TaskType.CATEGORY,
   );
   if (droppingSomeCategories && parentType !== TaskType.PROJECT) return false;

   // If user is dragging any subcategories, they must drop them in a category
   const droppingSomeSubcategories = oldPositionContext.some(
      (oldPosition: any) => oldPosition.record.getData("type") === TaskType.SUBCATEGORY,
   );
   if (droppingSomeSubcategories && parentType !== TaskType.CATEGORY) return false;

   // Disallow user from dropping projects anywhere
   const droppingSomeProjects = oldPositionContext.some(
      (oldPosition: any) => oldPosition.record.getData("type") === TaskType.PROJECT,
   );
   if (droppingSomeProjects) return false;
};
