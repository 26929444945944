import { Assignment2Store as AssignmentStore } from "@/stores/assignment-2-store.core";
import { defaultStore } from "@/stores/default-store";
import { TaskModel } from "@bryntum/gantt-thin";
import type { BryntumGanttProjectModelProps, BryntumGanttProps } from "@bryntum/gantt-react-thin";
import type { ganttFilterType } from "./prop-types";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import { DateHelper } from "@bryntum/core-thin";

// We're extending the task model so we can specify that we want our "type" field to always write/appear in the task record data
// whenever the tasks are added/updated/deleted and sent to the backend through our syncUrl.
export class ProcoreTaskModel extends TaskModel {
   static get fields() {
      return [
         { name: "name", type: "string", alwaysWrite: true },
         { name: "type", type: "string", alwaysWrite: true },
         { name: "projectId", type: "string", alwaysWrite: true },
         { name: "categoryId", type: "string", alwaysWrite: true },
         { name: "subcategoryId", type: "string", alwaysWrite: true },
         {
            name: "resourceId",
            type: "string",
            alwaysWrite: true,
         },
         // Fields inherited from Bryntum's TaskModel that we want to block from sending updates through autoSync
         { name: "duration", type: "number", alwaysWrite: false, persist: false },
         { name: "effort", type: "number", alwaysWrite: false, persist: false },
         { name: "inactive", type: "boolean", alwaysWrite: false, persist: false },
         { name: "calendarId", type: "string", alwaysWrite: false, persist: false },
         { name: "calendar", type: "string", alwaysWrite: false, persist: false },
      ];
   }
}

// This is a new method, created to support this syncUrl feature. We typically use a store method to communicate between client and server,
// but this syncUrl param only takes the URI and handles the request to the server internally. We could have just provided the raw
// string, but I'm unsure if there's special logic in the store.core.ts request methods that change the basePath whenever we're in non-local
// environments. To be safe, I opted to put the URI construction logic into it's own abstract method, this way we know we're always providing
// the correct path to our lc-core-api server.
const completeSyncUrl = AssignmentStore.getFullRequestUrl({
   path: "/api/v3/gantt/sync",
});

export const projectConfig: BryntumGanttProjectModelProps = {
   autoSync: true,
   phantomIdField: "phantomId",
   syncUrl: completeSyncUrl,
   useRawData: false,
   autoMergeAdjacentSegments: false,
};

const ITEMS_TO_SHOW_IF_TASK = ["deleteAssignment", "editAssignment", "splitTask"];
const ITEMS_TO_SHOW_IF_GRID = [
   "createAssignment",
   "createRequest",
   "createAssignmentGrouping",
   "deleteAssignmentGrouping",
   "sendAssignmentAlerts",
];

export const ENABLE_TASK_SPLITTING = LaunchDarklyClient.getFlagValue("gantt-task-splitting");

export const ganttConfig: BryntumGanttProps = {
   autoAdjustTimeAxis: false,
   barMargin: 4,
   baselinesFeature: false,
   dependenciesFeature: false,
   height: "calc(100vh - 250px)",
   headerMenuFeature: false,
   indicatorsFeature: false,
   percentBarFeature: false,
   projectLinesFeature: false,
   progressLineFeature: false,
   taskMenuFeature: {
      items: {
         convertToMilestone: false,
         editTask: false,
         milestoneAction: false,
         cut: false,
         copy: false,
         paste: false,
         filterMenu: false,
         add: false,
         indent: false,
         outdent: false,
         deleteTask: false,
         linkTasks: false,
         unlinkTasks: false,
         taskColor: false,
         // We are using the onTaskMenuBeforeShow event listener to programatically update this item
         // to have context-specific text and onItem behavior
         createAssignment: {
            text: "new assignment",
         },
         createRequest: {
            text: "new request",
         },
         editAssignment: {
            text: "edit assignment",
         },
         editRequest: {
            text: "edit request",
         },
         splitTask: ENABLE_TASK_SPLITTING
            ? {
                 text: "split task",
                 icon: "",
              }
            : false,
         createAssignmentGrouping: {
            text: "add category/subcategory",
            separator: true,
         },
         sendAssignmentAlerts: {
            text: "send assignment alerts",
            separator: true,
         },
         deleteAssignmentGrouping: {
            text: "delete category/subcategory",
            separator: true,
         },
         deleteAssignment: {
            text: "delete assignment",
            separator: true,
         },
         deleteRequest: {
            text: "delete request",
            separator: true,
         },
         renameSegment: false,
      },
      processItems: (e) => {
         if (!ENABLE_TASK_SPLITTING) return true;

         let currentTarget = e.domEvent.target as HTMLElement;
         let hasClickedOnTask = false;
         let hasClickedOnGrid = false;

         let i = 0;
         // We're going to check the target element and it's parents to see if we've clicked on a task
         // on a depth of 20 elements. If we haven't found a task by then, we'll assume we're not clicking on a task.
         while (i < 20) {
            if (
               ["b-gantt-task", "b-grid-row"].some((cls) => currentTarget.classList.contains(cls))
            ) {
               hasClickedOnTask = currentTarget.classList.contains("b-gantt-task");
               hasClickedOnGrid = currentTarget.classList.contains("b-grid-row");
               break;
            }
            currentTarget = currentTarget.parentElement as HTMLElement;
            i++;
         }

         // If clicked on a white space in the grid, we should not show the task menu
         if (hasClickedOnGrid) {
            for (let i = 0; i < currentTarget.children.length; i++) {
               const child = currentTarget.children.item(0) as HTMLElement;
               if (child.classList.contains("b-timeaxis-cell")) {
                  hasClickedOnGrid = false;
                  hasClickedOnTask = false;
                  break;
               }
            }
         }

         if (hasClickedOnTask) {
            for (const item of ITEMS_TO_SHOW_IF_TASK) {
               (e.items[item] as any).hasClickedOnTask = true;
            }
         }
         if (hasClickedOnGrid) {
            for (const item of ITEMS_TO_SHOW_IF_GRID) {
               (e.items[item] as any).hasClickedOnGrid = true;
            }
         }

         console.log(hasClickedOnGrid, hasClickedOnTask);
         if (hasClickedOnGrid || hasClickedOnTask) {
            return true;
         }
         return false;
      },
   },
   timeAxisHeaderMenuFeature: false,
   sortFeature: {
      toggleOnHeaderClick: false,
   },
   timeRangesFeature: {
      showCurrentTimeLine: {
         name: "Today",
         cls: "gantt-current-timeline",
      },
      tooltipTemplate: ({ timeRange }) => {
         const dateString = DateHelper.format(
            new Date(timeRange.startDate),
            defaultStore.getDateFormat(),
         );
         return dateString + " - " + new Date().toLocaleTimeString();
      },
   },
   treeFeature: true,
   onTaskDblClick: () => false,
   visibleDate: new Date(),
   enableUndoRedoKeys: true,
};

export const INITIAL_GANTT_FILTER: ganttFilterType = {
   jobTitles: [],
   projectStatuses: [],
   assignmentsOrRequests: [],
   peopleOrEquipment: [],
   hideEmptyProject: false,
   hideEmptyCategories: false,
   customFieldsFilters: {},
};

export type ExtractGanttProp<T extends keyof BryntumGanttProps> = Exclude<
   BryntumGanttProps[T],
   string | undefined
>;
