import type { Filter } from "@/lib/components/chip-filter/chip-filter";
import type { AssignmentData, AssignmentWorkDays } from "@/models/assignment";
import type { TaskModel } from "@bryntum/gantt-thin";
import type { EventSegmentModel } from "@bryntum/schedulerpro-thin";
import type { Person, Project, TimeOff } from "@laborchart-modules/common";
import type { JobTitle } from "@laborchart-modules/common/dist/postgres/schemas/types/job_titles";

export type GanttContainerPropTypes = {
   groupId: string;
};

type RequestData = {
   id: string;
   end_day: string;
   tag_ids: string[];
   archived: boolean;
   comments: string[];
   end_time: string;
   label_id: string | null;
   start_day: string;
   status_id: string | null;
   work_days: AssignmentWorkDays;
   company_id: string;
   created_at: string;
   creator_id: string | null;
   editor_ids: string[];
   project_id: string;
   start_time: string;
   archived_at: string | null;
   cost_code_id: string;
   job_title_id: string | null;
   custom_fields: any[];
   work_scope_text: string | null;
   instruction_text: string | null;
   percent_allocated: null | number;
};

export type EquipmentAssignmentData = {
   id: string;
   status: string | null;
   end_day: string;
   end_time: string;
   label_id: string | null;
   overtime: boolean;
   pay_split: any | null;
   start_day: string;
   status_id: string | null;
   work_days: AssignmentWorkDays;
   start_time: string;
   resource_id: string;
   cost_code_id: string | null;
   overtime_rates: any | null;
   percent_allocated: number | null;
   cost_code: {
      id: string;
      name: string;
      labels: Array<{
         id: string;
         name: string;
         archived: boolean;
         sequence: number;
      }>;
      archived: boolean;
      sequence: number;
   } | null;
   label: {
      id: string;
      name: string;
      archived: boolean;
      sequence: number;
   } | null;
};

export type GanttProject = Omit<Project, "start_date" | "est_end_date"> & {
   start_date: string;
   est_end_date: string;
   assignments: AssignmentData[];
   requests: RequestData[];
   equipment_assignments: EquipmentAssignmentData[];
   tbd_weeks: number;
};

export type GanttOptions = {
   skip: number;
   limit?: number;
   projectSort: string;
   group_id: string;
   startDay: number;
   endDay: number;
   filters?: {
      [key: string]: Filter[];
   };
   search?: string;
   timezone: string;
};

export type Equipment = {
   id: string;
   equipment_id: string;
   identification_number: string;
   equipment_name: string;
   name: string;
   type: {
      id: string;
      name: string;
   };
   profile_photo: string;
   status: string;
   serial_number: string;
   category: {
      id: string;
      name: string;
   };
   make: {
      id: string;
      name: string;
   };
   model: {
      id: string;
      name: string;
   };
   year: number;
   rate_per_hour: number;
   ownership: string;
   notes: string;
   scope: string;
   created_at: string;
   project_ids: number[];
   current_project_id: string;
   error: {
      code: string;
      message: string;
      details: Array<{
         code: string;
         message: string;
         source: string;
      }>;
   };
   is_deleted: boolean;
};

export type RawGanttData = {
   jobTitles: JobTitle[];
   people: Array<Person & { time_off: TimeOff[]; job_title_id: string }>;
   equipment: Equipment[];
   projects: GanttProject[];
};

export type Category = {
   id: string;
   name: string;
   labels: Subcategory[];
   sequence: number;
   archived: boolean;
};

export type Subcategory = {
   id: string;
   name: string;
   archived: boolean;
   sequence: number;
};

export type GroupableTask = {
   cost_code_id: string;
   label_id: string;
};

export enum TaskType {
   ASSIGNMENT = "assignment",
   EQUIPMENT_ASSIGNMENT = "equipment_assignment",
   REQUEST = "request",
   PROJECT = "project",
   CATEGORY = "category",
   SUBCATEGORY = "subcategory",
}

export type Task = Partial<TaskModel> & {
   type: TaskType;
   calendar?: string;
   projectId: string;
   projectName: string;
   projectColor: string;
   projectJobNumber: string;
   jobTitleName: string;
   jobTitleColor?: string;
   parentCategoryId?: string;
   parentCategoryName?: string;
   parentSubcategoryId?: string;
   parentSubcategoryName?: string;
   startTime?: string;
   endTime?: string;
   workDays: AssignmentWorkDays;
   sortPriority: number;
   timeOff?: TimeOff[];
   categoryId: string | null;
   subcategoryId: string | null;
   resourceId?: string;
   resourceName?: string;
   segments?: EventSegmentModel[];
   overtime?: boolean;
   status?: {
      id: string;
      name: string;
      color: string | null;
   };
   percentAllocated?: number | null;
};

export type GroupedTasks = Array<
   | {
        name: string; // category
        children: Array<{
           name: string; // subcategory
           children: Task[]; // assignments or requests
        }>;
     }
   | Task
>;

export type TaskLookup = {
   [categoryId: string]: {
      [subcategoryId: string]: Task[];
   };
};

export type noSubcategoryTasks = {
   [categoryId: string]: Task[];
};

export type projectStatus = {
   id: number;
   name: string;
};

export enum FilterTaskType {
   ASSIGNMENTS = "assignments",
   REQUESTS = "requests",
}

export enum FilterResourceType {
   LABOR = "labor",
   EQUIPMENT = "equipment",
}

export enum dateQualifier {
   ONORBEFORE = "<=",
   ONORAFTER = ">=",
   ON = "=",
   AFTER = ">",
   BEFORE = "<",
}

export type ganttFilterType = {
   jobTitles: Array<{ id: string; name: string }>;
   projectStatuses: projectStatus[];
   assignmentsOrRequests: FilterTaskType[];
   peopleOrEquipment: FilterResourceType[];
   hideEmptyProject: boolean;
   hideEmptyCategories: boolean;
   customFieldsFilters: { [key: string]: any };
   // startDate: {
   //    qualifier: dateQualifier | null;
   //    date: string;
   // };
   // endDate: {
   //    qualifier: dateQualifier | null;
   //    date: string;
   // };
};

export type ganttFilterOptionTypes =
   | "jobTitles"
   | "projectStatuses"
   | "assignmentsOrRequests"
   | "peopleOrEquipment"
   | "hideEmptyProject"
   | "hideEmptyCategories";
// | "startDate"
// | "endDate";

export enum ganttFilterTypeEnums {
   jobTitles = "jobTitles",
   projectStatuses = "projectStatuses",
   onlyShow = "onlyShow",
   hideEmptyProject = "hideEmptyProject",
   customFieldsFilters = "customFieldsFilters",
}

export enum ganttFilterPillType {
   MULTISELECT = "multiselect",
   BOOLEAN = "boolean",
   CHECKBOX = "checkbox",
   DATE = "date",
}

export enum TotalUnitType {
   PEOPLE = "people",
   COST = "cost",
   HOURS = "hours",
   MAN_DAYS = "manDays",
}

export enum AssignmentBarColors {
   PROJECT = "Project",
   ALLOCATION_PERCENTAGE = "Allocation Percentage",
   JOB_TITLES = "Job Titles",
   OVERTIME = "Overtime",
   STATUS = "Status",
}

export type TotalByDay = {
   date: Date;
   hours?: number;
   manDays?: number;
   cost?: number;
   people?: number;
};

export type GroupedTasksWrapper = {
   tasks: GroupedTasks;
   earliestTaskStartDate: null | Date;
   latestTaskEndDate: null | Date;
};

export type GanttCustomFieldFilter = {
   property: string;
   type: string;
   negation: boolean;
   filterName: string | number;
   customFieldId: string | number;
   classifier?: any;
   value?: any;
};
