import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

export type WindowType = {
   config?: {
      LC_PROCORE_AUTH_URL: string;
      LC_PROCORE_REDIRECT_URI: string;
      LC_WFP_OAUTH_CLIENT_ID: string;
      LC_WFP_OAUTH_REDIRECT_URI: string;
      WFP_FEATURE_AWS: "true" | "false";
   };
} & Window &
   typeof globalThis;

export abstract class ProcoreStore {
   static loginWithHostApp(): void {
      const config = (window as WindowType).config;
      const authUrl = config?.LC_PROCORE_AUTH_URL || "";
      const clientID = config?.LC_WFP_OAUTH_CLIENT_ID || "";
      const usingAWS = config?.WFP_FEATURE_AWS || "false";
      const state = window.location.href;
      let redirectURI = config?.LC_WFP_OAUTH_REDIRECT_URI || "";

      if (LaunchDarklyClient.getFlagValue("use-jwt-auth") === true) {
         const redirectPath =
            usingAWS === "true"
               ? "/webclients/host/companies/tools/aws-workforce-planning/oauth/callback"
               : "/webclients/host/companies/tools/workforce-planning/oauth/callback";
         redirectURI = location.origin + redirectPath;
      }

      const url = `${authUrl}?client_id=${clientID}&response_type=token&redirect_uri=${redirectURI}&state=${state}`;

      window.location.replace(url);
   }
}
