import React, { useEffect, useState } from "react";
import {
   Card,
   Box,
   EmptyState,
   FlexList,
   Button,
   Spinner,
   H2,
   Grid,
   Typography,
   useI18nContext,
} from "@procore/core-react";
import { Pencil, Trash } from "@procore/core-icons";
import classnames from "classnames";
import { useModal } from "@/react/shared/hooks/useModal";
import type {
   EmptyRolesProps,
   RolesByJobTitleGroup,
   ListRolesCardProps,
   ProjectRoleData,
} from "../types";
import { useGetJobTitles, useGetPeopleStream } from "@/react/components/common/queries/queries";
import { useGroupContext } from "@/react/providers/group-context-provider";
import { useToastAlertContext } from "@procore/toast-alert";
import { usePermissionContext, AuthAction } from "@/react/providers/permission-context-provider";
import { AddRolesProject } from "./project-details-add-roles";
import type { ResourceName } from "@/react/prop-types";
import { ActionMode } from "@/react/prop-types";
import { DetailsCardHeader } from "@/react/shared/components/details-card-header";
import { ColorSwab } from "@/react/components/data-table/ColorSelectComponent/ColorSelectColumn";
import { getGroupedDataForRolesByJobTitle, prepareRolesPayload } from "../helpers";
import { commonButtonStyle } from "@/react/shared/helper";
import type { DetailsCardProps } from "../../types";

import styles from "./project-details.scss";
const cx = classnames.bind(styles);

const EmptyRoles: React.FC<EmptyRolesProps> = ({
   editRoles,
   openAddRolesModal,
   isAddRolesOpen,
}) => {
   const I18n = useI18nContext();
   if (isAddRolesOpen) {
      return null;
   }
   return (
      <>
         <Box padding="lg">
            <FlexList justifyContent="space-between">
               <H2>{I18n.t("views.company.workforce_planning.project_details_roles.title")}</H2>
            </FlexList>
         </Box>
         <Box padding="sm">
            <EmptyState compact>
               <EmptyState.Title>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_roles.roles_empty_state_title",
                  )}
               </EmptyState.Title>
               <EmptyState.Description className={cx("empty-state-description")}>
                  {I18n.t(
                     "views.company.workforce_planning.project_details_roles.roles_empty_state_description",
                  )}
               </EmptyState.Description>
               {editRoles && (
                  <EmptyState.Actions className={cx("empty-state-actions")}>
                     <Button variant="secondary" onClick={openAddRolesModal}>
                        {I18n.t("views.company.workforce_planning.project_details_roles.add_role")}
                     </Button>
                  </EmptyState.Actions>
               )}
            </EmptyState>
         </Box>
      </>
   );
};

const ListRolesCard: React.FC<ListRolesCardProps> = ({
   roles,
   editRoles,
   openAddRolesModal,
   isAddRolesOpen,
   selectedRoleByJobTitleId,
   setSelectedRoleByJobTitleId,
   jobTitles,
   resourceName,
   onUpdateProject,
}) => {
   const { openModal, closeModal } = useModal();
   const [mode, setMode] = useState(ActionMode.READ);
   const toggleSelectedRoleByJobTitleId = (jobTitleId: string, mode: ActionMode) => {
      setSelectedRoleByJobTitleId(selectedRoleByJobTitleId === jobTitleId ? "" : jobTitleId);
      setMode(mode);
      openModal();
   };
   const resetView = () => {
      closeModal();
      setSelectedRoleByJobTitleId("");
      setMode(ActionMode.READ);
   };
   const getInitialValues = (role: RolesByJobTitleGroup) => {
      return {
         job_title: {
            id: role.job_title_id,
            label: role.job_title_name,
         },
         resources: role.persons.map((person) => ({
            id: person.id,
            label: person.name,
            role_id: person.role_id, // role_id is linked to individual resource
         })),
      };
   };

   if (isAddRolesOpen) {
      return null;
   }

   return (
      <>
         <DetailsCardHeader
            titleKey="views.company.workforce_planning.project_details_roles.title"
            helpTextKey="views.company.workforce_planning.project_details_roles.roles_help_text"
            buttonTextKey="views.company.workforce_planning.project_details_roles.add_role"
            btnDisabled={selectedRoleByJobTitleId !== ""}
            openAddItemModal={openAddRolesModal}
            permission={editRoles}
         />
         <Box className={cx("custom-box")}>
            <>
               {roles.map((role: RolesByJobTitleGroup, index: number) => (
                  <React.Fragment key={role.job_title_id}>
                     <Box
                        padding="md"
                        style={{
                           borderBottom: index !== roles.length - 1 ? "1px solid #D6DADC" : "none",
                        }}
                     >
                        {selectedRoleByJobTitleId !== role.job_title_id && (
                           <Grid>
                              <Grid.Row>
                                 <Grid.Col
                                    colWidth={5}
                                    style={{ display: "flex", alignItems: "center" }}
                                 >
                                    <ColorSwab
                                       color={role.job_title_color}
                                       shape="circle"
                                       label={role.job_title_name}
                                       height="20px"
                                       width="20px"
                                    />{" "}
                                    <Typography
                                       intent="h3"
                                       className={cx("custom-line-height-font-size-margin")}
                                    >
                                       {role.job_title_name}
                                    </Typography>
                                 </Grid.Col>
                                 <Grid.Col colWidth={5} style={{ display: "flex" }}>
                                    <Typography
                                       intent="small"
                                       className={cx("custom-line-height-font-size-margin")}
                                       style={{ color: "#6A767C" }}
                                    >
                                       {role.persons.map((person) => person.name).join(", ")}
                                    </Typography>
                                 </Grid.Col>
                                 {editRoles && (
                                    <Grid.Col colWidth={2} className={cx("icons-at-end")}>
                                       <span>
                                          <Pencil
                                             size="sm"
                                             data-testid="edit-role"
                                             style={{
                                                ...commonButtonStyle({
                                                   selectedId: selectedRoleByJobTitleId,
                                                   currentId: role.job_title_id,
                                                }),
                                                marginRight: "15px",
                                             }}
                                             onClick={() =>
                                                toggleSelectedRoleByJobTitleId(
                                                   role.job_title_id,
                                                   ActionMode.EDIT,
                                                )
                                             }
                                          />

                                          <Trash
                                             size="sm"
                                             data-testid="delete-role"
                                             style={commonButtonStyle({
                                                selectedId: selectedRoleByJobTitleId,
                                                currentId: role.job_title_id,
                                             })}
                                             onClick={() =>
                                                toggleSelectedRoleByJobTitleId(
                                                   role.job_title_id,
                                                   ActionMode.DELETE,
                                                )
                                             }
                                          />
                                       </span>
                                    </Grid.Col>
                                 )}
                              </Grid.Row>
                           </Grid>
                        )}
                        {selectedRoleByJobTitleId === role.job_title_id && (
                           <AddRolesProject
                              onClose={resetView}
                              handleCreate={onUpdateProject}
                              mode={mode}
                              initialValues={getInitialValues(role)}
                              availableJobTitles={jobTitles}
                              availableResourceName={resourceName}
                              roles={roles}
                           />
                        )}
                     </Box>
                  </React.Fragment>
               ))}
            </>
         </Box>
         <Box padding="xxs" />
      </>
   );
};

export const ProjectDetailsRolesCard: React.FC<DetailsCardProps> = ({
   detailsData,
   updateData,
   refetchData,
   loading: projectDataLoading,
   refreshTable,
}) => {
   const I18n = useI18nContext();
   const { checkAuthAction } = usePermissionContext();
   const { groupId } = useGroupContext();
   const { showToast } = useToastAlertContext();
   const { openModal, closeModal, isOpen } = useModal();
   const { data: jobTitles } = useGetJobTitles(groupId);
   const { data: resourceName } = useGetPeopleStream(groupId != "my-groups" ? groupId : undefined);
   const [canEditRoles, setCanEditRoles] = useState<boolean>(false);
   const [selectedRoleByJobTitleId, setSelectedRoleByJobTitleId] = useState<string>("");
   const [groupedRolesDataByJobTitle, setGroupedRolesDataByJobTitle] = useState<
      RolesByJobTitleGroup[]
   >([]);
   // this is responsible for grouping the roles by job title because the API returns a flat list
   useEffect(() => {
      if (!detailsData?.data?.roles) {
         return;
      }
      const groupedData = getGroupedDataForRolesByJobTitle(detailsData?.data?.roles);
      setGroupedRolesDataByJobTitle(Object.values(groupedData));
   }, [detailsData?.data?.roles]);

   useEffect(() => {
      const canEditRoles = checkAuthAction(AuthAction.EDIT_PROJECT_ROLES);
      setCanEditRoles(canEditRoles);
   }, [checkAuthAction]);

   const handleRolesCreate = async (
      jobTitleValue: string,
      resources: ResourceName[],
      archived: boolean,
   ) => {
      const payload: ProjectRoleData = prepareRolesPayload(jobTitleValue, resources, archived);
      try {
         const updatedProject = await updateData(payload);
         if (updatedProject.data) {
            setSelectedRoleByJobTitleId("");
            refetchData();
            if (refreshTable) {
               refreshTable();
            }
            closeModal();
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.error"));
      }
   };

   return (
      <Card style={{ marginBottom: "5px" }}>
         <Spinner loading={projectDataLoading}>
            {groupedRolesDataByJobTitle && groupedRolesDataByJobTitle.length === 0 && (
               <EmptyRoles
                  editRoles={canEditRoles}
                  openAddRolesModal={openModal}
                  isAddRolesOpen={isOpen}
               />
            )}
            {groupedRolesDataByJobTitle && groupedRolesDataByJobTitle.length > 0 && (
               <ListRolesCard
                  roles={groupedRolesDataByJobTitle}
                  editRoles={canEditRoles}
                  openAddRolesModal={openModal}
                  isAddRolesOpen={isOpen}
                  selectedRoleByJobTitleId={selectedRoleByJobTitleId}
                  setSelectedRoleByJobTitleId={setSelectedRoleByJobTitleId}
                  jobTitles={jobTitles}
                  resourceName={resourceName}
                  onUpdateProject={handleRolesCreate}
               />
            )}
            {isOpen && groupedRolesDataByJobTitle && (
               <AddRolesProject
                  onClose={closeModal}
                  handleCreate={handleRolesCreate}
                  availableJobTitles={jobTitles}
                  availableResourceName={resourceName}
                  roles={groupedRolesDataByJobTitle}
               />
            )}
         </Spinner>
      </Card>
   );
};
