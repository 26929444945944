import { ActionMode } from "@/react/prop-types";
import {
   useI18nContext,
   Box,
   Grid,
   H3,
   Spinner,
   Button,
   Dropzone,
   useDropzone,
} from "@procore/core-react";
import React, { useEffect, useState } from "react";
import { type DataUploadProps } from "./types";
import { AttachmentLink } from "@/react/shared/components/attachment-links";
import { useToastAlertContext } from "@procore/toast-alert";

export const ACCEPTED_FILE_TYPES = [".csv"];

export const DataUpload = ({
   uploadFiles,
   showLoader,
   mode = ActionMode.CREATE,
   initialValues,
}: DataUploadProps) => {
   const { showToast } = useToastAlertContext();
   const I18n = useI18nContext();
   const [attachments, setAttachments] = useState<any[]>([]);
   const [loading, setLoading] = useState(false);
   const [files, setFiles] = useState<File[] | null>(null);
   const [countId, setCountId] = useState(1);

   /* istanbul ignore next */
   const dropzoneState = useDropzone({
      multiple: true,
      onDropAccepted: (acceptedFiles) => {
         saveFiles(acceptedFiles);
      },
      // this part of code will get triggered if we add some kind of validations for uploading file and it fails
      onDropRejected: (rejected) => {
         //keeping the log for now, if some validation is added in future
         console.log({
            rejected,
         });
      },
   });

   useEffect(() => {
      if (initialValues) {
         setAttachments(initialValues.attachments);
      }
   }, [initialValues]);

   const clean = () => {
      setAttachments([]);
      setFiles([]);
   };

   /* istanbul ignore next */
   const saveFiles = async (newFiles: any[]) => {
      setLoading(true);

      let count = countId;
      const newAttachments = newFiles.map((file) => {
         const { name, size, type } = file;
         const data = {
            id: count,
            name,
            mimetype: type,
            bytes: size,
         };
         count++;
         return data;
      });

      setCountId(count);

      try {
         setAttachments([...attachments, ...newAttachments]);
         setFiles([...(files ?? []), ...newFiles]);
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_upload_message"));
      } finally {
         setLoading(false);
      }
   };

   const handleDataUploadFiles = async () => {
      const formData = new FormData();
      if (files) {
         [...files].forEach((file) => {
            formData.append("csvFiles", file);
         });

         try {
            setLoading(true);
            await uploadFiles(formData);
            showToast.success("Successfully uploaded!");
            clean();
         } catch (error) {
            showToast.error(I18n.t("views.company.workforce_planning.failure_file_upload_message"));
         } finally {
            setLoading(false);
         }
      }
   };

   const handleDeleteAttachments = async (attachmentId: string) => {
      try {
         setLoading(true);
         const updatedAttachments = attachments.filter(
            (attachment) => attachment.id !== attachmentId,
         );
         setAttachments(updatedAttachments);
         if (files) {
            const attachmentToDelete = attachments.find(
               (attachment) => attachment.id === attachmentId,
            );

            const updatedFiles = files.filter((file) => file.name !== attachmentToDelete.name);
            setFiles(updatedFiles);
         }
      } catch (error) {
         showToast.error(I18n.t("views.company.workforce_planning.failure_file_delete_message"));
      } finally {
         setLoading(false);
      }
   };

   return (
      <>
         {mode !== ActionMode.DELETE && (
            <Box padding={mode === ActionMode.CREATE ? "md" : ""} className="custom-box">
               <Grid style={{ marginTop: "20px" }}>
                  <Grid.Row>
                     <Grid.Col colWidth={12}>
                        <H3>Data to upload</H3>
                     </Grid.Col>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: "5px" }}>
                     <Grid.Col colWidth={12}>
                        <Box>
                           <Spinner loading={loading}>
                              <Dropzone {...dropzoneState} disabled={loading} />
                              <br />
                              {!loading && attachments && attachments.length > 0 && (
                                 <AttachmentLink
                                    attachments={attachments}
                                    onLinkClick={() => {}}
                                    mode={ActionMode.EDIT}
                                    onAttachmentDelete={handleDeleteAttachments}
                                 />
                              )}
                           </Spinner>
                        </Box>
                     </Grid.Col>
                  </Grid.Row>
               </Grid>
               <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: "100%" }}
                  padding="sm"
               >
                  <Box>
                     <Button
                        variant="primary"
                        onClick={handleDataUploadFiles}
                        disabled={loading || !files || (files && files.length === 0)}
                        style={{ width: !showLoader ? "auto" : "70px" }}
                     >
                        {!showLoader ? (
                           I18n.t("views.company.workforce_planning.save")
                        ) : (
                           <Spinner loading={true} size="md" color="white" />
                        )}
                     </Button>
                  </Box>
               </Box>
            </Box>
         )}
         <Box padding="xxs" />
      </>
   );
};
