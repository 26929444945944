import React, { useCallback, useEffect, useState } from "react";
import { Lock } from "@procore/core-icons";
import type {
   ExtendedFieldType,
   IntegratedField,
   StreamResponseConversion,
   Tab,
} from "@/react/prop-types";
import {
   ActionMode,
   EntityAbbreviation,
   FieldType,
   PageTitle,
   TearsheetTabs,
} from "@/react/prop-types";
import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   H2,
   Page,
   Required,
   Spinner,
   Token,
   Tooltip,
   useI18nContext,
} from "@procore/core-react";
import { CustomTabs } from "@/react/shared/components/custom-tabs";
import {
   useGetCompany,
   useGetJobTitles,
   useGetNotificationProfiles,
   useGetPermissionLevelOptions,
   useGetGroupOptionsQuery,
   validatePhoneNumber,
} from "../../common/queries/queries";
import { useGetPeopleDetails } from "./queries";
import { FieldMapping } from "../../people-list/people-list-enums";
import { PEOPLE_STATUS_OPTIONS } from "../../people-list/people-list-prop-types";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type {
   GetPersonDetailsResponse,
   SerializedPersonDetailsPerson,
} from "@laborchart-modules/lc-core-api/dist/api/people/get-person";
import type {
   PersonDetailTearsheetProps,
   PersonDetailFields,
   PersonDetailFormValues,
   peopleDetailsFieldsSection,
} from "./prop-types";
import {
   processCustomFieldsFormValues,
   processCustomFieldsWithPermission,
} from "@/react/shared/custom-field-utils";
import type { FormikHelpers, FormikProps } from "formik";
import { ColorPicker } from "../../common/components/color-picker";
import {
   peopleDetailFieldsSection1,
   peopleDetailFieldsSection2,
   peopleDetailFieldsSection3,
   peopleDetailFieldsSection4,
   peopleDetailFieldsSection5,
   peopleDetailsGroupOptionForAdminAccess,
} from "./constants";
import {
   getFilteredFields,
   isIntegratedField,
   isSensitiveFieldEditable,
} from "@/react/shared/helper";
import {
   findById,
   generateCustomLabel,
   getFullName,
   getPersonGenderOptions,
   getPersonStatusMap,
   getPersonType,
   getPersonTypes,
   getReadOnlyViewForDates,
} from "./tearsheet-utils";
import { defaultStore } from "@/stores/default-store";
import { DateUtils } from "@/lib/utils/date";
import { legacyFieldNamesMap } from "../../people-list/helpers";
import { PhotoUploader } from "../shared/photo-uploader";
import { PersonStore } from "@/stores/person-store.core";
import { authManager } from "@/lib/managers/auth-manager";
import { QRCodeCard } from "@/react/shared/components/qr-code-card";
import { DetailsNotesCard } from "../notes/details-notes-card";
import { NotesType } from "../notes/types";
import { AuthAction, usePermissionContext } from "@/react/providers/permission-context-provider";
import { DetailsAttachmentsCard } from "../attachments/details-attachments-card";
import { PageType } from "../types";
import { DetailsTagsCard } from "../tags/details-tags-card";
import { PersonDetailsActivities } from "./person-detail-cards/person-details-activities-card";
import type { UpdateSinglePersonResponse } from "@laborchart-modules/lc-core-api/dist/api/people";

import { DefaultStoreCore } from "@/stores/default-store.core";
import {
   buildPayload,
   getColStart,
   getUpdatedValues,
   updateSectionFields,
} from "./person-detail-helper";
import { peopleDetailsSchema } from "./validation-schemas";

import { getProcoreCompanyIdFromURL } from "@/lib/utils/url";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { renderLinkToProcoreEntity } from "../utils";
import { DetailsTimeOffCard } from "../time-off/details-timeoff-card";

import { RadioButtonField } from "./radio-button-field";
import { ErrorBanner } from "../../error-banner";
import { validateEmail } from "@/lib/utils/validation";
import { useGroupContext } from "@/react/providers/group-context-provider";

//adding more objects to this array will automatically start rendering other tabs, just maintain unique ID and labels.
const PERSON_DETAILS_TABS: Tab[] = [
   { id: 0, label: TearsheetTabs.OVERVIEW, visible: true },
   { id: 1, label: TearsheetTabs.NOTES, visible: true },
   { id: 2, label: TearsheetTabs.TIME_OFF, visible: true },
   { id: 3, label: TearsheetTabs.ACTIVITY, visible: true },
];

export const PersonDetailTearsheet: React.FC<PersonDetailTearsheetProps> = ({
   personId,
   customFields,
   integratedFields,
   onUpdatePeople,
   showActiveTab,
}) => {
   const I18n = useI18nContext();
   const { groupId } = useGroupContext();
   const { checkAuthAction } = usePermissionContext();
   const {
      data: peopleData,
      isLoading: peopleDataLoading,
      refetch,
   } = useGetPeopleDetails(personId);
   const { data: companyData } = useGetCompany();
   const { data: groups } = useGetGroupOptionsQuery();
   const { data: jobTitles } = useGetJobTitles(groupId);
   const { data: permissionLevels } = useGetPermissionLevelOptions();
   const { data: notificationProfiles } = useGetNotificationProfiles();
   const [personIntegratedFields, setPersonIntegratedFields] = useState<IntegratedField[]>([]);
   const activeTabId =
      (showActiveTab && PERSON_DETAILS_TABS.find((tab) => tab.label === showActiveTab)?.id) ?? 0;
   const [activeTab, setActiveTab] = useState(activeTabId || 0);
   const [formViewMode, setFormViewMode] = React.useState<"read" | "update">(ActionMode.READ);
   const personStatusMap = getPersonStatusMap(I18n);
   const personTypes = getPersonTypes(I18n);
   const personGenderOptions = getPersonGenderOptions(I18n);
   const [qrCodeEnabled, setQrCodeEnabled] = React.useState<boolean | undefined>(false);
   const [peopleQrCodeEnabled, setPeopleQRCodeEnabled] = React.useState<boolean | undefined>(false);
   const [canViewPeopleAttachments, setCanViewPeopleAttachments] = useState<boolean>(false);
   const [canViewPeopleTags, setCanViewPeopleTags] = useState<boolean>(false);
   const [canViewPeopleFinancials, setCanViewPeopleFinancials] = useState<boolean>(false);
   const [canEditPeopleSensitiveFields, setCanEditPeopleSensitiveFields] = useState<boolean>(false);
   const [canEditPeopleDetails, setCanEditPeopleDetails] = useState<boolean>(false);
   const [canEditPeoplePermissions, setCanEditPeoplePermissions] = useState<boolean>(false);
   const [canViewPeopleSensitiveFields, setCanViewPeopleSensitiveFields] = useState<boolean>(false);
   const [peopleDetailsFieldsSection1, setPeopleDetailsFieldsSection1] =
      useState<peopleDetailsFieldsSection>([]);
   const [peopleDetailsFieldsSection2, setPeopleDetailsFieldsSection2] =
      useState<peopleDetailsFieldsSection>([]);
   const [peopleDetailsFieldsSection3, setPeopleDetailsFieldsSection3] =
      useState<peopleDetailsFieldsSection>([]);
   const [peopleDetailsFieldsSection4, setPeopleDetailsFieldsSection4] =
      useState<peopleDetailsFieldsSection>([]);
   const [peopleDetailsFieldsSection5, setPeopleDetailsFieldsSection5] =
      useState<peopleDetailsFieldsSection>([]);
   const [peopleSensitiveFields, setPeopleSensitiveFields] = useState<string[]>([]);
   const [personDetailTabsState, setPersonDetailTabsState] = useState(PERSON_DETAILS_TABS);
   const [phoneNumberValid, setPhoneNumberValid] = React.useState<boolean>(true);
   const [disabledField, setDisabledField] = useState<{ [fieldName: string]: boolean }>({
      fieldName: false, // Replace "fieldName" with the actual field name and set the value to true or false
   });
   const [isError, setIsError] = React.useState<boolean>(false);
   const [errorMessage, setErrorMessage] = React.useState<string>("");
   const [emailFieldAvailability, setEmailFieldAvailability] = React.useState<boolean>(true);

   let initialValues: PersonDetailFormValues = {} as PersonDetailFormValues;
   const validationSchema = peopleDetailsSchema(
      I18n,
      phoneNumberValid,
      customFields,
      emailFieldAvailability,
   );

   const languageOptions = DefaultStoreCore.LanguageOptions.map((item) => ({
      id: item.value,
      label: item.name,
   }));
   const procoreCompanyId: string | undefined = getProcoreCompanyIdFromURL();

   const checkUserCanViewTab = () => {
      const authActions: Array<AuthAction | null> = [
         null, // Placeholder for index alignment
         AuthAction.VIEW_PEOPLE_NOTES,
         AuthAction.VIEW_PEOPLE_TIMEOFF,
         AuthAction.VIEW_PEOPLE_ACTIVITY,
      ];

      const newPersonDetailTabsState = personDetailTabsState.map((tab, index) => ({
         ...tab,
         visible:
            authActions[index] !== null
               ? checkAuthAction(authActions[index] as AuthAction)
               : tab.visible,
      }));

      setPersonDetailTabsState(newPersonDetailTabsState);
   };

   useEffect(() => {
      checkUserCanViewTab();
      setQrCodeEnabled(authManager.companyModules()?.qrCodes);
      setPeopleQRCodeEnabled(authManager.companyModules()?.peopleQrCodes);
      const canViewPeopleAttachments = checkAuthAction(AuthAction.VIEW_PEOPLE_ATTACHMENTS);
      setCanViewPeopleAttachments(canViewPeopleAttachments);
      const canViewPeopleTags = checkAuthAction(AuthAction.VIEW_PEOPLE_TAGS);
      setCanViewPeopleTags(canViewPeopleTags);
      const canViewPeopleFinancials = checkAuthAction(AuthAction.VIEW_PEOPLE_FINANCIALS);
      setCanViewPeopleFinancials(canViewPeopleFinancials);
      const canEditPeopleSensitiveFields = checkAuthAction(AuthAction.EDIT_PEOPLE_SENSITIVE);
      setCanEditPeopleSensitiveFields(canEditPeopleSensitiveFields);
      const canEditPeopleDetails = checkAuthAction(AuthAction.EDIT_PEOPLE_DETAILS);
      setCanEditPeopleDetails(canEditPeopleDetails);
      const canEditPeoplePermissions = checkAuthAction(AuthAction.EDIT_PEOPLE_PERMISSIONS);
      setCanEditPeoplePermissions(canEditPeoplePermissions);
      const canViewPeopleSensitiveFields = checkAuthAction(AuthAction.VIEW_PEOPLE_SENSITIVE);
      setCanViewPeopleSensitiveFields(canViewPeopleSensitiveFields);

      setPeopleSensitiveFields(
         authManager
            .peopleSensitiveFields()
            .map((field) => (field == "is_male" ? "gender" : field)),
      );
   }, []);

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.people_integrated_fields)) {
            const updatedFields = integratedFields.data.people_integrated_fields.map((field) => {
               switch (field.property) {
                  case "position_id":
                     return { ...field, property: "position" };
                  case "permission_level_id":
                     return { ...field, property: "permission" };
                  default:
                     return field;
               }
            });

            setPersonIntegratedFields(updatedFields);
         }
      }
   }, [integratedFields]);

   useEffect(() => {
      updateSectionFields(
         peopleDetailFieldsSection1 as peopleDetailsFieldsSection,
         setPeopleDetailsFieldsSection1,
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         canEditPeopleSensitiveFields,
         canViewPeopleFinancials,
         personIntegratedFields,
      );
      updateSectionFields(
         peopleDetailFieldsSection2 as peopleDetailsFieldsSection,
         setPeopleDetailsFieldsSection2,
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         canEditPeopleSensitiveFields,
         canViewPeopleFinancials,
         personIntegratedFields,
      );
      updateSectionFields(
         peopleDetailFieldsSection3 as peopleDetailsFieldsSection,
         setPeopleDetailsFieldsSection3,
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         canEditPeopleSensitiveFields,
         canViewPeopleFinancials,
         personIntegratedFields,
      );
      updateSectionFields(
         peopleDetailFieldsSection4 as peopleDetailsFieldsSection,
         setPeopleDetailsFieldsSection4,
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         canEditPeopleSensitiveFields,
         canViewPeopleFinancials,
         personIntegratedFields,
      );
      updateSectionFields(
         peopleDetailFieldsSection5 as peopleDetailsFieldsSection,
         setPeopleDetailsFieldsSection5,
         peopleSensitiveFields,
         canViewPeopleSensitiveFields,
         canEditPeopleSensitiveFields,
         canViewPeopleFinancials,
         personIntegratedFields,
      );
   }, [
      peopleSensitiveFields,
      canViewPeopleSensitiveFields,
      canEditPeopleSensitiveFields,
      canViewPeopleFinancials,
      personIntegratedFields,
   ]);

   useEffect(() => {
      if (
         !peopleDetailsFieldsSection2?.[0]?.showField &&
         peopleDetailsFieldsSection2?.[0]?.disabled
      ) {
         setEmailFieldAvailability(false);
      }
   }, [peopleDetailsFieldsSection2]);

   // Function to render form fields
   const renderFormFields = useCallback(
      (fields: PersonDetailFields[], fieldSource?: string) => {
         const filteredFields = getFilteredFields(
            fields,
            peopleSensitiveFields,
            canViewPeopleSensitiveFields,
            canViewPeopleFinancials,
         );

         const rows = [];
         const colWidth = 4;
         for (let i = 0; i < filteredFields.length; i += 3) {
            const rowFields = filteredFields.slice(i, i + 3);

            const validComponents = rowFields
               .map((field: PersonDetailFields, index: number) => {
                  let FormComponent = null;
                  let additionalProps = {};
                  let colStartBase: 1 | 5 | 9;
                  let legacyFieldNameKey = field.name;
                  //logic for handling the column start base in the form field
                  switch (index) {
                     case 0:
                        colStartBase = 1;
                        break;
                     case 1:
                        colStartBase = 5;
                        break;
                     default:
                        colStartBase = 9;
                        break;
                  }

                  if (fieldSource === PageTitle.PEOPLE_DETAILS) {
                     switch (field.name) {
                        case FieldMapping.groups:
                           field.values = groups as any;
                           break;
                        case FieldMapping.status:
                           field.values = PEOPLE_STATUS_OPTIONS;
                           break;
                        case FieldMapping.job_title:
                           field.values = jobTitles;
                           legacyFieldNameKey = legacyFieldNamesMap["position_id"];
                           break;
                        case FieldMapping.type:
                           field.values = personTypes;
                           break;
                        case FieldMapping.permission:
                           field.values = permissionLevels;
                           legacyFieldNameKey = legacyFieldNamesMap["permission_level_id"];
                           break;
                        case FieldMapping.notification_profile:
                           field.values = notificationProfiles;
                           break;
                        case FieldMapping.gender:
                           field.values = personGenderOptions;
                           break;
                        default:
                           break;
                     }
                  }

                  switch (field.type as ExtendedFieldType) {
                     case CustomFieldType.DATE:
                        /* If form view mode is read, render a normal div with dates to respect configured date format in company setting, 
                     else render as date select, as date picker always shows dates in mm/dd/yyyy format */
                        if (formViewMode === ActionMode.READ) {
                           let fieldValue =
                              initialValues[field.name as keyof PersonDetailFormValues];
                           //here using DateUtils to format the date as Globalization toolkit doesn't support date formatting with all the options
                           fieldValue = fieldValue
                              ? DateUtils.formatDate(
                                   new Date(Number(fieldValue)),
                                   defaultStore.getDateFormat(), // this will give the date format from company settings
                                )
                              : "--";
                           FormComponent = () =>
                              getReadOnlyViewForDates(
                                 fieldSource,
                                 colStartBase,
                                 colWidth,
                                 I18n,
                                 field,
                                 personIntegratedFields,
                                 fieldValue as string,
                              );
                        } else {
                           FormComponent = Form.DateSelect;
                        }
                        break;
                     case CustomFieldType.NUMBER:
                        FormComponent = Form.Number;
                        break;
                     case CustomFieldType.TEXT:
                        FormComponent = Form.Text;
                        break;
                     case CustomFieldType.SELECT:
                        FormComponent = Form.Select;
                        additionalProps = {
                           options:
                              fieldSource === PageTitle.PEOPLE_DETAILS
                                 ? field.values
                                 : field.values?.map((value: string) => ({
                                      id: field.name + "_" + value,
                                      label: value,
                                   })) ?? [],
                           onSearch: false,
                           placeholder: I18n.t("views.company.workforce_planning.select_option"),
                        };
                        break;
                     case CustomFieldType.MULTI_SELECT:
                        FormComponent = Form.MultiSelect;
                        additionalProps = {
                           options:
                              fieldSource === PageTitle.PEOPLE_DETAILS
                                 ? field.values
                                 : field.values?.map((value: string) => ({
                                      id: field.name + "_" + value,
                                      label: value,
                                   })) ?? [],
                           placeholder: I18n.t("views.company.workforce_planning.select_option"),
                        };
                        break;
                     case CustomFieldType.PARAGRAPH:
                        FormComponent = Form.TextArea;
                        break;
                     case CustomFieldType.BOOL:
                        FormComponent = Form.Checkbox;
                        break;
                     case CustomFieldType.CURRENCY:
                        FormComponent = Form.Currency;
                        break;
                     case CustomFieldType.HEX_COLOR:
                        FormComponent = Form.Field;
                        additionalProps = {
                           as: ColorPicker,
                        };
                        break;
                     case FieldType.RADIO:
                        FormComponent = Form.RadioButtons;
                        additionalProps = {
                           options:
                              fieldSource === PageTitle.PEOPLE_DETAILS
                                 ? field.values
                                 : field.values?.map((value: string) => ({
                                      id: field.name + "_" + value,
                                      label: value,
                                   })) ?? [],
                        };
                        break;
                     case FieldType.PILL_SELECT:
                        FormComponent = Form.PillSelect;
                        additionalProps = {
                           options:
                              field.values?.map((value) => ({
                                 ...value,
                                 label: I18n.t(value.label),
                              })) ?? [],
                        };
                        break;
                  }

                  if (!FormComponent) return null; // If FormComponent is null, return null
                  // Set disabled true if field is an integrated field and is locked
                  // in case of custom fields property matches with id and for common fields it matches with name
                  if (
                     isIntegratedField(
                        fieldSource === PageTitle.PEOPLE_DETAILS
                           ? legacyFieldNamesMap[legacyFieldNameKey]
                           : field.id,
                        personIntegratedFields,
                     ) ||
                     field.integration_only ||
                     isSensitiveFieldEditable(
                        field.name,
                        peopleSensitiveFields,
                        canEditPeopleSensitiveFields,
                     )
                  ) {
                     additionalProps = { ...additionalProps, disabled: true };
                  }
                  return (
                     <React.Fragment
                        key={
                           fieldSource === PageTitle.PEOPLE_DETAILS
                              ? `people-field-fragment-outside-${field.name}`
                              : `custom-field-fragment-outside-${field.id}-${index}`
                        }
                     >
                        <FormComponent
                           key={
                              fieldSource === PageTitle.PEOPLE_DETAILS
                                 ? `people-field-${field.name}`
                                 : `custom-field-${field.id}`
                           }
                           colStart={colStartBase}
                           colWidth={colWidth}
                           name={field.name}
                           // @ts-expect-error: passing element as needed to append lock icon
                           label={
                              fieldSource === PageTitle.PEOPLE_DETAILS ? (
                                 generateCustomLabel(
                                    legacyFieldNamesMap[legacyFieldNameKey],
                                    field.id,
                                    personIntegratedFields,
                                    I18n,
                                 )
                              ) : (
                                 <div style={{ display: "flex", alignItems: "center" }}>
                                    <span>{field.name}</span>
                                    {(isIntegratedField(field.id, personIntegratedFields) ||
                                       field.integration_only) && (
                                       <Lock size="sm" style={{ marginLeft: "3px" }} />
                                    )}
                                 </div>
                              )
                           }
                           {...additionalProps}
                           {...(field.required === true && { required: true })}
                        />
                     </React.Fragment>
                  );
               })
               .filter((component: any) => component !== null); // Filter out null components
            if (validComponents.length > 0) {
               rows.push(<Form.Row key={`custom-fields-row-${i}`}>{validComponents}</Form.Row>);
            }
         }
         return rows;
      },
      [formViewMode, initialValues, personIntegratedFields],
   );

   /* istanbul ignore next */
   async function updatePeopleData(payload: any) {
      return await PersonStore.updatePerson(personId, payload).payload;
   }

   /* istanbul ignore next */
   async function handleUploadPhoto(url: string | null) {
      await updatePeopleData({ profile_pic_url: url });
   }

   if (peopleData?.data && groups) {
      initialValues = getFormValues(peopleData, groups);
   }
   async function handleSubmit(
      values: PersonDetailFormValues,
      { setValues, resetForm }: FormikHelpers<PersonDetailFormValues>,
   ) {
      try {
         const updatedValues = getUpdatedValues(initialValues, values);
         // Build the payload to send to the API
         let payload: any = {};
         if (customFields) {
            payload.custom_fields = processCustomFieldsWithPermission(
               customFields,
               updatedValues,
               personIntegratedFields,
               canViewPeopleFinancials,
               peopleSensitiveFields,
               canEditPeopleSensitiveFields,
            );
         }
         payload = {
            ...payload,
            ...buildPayload(values, updatedValues, groups, customFields),
         };

         // Removing integrated fields from payload as they are not editable
         Object.entries(payload).forEach(([fieldName]) => {
            if (isIntegratedField(legacyFieldNamesMap[fieldName], personIntegratedFields)) {
               delete payload[fieldName];
            }
            if (fieldName === "group_ids" && isIntegratedField(fieldName, personIntegratedFields)) {
               delete payload[fieldName];
            }
            if (fieldName === "permission_level_id") {
               fieldName = "permission";
               if (isIntegratedField(fieldName, personIntegratedFields)) delete payload[fieldName];
            }
         });

         // Removing sensitive fields from payload if user does not have permission to view them or if they are noneditable
         Object.entries(payload).forEach(([fieldName]) => {
            if (!canEditPeopleSensitiveFields && peopleSensitiveFields.includes(fieldName)) {
               delete payload[fieldName];
            }
         });

         if (!canViewPeopleFinancials) {
            delete payload["hourly_wage"];
         }
         setIsError(false);
         setErrorMessage("");
         const updatedPeople = await updatePeopleData(payload);
         // If the update is successful, refetch the data and update the form values
         if (updatedPeople.data && groups) {
            refetch();
            setValues(getFormValues(updatedPeople, groups));
            setFormViewMode("read");
            onUpdatePeople();
         }
      } catch (e: any) {
         resetForm();
         setIsError(true);
         setErrorMessage(e.validation?.[0].message);
      }
   }
   function getFormValues(
      peopleData: GetPersonDetailsResponse | UpdateSinglePersonResponse,
      groups: StreamResponseConversion,
   ) {
      const data = peopleData.data;
      const personType = getPersonType(data.is_assignable, data.is_user, personTypes);
      const baseFormValues = {
         first_name: data.name.first,
         last_name: data.name.last,
         status: personStatusMap[data.status],
         groups:
            data.group_ids && data.group_ids.length > 0
               ? data.group_ids.map((groupId) => findById(groups, groupId)).filter(Boolean)
               : peopleDetailsGroupOptionForAdminAccess.map((group) => {
                    return { id: group.id, label: I18n.t(group.label) };
                 }),
         job_title: findById(
            jobTitles,
            (data as SerializedPersonDetailsPerson).position?.id ?? data.job_title_id,
         ),
         type: personType,
         permission: findById(permissionLevels, data.permission_level_id),
         address_1: data.address_1 ?? null,
         address_2: data.address_2 ?? null,
         city_town: data.city_town ?? null,
         state_province: data.state_province ?? null,
         zipcode: data.zipcode ?? null,
         country: data.country ?? null,
         employee_number: data.employee_number ?? null,
         email: data.email ?? null,
         phone: data.phone ?? null,
         can_receive_email: data.can_receive_email,
         can_receive_sms: data.can_receive_sms,
         can_receive_mobile: data.can_receive_mobile,
         notification_profile: findById(notificationProfiles, data.notification_profile_id),
         emergency_contact_name: data.emergency_contact_name,
         emergency_contact_number: data.emergency_contact_number,
         emergency_contact_relation: data.emergency_contact_relation,
         hourly_wage: data.hourly_wage ?? null,
         language: findById(languageOptions, data.language),
         gender: findById(personGenderOptions, data.gender),
         hired_date: data.hired_date ? new Date(data.hired_date) : null,
         dob: data.dob ? new Date(data.dob) : null,
      };
      const customFieldValues = processCustomFieldsFormValues(data.custom_fields);
      return { ...baseFormValues, ...customFieldValues };
   }

   return (
      <Page>
         <Page.Main
            style={{
               width: "952px",
               display: "flex",
               flexDirection: "column",
            }}
         >
            {peopleDataLoading ? (
               <Spinner loading />
            ) : (
               <>
                  <Page.Header>
                     <Page.Title>
                        <H1>
                           {getFullName(peopleData?.data.name)}{" "}
                           {renderLinkToProcoreEntity({
                              entityType: CustomFieldEntity.PERSON,
                              procoreEntityId: peopleData?.data.procore_person_id,
                              procoreCompanyId: procoreCompanyId,
                           })}
                        </H1>
                     </Page.Title>
                     <CustomTabs
                        tabs={personDetailTabsState}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                     />
                  </Page.Header>
                  {activeTab === personDetailTabsState[0].id && (
                     <Form
                        view={formViewMode}
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {({
                           submitForm,
                           isSubmitting,
                           resetForm,
                           setFieldValue,
                           setFieldError,
                           setFieldTouched,
                        }: FormikProps<PersonDetailFormValues>) => (
                           <>
                              <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                                 <Card style={{ marginBottom: "5px" }}>
                                    <Form.Form>
                                       <Box padding="md">
                                          {/* istanbul ignore next */}
                                          {isError && (
                                             <ErrorBanner
                                                title="Error"
                                                content={
                                                   errorMessage === "Email is already in use."
                                                      ? I18n.t(
                                                           "views.company.workforce_planning.people.validations.email_already_in_use",
                                                        )
                                                      : I18n.t(
                                                           "views.company.workforce_planning.people.validations.generic_error",
                                                        )
                                                }
                                             />
                                          )}
                                          <FlexList justifyContent="space-between">
                                             <H2>
                                                {I18n.t(
                                                   "views.company.workforce_planning.information",
                                                )}
                                             </H2>
                                             {formViewMode === ActionMode.READ &&
                                                canEditPeopleDetails && (
                                                   <Button
                                                      variant="secondary"
                                                      onClick={() =>
                                                         setFormViewMode(ActionMode.UPDATE)
                                                      }
                                                      data-testid="edit-people-details"
                                                   >
                                                      {I18n.t(
                                                         "views.company.workforce_planning.edit",
                                                      )}
                                                   </Button>
                                                )}
                                          </FlexList>
                                       </Box>
                                       <Box padding="md">
                                          {peopleDetailsFieldsSection1.length > 0 &&
                                             (peopleDetailsFieldsSection1[0].showField ||
                                                peopleDetailsFieldsSection1[1].showField ||
                                                peopleDetailsFieldsSection1[2].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection1[0].showField && (
                                                      <Form.Text
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[0].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[0]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[0].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         required={
                                                            peopleDetailsFieldsSection1[0].required
                                                         }
                                                         disabled={
                                                            peopleDetailsFieldsSection1[0].disabled
                                                         }
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection1[1].showField && (
                                                      <Form.Text
                                                         colStart={
                                                            peopleDetailsFieldsSection1[0].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[1].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[1]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[1].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         required={
                                                            peopleDetailsFieldsSection1[1].required
                                                         }
                                                         disabled={
                                                            peopleDetailsFieldsSection1[1].disabled
                                                         }
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection1[2].showField && (
                                                      <Form.PillSelect
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection1[1]
                                                               .showField,
                                                            peopleDetailsFieldsSection1[0]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[2].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[2]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[2].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         required={
                                                            peopleDetailsFieldsSection1[2].required
                                                         }
                                                         disabled={
                                                            peopleDetailsFieldsSection1[2].disabled
                                                         }
                                                         options={PEOPLE_STATUS_OPTIONS.map(
                                                            (status) => ({
                                                               ...status,
                                                               label: I18n.t(status.label),
                                                            }),
                                                         )}
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          {peopleDetailsFieldsSection1.length > 0 &&
                                             (peopleDetailsFieldsSection1[3].showField ||
                                                peopleDetailsFieldsSection1[4].showField ||
                                                peopleDetailsFieldsSection1[5].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection1[3].showField && (
                                                      <Form.MultiSelect
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[3].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[3]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[3].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         required={
                                                            peopleDetailsFieldsSection1[3].required
                                                         }
                                                         options={[
                                                            {
                                                               id: "select_all",
                                                               label: I18n.t(
                                                                  "views.company.workforce_planning.select_all",
                                                               ),
                                                            },
                                                         ].concat(groups ?? [])}
                                                         disabled={
                                                            !peopleDetailsFieldsSection1[3].disabled
                                                               ? disabledField[
                                                                    peopleDetailsFieldsSection1[3]
                                                                       .name
                                                                 ] ??
                                                                 initialValues.permission?.is_admin
                                                               : true
                                                         }
                                                         tokenRenderer={({
                                                            focused,
                                                            disabled,
                                                            getLabel,
                                                            option,
                                                            removeToken,
                                                         }) => {
                                                            return (
                                                               <Tooltip
                                                                  overlay={getLabel(option)}
                                                                  trigger="hover"
                                                               >
                                                                  <Token
                                                                     disabled={disabled}
                                                                     focused={focused}
                                                                  >
                                                                     <Token.Label>
                                                                        {option.id == "select_all"
                                                                           ? I18n.t(
                                                                                "views.company.workforce_planning.all_groups",
                                                                             )
                                                                           : getLabel(option)}
                                                                     </Token.Label>
                                                                     <Token.Remove
                                                                        data-close
                                                                        onClick={removeToken}
                                                                     />
                                                                  </Token>
                                                               </Tooltip>
                                                            );
                                                         }}
                                                         view={
                                                            disabledField[FieldMapping.groups] ??
                                                            initialValues.permission?.is_admin
                                                               ? "read"
                                                               : undefined
                                                         }
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection1[4].showField && (
                                                      <Form.Select
                                                         colStart={
                                                            peopleDetailsFieldsSection1[3].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[4].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[4]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[4].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         options={jobTitles}
                                                         disabled={
                                                            peopleDetailsFieldsSection1[4].disabled
                                                         }
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection1[5].showField && (
                                                      <Form.Text
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection1[3]
                                                               .showField,
                                                            peopleDetailsFieldsSection1[4]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection1[5].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection1[5]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection1[5].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         disabled={
                                                            peopleDetailsFieldsSection1[5].disabled
                                                         }
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          {
                                             // Only show the permission field if the user has permission to edit permissions
                                             canEditPeoplePermissions && (
                                                <Form.Row>
                                                   <Form.Field
                                                      as={(props: any) => {
                                                         const disabledField =
                                                            peopleDetailsFieldsSection1[6].disabled;
                                                         const modifiedProps = {
                                                            ...props,
                                                            initialValues,
                                                            setFieldValue,
                                                            setDisabledField,
                                                            formViewMode,
                                                            disabledField,
                                                         };
                                                         return (
                                                            <RadioButtonField {...modifiedProps} />
                                                         );
                                                      }}
                                                      name={peopleDetailsFieldsSection1[6].name}
                                                      label={
                                                         generateCustomLabel(
                                                            legacyFieldNamesMap[
                                                               peopleDetailsFieldsSection1[6].name
                                                            ],
                                                            peopleDetailsFieldsSection1[6].id,
                                                            personIntegratedFields,
                                                            I18n,
                                                         ) as any
                                                      }
                                                      colStart={1}
                                                      colWidth={4}
                                                      disabled={
                                                         !peopleDetailsFieldsSection1[6].disabled
                                                      }
                                                   />

                                                   <Form.Select
                                                      style={{ marginTop: "0px" }}
                                                      colStart={5}
                                                      colWidth={4}
                                                      name={peopleDetailFieldsSection1[7].name}
                                                      label={
                                                         generateCustomLabel(
                                                            legacyFieldNamesMap[
                                                               peopleDetailsFieldsSection1[7].name
                                                            ],
                                                            peopleDetailsFieldsSection1[7].id,
                                                            personIntegratedFields,
                                                            I18n,
                                                         ) as any
                                                      }
                                                      options={permissionLevels}
                                                      disabled={
                                                         !peopleDetailsFieldsSection1[7].disabled
                                                            ? disabledField[
                                                                 FieldMapping.permission
                                                              ] ??
                                                              initialValues.type?.value ===
                                                                 "assignable"
                                                            : true
                                                      }
                                                      /* istanbul ignore next */
                                                      onSelect={(e) => {
                                                         const { item } = e;
                                                         // Admins has access to all groups
                                                         setDisabledField((prevDisabledFields) => ({
                                                            ...prevDisabledFields,
                                                            groups: item.is_admin,
                                                         }));
                                                         if (item.is_admin) {
                                                            setFieldValue(
                                                               FieldMapping.groups,
                                                               peopleDetailsGroupOptionForAdminAccess.map(
                                                                  (group) => {
                                                                     return {
                                                                        id: group.id,
                                                                        label: I18n.t(group.label),
                                                                     };
                                                                  },
                                                               ),
                                                            ); // set the default group for admin
                                                         } else if (
                                                            initialValues.permission?.is_admin
                                                         ) {
                                                            setFieldValue(FieldMapping.groups, []); // clear the groups if user is not admin and was admin previously
                                                         } else {
                                                            setFieldValue(
                                                               FieldMapping.groups,
                                                               initialValues.groups,
                                                            ); // set the groups to the previous value if user is not admin
                                                         }
                                                      }}
                                                   />
                                                </Form.Row>
                                             )
                                          }
                                          <hr />

                                          {peopleDetailsFieldsSection2.length > 0 &&
                                             (peopleDetailsFieldsSection2[0].showField ||
                                                peopleDetailsFieldsSection2[1].showField ||
                                                (canEditPeoplePermissions &&
                                                   peopleDetailsFieldsSection2[2].showField)) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection2[0].showField && (
                                                      <Form.Text
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection2[0].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection2[0]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection2[0].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         onBlur={(e) => {
                                                            setFieldTouched("email", true, false);
                                                            const value = e.target.value;
                                                            if (value) {
                                                               const result = validateEmail(value);
                                                               if (!result) {
                                                                  setFieldError(
                                                                     "email",
                                                                     I18n.t(
                                                                        "views.company.workforce_planning.validations.invalid_email_address",
                                                                     ),
                                                                  );
                                                               } else {
                                                                  setFieldError("email", undefined);
                                                               }
                                                            }
                                                         }}
                                                         {...(peopleDetailsFieldsSection2[0]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection2[1].showField && (
                                                      <Form.Text
                                                         colStart={
                                                            peopleDetailsFieldsSection2[0].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection2[1].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection2[1]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection2[1].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         onBlur={async (e) => {
                                                            const value = e.target.value;
                                                            setFieldTouched("phone", true, false);
                                                            if (value) {
                                                               const result =
                                                                  await validatePhoneNumber(value);
                                                               if (
                                                                  result.data.formatted_number &&
                                                                  result.data.conflicts?.length ===
                                                                     0
                                                               ) {
                                                                  setFieldValue(
                                                                     "phone",
                                                                     result.data.formatted_number,
                                                                     false,
                                                                  );
                                                                  setFieldError("phone", undefined);
                                                                  setPhoneNumberValid(true);
                                                               } else {
                                                                  const message =
                                                                     result.data
                                                                        .formatted_number === null
                                                                        ? I18n.t(
                                                                             "views.company.workforce_planning.people.validations.invalid_phone_number",
                                                                          )
                                                                        : I18n.t(
                                                                             "views.company.workforce_planning.people.validations.phone_conflict",
                                                                          );
                                                                  setFieldError("phone", message);
                                                                  setPhoneNumberValid(false);
                                                               }
                                                            } else {
                                                               setFieldError("phone", undefined);
                                                               setPhoneNumberValid(true);
                                                            }
                                                         }}
                                                         disabled={
                                                            peopleDetailsFieldsSection2[1].disabled
                                                         }
                                                      />
                                                   )}
                                                   {canEditPeoplePermissions &&
                                                      peopleDetailsFieldsSection2[2].showField && (
                                                         <Form.Select
                                                            colStart={getColStart(
                                                               peopleDetailsFieldsSection2[1]
                                                                  .showField,
                                                               peopleDetailsFieldsSection2[0]
                                                                  .showField,
                                                            )}
                                                            colWidth={4}
                                                            name={
                                                               peopleDetailsFieldsSection2[2].name
                                                            }
                                                            label={
                                                               generateCustomLabel(
                                                                  legacyFieldNamesMap[
                                                                     peopleDetailsFieldsSection2[2]
                                                                        .name
                                                                  ],
                                                                  peopleDetailsFieldsSection2[2].id,
                                                                  personIntegratedFields,
                                                                  I18n,
                                                               ) as any
                                                            }
                                                            options={notificationProfiles}
                                                            disabled={
                                                               !peopleDetailsFieldsSection2[2]
                                                                  .disabled
                                                                  ? disabledField[
                                                                       FieldMapping
                                                                          .notification_profile
                                                                    ] ??
                                                                    initialValues.type?.value ===
                                                                       "assignable"
                                                                  : true
                                                            }
                                                         />
                                                      )}{" "}
                                                </Form.Row>
                                             )}
                                          {peopleDetailsFieldsSection2.length > 0 &&
                                             (peopleDetailsFieldsSection2[3].showField ||
                                                peopleDetailsFieldsSection2[4].showField ||
                                                peopleDetailsFieldsSection2[5].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection2[3].showField && (
                                                      <Form.Checkbox
                                                         name={peopleDetailsFieldsSection2[3].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection2[3]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection2[3].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         colStart={1}
                                                         colWidth={4}
                                                         {...(peopleDetailsFieldsSection2[3]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection2[4].showField && (
                                                      <Form.Checkbox
                                                         name={peopleDetailsFieldsSection2[4].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection2[4]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection2[4].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         colStart={
                                                            peopleDetailsFieldsSection2[3].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         {...(peopleDetailsFieldsSection2[4]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection2[5].showField && (
                                                      <Form.Checkbox
                                                         name={peopleDetailsFieldsSection2[5].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection2[5]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection2[5].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection2[4]
                                                               .showField,
                                                            peopleDetailsFieldsSection2[3]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         {...(peopleDetailsFieldsSection2[5]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          <hr />
                                          {peopleDetailsFieldsSection3.length > 0 &&
                                             (peopleDetailsFieldsSection3[0].showField ||
                                                peopleDetailsFieldsSection3[1].showField ||
                                                peopleDetailsFieldsSection3[2].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection3[0].showField && (
                                                      <Form.Text
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[0].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[0]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[0].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[0]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection3[1].showField && (
                                                      <Form.Text
                                                         colStart={
                                                            peopleDetailsFieldsSection3[0].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[1].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[1]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[1].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[1]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection3[2].showField && (
                                                      <Form.Text
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection3[1]
                                                               .showField,
                                                            peopleDetailsFieldsSection3[0]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[2].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[2]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[2].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[2]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          {peopleDetailsFieldsSection3.length > 0 &&
                                             (peopleDetailsFieldsSection3[3].showField ||
                                                peopleDetailsFieldsSection3[4].showField ||
                                                peopleDetailsFieldsSection3[5].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection3[3].showField && (
                                                      <Form.Text
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[3].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[3]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[3].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[3]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection3[4].showField && (
                                                      <Form.Text
                                                         colStart={
                                                            peopleDetailsFieldsSection3[3].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[4].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[4]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[4].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[4]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection3[5].showField && (
                                                      <Form.Text
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection3[4]
                                                               .showField,
                                                            peopleDetailsFieldsSection3[3]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection3[5].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection3[5]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection3[5].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection3[5]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          <hr />
                                          {peopleDetailsFieldsSection4.length > 0 &&
                                             (peopleDetailsFieldsSection4[0].showField ||
                                                peopleDetailsFieldsSection4[1].showField ||
                                                peopleDetailsFieldsSection4[2].showField) && (
                                                <Form.Row>
                                                   {peopleDetailsFieldsSection4[0].showField && (
                                                      <Form.Currency
                                                         colStart={1}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection4[0].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection4[0]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection4[0].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         {...(peopleDetailsFieldsSection4[0]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection4[1].showField && (
                                                      <Form.Select
                                                         colStart={
                                                            peopleDetailsFieldsSection4[0].showField
                                                               ? 5
                                                               : 1
                                                         }
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection4[1].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection4[1]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection4[1].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         options={languageOptions}
                                                         {...(peopleDetailsFieldsSection4[1]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                   {peopleDetailsFieldsSection4[2].showField && (
                                                      <Form.Select
                                                         colStart={getColStart(
                                                            peopleDetailsFieldsSection4[1]
                                                               .showField,
                                                            peopleDetailsFieldsSection4[0]
                                                               .showField,
                                                         )}
                                                         colWidth={4}
                                                         name={peopleDetailsFieldsSection4[2].name}
                                                         label={
                                                            generateCustomLabel(
                                                               legacyFieldNamesMap[
                                                                  peopleDetailsFieldsSection4[2]
                                                                     .name
                                                               ],
                                                               peopleDetailsFieldsSection4[2].id,
                                                               personIntegratedFields,
                                                               I18n,
                                                            ) as any
                                                         }
                                                         options={personGenderOptions}
                                                         {...(peopleDetailsFieldsSection4[2]
                                                            .disabled
                                                            ? { disabled: true }
                                                            : {})}
                                                      />
                                                   )}
                                                </Form.Row>
                                             )}
                                          {peopleDetailsFieldsSection4.length > 0 &&
                                             (peopleDetailsFieldsSection4[3].showField ||
                                                peopleDetailsFieldsSection4[4].showField) && (
                                                <Form.Row>
                                                   {formViewMode === ActionMode.READ && (
                                                      //here using DateUtils to format the date as Globalization toolkit doesn't support date formatting with all the options

                                                      <>
                                                         {[3, 4].map((index) => {
                                                            // If the field is not shown, return null
                                                            if (
                                                               !peopleDetailsFieldsSection4[index]
                                                                  .showField
                                                            ) {
                                                               return null;
                                                            }
                                                            let colStart = 1 | 5 | 9;
                                                            if (
                                                               index === 3 &&
                                                               peopleDetailsFieldsSection4[index]
                                                                  .showField
                                                            ) {
                                                               colStart = 1;
                                                            } else if (index === 4) {
                                                               // For index 4, set colStartBase to 1 if peopleDetailsFieldsSection4[3].showField is false
                                                               colStart =
                                                                  peopleDetailsFieldsSection4[index]
                                                                     .showField &&
                                                                  !peopleDetailsFieldsSection4[3]
                                                                     .showField
                                                                     ? 1
                                                                     : 5;
                                                            }

                                                            let fieldValue =
                                                               initialValues[
                                                                  peopleDetailsFieldsSection4[index]
                                                                     .name as keyof PersonDetailFormValues
                                                               ];
                                                            fieldValue = fieldValue
                                                               ? DateUtils.formatDate(
                                                                    new Date(Number(fieldValue)),
                                                                    defaultStore.getDateFormat(), // this will give the date format from company settings
                                                                 )
                                                               : "--";
                                                            return getReadOnlyViewForDates(
                                                               PageTitle.PEOPLE_DETAILS,
                                                               colStart,
                                                               4,
                                                               I18n,
                                                               peopleDetailsFieldsSection4[index],
                                                               personIntegratedFields,
                                                               fieldValue,
                                                            );
                                                         })}
                                                      </>
                                                   )}
                                                   {formViewMode === ActionMode.UPDATE && (
                                                      <>
                                                         {peopleDetailsFieldsSection4[3]
                                                            .showField && (
                                                            <Form.DateSelect
                                                               colStart={1}
                                                               colWidth={4}
                                                               name={
                                                                  peopleDetailsFieldsSection4[3]
                                                                     .name
                                                               }
                                                               label={
                                                                  generateCustomLabel(
                                                                     legacyFieldNamesMap[
                                                                        peopleDetailsFieldsSection4[3]
                                                                           .name
                                                                     ],
                                                                     peopleDetailsFieldsSection4[3]
                                                                        .id,
                                                                     personIntegratedFields,
                                                                     I18n,
                                                                  ) as any
                                                               }
                                                               {...(peopleDetailsFieldsSection4[3]
                                                                  .disabled
                                                                  ? { disabled: true }
                                                                  : {})}
                                                            />
                                                         )}
                                                         {peopleDetailsFieldsSection4[4]
                                                            .showField && (
                                                            <Form.DateSelect
                                                               colStart={
                                                                  peopleDetailsFieldsSection4[3]
                                                                     .showField
                                                                     ? 5
                                                                     : 1
                                                               }
                                                               colWidth={4}
                                                               name={
                                                                  peopleDetailsFieldsSection4[4]
                                                                     .name
                                                               }
                                                               label={
                                                                  generateCustomLabel(
                                                                     legacyFieldNamesMap[
                                                                        peopleDetailsFieldsSection4[4]
                                                                           .name
                                                                     ],
                                                                     peopleDetailsFieldsSection4[4]
                                                                        .id,
                                                                     personIntegratedFields,
                                                                     I18n,
                                                                  ) as any
                                                               }
                                                               {...(peopleDetailsFieldsSection4[4]
                                                                  .disabled
                                                                  ? { disabled: true }
                                                                  : {})}
                                                            />
                                                         )}
                                                      </>
                                                   )}
                                                </Form.Row>
                                             )}

                                          {peopleDetailsFieldsSection5.length > 0 &&
                                             (peopleDetailsFieldsSection5[0].showField ||
                                                peopleDetailsFieldsSection5[1].showField ||
                                                peopleDetailsFieldsSection5[2].showField) && (
                                                <>
                                                   {" "}
                                                   <hr />
                                                   <Form.Row>
                                                      {peopleDetailsFieldsSection5[0].showField && (
                                                         <Form.Text
                                                            colStart={1}
                                                            colWidth={4}
                                                            name={
                                                               peopleDetailsFieldsSection5[0].name
                                                            }
                                                            label={
                                                               generateCustomLabel(
                                                                  legacyFieldNamesMap[
                                                                     peopleDetailsFieldsSection5[0]
                                                                        .name
                                                                  ],
                                                                  peopleDetailsFieldsSection5[0].id,
                                                                  personIntegratedFields,
                                                                  I18n,
                                                               ) as any
                                                            }
                                                            {...(peopleDetailsFieldsSection5[0]
                                                               .disabled
                                                               ? { disabled: true }
                                                               : {})}
                                                         />
                                                      )}
                                                      {peopleDetailsFieldsSection5[1].showField && (
                                                         <Form.Text
                                                            colStart={
                                                               peopleDetailsFieldsSection5[0]
                                                                  .showField
                                                                  ? 5
                                                                  : 1
                                                            }
                                                            colWidth={4}
                                                            name={
                                                               peopleDetailsFieldsSection5[1].name
                                                            }
                                                            label={
                                                               generateCustomLabel(
                                                                  legacyFieldNamesMap[
                                                                     peopleDetailsFieldsSection5[1]
                                                                        .name
                                                                  ],
                                                                  peopleDetailsFieldsSection5[1].id,
                                                                  personIntegratedFields,
                                                                  I18n,
                                                               ) as any
                                                            }
                                                            {...(peopleDetailsFieldsSection5[1]
                                                               .disabled
                                                               ? { disabled: true }
                                                               : {})}
                                                         />
                                                      )}
                                                      {peopleDetailsFieldsSection5[2].showField && (
                                                         <Form.Text
                                                            colStart={getColStart(
                                                               peopleDetailsFieldsSection5[1]
                                                                  .showField,
                                                               peopleDetailsFieldsSection5[0]
                                                                  .showField,
                                                            )}
                                                            colWidth={4}
                                                            name={
                                                               peopleDetailsFieldsSection5[2].name
                                                            }
                                                            label={
                                                               generateCustomLabel(
                                                                  legacyFieldNamesMap[
                                                                     peopleDetailsFieldsSection5[2]
                                                                        .name
                                                                  ],
                                                                  peopleDetailsFieldsSection5[2].id,
                                                                  personIntegratedFields,
                                                                  I18n,
                                                               ) as any
                                                            }
                                                            {...(peopleDetailsFieldsSection5[2]
                                                               .disabled
                                                               ? { disabled: true }
                                                               : {})}
                                                         />
                                                      )}
                                                   </Form.Row>
                                                </>
                                             )}

                                          {customFields && (
                                             <>
                                                <hr />
                                                {renderFormFields(
                                                   customFields as PersonDetailFields[],
                                                )}
                                             </>
                                          )}
                                       </Box>
                                    </Form.Form>
                                 </Card>
                                 <Card style={{ marginBottom: "5px" }}>
                                    <Box padding="md">
                                       <H2>
                                          {I18n.t(
                                             "views.company.workforce_planning.people.profile_photo",
                                          )}
                                       </H2>
                                       <PhotoUploader
                                          imgUrl={peopleData?.data.profile_pic_url ?? ""}
                                          onUploadPhoto={handleUploadPhoto}
                                          disableUpload={false} // here editing permission can be passed, is the user having privilege of editing people details
                                       />
                                    </Box>
                                 </Card>
                                 {canViewPeopleTags && (
                                    <DetailsTagsCard
                                       detailsData={peopleData}
                                       refetchData={refetch}
                                       loading={peopleDataLoading}
                                       updateData={updatePeopleData}
                                       pageType={PageType.PERSON}
                                       refreshTable={onUpdatePeople}
                                    ></DetailsTagsCard>
                                 )}
                                 {canViewPeopleAttachments && (
                                    <DetailsAttachmentsCard
                                       attachmentData={peopleData}
                                       refetchData={refetch}
                                       loading={peopleDataLoading}
                                       updateData={updatePeopleData}
                                       pageType={PageType.PERSON}
                                    />
                                 )}
                                 {qrCodeEnabled && peopleQrCodeEnabled && (
                                    <QRCodeCard
                                       entityAbbreviation={EntityAbbreviation.PERSON}
                                       companyQrId={companyData?.data.qr_id}
                                       entityQrId={peopleData?.data.qr_id}
                                       entityTitle={getFullName(peopleData?.data.name)}
                                       entitySubtitle={peopleData?.data.employee_number}
                                       imageDetailsHelpText={I18n.t(
                                          "views.company.workforce_planning.people.qr_code.image_details_help_text",
                                       )}
                                    />
                                 )}
                              </Page.Body>
                              {formViewMode === ActionMode.UPDATE && (
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="space-between" alignItems="center">
                                          <Required showLabel />
                                          <FlexList space="sm">
                                             <Button
                                                variant="tertiary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                   setFormViewMode(ActionMode.READ);
                                                   resetForm();
                                                   //reset group field
                                                   setDisabledField((prevDisabledFields) => {
                                                      const updatedFields = {
                                                         ...prevDisabledFields,
                                                      };
                                                      delete updatedFields.groups;
                                                      return updatedFields;
                                                   });
                                                }}
                                             >
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                             >
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              )}
                           </>
                        )}
                     </Form>
                  )}
                  {activeTab === personDetailTabsState[1].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <DetailsNotesCard
                           loading={peopleDataLoading}
                           refetchData={refetch}
                           noteType={NotesType.PERSON}
                           notesData={peopleData}
                           contextId={personId}
                        />
                     </Page.Body>
                  )}
                  {activeTab === personDetailTabsState[2].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <DetailsTimeOffCard
                           loading={peopleDataLoading}
                           refetchData={refetch}
                           timeOffData={peopleData}
                           contextId={personId}
                           pageType={PageType.PERSON}
                        />
                     </Page.Body>
                  )}
                  {activeTab === personDetailTabsState[3].id && (
                     <Page.Body style={{ width: "100%", marginBottom: "auto" }}>
                        <PersonDetailsActivities personId={personId} />
                     </Page.Body>
                  )}
               </>
            )}
         </Page.Main>
      </Page>
   );
};
