import {
   TIME_ZONES_MAP,
   timeOptions,
   timeZoneOptions,
   timeZoneSearchComparator,
} from "@/lib/utils/timezones";
import type { CustomField, IntegratedField } from "@/react/prop-types";
import { ProjectStore } from "@/stores/project-store.core";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { CustomFieldType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { GetIntegratedFieldsResponse } from "@laborchart-modules/lc-core-api/dist/api/company/get-integrated-fields";
import { Lock, Pencil } from "@procore/core-icons";
import {
   Box,
   Button,
   Card,
   Flex,
   FlexList,
   Form,
   H1,
   Page,
   Spinner,
   Tearsheet,
   useI18nContext,
} from "@procore/core-react";
import { useRowSelectionState } from "@procore/data-table";
import type { TableApi } from "@procore/data-table";
import { useToastAlertContext } from "@procore/toast-alert";
import { isAfter } from "date-fns";
import type { FormikProps } from "formik";
import React, { useEffect, useState } from "react";
import { ErrorBanner } from "../../error-banner";
import {
   PROJECT_STATUS_OPTIONS,
   type EditProjectFormValues,
   type ProjectListData,
} from "../../project-list/project-list-prop-types";
import { useGetGroupOptionsQuery } from "../../common/queries/queries";
import { bulkEditProjectSchema } from "./validation-schemas";
import { ColorPicker } from "../../common/components/color-picker";

export type BulkEditProjectTearsheetPropTypes = {
   tableApi?: TableApi;
   integratedFields: GetIntegratedFieldsResponse;
   customFields: CustomField[];
};

export const BulkEditProjectTearsheet = (props: BulkEditProjectTearsheetPropTypes) => {
   const { tableApi, integratedFields, customFields } = props;
   const I18n = useI18nContext();
   const [isOpen, setIsOpen] = useState(false);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(false);
   const { showToast } = useToastAlertContext();
   const selectedRows = useRowSelectionState().map((x: any) => x.data);
   const { data: groupOptions } = useGetGroupOptionsQuery();
   const validationSchema = bulkEditProjectSchema(I18n, customFields);
   const [projectIntegratedFields, setProjectIntegratedFields] = useState<IntegratedField[]>([]);

   const initialValues: EditProjectFormValues = {
      job_number: "",
      status: undefined,
      group_ids: [],
      start_date: null,
      est_end_date: null,
      daily_start_time: undefined,
      daily_end_time: undefined,
      timezone: undefined,
      address_1: "",
      address_2: "",
      city_town: "",
      state_province: "",
      country: "",
      zipcode: "",
      bid_rate: null,
      customer_name: "",
      project_type: "",
      percent_complete: null,
   };

   useEffect(() => {
      if (integratedFields) {
         if (Array.isArray(integratedFields.data.projects_integrated_fields)) {
            setProjectIntegratedFields(integratedFields.data.projects_integrated_fields);
         }
      }
   }, [integratedFields]);

   const handleSubmit = async (values: EditProjectFormValues) => {
      const basePayload: any = {
         job_number: values.job_number,
         status: values.status?.id,
         group_ids: groupOptions!
            .map(({ id }) => ({
               [id]: Boolean(values.group_ids?.find(({ id: groupId }) => groupId === id)),
            }))
            .reduce((acc, next) => ({ ...acc, ...next })),
         start_date: values.start_date ? new Date(values.start_date).getTime() : null,
         est_end_date: values.est_end_date ? new Date(values.est_end_date).getTime() : null,
         daily_start_time: values.daily_start_time?.id,
         daily_end_time: values.daily_end_time?.id,
         timezone: values.timezone ? TIME_ZONES_MAP[values.timezone.id] : null,
         address_1: values.address_1,
         address_2: values.address_2,
         city_town: values.city_town,
         state_province: values.state_province,
         country: values.country,
         zipcode: values.zipcode,
         bid_rate: values.bid_rate,
         color: values.color,
         customer_name: values.customer_name,
         percent_complete: +(values.percent_complete ?? 0),
         project_type: values.project_type,
      };

      // Remove group_ids if all groups are unchecked, this to prevent sending empty group_ids
      // and remove the groups from the projects selected
      const notGroupsSelected = Object.values(basePayload.group_ids).every((value) => !value);

      Object.entries(basePayload).forEach(([fieldName]) => {
         // Removing integrated fields from basePayload as they are not editable
         if (isIntegratedField(fieldName)) {
            delete basePayload[fieldName];
         }
         // Remove empty fields from basePayload
         if (
            basePayload[fieldName] === null ||
            basePayload[fieldName] === "" ||
            basePayload[fieldName] === undefined ||
            (typeof basePayload[fieldName] === "object" &&
               Object.keys(basePayload[fieldName]).length === 0)
         ) {
            delete basePayload[fieldName];
         }
      });

      // Adding custom fields to basePayload updating the values as per the custom field type
      if (customFields) {
         basePayload.custom_fields = processCustomFields(customFields, values);
      }

      const payload = selectedRows.map((x: ProjectListData) => ({
         id: x.id,
         ...basePayload,
         group_ids: notGroupsSelected
            ? groupOptions!
                 .map(({ id }) => ({
                    [id]: Boolean(x?.group_ids?.find((groupId) => groupId === id)),
                 }))
                 .reduce((acc, next) => ({ ...acc, ...next }))
            : basePayload.group_ids,
      }));

      try {
         setLoading(true);
         await ProjectStore.updateProjectsStream(payload).stream;
         showToast.success(
            I18n.t("views.company.workforce_planning.projects.modals.bulk_edit.success", {
               count: selectedRows.length,
            }),
         );
         tableApi?.refreshServerSide({});
         setIsOpen(false);
         setLoading(false);
      } catch (err) {
         setLoading(false);
         setError(true);
      }
   };

   const handleClose = () => {
      setIsOpen(false);
      setError(false);
      setLoading(false);
   };

   function isIntegratedField(field: string) {
      return projectIntegratedFields.some(
         (integratedField: IntegratedField) =>
            integratedField.property === field && integratedField.locked,
      );
   }

   function getColStartBase(index: number): 1 | 5 | 9 {
      switch (index) {
         case 0:
            return 1;
         case 1:
            return 5;
         default:
            return 9;
      }
   }

   function mapFieldToComponent(field: CustomField, index: number) {
      let FormComponent = null;
      let additionalProps = {};
      const colStartBase = getColStartBase(index);

      switch (field.type) {
         case CustomFieldType.DATE:
            FormComponent = Form.DateSelect;
            break;
         case CustomFieldType.NUMBER:
            FormComponent = Form.Number;
            break;
         case CustomFieldType.TEXT:
            FormComponent = Form.Text;
            break;
         case CustomFieldType.SELECT:
         case CustomFieldType.MULTI_SELECT:
            FormComponent = field.type === CustomFieldType.SELECT ? Form.Select : Form.MultiSelect;
            additionalProps = {
               options: field.values?.map((value) => ({
                  id: `${field.name}_${value}`,
                  label: value,
               })),
               onSearch: field.type === CustomFieldType.SELECT ? false : undefined,
            };
            break;
         case CustomFieldType.PARAGRAPH:
            FormComponent = Form.TextArea;
            break;
         case CustomFieldType.BOOL:
            FormComponent = Form.Checkbox;
            break;
         case CustomFieldType.CURRENCY:
            FormComponent = Form.Currency;
            break;
      }

      if (isIntegratedField(field.id) || field.integration_only) {
         additionalProps = { ...additionalProps, disabled: true };
      }

      return FormComponent ? (
         <React.Fragment key={`custom-field-fragment-outside-${field.id}-${index}`}>
            <FormComponent
               key={`custom-field-${field.id}`}
               colStart={colStartBase}
               colWidth={4}
               name={field.name}
               // @ts-expect-error: passing element as needed to append lock icon
               label={
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <span>{field.name.toString()}</span>
                     {(isIntegratedField(field.id) || field.integration_only) && (
                        <Lock size="sm" style={{ marginLeft: "3px" }} />
                     )}
                  </div>
               }
               {...additionalProps}
            />
         </React.Fragment>
      ) : null;
   }

   function renderCustomFields(fields: CustomField[]) {
      return fields
         .filter((field) => field.type !== CustomFieldType.HEX_COLOR)
         .reduce((acc: React.ReactElement[], field, index, filteredFields) => {
            // Create a row every 3 fields
            if (index % 3 === 0) {
               const rowFields = filteredFields.slice(index, index + 3);

               const validComponents = rowFields.map(mapFieldToComponent).filter(Boolean); // Simple filter to remove nulls

               if (validComponents.length > 0) {
                  const key = validComponents.reduce((prev, curr) => prev + "-" + curr?.key, "");

                  acc.push(<Form.Row key={key}>{validComponents}</Form.Row>);
               }
            }
            return acc;
         }, []);
   }

   // Function to process custom fields
   function processCustomFields(
      customFields: CustomField[],
      values: Record<string, any>,
   ): { [key: string]: any } {
      return customFields.reduce((acc, customField) => {
         // Actualizar el valor del campo personalizado si existe en el objeto `values`
         const updatedValue =
            customField.name in values ? values[customField.name] : customField.value;
         // Procesar el campo personalizado si no es un campo integrado y no es solo de integración
         if (!(isIntegratedField(customField.id) || customField.integration_only)) {
            let processedValue;
            switch (customField.type) {
               case CustomFieldType.DATE:
                  processedValue = updatedValue
                     ? getDetachedDay(new Date(updatedValue))
                     : undefined;
                  break;
               case CustomFieldType.MULTI_SELECT:
                  processedValue =
                     updatedValue && updatedValue.length > 0
                        ? updatedValue.map((v: any) => v.label)
                        : undefined;
                  break;
               case CustomFieldType.SELECT:
                  processedValue = updatedValue ? updatedValue.label : undefined;
                  break;
               default:
                  processedValue = updatedValue;
                  break;
            }
            if (processedValue !== undefined) {
               acc[customField.id as string] = processedValue;
            }
         }
         return acc;
      }, {} as { [key: string]: any });
   }

   return (
      <>
         <Button
            onClick={() => setIsOpen(true)}
            icon={<Pencil />}
            variant="tertiary"
            data-testid="edit-btn"
         />
         <Tearsheet open={isOpen} onClose={handleClose} data-testid="tearsheet">
            <Spinner loading={loading}>
               <Page>
                  <Page.Main
                     style={{
                        width: 800,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                     }}
                  >
                     <Page.Header>
                        {error && (
                           <Page.Banner style={{ width: "400px" }}>
                              <ErrorBanner
                                 title={I18n.t("views.company.workforce_planning.error")}
                                 content={I18n.t(
                                    "views.company.workforce_planning.projects.modals.bulk_edit.error",
                                 )}
                              />
                           </Page.Banner>
                        )}
                        <Page.Title>
                           <H1>
                              {I18n.t(
                                 "views.company.workforce_planning.projects.modals.bulk_edit.header",
                              )}
                           </H1>
                        </Page.Title>
                     </Page.Header>

                     <Form
                        view="update"
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                     >
                        {/* @ts-expect-error Core React Form uses Formik under the hood; this is valid implementation */}
                        {(formikProps: FormikProps<any>) => {
                           return (
                              <Form.Form>
                                 <Page.Body style={{ width: "100%", marginBottom: 20 }}>
                                    <Card>
                                       <Box padding="md">
                                          <Form.Row>
                                             <Form.Text
                                                name="address_1"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.address",
                                                )}
                                                disabled={isIntegratedField("address_1")}
                                             />
                                             <Form.Text
                                                name="address_2"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.address_2",
                                                )}
                                                disabled={isIntegratedField("address_2")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Text
                                                name="city_town"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.city",
                                                )}
                                                disabled={isIntegratedField("city_town")}
                                             />
                                             <Form.Text
                                                name="country"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.country",
                                                )}
                                                disabled={isIntegratedField("country")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Field
                                                name="color"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.color",
                                                )}
                                                disabled={isIntegratedField("color")}
                                                as={ColorPicker}
                                             />
                                             <Form.Text
                                                name="customer_name"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.customer",
                                                )}
                                                disabled={isIntegratedField("customer_name")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="daily_start_time"
                                                options={timeOptions}
                                                colStart={1}
                                                colWidth={6}
                                                onSearch={false}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.daily_start_time",
                                                )}
                                                disabled={isIntegratedField("daily_start_time")}
                                             />
                                             <Form.Select
                                                name="daily_end_time"
                                                options={timeOptions}
                                                colStart={7}
                                                colWidth={6}
                                                onSearch={false}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.daily_end_time",
                                                )}
                                                disabled={isIntegratedField("daily_end_time")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.DateSelect
                                                name="start_date"
                                                data-testid="start-date"
                                                colStart={1}
                                                colWidth={6}
                                                onChange={(date) => {
                                                   // Manually set end date when the user selects a start date if end date is not set.
                                                   // This mimics legacy behavior so new modal does not add clicks.
                                                   formikProps.setFieldValue("start_date", date);
                                                   if (!formikProps.values.end_date && date) {
                                                      formikProps.setFieldValue("end_date", date);
                                                   }
                                                }}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.start_date",
                                                )}
                                                disabled={isIntegratedField("start_date")}
                                             />
                                             <Form.DateSelect
                                                name="est_end_date"
                                                colStart={7}
                                                colWidth={6}
                                                disabledDate={(date) => {
                                                   const { start_date } = formikProps.values;
                                                   return isAfter(start_date, date);
                                                }}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.est_end_date",
                                                )}
                                                disabled={isIntegratedField("est_end_date")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Number
                                                name="bid_rate"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.est_avg_rate",
                                                )}
                                                disabled={isIntegratedField("bid_rate")}
                                             />
                                             <Form.MultiSelect
                                                name="group_ids"
                                                colStart={7}
                                                colWidth={6}
                                                options={groupOptions ?? []}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.groups",
                                                )}
                                                disabled={isIntegratedField("group_ids")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Text
                                                name="percent_complete"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.percent_complete",
                                                )}
                                                disabled={isIntegratedField("percent_complete")}
                                             />
                                             <Form.Text
                                                name="zipcode"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.postal",
                                                )}
                                                disabled={isIntegratedField("zipcode")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Text
                                                name="job_number"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.project_number",
                                                )}
                                                disabled={isIntegratedField("job_number")}
                                             />
                                             <Form.Text
                                                name="project_type"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.project_type",
                                                )}
                                                disabled={isIntegratedField("project_type")}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Text
                                                name="state_province"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.state",
                                                )}
                                                disabled={isIntegratedField("state_province")}
                                             />
                                             <Form.PillSelect
                                                name="status"
                                                colStart={7}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.status",
                                                )}
                                                disabled={isIntegratedField("status")}
                                                options={PROJECT_STATUS_OPTIONS.map((status) => ({
                                                   ...status,
                                                   label: I18n.t(status.label),
                                                }))}
                                             />
                                          </Form.Row>
                                          <Form.Row>
                                             <Form.Select
                                                name="timezone"
                                                colStart={1}
                                                colWidth={6}
                                                label={I18n.t(
                                                   "views.company.workforce_planning.projects.timezone",
                                                )}
                                                disabled={isIntegratedField("timezone")}
                                                options={timeZoneOptions}
                                                searchComparator={timeZoneSearchComparator}
                                             />
                                          </Form.Row>
                                          {customFields && (
                                             <>
                                                <hr />
                                                {renderCustomFields(customFields)}
                                             </>
                                          )}
                                       </Box>
                                    </Card>
                                 </Page.Body>
                                 <Page.Footer>
                                    <Box padding="md">
                                       <Flex justifyContent="flex-end" alignItems="center">
                                          <FlexList space="md">
                                             <Button variant="tertiary" onClick={handleClose}>
                                                {I18n.t("views.company.workforce_planning.cancel")}
                                             </Button>
                                             <Button variant="primary" type="submit">
                                                {I18n.t("views.company.workforce_planning.save")}
                                             </Button>
                                          </FlexList>
                                       </Flex>
                                    </Box>
                                 </Page.Footer>
                              </Form.Form>
                           );
                        }}
                     </Form>
                  </Page.Main>
               </Page>
            </Spinner>
         </Tearsheet>
      </>
   );
};
