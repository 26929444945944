import { Box, Link } from "@procore/core-react";
import type { ReactElement } from "react";
import React from "react";
import type { DataTableCellRendererProps, ColumnDefinition } from "@procore/data-table";
import { withDataTableRenderer } from "@procore/data-table";
import { onPersonDetailClick, usePersonTearsheet } from "../../tearsheets/people/people-tearsheet";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import { useGroupContext } from "@/react/providers/group-context-provider";

type Role = {
   label: string;
   personId: string;
};

export const ProjectRolesCell: React.FunctionComponent<ProjectRolesRendererProps> = (
   props,
): ReactElement | null => {
   const { value } = props;
   const { dispatch: personTearsheetDispatch } = usePersonTearsheet();
   const { groupId } = useGroupContext();

   /*using below LD flag to switch between navigation to legacy people details page or people details tearsheet
   this check can be removed once we have the people list helix page live
   **/
   const peopleListReactEnabled = LaunchDarklyClient.getFlagValue("people-list-react-enabled");

   if (!value) {
      return null;
   }

   return (
      <div>
         {value.map((role: Role) => (
            <Box key={role.personId}>
               {peopleListReactEnabled ? (
                  <Link
                     onClick={() => {
                        onPersonDetailClick(personTearsheetDispatch, role.personId);
                     }}
                  >
                     {role.label}
                  </Link>
               ) : (
                  <Link href={`/groups/${groupId}/people/${role.personId}`}>{role.label}</Link>
               )}
            </Box>
         ))}
      </div>
   );
};

export type ProjectRolesColumnDefinition = ColumnDefinition<string, typeof ProjectRolesCell>;

export type ProjectRolesRendererProps = DataTableCellRendererProps<
   Role[] | undefined,
   ProjectRolesColumnDefinition
>;

export const ProjectRolesCellRenderer = withDataTableRenderer(ProjectRolesCell, "input");
