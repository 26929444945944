import type { RefObject } from "react";
import React from "react";
import type { TaskModel } from "@bryntum/gantt-thin";
import { TaskType } from "./prop-types";
import { Link } from "@procore/core-react";
import { EllipsisVertical } from "@procore/core-icons";
import { createProjectTask, rawRequestToTask } from "./gantt-utils";
import { GanttViewMode } from "./gantt-control-panel";
import type { GanttTearsheetRef } from "./gantt-tearsheets";
import type { BryntumGantt } from "@bryntum/gantt-react-thin";

export const taskRenderer = ({
   record,
   cellElement,
   ganttViewMode,
   canViewPeople,
   canViewProject,
   personDetailsTearsheetRef,
   projectDetailsTearsheetRef,
   ganttRef,
   expandedTasks,
}: {
   record: any;
   cellElement: any;
   ganttViewMode: GanttViewMode;
   canViewPeople: boolean;
   canViewProject: boolean;
   personDetailsTearsheetRef: RefObject<GanttTearsheetRef>;
   projectDetailsTearsheetRef: RefObject<GanttTearsheetRef>;
   ganttRef: RefObject<BryntumGantt>;
   expandedTasks: any;
}) => {
   if (!cellElement) return;
   const taskType = record.getData("type");
   const emptyClassName = "no-children";

   // Doesn't render REQUEST tasks in the Resources view
   if (ganttViewMode === GanttViewMode.RESOURCES && taskType === TaskType.REQUEST) {
      return;
   }
   if (
      [TaskType.PROJECT, TaskType.CATEGORY, TaskType.SUBCATEGORY].includes(taskType) &&
      (record.children == undefined || (record.children as any[]).length === 0)
   ) {
      cellElement.classList.add(emptyClassName);
   }

   const onEllipsisClick = (event: any) => {
      cellElement.dispatchEvent(
         new MouseEvent("contextmenu", {
            bubbles: true,
            cancelable: true,
            view: window,
            clientX: event.clientX, // Pass the same mouse position
            clientY: event.clientY,
         }),
      );
   };

   cellElement.setAttribute("data-task-type", taskType);

   if (
      [TaskType.CATEGORY, TaskType.SUBCATEGORY, TaskType.ASSIGNMENT, TaskType.REQUEST].includes(
         taskType,
      )
   ) {
      cellElement.classList.add("gantt-grid-row-ellipsis__container");
      if (taskType === TaskType.ASSIGNMENT) {
         return (
            <GanttAssignmentCell
               record={record}
               canViewPeople={canViewPeople}
               personDetailsTearsheetRef={personDetailsTearsheetRef}
               onEllipsisClick={onEllipsisClick}
            />
         );
      }
      return <GanttCell record={record} taskType={taskType} onEllipsisClick={onEllipsisClick} />;
   } else if (
      [TaskType.ASSIGNMENT, TaskType.REQUEST, TaskType.EQUIPMENT_ASSIGNMENT].includes(taskType)
   ) {
      return record.getData("name");
   }
   return (
      <GanttProjectNameCell
         record={record}
         canViewProject={canViewProject}
         projectDetailsTearsheetRef={projectDetailsTearsheetRef}
         ganttRef={ganttRef}
         expandedTasks={expandedTasks}
         onEllipsisClick={onEllipsisClick}
      />
   );
};

export const GanttProjectNameCell = (props: {
   record: any;
   canViewProject: boolean;
   projectDetailsTearsheetRef: RefObject<GanttTearsheetRef>;
   ganttRef: RefObject<BryntumGantt>;
   expandedTasks: any;
   onEllipsisClick: (event: any) => void;
}) => {
   const {
      record,
      canViewProject,
      projectDetailsTearsheetRef,
      ganttRef,
      expandedTasks,
      onEllipsisClick,
   } = props;
   return (
      <div
         style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            justifyContent: "space-between",
         }}
      >
         <div
            style={{
               overflow: "hidden",
               textOverflow: "ellipsis",
            }}
         >
            {canViewProject ? (
               <Link
                  onClick={
                     /* istanbul ignore next */
                     () => {
                        const projectId = String(record.id);
                        projectDetailsTearsheetRef.current?.handleOpenTearsheet({
                           projectId,
                           callback: async ({ updatedProject, requests, jobTitles }: any) => {
                              await (
                                 ganttRef.current?.instance as any
                              ).crudManager.suspendAutoSync();

                              const taskFromStore = ganttRef.current?.instance.taskStore.getById(
                                 updatedProject.id,
                              );

                              if (!updatedProject.est_end_date) {
                                 updatedProject.est_end_date = taskFromStore?.getData("endDate");
                              }
                              taskFromStore?.set(createProjectTask(updatedProject, expandedTasks));
                              // If requests were created via Labor Plan, add them as tasks
                              if (requests) {
                                 requests.forEach(async (r: any) => {
                                    const requestTask = rawRequestToTask({
                                       request: r,
                                       project: updatedProject,
                                       jobTitles: jobTitles,
                                    });

                                    let parentTaskId: string;

                                    // Refererence the actual request object, the rawRequestToTask method returns cost_code_id/label_id
                                    // but labor plans requests call it category_id/subcategory_id
                                    if (r.subcategory_id) {
                                       parentTaskId = r.subcategory_id;
                                    } else if (r.category_id) {
                                       parentTaskId = r.category_id;
                                    } else {
                                       parentTaskId = updatedProject.id;
                                    }

                                    const parentTask =
                                       ganttRef.current?.instance.taskStore.getById(parentTaskId);
                                    const newTaskRecord =
                                       await ganttRef.current?.instance.addSubtask(
                                          parentTask as TaskModel, // Parent task (Project, Category, or Subcategory)
                                          {
                                             data: requestTask,
                                          },
                                       );
                                    // Using this method to update the endDate because is updating the schedule otherwise the endDate change is not reflected
                                    await newTaskRecord?.setEndDate(
                                       new Date(requestTask!.endDate!),
                                    );
                                 });
                              }

                              await (ganttRef.current?.instance as any).crudManager.clearChanges();
                              await (
                                 ganttRef.current?.instance as any
                              ).crudManager.resumeAutoSync();
                           },
                        });
                     }
                  }
                  className="gantt-project-name"
                  data-testid="gantt-project-name"
                  aria-disabled={!canViewProject}
               >
                  {record.getData("name")}
               </Link>
            ) : (
               <span>{record.getData("name")}</span>
            )}
         </div>
         <EllipsisVertical
            size="sm"
            className="gantt-grid-row-ellipsis__project"
            data-testid="gantt-grid-row-ellipsis"
            onClick={onEllipsisClick}
         />
      </div>
   );
};

const GanttAssignmentCell = (props: {
   canViewPeople: boolean;
   personDetailsTearsheetRef: RefObject<GanttTearsheetRef>;
   onEllipsisClick: (event: any) => void;
   record: any;
}) => {
   const { record, canViewPeople, personDetailsTearsheetRef, onEllipsisClick } = props;
   return (
      <div
         style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            justifyContent: "space-between",
            alignItems: "center",
         }}
      >
         <span
            style={{
               overflow: "hidden",
               textOverflow: "ellipsis",
            }}
         >
            {canViewPeople ? (
               <Link
                  onClick={() => {
                     const personId = String(record.getData("resourceId"));
                     personDetailsTearsheetRef.current?.handleOpenTearsheet({
                        projectId: "",
                        personId,
                        callback: () => {},
                     });
                  }}
                  className="gantt-person-name-link"
                  data-testid="gantt-person-name"
                  aria-disabled={!canViewPeople}
               >
                  {record.getData("name")}
               </Link>
            ) : (
               <span>{record.getData("name")}</span>
            )}
         </span>
         <EllipsisVertical
            size="sm"
            className="gantt-grid-row-ellipsis__resource"
            onClick={onEllipsisClick}
         />
      </div>
   );
};

const GanttCell = (props: {
   taskType: TaskType;
   record: any;
   onEllipsisClick: (event: any) => void;
}) => {
   const { taskType, record, onEllipsisClick } = props;
   return (
      <div
         style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr auto",
            justifyContent: "space-between",
            alignItems: "center",
         }}
      >
         <span
            style={{
               overflow: "hidden",
               textOverflow: "ellipsis",
            }}
         >
            {taskType === TaskType.REQUEST ? (
               <div className="requestPill">{record.getData("name")}</div>
            ) : (
               record.getData("name")
            )}
         </span>
         <EllipsisVertical
            size="sm"
            className="gantt-grid-row-ellipsis__resource"
            onClick={onEllipsisClick}
         />
      </div>
   );
};
