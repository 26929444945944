import type { OauthAppData } from "@/models/oauth-app";
import type { CustomFieldType } from "@laborchart-modules/common/dist/rethink/schemas/common/custom-field-type";
import type {
   SerializedTagInstance,
   SerializedTag,
} from "@laborchart-modules/common/dist/rethink/serializers";
import type { SerializedAttachment } from "@laborchart-modules/common/dist/rethink/serializers/attachment-serializer";
import type { IServerFilter } from "@procore/data-table";
import type { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { ExportFormValues } from "./components/data-table/DataExport/export-modal";
import type { ValueSet } from "@/models/value-set";

export type ShiftProjectPropTypes = {
   projectId: string;
   startDate: Date;
   projectName: string;
   refreshRootPage: () => void;
   I18n: any;
};

export type OauthAppsPropTypes = {
   getData: () => Promise<OauthAppData[] | undefined>;
   updateData: (oauthApp: Partial<OauthAppData>) => Promise<OauthAppData | undefined>;
   createData: (oauthApp: Partial<OauthAppData>) => Promise<OauthAppData | undefined>;
   deleteData: (oauthAppId: string) => Promise<string | undefined>;
};

export type SelectValue<T> = {
   id: T;
   value: T;
   label: string;
   onClick?: () => void;
};

export type PillValue<T> = {
   id: string;
   value: T;
   label: string;
   color: "blue" | "gray" | "green" | "red" | "yellow";
};

export type SortModel = {
   field: string;
   sort: "asc" | "desc";
   fieldId?: string;
   entity?: EntityTypes;
};

export type ColumnMap = { [key: string]: any };

export type CustomField = {
   can_filter: boolean;
   company_id: string;
   description: string | null;
   type: ExtendedFieldType;
   id: string;
   integration_name: string;
   integration_only: boolean;
   name: string;
   on_assignments: boolean;
   on_people: boolean;
   on_projects: boolean;
   on_requests: boolean;
   sort_by: string | null;
   values: any[] | null;
   value?: any;
   entity?: EntityTypes;
};
export type CustomFieldProjectType = {
   field_id: string;
   integration_name: string;
   name: string;
   type: CustomFieldType;
   value?: any;
};
export type CustomFieldPeopleType = {
   field_id: string;
   integration_name: string;
   name: string;
   type: CustomFieldType;
   value?: any;
};

export type HexColor = {
   label: string;
   color: string;
   shape: string;
};
//created this interface by extending IServerFilter, as new fieldId, fieldType is added to the filter for custom_field filtering
export interface ProjectListServerFilter extends IServerFilter {
   fieldId?: string;
   fieldType?: string;
}
export type IntegratedField = {
   name: string;
   locked: boolean;
   property: string;
};
export type Tab = {
   id: number;
   label: string;
   visible: boolean;
};

export type TabsProps = {
   tabs: Tab[];
   activeTab: number;
   setActiveTab: (tabId: number) => void;
};

export type TagInstance = Array<
   SerializedTagInstance & {
      tag: SerializedTag;
      attachments: SerializedAttachment[];
   }
>;

export type FileDownloadModalProps = {
   open: boolean;
   onClose: () => void;
   fileName: string;
   uploadedOn: string;
   attachmentId: string;
   mimeType: string;
};

export type Mode = "read" | "edit" | "delete" | "save";

export type AttachmentLinkProps = {
   attachments: SerializedAttachment[];
   onLinkClick: (attachment: SerializedAttachment) => void;
   mode?: Mode;
   onAttachmentDelete?: (attachmentId: string) => void;
};

export enum ActionMode {
   READ = "read",
   EDIT = "edit",
   DELETE = "delete",
   SAVE = "save",
   CREATE = "create",
   UPDATE = "update",
}

export type TagOption = {
   id: string;
   label: string;
   name: string;
   color: string;
   categories: string[];
   group_ids: string[];
   globally_accessible: boolean;
   require_expr_date: boolean;
};

export enum PageTitle {
   PEOPLE_LIST = "People List",
   PROJECT_LIST = "Project List",
   PEOPLE_DETAILS = "People Details",
}

export type TagInstances = {
   [tagId: string]: {
      attachment_ids: string[];
      expr_date?: number | null;
   } | null; // Represents deleted tags
};

export type WageOverride = {
   [wageOverrideId: string]: number | null;
};

export type Attachment = {
   id: string;
   name: string;
   created_at: number;
   mimetype: string;
};

export type FileUploadProps = {
   attachments: any[];
   setAttachments: (attachments: any[]) => void;
   deletedAttachmentIds: string[];
   setDeletedAttachmentIds: (deletedIds: string[]) => void;
   loading: boolean;
   setLoading: (loading: boolean) => void;
   disabled?: boolean;
};

type QRCodeCommonProps = {
   companyQrId: string | undefined | null;
   entityQrId: string | undefined | null;
   entityTitle?: string;
   entitySubtitle?: string | null;
   entityAbbreviation: EntityAbbreviation;
};

export type QRCodeDownloadURLProps = QRCodeCommonProps & {
   selectedSize: number;
   includeInfo: boolean;
};

export enum EntityAbbreviation {
   PROJECT = "pr",
   PERSON = "pe",
}

export type QRCodeCardProps = QRCodeCommonProps & {
   imageDetailsHelpText: string;
};

export type JobTitle = {
   id: string;
   label: string;
};

export type ResourceName = {
   id: string;
   label: string;
   role_id?: string;
   archived?: boolean;
};

export type StreamResponseConversion = Array<{ id: string; label: string }>;

type SortOption = {
   id: string;
   label: string;
   value: Order;
};

export type NotesToolbarProps = {
   sortOptions: SortOption[];
   selectedSortOption: SortOption;
   onSortOptionSelect?: (option: SortOption) => void;
   onAddNoteClick?: () => void;
   isAddNoteDisabled?: boolean;
   canEditNotes: boolean;
};

export type FormattedOption = {
   id: string;
   label: string;
};

export type EquipmentDropDownOption = {
   id: string;
   name: string;
};

export type RawOption = {
   id?: string;
   value?: string | null;
   name: string;
};

export type CreateAssignmentTearsheetProps = {
   onClose: () => void;
   showProjectDetail?: (projectId: string) => void;
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   assignmentId?: string;
   callback?: (data: any) => void;
};

export type CreateRequestTearsheetProps = {
   onClose: () => void;
   requestId?: string;
   projectId?: string;
   categoryId?: string;
   subcategoryId?: string;
   callback?: (data: any) => void;
};

export type FormSelectOption<T> = {
   id: T;
   label: string;
};

export const FieldType = {
   PILL_SELECT: "pill-select",
   RADIO: "radio-button",
} as const;

export type ExtendedFieldType = CustomFieldType | (typeof FieldType)[keyof typeof FieldType] | null;

export type DetailsCardHeaderProps = {
   titleKey: string;
   helpTextKey: string;
   buttonTextKey: string;
   openAddItemModal?: () => void;
   btnDisabled: boolean;
   permission?: boolean;
   icon?: React.ReactNode | null;
};

export type CommonStyleProps = {
   selectedId: string | null;
   currentId: string;
};

export type SensitiveFieldProps = {
   canViewSensitiveFields: boolean;
   sensitiveFields: string[];
   canEditSensitiveFields: boolean;
};

export type ReportResponse = {
   data: {
      id: string;
      name: string;
   };
};

export type CommonReportParams = {
   formValues: ExportFormValues;
   groupId: string;
   I18n: any;
   customFields?: CustomField[] | null;
   configKey: string;
   isLastNameFirst: boolean;
   search: string | null;
};

export enum ReportType {
   ProjectList = "project_list",
   PeopleList = "people_list",
}

export enum GenerateReportType {
   ProjectList = "project-list-report",
   PeopleList = "people-list-report",
}

export type PersonName = { first?: string; last?: string } | null;

export enum GroupByColumn {
   JobTitle = "job_title",
}

export enum TearsheetTabs {
   OVERVIEW = "Overview",
   DETAILS = "Details",
   NOTES = "Notes",
   ACTIVITY = "Activity",
   TIME_OFF = "Time Off",
}

export type CompanyEntityOptionsResponse = {
   group_options?: Array<ValueSet<string, null>>;
   user_options?: Array<ValueSet<string, null>>;
   people_options?: Array<ValueSet<string, null>>;
   status_options?: Array<ValueSet<string, { abbreviation: string }>>;
   tag_options?: Array<ValueSet<string, null>>;
   position_options?: Array<ValueSet<string, null>>;
   project_options?: Array<ValueSet<string, { additional_searchables: string[] }>>;
   active_project_options?: Array<ValueSet<string, { additional_searchables: string[] }>>;
   pending_project_options?: Array<ValueSet<string, { additional_searchables: string[] }>>;
   inactive_project_options?: Array<ValueSet<string, { additional_searchables: string[] }>>;
   grouped_cost_code_options?: Record<string, unknown>;
   grouped_label_options?: Record<string, unknown>;
   categorized_tag_options?: any;
   assignments_custom_field_filters?: any;
   people_custom_field_filters?: any;
   projects_custom_field_filters?: any;
   requests_custom_field_filters?: any;
   people_custom_fields?: any;
   projects_custom_fields?: any;
};

export const EntitiesValues = [
   "positions",
   "projects",
   "people",
   "categorized-tags",
   "people_custom_field_filters",
   "projects_custom_field_filters",
];

export type DataUploadPropTypes = {
   uploadFiles: (dataUpload: FormData) => Promise<Record<string, any> | undefined>;
};

export enum EntityTypes {
   ASSIGNMENT = "Assignment",
   PROJECT = "Project",
   PEOPLE = "People",
   REQUEST = "Request",
}
