import type { IntegratedField, ProjectListServerFilter } from "@/react/prop-types";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { Filter } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { PeopleListServerFilter } from "../people-list/people-list-prop-types";
import type { FilterClassifier } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { convertDateFilters } from "@/react/shared/custom-field-utils";
import type { DataTableConfig, IServerFilter } from "@procore/data-table";
import type { CustomField } from "@laborchart-modules/common";
import { StatusStore } from "@/stores/status-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { PersonStatus } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { PersonStore } from "@/stores/person-store.core";

const GROUP_LIMIT = 3;

// Sort Order Maps
export const sortOrderMap: { [key: string]: Order } = {
   asc: Order.ASCENDING,
   desc: Order.DESCENDING,
};

export function isFieldLocked(integratedField: IntegratedField[], field: string) {
   return integratedField.some(
      (integratedField: IntegratedField) =>
         integratedField.property === field && integratedField.locked,
   );
}

export function getFormattedGroupName(
   cellValue: Array<{ label: string }>,
   groupOptions: any,
   I18nObject: any,
) {
   // If the cellValue is more than GROUP_LIMIT, then show only GROUP_LIMIT and add a +more count
   if (cellValue.length > GROUP_LIMIT && cellValue.length !== groupOptions?.length) {
      const formattedValue = cellValue.slice(0, GROUP_LIMIT);
      const moreCount = cellValue.length - GROUP_LIMIT;
      formattedValue.push({
         label: I18nObject.t("views.company.workforce_planning.projects.group_count", {
            count: moreCount,
         }),
      });
      return formattedValue;
   }
   // If the cellValue is equal to the groupOptions length, then show "All Groups"
   if (cellValue.length === groupOptions?.length) {
      return [{ label: I18nObject.t("views.company.workforce_planning.projects.all_groups") }];
   }
   // If the cellValue is less than 3, then show all the values
   return cellValue.map((item) => item.label);
}

export function convertNestedCustomFilters(
   filter: ProjectListServerFilter | PeopleListServerFilter,
   filterMapper: { [key: string]: FilterFieldType },
): Filter[] {
   const customFilters: Filter[] = [];
   let valueSets: any[] = [];
   if (filter.fieldType === FilterFieldType.BOOL) {
      valueSets = filter.selected.map((selection: any) => ({
         negation: false,
         value: selection.value,
      }));
   } else {
      // Handling non-bool type here
      valueSets = filter.selected.map((selection: any) => ({
         negation: false,
         value: [selection] as string[],
      }));
   }
   customFilters.push({
      name: filter.fieldId!,
      property: "custom_fields",
      type: filterMapper[filter.fieldType!],
      value_sets: valueSets,
      custom_field_id: filter.fieldId,
   });
   return customFilters;
}

export const createDefaultColumnState = () => ({
   hidden: false,
   pinned: null,
   width: 140,
   sort: null,
   sortIndex: null,
   rowGroup: false,
   rowGroupIndex: null,
});

export const GENDER_OPTIONS = [
   {
      id: "male",
      label: "views.company.workforce_planning.male",
   } as const,
   {
      id: "female",
      label: "views.company.workforce_planning.female",
   } as const,
];

export interface ListServerFilter extends IServerFilter {
   fieldId?: string;
   fieldType?: string;
}

type MapperString = {
   [key: string]: string;
};

/**
 * Transform the filters from the data table to the format that the API expects
 * @param filters       List of filters to be converted
 * @param mappers       Object containing the mappers for the filter name, type and property, this is used
 *                      to map the filters to the correct fields
 * @param searchFields  Object containing the fields that are date, numeric, numeric types and nested types,
 *                      used in the conversion to specify what how data should be transformed
 * @returns             List of filters in the format that the API expects
 */
export function convertDataTableFilters(
   filters: ListServerFilter[],
   mappers: {
      nameMapper: MapperString;
      typeMapper: { [key: string]: FilterFieldType };
      propertyMapper?: MapperString;
   } = {
      nameMapper: {},
      typeMapper: {},
      propertyMapper: {},
   },
   searchFields: {
      date: string[];
      numeric: string[];
      numericTypes: string[];
      nestedTypes: string[];
   } = {
      date: [],
      numeric: [],
      numericTypes: [],
      nestedTypes: [],
   },
): Filter[] {
   const coreApiFilters: Filter[] = [];

   //filter.fieldId and filter.fieldType are added to identify the custom_field
   for (const filter of filters) {
      if (checkFilterIsDate(filter, searchFields.date)) {
         const dateFilters = convertDateFilters(filter, mappers.nameMapper, mappers.typeMapper);
         coreApiFilters.push(...dateFilters);
         continue;
      }

      if (checkFilterIsNumeric(filter, searchFields.numeric, searchFields.numericTypes)) {
         const numericFilters = convertNumericFilters(
            filter,
            mappers.typeMapper,
            mappers.nameMapper,
         );
         coreApiFilters.push(...numericFilters);
         continue;
      }

      if (filter.fieldId && searchFields.nestedTypes.includes(filter.fieldType!)) {
         const nestedFilters = convertNestedCustomFilters(filter, mappers.typeMapper);
         coreApiFilters.push(...nestedFilters);
         continue;
      }

      // Convert project roles filters
      if (checkFilterIsRoles(filter)) {
         if (Array.isArray(filter.value)) {
            filter.value.forEach((valueItem) => {
               const maybeExistingFilter = coreApiFilters.find(
                  (x: Filter) => x.property === "project_roles",
               );
               const personId =
                  typeof valueItem === "string" ? valueItem : (valueItem as any).value;

               let coreApiRolesFilter;

               if (maybeExistingFilter) {
                  coreApiRolesFilter = maybeExistingFilter; // Reference the existing filter
               } else {
                  coreApiRolesFilter = {
                     name: "Project Roles",
                     type: FilterFieldType.SELECT,
                     property: "project_roles",
                     value_sets: [] as any[],
                  };
               }
               coreApiRolesFilter.value_sets.push({
                  negation: false,
                  value: [filter.field.split("_")[1], personId],
               });

               // Push the coreApiRolesFilter to coreApiFilters only once after processing the valueItem
               if (!coreApiFilters.includes(coreApiRolesFilter)) {
                  coreApiFilters.push(coreApiRolesFilter);
               }
            });
         }

         continue;
      }

      //default custom filter method for field type text, select
      if (filter.fieldId) {
         const customFilters = convertCustomFilters(filter, mappers.typeMapper);
         coreApiFilters.push(...customFilters);
         continue;
      }
      // if none of the conditions met then default creation of filter
      coreApiFilters.push({
         name: mappers.nameMapper[filter.field],
         property: mappers.propertyMapper?.[filter.field] ?? filter.field,
         type: mappers.typeMapper[filter.field],
         value_sets:
            mappers.typeMapper[filter.field] !== FilterFieldType.MULTI_SELECT
               ? filter.selected.map((x: any) => ({ negation: false, value: x.value ?? x.id }))
               : filter.selected.map((x: any) => ({
                    negation: false,
                    value: [x.value ?? x.id] as string[],
                 })),
      });
   }

   return coreApiFilters;
}

export function checkFilterIsRoles(filter: ListServerFilter): boolean {
   const positionsRegex =
      /^positions_[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;

   return positionsRegex.test(filter.field);
}

const checkFilterIsDate = (filter: ListServerFilter, DATE_FIELDS: string[]) =>
   DATE_FIELDS.includes(filter.field) || filter.fieldType === FilterFieldType.DATE;

const checkFilterIsNumeric = (
   filter: ListServerFilter,
   NUMERIC_FIELDS: string[],
   NUMERIC_FIELDS_TYPE: string[],
) =>
   NUMERIC_FIELDS.includes(filter.field) ||
   (filter.fieldId && NUMERIC_FIELDS_TYPE.includes(filter.fieldType!));

function convertNumericFilters(
   filter: ProjectListServerFilter,
   filterTypeMaps: { [key: string]: FilterFieldType },
   filterNameMaps: { [key: string]: string },
): Filter[] {
   const numericFilters: Filter[] = [];
   filter.selected.forEach(
      (x: { value: number; classifier: { label: string; value: FilterClassifier } }) => {
         const filterObject: Filter = {
            name: filter.fieldId ? filter.fieldId : filterNameMaps[filter.field],
            property: filter.fieldId ? "custom_fields" : filter.field,
            type: filterTypeMaps[filter.fieldType ? filter.fieldType : filter.field],
            value_sets: [
               {
                  negation: false,
                  value: x.value,
                  classifier: x.classifier.value,
               },
            ],
         };
         if (filter.fieldId) {
            filterObject.custom_field_id = filter.fieldId;
         }
         numericFilters.push(filterObject);
      },
   );
   return numericFilters;
}

function convertCustomFilters(
   filter: ProjectListServerFilter,
   filterTypeMaps: { [key: string]: FilterFieldType },
): Filter[] {
   const customFilters: Filter[] = [];
   customFilters.push({
      name: filter.fieldId ?? "custom_field",
      property: "custom_fields",
      type: filterTypeMaps[filter.fieldType!],
      value_sets: filter.selected.map((x: { label: string; value: boolean }) => ({
         negation: false,
         value: x.value ?? x,
      })),
      custom_field_id: filter.fieldId,
   });
   return customFilters;
}

export function addCustomFieldIDToStoredConfig(
   config: DataTableConfig,
   customFields?: CustomField[] | null,
): DataTableConfig {
   const filters = config.serverFilters?.map((filter: IServerFilter & { fieldId?: string }) => {
      const maybeCustomField = customFields?.find(
         (x: CustomField) => x.integration_name === filter.field,
      );

      if (maybeCustomField) {
         filter.fieldId = maybeCustomField.id;
      }
      return filter;
   });

   return {
      ...config,
      serverFilters: filters,
   };
}

export const streamViewableStatuses = async () => {
   const statuses = [{ id: "no_status", label: "No Status" }]; // keeping a no status option as seen in the legacy flow
   const stream = await StatusStore.findStatusesStream({}).stream;
   for await (const { id, name } of stream) {
      statuses.push({ id: id, label: name });
   }
   return statuses;
};

export const streamJobTitles = async (groupId: string) => {
   const params: { group_id?: string } = {};
   if (groupId != "my-groups") {
      params.group_id = groupId;
   }
   const stream = await PositionStore.findPositionsStream(params).stream;
   const job_titles = [];
   for await (const item of stream) {
      job_titles.push({
         id: item.id,
         label: item.name,
      });
   }
   return job_titles;
};

export const streamPeople = async (groupId: string) => {
   const params: {
      group_id?: string;
      status: PersonStatus;
   } = {
      status: PersonStatus.ACTIVE,
   };
   if (groupId != "my-groups") {
      params.group_id = groupId;
   }
   const stream = await PersonStore.findPeopleStream(params).stream;
   const people = [];
   for await (const item of stream) {
      people.push({
         id: item.id,
         label: `${item.name.first} ${item.name.last}`,
      });
   }
   return people;
};

//Utility function to create and populate role attributes
export const createRoleAttrs = (
   roles: Array<{
      job_title_id: string;
      person_id: string;
      assignee_name: string;
   }>,
) => {
   const roleAttrs: Record<string, any> = {};

   roles.forEach((role) => {
      const key = `positions_${role.job_title_id}`;
      const name = role.assignee_name ?? "";

      const roleData = {
         label: name,
         personId: role.person_id,
      };
      if (roleAttrs[key]) {
         roleAttrs[key].push(roleData);
      } else {
         roleAttrs[key] = [roleData];
      }
   });

   return roleAttrs;
};
